import { Container } from 'react-bootstrap';
import { GrDocumentPdf } from 'react-icons/gr';
import brocker from '../../Images/brocker.png';
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Opacity } from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getAPI } from '../../Api/ApiRequest';
import { apiEndpoints } from '../../Api/ApiEndpoint';
import InvoiceModal from './InvoiceModal';

const PendingInvoice = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [invoice, setInvoice] = useState('');
  const [claimID, setClaimID] = useState();

  const builderID = localStorage.getItem('builderId');
  console.log('builderID:', builderID);
  const placeholderData = {
    image: '/assets/property-screen-img1.png',
  };

  useEffect(() => {
    const getAddedProperties = async () => {
      setLoading(true);
      const response = await getAPI(
        `${apiEndpoints?.getAllInvoiceForBuilder}${builderID}&status=pending`
      );
      setData(response?.data[0]?.invoices);
      setClaimID(response?.data[0]?.claimId);
      setLoading(false);
    };
    getAddedProperties();
  }, []);

  const placeholderArray = Array.from(
    { length: 8 },
    (_, index) => ({
      ...placeholderData,
      id: index,
    })
  );

  const handleButtonClick = (id) => {
    setViewModal(true);
    setInvoice(id);
  };

  return (
    <Container>
      {data && data.length > 0 ? (
        <Grid container spacing={3}>
          {data?.map((item) => (
            <Grid item md={6} xs={12} key={item?.id}>
              <Box
                p={3}
                sx={{
                  boxShadow:
                    '0px 5.09px 22.26px 0px rgba(0,0,0,0.12)',
                  borderRadius: '1.2rem',
                }}
              >
                <Link
                  to={`/builder/home-dashboard/promoted/claim/${claimID}`}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                      <Box
                        sx={{
                          width: 'auto',
                          height: '7rem',
                          borderRadius: '10px',
                          objectFit: 'contain',
                        }}
                      >
                        <img
                          src={
                            item?.claimId?.propertyId?.images[0]
                          }
                          alt={`img${item?.id}`}
                          style={{
                            height: '100%',
                            width: '100%',
                            borderRadius: '10px',
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item md={9} xs={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          fontSize={{
                            md: '1.2rem',
                            xs: '.9rem',
                          }}
                          fontWeight={{ md: '600', xs: 400 }}
                        >
                          {item?.claimId?.propertyId?.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '.9rem',
                            color: '#8B9199',
                          }}
                        >
                          {new Date(
                            item?.claimId?.propertyId?.createdAt
                          ).toLocaleDateString('en-IN', {
                            timeZone: 'Asia/Kolkata',
                          })}
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: '.9rem',
                          color: '#8B9199',
                          textDecoration: 'underline',
                        }}
                      >
                        {item?.claimId?.propertyId?.location}
                      </Typography>
                      <Grid
                        container
                        mt={2}
                        justifyContent={'space-between'}
                      >
                        <Grid item xs={4}>
                          {' '}
                          <Typography
                            sx={{
                              fontSize: {
                                md: '.9rem',
                                xs: '.7rem',
                              },
                              fontWeight: 300,
                              color: '#8B9199',
                            }}
                          >
                            Broker Name
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '1.1rem',
                              fontWeight: 'bold',
                            }}
                          >
                            {item?.brokerId?.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            sx={{
                              fontSize: {
                                md: '.9rem',
                                xs: '.7rem',
                              },
                              fontWeight: 300,
                              color: '#8B9199',
                            }}
                          >
                            Unit Number
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '1rem',
                              fontWeight: 'bold',
                            }}
                          >
                            {item?.claimId?.unitNumber}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          {' '}
                          <Typography
                            sx={{
                              fontSize: {
                                md: '.9rem',
                                xs: '.7rem',
                              },
                              fontWeight: 300,
                              color: '#8B9199',
                            }}
                          >
                            Claimed Amount
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '1rem',
                              fontWeight: 'bold',
                            }}
                          >
                            ₹ {item?.claimId?.amount}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Link>
                <Box mt={3}>
                  <TableContainer
                    component={Paper}
                    sx={{ px: 2 }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: '600' }}>
                            Invoice ID
                          </TableCell>
                          <TableCell sx={{ fontWeight: '600' }}>
                            Invoice amount
                          </TableCell>
                          <TableCell sx={{ fontWeight: '600' }}>
                            Status
                          </TableCell>
                          <TableCell sx={{ fontWeight: '600' }}>
                            View Invoice
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {item?.claimId?.boughtPropertyId?.claims?.map(
                          (claim) =>
                            claim.invoiceIds.map((invoice) => (
                              <TableRow key={invoice._id}>
                                <TableCell
                                  sx={{ color: 'gray' }}
                                >
                                  {invoice._id}
                                </TableCell>
                                <TableCell
                                  sx={{ color: 'gray' }}
                                >
                                  {invoice.invoiceAmount}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom:
                                      '1px solid #E9E9E9',
                                  }}
                                >
                                  <Button
                                    variant='outlined'
                                    sx={{
                                      color: 'black',
                                      bgcolor: '#FFE1E1',
                                      textTransform: 'none',
                                      borderColor: '#FC5C67',
                                      borderRadius: '2rem',
                                    }}
                                  >
                                    {invoice.status}
                                  </Button>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom:
                                      '1px solid #E9E9E9',
                                  }}
                                >
                                  <Box
                                    textAlign={'center'}
                                    sx={{ width: '80%' }}
                                    component={'button'}
                                    onClick={() =>
                                      handleButtonClick(
                                        invoice?._id
                                      )
                                    }
                                  >
                                    {' '}
                                    <img
                                      src='/assets/pdfIcon.svg'
                                      alt='img'
                                      style={{
                                        height: '1.8rem',
                                      }}
                                    />
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <InvoiceModal
                    isOpen={viewModal}
                    setIsOpen={setViewModal}
                    invoice={invoice}
                    claim={claimID}
                  />
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box>
          <Typography
            my={6}
            textAlign={'center'}
            color={'var(--primary-color-darker)'}
            fontWeight={'bold'}
          >
            No pending invoice available
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default PendingInvoice;
