import { useEffect, useState } from 'react';

import { Box, Container, Grid } from '@mui/material';
import { Button, Card, Col, Row } from 'react-bootstrap';
import {  Link, useNavigate, useParams } from 'react-router-dom';
import { searchProperty } from '../../../API/action';
import { useDispatch, useSelector } from 'react-redux';
import DashboardHeader from '../header/DashboardHeader';
import SearchFilterBox from '../search-filter/SearchFilter';
import SoldOut from '../dashboard-soldout-properties/SoldOut';
import Footer from '../Footer/Footer';
import { RiVipCrownFill } from 'react-icons/ri';
import build from '../../Images/build.png';
import { BsPencilFill } from 'react-icons/bs';



const SearchPropertyBuilder = () => {
  const dispatch = useDispatch();
  const {search} = useParams();
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const builderId = useSelector((state) => state?.auth?.builderId);
  

  const handleCardClick = (productId) => {
    navigate(`/broker/property-details/${productId}`);
  };

  const formatPrice = (price) => {
    if (price >= 10000000) {
      return `₹ ${(price / 10000000).toFixed(2)} Cr`;
    } else if (price >= 100000) {
      return `₹ ${(price / 100000).toFixed(2)} L`;
    } else {
      return `₹ ${price}`;
    }
  };

  const [properties, setProperties] = useState([]);


  useEffect(() => {
    dispatch(searchProperty(search,{}, (data) => {
      setProperties(data.data.data);
    }, (err) => {
      console.log(err);
    }));
  }, [search]);

  console.log(search)

  return (
    <>
    <DashboardHeader builderID={builderId} />
     

      <Container className='pt-2 pb-4 dashboard__wrapper'>
       

        <SearchFilterBox
        //   isRoleScreen
        //   searchTerm={searchTerm}
        //   setSearchTerm={setSearchTerm}
        />

<Box
        mt={4}
        mb={4}
      >
        <h3 className='col-8 heading'>Search Property</h3> 
      </Box>

    <Grid container spacing={4}>
    {properties?.map((itm) => (
        <Grid item md={4} xs={12}  
           id={itm?._id}
           key={itm?._id}>
        <Link to={`/builder/home-dashboard/description/${itm?._id}`}>
             <Box
               sx={{
                 position: 'relative',
                 height: '11rem',
               }}
             >
               <Card.Img
                 className='w-100 bl-prop-img '
                 variant='top'
                 src={itm?.images[0]}
                 style={{
                   height: '11rem',
                   borderRadius: '1rem',
                 }}
               />
               <Box
                 sx={{
                   bgcolor: '#EFEFEF',
                   position: 'absolute',
                   padding: '3px 5px',
                   top: 12,
                   right: 0,
                 }}
               >
                 Listing Date {itm?.createdAt?.substring(0, 10)}
               </Box>
               {/* <Box
                 px={{ md: 1, xs: 4 }}
                 py={{ md: 1, xs: 1 }}
                 sx={{
                   backgroundColor: '#FE5E6A',
                   color: '#fff',
   
                   bottom: 50,
                   borderRadius: '.3rem',
                   width: { md: '55%', xs: '100%' },
                 }}
               >
                 <Grid container spacing={1}>
                   <Grid item xs={6}>
                     --
                   </Grid>
                   <Grid item xs={6}>
                     {itm?.visitCount}/{itm?.noOfVisits} visits
                   </Grid>
                 </Grid>
               </Box> */}
             </Box>
           </Link>
           <Card.Body>
             <Card.Text>
               <Row className='mt-2'>
                 <Col md={10} sm={10}>
                   <div>
                     {' '}
                     <Link
                       className='recent-heading'
                       to={`/builder/home-dashboard/description/${itm?._id}`}
                     >
                       {itm?.name}
                     </Link>
                   </div>
                   <p style={{ opacity: 0.5 }}>{itm?.location}</p>
                 </Col>
                 <Col md={2} sm={2}>
                   <RiVipCrownFill
                     style={{
                       padding: '5px',
                       borderRadius: '50%',
                       background: '#ffd600',
                       color: '#fff',
                       width: '1.85em',
                       height: '1.85em',
                     }}
                   />
                 </Col>
               </Row>
               <Row className='mt-2 mb-2'>
                 {itm?.unitTypeNew?.slice(0, 4).map((item) => (
                   <Col md={3} sm={3} className='text-center'>
                     <img src={build} alt='build' className='build-icon' />
                     <p style={{ opacity: 0.5 }}>{item}</p>
                   </Col>
                 ))}
               </Row>
   
               <Row className='align-items-center mb-3'>
                 <Col
                   md={6}
                   style={{
                     color: '#000000',
                     fontSize: '1.35em',
                     fontWeight: 'bold',
                   }}
                 >
                   {formatPrice(itm?.minPrice)}-{formatPrice(itm?.maxPrice)}
                 </Col>
                 <Col md={6} style={{ color: '#7D7F88', fontSize: '0.8em' }}>
                   {itm?.builderDiscount}
                 </Col>
               </Row>
               {/* <div className='d-flex justify-content-end gap-2 mt-2'>
                 <Button
                   variant='transparent'
                   type='button'
                   className='rounded-pill px-4 py-2 border border-primary'
                   style={{ color: '#278FD9', fontSize: '14px' }}
                   as={Link}
                   to={`/builder/home-dashboard/visit/${itm?.builderId?._id}`}
                 >
                   {itm?.noOfVisits} Visits
                 </Button>
                 <Button
                   variant='transparent'
                   type='button'
                   className='primary rounded-pill px-4 py-2 border border-primary'
                   as={Link}
                   to={`/builder/home-dashboard/propertyedit/${itm?._id}`}
                 >
                   <BsPencilFill
                     style={{
                       width: '0.9em',
                       color: '#fff',
                       background: '#278fd9',
                       padding: '2px',
                       borderRadius: '50%',
                       marginRight: '4px',
                       marginBottom: '5px',
                     }}
                   />
                   Edit
                 </Button>
               </div> */}
             </Card.Text>
           </Card.Body>
        </Grid>
         ))}
    </Grid>
          {/* {properties?.map((itm) => (
           <Col
           md={3}
           className='card bl-card mb-4 shadow-sm rounded-4 col-md-3 p-0 border-0 w-100'
           style={{ width: '21.75rem' }}
           id={itm?._id}
           key={itm?._id}
         >
           <Link to={`/builder/home-dashboard/description/${itm?._id}`}>
             <Box
               sx={{
                 position: 'relative',
                 height: '11rem',
               }}
             >
               <Card.Img
                 className='w-100 bl-prop-img '
                 variant='top'
                 src={itm?.images[0]}
                 style={{
                   height: '11rem',
                   borderRadius: '1rem',
                 }}
               />
               <Box
                 sx={{
                   bgcolor: '#EFEFEF',
                   position: 'absolute',
                   padding: '3px 5px',
                   top: 12,
                   right: 0,
                 }}
               >
                 Listing Date {itm?.createdAt?.substring(0, 10)}
               </Box>
               <Box
                 px={{ md: 1, xs: 4 }}
                 py={{ md: 1, xs: 1 }}
                 sx={{
                   backgroundColor: '#FE5E6A',
                   color: '#fff',
   
                   bottom: 50,
                   borderRadius: '.3rem',
                   width: { md: '55%', xs: '100%' },
                 }}
               >
                 <Grid container spacing={1}>
                   <Grid item xs={6}>
                     --
                   </Grid>
                   <Grid item xs={6}>
                     {itm?.visitCount}/{itm?.noOfVisits} visits
                   </Grid>
                 </Grid>
               </Box>
             </Box>
           </Link>
           <Card.Body>
             <Card.Text>
               <Row>
                 <Col md={10} sm={10}>
                   <div>
                     {' '}
                     <Link
                       className='recent-heading'
                       to={`/builder/home-dashboard/description/${itm?.propertyId?._id}`}
                     >
                       {itm?.name}
                     </Link>
                   </div>
                   <p style={{ opacity: 0.5 }}>{itm?.location}</p>
                 </Col>
                 <Col md={2} sm={2}>
                   <RiVipCrownFill
                     style={{
                       padding: '5px',
                       borderRadius: '50%',
                       background: '#ffd600',
                       color: '#fff',
                       width: '1.85em',
                       height: '1.85em',
                     }}
                   />
                 </Col>
               </Row>
               <Row className='mt-2 mb-2'>
                 {itm?.unitTypeNew?.slice(0, 4).map((item) => (
                   <Col md={3} sm={3} className='text-center'>
                     <img src={build} alt='build' className='build-icon' />
                     <p style={{ opacity: 0.5 }}>{item}</p>
                   </Col>
                 ))}
               </Row>
   
               <Row className='align-items-center mb-3'>
                 <Col
                   md={6}
                   style={{
                     color: '#000000',
                     fontSize: '1.35em',
                     fontWeight: 'bold',
                   }}
                 >
                   {formatPrice(itm?.minPrice)}-{formatPrice(itm?.maxPrice)}
                 </Col>
                 <Col md={6} style={{ color: '#7D7F88', fontSize: '0.8em' }}>
                   {itm?.builderDiscount}
                 </Col>
               </Row>
               <div className='d-flex justify-content-end gap-2 mt-2'>
                 <Button
                   variant='transparent'
                   type='button'
                   className='rounded-pill px-4 py-2 border border-primary'
                   style={{ color: '#278FD9', fontSize: '14px' }}
                   as={Link}
                   to={`/builder/home-dashboard/visit/${itm?.builderId?._id}`}
                 >
                   {itm?.noOfVisits} Visits
                 </Button>
                 <Button
                   variant='transparent'
                   type='button'
                   className='primary rounded-pill px-4 py-2 border border-primary'
                   as={Link}
                   to={`/builder/home-dashboard/propertyedit/${itm?._id}`}
                 >
                   <BsPencilFill
                     style={{
                       width: '0.9em',
                       color: '#fff',
                       background: '#278fd9',
                       padding: '2px',
                       borderRadius: '50%',
                       marginRight: '4px',
                       marginBottom: '5px',
                     }}
                   />
                   Edit
                 </Button>
               </div>
             </Card.Text>
           </Card.Body>
         </Col>
          ))} */}
     
    
</Container>
      <Footer />

      {/* <div className='nes__dashboard__brok__app d-flex flex-column min-vh-100'>
        <Header isOpen={isOpen} setIsOpen={setIsOpen}  />
        <Container fluid='lg'>
        <SmallCardSection/>
      <section className='mt-3'>
        <SearchFilter addBtn
         />
       
         <section className='nes__product__section'>
      <Row className='gy-3'>
        <Col
          sm={12}
          className='d-flex justify-content-between align-items-center mb-3'
        >
          <h2 className='nes__product__section__title'>Search Properties</h2>
        
        </Col>

       
        {properties.length > 0 ? (
          properties.map((property) => (
            <Col key={property._id} xs={12} sm={12} md={4} className='mb-4'>
              <ProductCard
                {...property}
                view='broker'
                isOpen={isOpen}
                pagination
                onClick={() => handleCardClick(property._id)}
              />
            </Col>
          ))
        ) : (
          <p
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '24px',
            }}
          >
            No property available
          </p>
        )}
      </Row>
    </section>


      </section>
         </Container>
        <Footer />
      </div> */}
    </>
  );
};

export default SearchPropertyBuilder;
