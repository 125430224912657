export const apiEndpoints = {
  verifyOtp: '/v1/builder/verifyOtp',
  sendOtp: '/v1/builder/sendOtp',
  resendOtp: '/v1/builder/resendOtp',
  addShareYourIntent: '/v1/builder/addShareYourIntent',
  getPropertyAnalytics: '/v1/builder/getPropertyAnalytics?id=',
  getPropertiesAnalyticsForIndividualProperty:
    '/v1/property/getPropertiesAnalyticsForIndividualProperty?builderId=641069056532f2569479fc9d&propertyId=640ee6a8cbe2f98daaa8813f',
  getPropertyById: '/v1/property/getPropertyById?id=',
  getBuilderById: '/v1/builder/getBuilderById?id=',
  getAllSubscriptionOrder:
    '/v1/subscriptionOrder/getAllSubscriptionOrder',
  getAllSubscription: '/v1/subscription/getAllSubscription',
  getPendingInvoice:
    '/v1/builder/getPendingInvoice?id=641069056532f2569479fc9d', //CRASH
  getVisitAnalytics: '/v1/visit/getVisitAnalytics?propertyId=',
  // getVisitAnalytics:
  //   '/v1/visit/getVisitAnalytics?builderId=641069056532f2569479fc9d',
  getAllVisit: 'v1/visit/getAllVisit?propertyId=',
  getAllVisitBuilder: 'v1/visit/getAllVisit?builderId=',
  getAllproperty: '/v1/property/getAllproperty',

  updateRequestProperty:
    '/v1/requestProperty/updateRequestProperty',
  getAllPayment: '/v1/payment/getAllPayment',
  getAllBoughtProperty:
    '/v1/boughtProperty/getAllBoughtProperty',
  getAllFaqAndSupport:
    '/v1/faqAndSupport/getAllfaqandsupport?for=builder&type=support',
  getAllFaqAndSupport1:
    '/v1/faqAndSupport/getAllfaqandsupport?for=builder&type=faq',
  getAllRaiseQuery: '/v1/raiseQuery/getAllRaiseQuery',
  getAllInvoiceForBuilder2:
    '/v1/invoice/getAllInvoiceForBuilder?status=pending',
  getAllInvoiceForBuilder1:
    '/v1/invoice/getAllInvoiceForBuilder?status=settled',
  getClaimById: '/v1/claim/getClaimById?id=',
  addPayment: '/v1/payment/addPayment',
  // getAllBuilder: "/v1/builder/getAllBuilder",
  getAllBanner: '/v1/banner/getAllBanner',
  getAllClaim: '/v1/claim/getAllClaim?id=',
  addRaiseDispute: '/v1/raiseDispute/addRaiseDispute',
  deleteRoles: '/v1/roles/deleteRoles?id=',
  getAllReason: '/v1/reason/getAllReason',
  addRole: '/v1/roles/addRoles',
  getAllRolesProperty: '/v1/roles/getAllRoles?builderId=',
  getAllRolesFinance: '/v1/roles/getAllRoles?builderId=',
  updateRoles: '/v1/roles/updateRoles',
  addRequestNewProperty:
    '/v1/requestNewProperty/addRequestNewProperty',
  addContactus: '/v1/contactus/addContactus',
  brokerRegister: '/v1/broker/registerName',
  brokerResendOtp: '/v1/broker/resendOtp',
  getBLAllVisits: `v1/visit/getAllVisit?&pageNo=1&pageSize=10&propertyId=`,
  getAllBank: `v1/bank/getAllBank`,
  getAllAmenities: `v1/amenities/getAllAmenities`,
  getAllLocationAdvantage: `v1/locationAdvantage/getAllLocationAdvantage`,
  getAllSoldOut: `v1/boughtProperty/getAllBoughtProperty?builderId=`,
  getAllResentProperty: `v1/property/getAllproperty?builderId=`,
  getAllInvoiceForBuilder: `v1/invoice/getAllInvoiceForBuilder?builderId=`,
  getAllInvoiceForBuilder1: `v1/invoice/getAllInvoiceForBuilder`,
  GET_INVOICE_PDF: 'v1/claim/getClaimInvoicePdfView?id=',
  GET_PASTPAST_PROPERTY:
    'v1/builderPastProperty/getbuilderPastProperty?builderId=',
  GET_FAQ: 'v1/faqAndSupport/getAllfaqandsupport',
  GET_ALL_LOCATION: 'v1/globals/search-location',
  GET_ALL_NOTIFICATION:
    'v1/notification/getAllNotification?builderId=',
  GET_ALL_BUILDER_QUERY: `v1/raiseQuery/getRaiseQueryById?id=`,
};
