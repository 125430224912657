import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import { useDispatch } from "react-redux";
import { openBookAVisit } from "../../../../store/ModalsSlice";
import { Card, Button, Row, Col } from "react-bootstrap";
import { MdShare } from "react-icons/md";
import { RiBuilding2Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import Ribbon from "../ribbon/Ribbon";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/navigation";
import "./ProductCard.css";
import formatNumber from "../../../../helper/common";
import { useEffect, useState } from "react";
import { BookAVisitModal } from "../../..";
import ShareBrochureModal from "../share-brochure-modal/ShareBrochureModal";

const ProductCard = ({
  _id,
  images,
  name,
  builderId,
  location,
  unitType,
  minPrice,
  maxPrice,
  discountDescription,
  builderDiscount,
  brokerageValue,
  brokerageType,
  view,
  navigation,
  pagination,
  isBestSelling,
  constructionStatus,
  isPromoted,
  floorPlanAndPricing,
  propertyType,
  propertyLiner,
  brochureUrl,
  propertyTypeSubCategory,
  isOpen,
}) => {
  const [unitTypes, setUnitTypes] = useState([]);
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(openBookAVisit());
  };
  useEffect(() => {
    const uniqueUnitTypes = new Set(
      floorPlanAndPricing.map((item) => item.unitType[0])
    );

    // If you need the unique unit types as an array, you can convert the Set back to an array
    const uniqueUnitTypesArray = [...uniqueUnitTypes];
    setUnitTypes(uniqueUnitTypesArray);
  }, []);

  const [showBookVisit, setShowBookVisit] = useState(false);
  const [showBrochureModal, setShowBrochureModal] = useState(false);

  const onHideBookVisitModal = () => {
    setShowBookVisit(false);
  };
  const onHideBrochureModal = () => {
    setShowBrochureModal(false);
  };

  return (
    <>
      <Card
        bg="white"
        border="0"
        className="custom-card nes__product__card"
        style={{
          background: "#FFFFFF",
          boxShadow: "0px 3.71188px 16.2395px rgba(0, 0, 0, 0.07)",
          borderRadius: "14px",
          height: "100%",
        }}
      >
        <div className="position-relative">
          {/* swiper image code start */}
          <div className="nes__swiper__slider__new">
            <Swiper
              modules={[Navigation, Autoplay, Pagination]}
              slidesPerView={1}
              navigation={!!navigation}
              pagination={pagination ? { clickable: true } : false}
              centeredSlides={true}
              className="mt-0 mb-5 position-relative"
            >
              {/* {images
                ? images.map((imagesItem, index) => (
                    <SwiperSlide key={index}> */}
              <Link to={`/broker/property-details/${_id}`}>
                <Card.Img
                  variant="top"
                  src={images[0]}
                  alt="Product Image"
                  className="nes__product__card__image"
                />
              </Link>
              {/* </SwiperSlide>
                  ))
                : null} */}
              {view === "broker" && (
                <>
                  {isBestSelling && (
                    <Ribbon cssClass="ribbonStyle" text="Best Seller" />
                  )}
                  {brokerageValue && isOpen && (
                    <Ribbon
                      cssClass="ribbonStyle2"
                      text={`Upto ${brokerageValue}${
                        brokerageType === "Percentage" ? "%" : "₹"
                      } Brokerage`}
                    />
                  )}

                  <Button className="nes__product__card__btndark px-4">
                    {constructionStatus}
                  </Button>
                </>
              )}
              {view !== "broker" && (
                <Button className="nes__product__card__btndark px-4">
                  {constructionStatus}
                </Button>
              )}
            </Swiper>
          </div>
          {/* swiper image code end here */}
        </div>
        <Card.Body className="px-0">
          <div className="px-4">
            <Card.Title
              as={Link}
              to={`/broker/property-details/${_id}`}
              className="nes__product__card__title"
            >
              {name}
            </Card.Title>
            <Card.Subtitle className="mt-1 mb-2 text-muted nes__product__card__subtitle">
              {propertyLiner}
            </Card.Subtitle>
            <Row className="my-3 nes__product__card__icons gx-5">
              {unitTypes.slice(0, 4).map((item, index) => (
                <Col
                  key={index}
                  className="col-md-3 d-flex flex-column justify-content-center align-items-center"
                >
                  <img
                    src={
                      propertyType?.name == "Flats / Apartment"
                        ? "/assets/flat.svg"
                        : propertyType?.name == "Commercial"
                        ? "/assets/commercial.svg"
                        : propertyType?.name == "Land/Plots"
                        ? "/assets/plot.svg"
                        : propertyType?.name == "Farmhouse / Villa"
                        ? "/assets/house.svg"
                        : "/assets/flat.svg"
                    }
                  />
                  <span>{item}</span>
                </Col>
              ))}
            </Row>
          </div>
          {view === "broker" && isPromoted && isOpen && (
            <div className="d-flex justify-content-end align-items-center my-2">
              <Ribbon
                cssClass="ribbonStyle3 d-inline-flex align-items-center"
                // text={`Get ₹ ${visitAmount} per Visit`}
                text={`Get ₹ 500 per Visit`}
              />
            </div>
          )}

          <div className="px-4 nes__product__card__price d-flex flex-wrap align-items-center gap-2">
            <h4 className="d-inline-block font-weight-bold m-0">
              {unitType}
              {`${formatNumber(minPrice)} - ${formatNumber(maxPrice)}`}
            </h4>
          </div>
          <div className="d-inline-block text-muted m-0 px-4">
            {`${builderDiscount}`}
          </div>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-end border-0 bg-white pt-0">
          <Button
            className="btn-color-outline-primary rounded-pill px-4"
            onClick={() => setShowBookVisit(true)}
          >
            Book a visit
          </Button>
          <Button
            className="mx-2"
            onClick={() => setShowBrochureModal(true)}
            variant="transparent"
          >
            <MdShare size={30} />
          </Button>
        </Card.Footer>
      </Card>
      <BookAVisitModal
        show={showBookVisit}
        onHide={onHideBookVisitModal}
        proprtyName={name}
        maximumPrice={maxPrice}
        minimumPrice={minPrice}
        BuilderId={builderId._id}
        propertyId={_id}
      />
      <ShareBrochureModal
        show={showBrochureModal}
        onHide={onHideBrochureModal}
        propertyType={propertyType}
        brochureUrl={brochureUrl}
        location={location}
        proprtyName={name}
        id={_id}
      />
    </>
  );
};

export default ProductCard;
