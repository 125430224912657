import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Button,
  Image,
} from 'react-bootstrap';
import {
  FiUser,
  FiClock,
  FiHelpCircle,
  FiMenu,
} from 'react-icons/fi';
import { BiTransferAlt } from 'react-icons/bi';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { CgNotes } from 'react-icons/cg';
import './Profile.css';
import { RiArrowRightSLine, RiPencilFill } from 'react-icons/ri';
import { BsBoxArrowRight } from 'react-icons/bs';
import PersonalDetailsForm from './personal-details/PersonalDetailsForm';
import QueriesHistory from './queries-history/QueriesHistory';
import TransactionHistory from './profile-table/TransactionHistory';
import TermsConditions from './terms-and-conditions/TermsConditions';
import RaiseQuery from './raise-query/RaiseQuery';
import CardWrapper from './card-wrapper/CardWrapper';
import Support from './support/Support';
import DashboardHeader from '../header/DashboardHeader';
import Footer from '../Footer/Footer';
import PastProperty from './Past Property/PastProperty';
import { MdSupportAgent } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { getBuilderDetail } from '../../redux/https-requests/builderDetail-http';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../redux/authSlice';
import { setAuthToken } from '../../Api/api';
import { getBuilderByID } from '../../Api/Action';
import { apiEndpoints } from '../../Api/ApiEndpoint';
import { getAPI } from '../../Api/ApiRequest';
import { enqueueSnackbar } from 'notistack';
import {
  updatePersonalInfoForBuilder,
  uploadDocToS3,
} from '../../../API/action';
import { Drawer, IconButton } from '@mui/material';
// import { useHistory } from "react-router-dom";

const tabs = [
  {
    title: 'Personal Details',
    icon: <FiUser color='#000000' />,
    url: '/personal-details',
  },
  {
    title: 'Transaction',
    icon: <BiTransferAlt color='#000000' />,
    url: '/transaction',
  },
  // {
  //   title: 'Manage Roles',
  //   icon: <HiOutlineUserGroup color='#000000' />,
  //   url: '/manage-roles',
  // },
  {
    title: 'Past Properties',
    icon: <FiClock color='#000000' />,
    url: '/past-properties',
  },
  {
    title: 'Terms & Conditions',
    icon: <CgNotes color='#000000' />,
    url: '/terms-conditions',
  },
  {
    title: 'Support',
    icon: <MdSupportAgent color='#000000' />,
    url: '/support',
  },
  {
    title: 'Raise Query',
    icon: <FiHelpCircle color='#000000' />,
    url: '/raise-query',
  },
];

const profileSidebarNavContent = [
  {
    element: <PersonalDetailsForm />,
  },
  {
    element: <TransactionHistory />,
  },
  // {
  //   element: <QueriesHistory />,
  // },
  {
    element: <PastProperty />,
  },

  {
    element: <TermsConditions />,
  },
  {
    element: <Support />,
  },
  {
    element: <RaiseQuery />,
    title: 'Your Recent Queries',
  },
];

const ProfilePage = () => {
  const [loading, setLoading] = useState(false);
  const builderId = useSelector((state) => state.auth.builderId);
  const [profilePictureUrl, setProfilePictureUrl] = useState(
    '/assets/profile-page/profile-mg-big.png'
  );
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabUrl, setActiveTabUrl] = useState('');
  const [builderInfo, setBuilderInfo] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState('');
  const builderID = localStorage.getItem('builderId');
  const dispatch = useDispatch();

  const builderData = useSelector(
    (state) => state.builderDetail.details[0]
  ) || { name: '' };
  useEffect(() => {
    if (builderId && builderId != 'undefined') {
      dispatch(getBuilderDetail(builderId));
    }
  }, [dispatch]);

  const navigate = useNavigate();

  const handleTabChange = (index, url) => {
    setActiveTab(index);
    setActiveTabUrl(url);
    navigate(url);
    setSidebarOpen(false);
  };

  const handleSubmit = () => {
    dispatch(logout());
    setAuthToken();
    navigate('/');
  };
  function handleImageUpload(e) {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      setProfilePictureUrl(event.target.result);
    };
    reader.readAsDataURL(file);
  }

  const fetchBuilderInfo = async () => {
    setLoading(true);
    try {
      const response = await getAPI(
        `${apiEndpoints?.getBuilderById}${builderID}`
      );
      console.log(response);
      setBuilderInfo(response?.data[0]);
    } catch (error) {
      console.error('Error fetching builder info:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBuilderInfo();
  }, [dispatch]);

  const handleImageChange = (e) => {
    const files = e.target.files;

    if (files && files.length > 0) {
      for (let index = 0; index < files.length; index++) {
        let uploadFormData = new FormData();
        uploadFormData.append('documents', files[index]);

        dispatch(
          uploadDocToS3(uploadFormData, (data) => {
            // Update profileImage with the new image URL
            setProfilePictureUrl((oldArr) => {
              if (!Array.isArray(oldArr)) {
                // Initialize oldArr as an empty array if it's not an array
                return [data.data.documents[0]];
              }
              // Spread the existing elements and add the new data
              return [...oldArr, data.data.documents[0]];
            });

            // Construct the payload and dispatch updatePersonalInfoForBuilder with FormData
            setProfilePictureUrl((oldArr) => {
              let updateFormData = new FormData();
              updateFormData.append('id', builderInfo._id);
              updateFormData.append(
                'profilePicture',
                files[index]
              ); // Append the file directly to FormData

              dispatch(
                updatePersonalInfoForBuilder(
                  updateFormData,
                  (data) => {
                    enqueueSnackbar('Update successful', {
                      variant: 'success',
                    });
                    window.location.reload();
                  },
                  (err) => {
                    const message =
                      err?.response?.data ||
                      'Error occurred while updating info';
                    enqueueSnackbar(message, {
                      variant: 'error',
                    });
                  }
                )
              );
            });
          })
        );
      }
    }
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      <DashboardHeader />
      <br />
      <Container fluid='lg' className='profile mb-5'>
        <Row className='gy-5 gx-lg-5'>
          <Col md={5} lg={3} className='d-lg-none'>
            <IconButton
              variant='outline-primary'
              onClick={toggleSidebar}
            >
              <FiMenu size={24} />
            </IconButton>
          </Col>
          <Drawer
            anchor='left'
            open={sidebarOpen}
            onClose={toggleSidebar}
          >
            <aside className='d-flex flex-column h-100 justify-content-between py-4 px-2'>
              <div>
                {/* profile img */}
                <div className='text-center'>
                  <div className='text-center position-relative profile__image mx-auto'>
                    <Image
                      src={
                        builderInfo?.profilePictureUrl ||
                        '/assets/profile-page/profile-mg-big.png'
                      }
                      roundedCircle
                      alt='profileImage'
                      style={{
                        objectFit: 'fill',
                        width: '100%',
                        height: '100%',
                      }}
                    />
                    <div className='profile__image__overlay'>
                      <label htmlFor='imageUpload'>
                        <RiPencilFill />
                      </label>
                      <input
                        type='file'
                        id='imageUpload'
                        accept='image/*'
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                      />
                    </div>
                  </div>
                  <h4 className='mt-3'>{builderInfo?.name}</h4>

                  <p className='text-muted mb-0'>
                    {builderInfo?._id}
                  </p>
                </div>
                {/* profile img code end here */}

                <Nav className='flex-column mt-4'>
                  {tabs.map((tab, index) => (
                    <Nav.Item key={index}>
                      <Nav.Link
                        active={activeTab === index}
                        onClick={() =>
                          handleTabChange(index, tab.url)
                        }
                        className='d-flex justify-content-between align-items-center gap-2'
                      >
                        <div className='d-flex align-items-center gap-2'>
                          <div className='profile__sidebar__wrapper__icon shadow bg-white d-flex justify-content-center align-items-center'>
                            {tab.icon}
                          </div>

                          {tab.title}
                        </div>

                        <RiArrowRightSLine size={28} />
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </div>
              <div className='mt-4 d-grid'>
                <Button
                  variant='transparent'
                  className='d-flex justify-content-center align-items-center gap-2 profile__sidebar__wrapper__signout'
                  onClick={handleSubmit}
                >
                  <BsBoxArrowRight className='icon' size={24} />{' '}
                  Sign Out
                </Button>
              </div>
            </aside>
          </Drawer>

          <Col
            md={5}
            lg={3}
            className='profile-sidebar profile__sidebar__wrapper align-self-start'
          >
            <aside className='d-flex flex-column h-100 justify-content-between py-4'>
              <div>
                {/* profile img */}
                <div className='text-center'>
                  <div className='text-center position-relative profile__image mx-auto'>
                    <Image
                      src={
                        builderInfo?.profilePictureUrl ||
                        '/assets/profile-page/profile-mg-big.png'
                      }
                      roundedCircle
                      alt='profileImage'
                      style={{
                        objectFit: 'fill',
                        width: '100%',
                        height: '100%',
                      }}
                    />
                    <div className='profile__image__overlay'>
                      <label htmlFor='imageUpload'>
                        <RiPencilFill />
                      </label>
                      <input
                        type='file'
                        id='imageUpload'
                        accept='image/*'
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                      />
                    </div>
                  </div>
                  <h4 className='mt-3'>{builderInfo?.name}</h4>

                  <p className='text-muted mb-0'>
                    {builderInfo?._id}
                  </p>
                </div>
                {/* profile img code end here */}

                <Nav className='flex-column mt-4'>
                  {tabs.map((tab, index) => (
                    <Nav.Item key={index}>
                      <Nav.Link
                        active={activeTab === index}
                        onClick={() =>
                          handleTabChange(index, tab.url)
                        }
                        className='d-flex justify-content-between align-items-center gap-2'
                      >
                        <div className='d-flex align-items-center gap-2'>
                          <div className='profile__sidebar__wrapper__icon shadow bg-white d-flex justify-content-center align-items-center'>
                            {tab.icon}
                          </div>

                          {tab.title}
                        </div>

                        <RiArrowRightSLine size={28} />
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </div>
              <div className='mt-4 d-grid'>
                <Button
                  variant='transparent'
                  className='d-flex justify-content-center align-items-center gap-2 profile__sidebar__wrapper__signout'
                  onClick={handleSubmit}
                >
                  <BsBoxArrowRight className='icon' size={24} />{' '}
                  Sign Out
                </Button>
              </div>
            </aside>
          </Col>
          <Col md={7} lg={9} className='pt-0 '>
            {/* Display the URL of the active tab */}
            <div className='text-center mb-3'></div>
            <Tab.Container activeKey={activeTab}>
              <Tab.Content>
                {tabs.map((_, index) => (
                  <Tab.Pane key={index} eventKey={index}>
                    {profileSidebarNavContent[index] &&
                    profileSidebarNavContent[index].element ? (
                      <CardWrapper
                        title={
                          profileSidebarNavContent[index].title
                        }
                      >
                        {profileSidebarNavContent[index].element}
                      </CardWrapper>
                    ) : (
                      <div>
                        No content available for this tab
                      </div>
                    )}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default ProfilePage;
