import { Box, FormControl, MenuItem, Select } from '@mui/material';

export default function CustomizedMenu({ title, menuItems, value, onChange }) {
  return (
    <Box sx={{ width: '100%' }}>
      <div>
        <FormControl fullWidth>
          <Select
            value={value}
            onChange={onChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            sx={{
              backgroundColor: '#F2F2F2',
              color: 'black',
              height: '3rem',
              borderRadius: '.3rem',
              '& .MuiSelect-root': {
                color: 'black',
              },
              '& .MuiSelect-select.MuiSelect-select': {
                paddingLeft: '0.5rem',
                color: 'black',
              },
              '& .MuiOutlinedInput-root': {
                border: 'none !important',
                '&:hover': {
                  borderColor: 'grey',
                },
                '&.Mui-focused, &.Mui-active': {
                  borderColor: 'grey !important',
                },
              },
              '& .MuiListItem-root': {
                '&:hover': {
                  backgroundColor: '#F2F2F2',
                },
              },
            }}
          >
            <MenuItem value='' disabled>
              <em style={{ opacity: 0.2 }}>{title}</em>
            </MenuItem>
            {menuItems.map((item, index) =>
              typeof item === 'string' ? (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ) : (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </div>
    </Box>
  );
}
