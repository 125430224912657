import SearchFilterBox from '../../search-filter/SearchFilter';
import PastPropertyList from './PastPropertyList';

const PastProperty = () => {
  return (
    <>
      <h2>Past Properties</h2>

      <PastPropertyList />
    </>
  );
};
export default PastProperty;
