import React, { useEffect, useRef } from 'react';
import SuperConatiner from './SuperContainer';
import SuperCard from './SuperCard';
import { Box } from '@mui/material';

export default function SuperSection({ setSection, number, src, title, desc }) {
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setSection(number);
          }
        });
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [number]);

  return (
    <Box ref={containerRef}>
      <SuperConatiner>
        <SuperCard title={title} desc={desc} src={src} />
      </SuperConatiner>
    </Box>
  );
}
