import React from 'react';
import { Card, Button, Row, Col, Container } from 'react-bootstrap';
import { MdModeEditOutline, MdLocalPhone } from 'react-icons/md';
import Footer from '../footer/Footer';
import formatDate from '../../../../helper/formatDate';
import './ClientCard.css';
import { useGetAllRequirement } from '../../../../hooks/LoginQuery';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CustomerModal from '../customer-modal/CustomerModal';
import { Box } from '@mui/material';
import PropertyVisitModal from '../customer-modal/PropertyVisitModal';

const VisitCards = ({  requirementDetails, visits }) => {
  const [openPropertyVisitModal, setOpenPropertyVisitModal] = useState(false);
  const [visitDetails, setVisitDetails] = useState("")
 
  const onHide = () => {
    setOpenPropertyVisitModal(false);
  };

    const {id} = useParams();

  console.log(requirementDetails);

  const handlePropertyModal = (data)=>{
   setOpenPropertyVisitModal(true)
   setVisitDetails(data)
  }

  return (
    <>
      <section className='mt-3'>
        <Container className='nes__dashboard__brok__client'>
          <Row>
            <Col xs={12}>
              <h1 className='section-title mb-4'>Visit Details</h1>
            </Col>

           
            {requirementDetails && (
              <>

                <Col xs={12} className='mb-3'>
                  <Card className='client__card border-0 mb-4'>
                    <Card.Header className='bg-white d-flex justify-content-between align-items-center border-0 p-3'>
                    <div
                        className='fw-bold text-truncate'
                        title={requirementDetails[0]?._id}
                      >
                        <span className='nes__dashboard__brok__client__name text-truncate'>
                          Requirement ID: {requirementDetails[0]?._id}
                        </span>
                      </div>
                      {/* <div className='d-flex align-items-center fw-bold ms-auto'>
                      <div className='d-flex align-items-center fw-bold flex-shrink-0'>
                        <Link className='client__Card__link text-decoration-underline'>
                          Related Search
                        </Link>
                      </div>
                      </div> */}
                    </Card.Header>
                    <Box sx={{display:'flex', gap:2, p:2}}>
                        <Box
                      variant='outlined'
                      sx={{
                        // width: '100%',
                        height: '2.5rem',
                        overflow: 'hidden',
                         gap:1,
                         px:2,
                        textTransform: 'none',
                        border: '1px solid #C7C9D9',
                        borderRadius: '10px',
                        color: 'black',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                        {requirementDetails[0]?.unitType.map((unit, index) => (
                            <span key={index} >{unit}</span>
                          ))}
                    </Box>
                    <Box
                      variant='outlined'
                      sx={{
                        // width: '100%',
                        height: '2.5rem',
                        overflow: 'hidden',
                         gap:1,
                         px:2,
                        textTransform: 'none',
                        border: '1px solid #C7C9D9',
                        borderRadius: '10px',
                        color: 'black',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                        <span className='fw-semibold d-flex justify-content-end gap-2'>
                        {`₹ ${requirementDetails[0]?.minPrice}-${requirementDetails[0]?.maxPrice}`}
                        </span>
                    </Box>
                       
                        </Box>
                  </Card>
                </Col>
              </>
            )}

           
            {visits &&
              visits.map((visit, index) => (
                <Col lg={4} md={6} className='mb-3' key={index}>
                  <Card className='client__card border-0'>
                    <Card.Header className='bg-white d-flex justify-content-between align-items-center border-0 pt-3 pb-0 gap-1'>
                      <div
                        className='fw-bold text-truncate'
                        title={visit._id}
                      >
                        <span className='nes__dashboard__brok__client__name text-truncate'>
                          Visit ID: {visit._id}
                        </span>
                      </div>
                    
                    </Card.Header>
                    <Card.Body>
                      <Card.Text className='mb-3 d-flex justify-content-between'>
                        <span className='fw-normal text-muted'>
                        Property Name
                        </span>{' '}
                        <span className='fw-semibold d-flex gap-2 justify-content-end'>
                        {visit?.propertyName}
                        </span>
                      </Card.Text>
                      <Card.Text className='mb-3 d-flex justify-content-between'>
                        <span className='fw-normal text-muted'>Location</span>{' '}
                        <span className='fw-semibold'>{visit?.propertyId?.location}</span>
                      </Card.Text>
                      <Card.Text className='mb-3 d-flex justify-content-between'>
                        <span className='fw-normal text-muted'>
                        Price
                        </span>{' '}
                        <span className='fw-semibold d-flex justify-content-end gap-2'>
                        {`${visit.minPrice}-${visit.maxPrice}`}
                        </span>
                      </Card.Text>
                      <Card.Text className='d-flex justify-content-between'>
                        <span className='fw-normal text-muted'>
                          Unit Type
                        </span>{' '}
                        <span className='fw-semibold text-end'>
                        {visit.unitType.map((unit, index) => (
                            <span key={index}>{unit}</span>
                          ))}
                        </span>
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className='bg-transparent border-0 d-flex justify-content-center gap-3 py-3 px-lg-3 px-xl-5'>
                      <div className='flex-fill follow-up rounded-pill d-flex justify-content-center align-items-center text-capitalize'>
                        {visit?.visitStatus}
                      </div>
                      <Button
                        className='flex-fill btn-color-primary rounded-pill'
                        onClick={()=>handlePropertyModal(visit)}
                      >
                        Visit Summary
                      </Button>
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
          </Row>
        </Container>
      </section>
      <Footer />

      <PropertyVisitModal show={openPropertyVisitModal} onHide={onHide} visitDetails={visitDetails} />
    </>
  );
};

export default VisitCards;
