import { Navbar, Nav, Container, Image } from "react-bootstrap";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { GiWallet } from "react-icons/gi";
import { BsFillBellFill } from "react-icons/bs";
import clientIcon from "../../assets/images/client.svg";
import brokerageIcon from "../../assets/images/brokerage.svg";
import brokerProfileImg from "../../assets/images/broker-profile-img.png";
import { Link } from "react-router-dom";
import { RiHome5Fill } from "react-icons/ri";
import "./Header.css";
import Avatar from "@mui/material/Avatar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetBrokerById } from "../../../../hooks/LoginQuery";
import BookAVisitModal from "../book-a-visit-modal/BookAVisitModal";
import { getAllNotifications } from "../../../../../API/action";
import { Box, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
// import { BookAVisitModal } from "../../../../features/Dashboard/components/book-a-visit-modal/BookAVisitModal";

const Header = ({ isOpen, setIsOpen }) => {
  const [profileImage, setProfileImage] = useState(null);
  const brokerID = localStorage.getItem("brokerID");
  const [showBookVisit, setShowBookVisit] = useState(false);

  const onHideBookVisitModal = () => {
    setShowBookVisit(false);
  };

  const { getBrokerByIdResponse } = useGetBrokerById(brokerID);
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    // Set initial values when getBrokerByIdResponse changes
    if (getBrokerByIdResponse) {
      setProfileImage(getBrokerByIdResponse[0]?.profilePicture || "");
    }
    dispatch(
      getAllNotifications(
        { brokerId: localStorage.getItem("brokerID") },
        (data) => {
          setNotifications(data.data.data);
        },
        (err) => {
          console.log("Error", err);
        }
      )
    );
  }, [getBrokerByIdResponse]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  return (
    <Navbar expand="lg" className="nes__navbar">
      <Container>
        <Navbar.Brand
          as={Link}
          to="/broker/dashboard"
          className="d-flex justify-content-center"
        >
          <img src="/assets/nesto-logo.png" alt="Logo" height="30" />
        </Navbar.Brand>

      <Box mr={3}>
            <Navbar.Toggle aria-controls='navbar-nav' />
          </Box>
        <Navbar.Collapse id="navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link
              as={Link}
              to="/broker/dashboard"
              className="mx-3 d-flex align-items-center gap-2"
            >
              <RiHome5Fill size={25} />
              <span>Home </span>
            </Nav.Link>

            <Nav.Link
              as={Link}
              to="/broker/clientManagement"
              href="/"
              className="mx-3 d-flex align-items-center gap-2"
            >
              <img
                src={clientIcon}
                alt="client icon"
                className="img-fluid mx-2"
              />
              Client
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/broker/brokerageManagement"
              className="mx-3 d-flex align-items-center gap-2"
            >
              <img
                src={brokerageIcon}
                alt="brokerage icon"
                className="img-fluid"
              />
              Brokerage
            </Nav.Link>
          </Nav>
          <Nav.Item>
            <div className="login-div">
              <button
                className="login-link"
                onClick={() => setShowBookVisit(true)}
              >
                Book A Visit
              </button>
            </div>
          </Nav.Item>
          <Nav className="ms-auto">
            <Nav.Link href="#">
              <div
                onClick={() =>
                  isOpen !== undefined && setIsOpen && setIsOpen(!isOpen)
                }
                className="nes__navbar__right__circle rounded-circle d-flex align-items-center justify-content-center bg-transparent"
              >
                {isOpen ? (
                  <AiOutlineEye size={24} color="#278FD9" />
                ) : (
                  <AiOutlineEyeInvisible size={24} color="#278FD9" />
                )}
              </div>
            </Nav.Link>
            <Nav.Link as={Link} to="/broker/wallet">
              <div className="nes__navbar__right__circle rounded-circle d-flex align-items-center justify-content-center">
                <GiWallet />
              </div>
            </Nav.Link>
            <Nav.Link
              href="#"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <div className="d-flex align-items-center position-relative">
                <div className="nes__navbar__right__circle rounded-circle d-flex align-items-center justify-content-center">
                  <BsFillBellFill />
                </div>
                {notifications.length > 0 && (
                  <div className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {notifications.length}
                    <span className="visually-hidden">New notifications</span>
                  </div>
                )}
              </div>
            </Nav.Link>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {notifications?.map((item, index) => (
                <MenuItem
                  key={index}
                  sx={{
                    "&:hover": {
                      backgroundColor: "inherit",
                    },
                    cursor: "initial",
                  }}
                >
                  <Typography
                    sx={{
                      background: "#F8F8F8",
                      padding: "0.5rem",
                      borderRadius: "10px",
                    }}
                  >
                    {item?.description?.split("\n").map((daa, index) => (
                      <>
                        <span
                          key={index}
                          style={{ color: "#000", fontWeight: "500" }}
                        >
                          {daa}
                        </span>
                        <br />
                      </>
                    ))}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
            <Nav.Link as={Link} to="/broker/profile">
              <div className="nes__navbar__right__circle d-flex align-items-center bg-transparent">
                {profileImage ? (
                  <Image
                    src={profileImage}
                    roundedCircle
                    width={"50px"}
                    height={"50px"}
                  />
                ) : (
                  <Avatar src="/broken.png" sx={{width:"100%", height:"100%"}}/>
                )}
                {/* <img
                  src={brokerProfileImg}
                  alt="Profile"
                  className="rounded-circle img-fluid"
                /> */}
                {/* <Avatar alt="Profile"  /> */}
              </div>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
      <BookAVisitModal show={showBookVisit} onHide={onHideBookVisitModal} />
    </Navbar>
  );
};

export default Header;
