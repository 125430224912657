import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BookAVisitModal,
  Footer,
  Header,
  SmallCardSection,
  ProductCard,
} from '../../features';
import '../../features/Dashboard/assets/styles/Dashboard.css';
import { setToken, setBrokerID } from '../../store/authSlice';
import { setAuthToken } from '../../services/api';
import { hideBookAVisit } from '../../store/ModalsSlice';
import { SearchFilter } from '../../../components';
import { searchProperty } from '../../../API/action';
import { Container } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import HeaderCategory from '../../features/Dashboard/components/properties/HeaderCategory';

const SearchProperty = () => {
  const dispatch = useDispatch();
  const {search} = useParams();
  const isBookAVisit = useSelector((state) => state?.modals?.isBookAVisit);
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();

  const onHide = () => {
    dispatch(hideBookAVisit());
  };

  const handleCardClick = (productId) => {
    navigate(`/broker/property-details/${productId}`);
  };

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    const brokerID = localStorage.getItem('brokerID');
    if (token && brokerID) {
      dispatch(setToken(token));
      setAuthToken(token);
      dispatch(setBrokerID(brokerID));
    }
  }, [dispatch]);

  const [properties, setProperties] = useState([]);


  useEffect(() => {
    dispatch(searchProperty(search,{}, (data) => {
      setProperties(data.data.data);
    }, (err) => {
      console.log(err);
    }));
  }, [search]);

  console.log(search)

  return (
    <>
      <BookAVisitModal show={isBookAVisit} onHide={onHide} />

      <div className='nes__dashboard__brok__app d-flex flex-column min-vh-100'>
        <Header isOpen={isOpen} setIsOpen={setIsOpen}  />
        <Container fluid='lg'>
      <HeaderCategory/>
        {/* <SmallCardSection/> */}
      <section className='mt-3'>
        <SearchFilter addBtn
         />
       
         <section className='nes__product__section'>
      <Row className='gy-3'>
        <Col
          sm={12}
          className='d-flex justify-content-between align-items-center mb-3'
        >
          <h2 className='nes__product__section__title'>Search Properties</h2>
        
        </Col>

        {/* Render best selling properties or message */}
        {properties.length > 0 ? (
          properties.map((property) => (
            <Col key={property._id} xs={12} sm={12} md={4} className='mb-4'>
              <ProductCard
                {...property}
                view='broker'
                isOpen={isOpen}
                pagination
                onClick={() => handleCardClick(property._id)}
              />
            </Col>
          ))
        ) : (
          <p
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '24px',
            }}
          >
            No property available
          </p>
        )}
      </Row>
    </section>


      </section>
         </Container>
        <Footer />
      </div>
    </>
  );
};

export default SearchProperty;
