import React, { useEffect } from 'react';
import { Card, Button, Row, Col, Container } from 'react-bootstrap';
import { MdModeEditOutline, MdLocalPhone } from 'react-icons/md';
import Footer from '../footer/Footer';
import formatDate from '../../../../helper/formatDate';
import './ClientCard.css';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CustomerModal from '../customer-modal/CustomerModal';
import { getAllRequirement } from '../../../../../API/action';
import { useDispatch } from 'react-redux';

const RequirementCards = () => {
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [openPropertyVisitModal, setOpenPropertyVisitModal] = useState(false);
  const [customerData, setCustomerData] = useState("");
  // const openCustomerModal = () => {
  //   console.log('cliked edit');
  //   setShowCustomerModal(true);
  // };

  const openCustomerModal = (cardData) => {
    setCustomerData({
      customerId: cardData._id,
      brokerId: cardData.brokerId._id,
      clientName: cardData.clientName,
      email: cardData.email,
      phoneNumber: cardData.phoneNumber,
      alternatePhoneNumber: cardData.alternatePhoneNumber,
      unitType: cardData.unitType,
      preferredLocation: cardData.preferredLocation,
      // You can add other fields here if needed
    });
    setShowCustomerModal(true);
  };

 
  const {id} = useParams();

  const navigate = useNavigate();

  const [allRequirement, setAllRequirement] = useState([]);
  const dispatch = useDispatch();

  const getData = () => {
    let payload ={
      // brokerId:localStorage.getItem("brokerID"),
      customerId:id,
    }
    dispatch(
      getAllRequirement(
        payload,
        (data) => {
          setAllRequirement(data.data.data);
        },
        (error) => {
          // Handle error
          console.error("Error fetching all requirement:", error);
        }
      )
    );
  };

  useEffect(() => {
    getData();
  }, [id]);


console.log(allRequirement)


  return (
    <>
      <section className='mt-3'>
        <Container className='nes__dashboard__brok__client'>
          <Row>
            <Col xs={12}>
              <h1 className='section-title mb-4'>Requirement Details</h1>
            </Col>

            {/* requirement section code start here */}
            {/* {allRequirement && (
              <>

                <Col xs={12} className='mb-3'>
                  <Card className='client__card border-0 mb-4'>
                    <Card.Header className='bg-white d-flex justify-content-between align-items-center border-0 p-3'>
                      <div className='fw-bold d-flex flex-column'>
                        <span className='nes__dashboard__brok__client__name nes__dashboard__brok__client__name-title fw-bold'>
                          {getAllRequirementResponse[0]?.customerId?.clientName}
                        </span>
                        <span className='text-muted fw-light'>
                          Client ID:
                          {getAllRequirementResponse[0]?.customerId?._id}
                        </span>
                      </div>
                      <div className='d-flex align-items-center fw-bold ms-auto'>
                        <div>
                          <button className='me-1 rounded-circle p-0 d-inline-flex nes__dashboard__brok__client__phone'>
                            <MdLocalPhone className='m-auto' size={10} />
                          </button>{' '}
                          <span className='nes__dashboard__brok__client__phone__text'>
                            {
                              getAllRequirementResponse[0]?.customerId
                                ?.phoneNumber
                            }
                          </span>
                        </div>
                      </div>
                    </Card.Header>
                  </Card>
                </Col>
              </>
            )} */}

            {allRequirement &&
              allRequirement.map((requirement, index) => (
                <Col lg={4} md={6} className='mb-3' key={index}>
                  <Card className='client__card border-0 cursor' onClick={()=>navigate(`/broker/requirement/visit-details/${requirement._id}`)}>
                    <Card.Header className='bg-white d-flex justify-content-between align-items-center border-0 pt-3 pb-0 gap-1'>
                      <div
                        className='fw-bold text-truncate'
                        title={requirement._id}
                      >
                        <span className='nes__dashboard__brok__client__name text-truncate'>
                          Requirement ID: {requirement._id}
                        </span>
                      </div>
                      {/* <div className='d-flex align-items-center fw-bold flex-shrink-0'>
                        <Link className='client__Card__link text-decoration-underline'>
                          Related Search
                        </Link>
                      </div> */}
                    </Card.Header>
                    <Card.Body>
                      <Card.Text className='mb-3 d-flex justify-content-between'>
                        <span className='fw-normal text-muted'>
                          Recent Requirement
                        </span>{' '}
                        <span className='fw-semibold d-flex gap-2 justify-content-end'>
                          {requirement.unitType.map((unit, index) => (
                            <span key={index}>{unit}</span>
                          ))}
                        </span>
                      </Card.Text>
                      <Card.Text className='mb-3 d-flex justify-content-between'>
                        <span className='fw-normal text-muted'>Budget</span>{' '}
                        <span className='fw-semibold'>{`${requirement.minPrice}-${requirement.maxPrice}`}</span>
                      </Card.Text>
                      <Card.Text className='mb-3 d-flex justify-content-between'>
                        <span className='fw-normal text-muted'>
                          Preferred Location
                        </span>{' '}
                        <span className='fw-semibold d-flex justify-content-end gap-2'>
                          {requirement.preferredLocation.map(
                            (location, index) => (
                              <span key={index}>{location}</span>
                            )
                          )}
                        </span>
                      </Card.Text>
                      <Card.Text className='d-flex justify-content-between'>
                        <span className='fw-normal text-muted'>
                          Recent Updated Visit
                        </span>{' '}
                        <span className='fw-semibold text-end'>
                          {requirement.latestVisit && requirement.latestVisit.propertyName}
                        </span>
                      </Card.Text>
                    </Card.Body>
                    {/* <Card.Footer className='bg-transparent border-0 d-flex justify-content-center gap-3 py-3 px-lg-3 px-xl-5'>
                      <div className='flex-fill follow-up rounded-pill d-flex justify-content-center align-items-center'>
                        Follow Up
                      </div>
                      <Button
                        className='flex-fill btn-color-primary rounded-pill'
                      >
                        Visit Summary
                      </Button>
                    </Card.Footer> */}
                  </Card>
                </Col>
              ))}
          </Row>
        </Container>
      </section>
      <Footer />

      <CustomerModal
        show={showCustomerModal}
        onHide={() => setShowCustomerModal(false)}
        data={customerData}
      />
    </>
  );
};

export default RequirementCards;
