import React from 'react';

export default function HTMLOutput({ data, className }) {
  // Define CSS styles for different elements
  const styles = {
    h3: {
      fontSize: '24px',
      fontWeight: 'bold',
      fontFamily: 'Calibri, sans-serif',
      // Add any other styles you want for h3
    },
    p: {
      fontSize: '15px',
      fontFamily: 'Calibri, sans-serif',
      // Add any other styles you want for p
    },
    span: {
      fontSize: '12px',
      fontFamily: 'Arial, sans-serif',
      // Add any other styles you want for span
    },
  };

  // Function to recursively render HTML content
  const renderHTML = (htmlString) => {
    return (
      <div
        className={className}
        dangerouslySetInnerHTML={{ __html: htmlString }}
      />
    );
  };

  return renderHTML(data);
}
