import { Card } from 'react-bootstrap';
import './TermsConditions.css';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getBuilderDetail } from '../../../redux/https-requests/builderDetail-http';
import TermsCondition from '../../../../informative/TermsCondition/TermsCondition';
import Terms from '../../../../informative/TermsCondition/Terms/Terms';
import { Fade } from 'react-reveal';
const TermsConditions = () => {
  const dispatch = useDispatch();
  // const builderData = useSelector(
  //   (state) => state.builderDetail.details[0]
  // ) || {
  //   email: "",
  //   phoneNumber: "",
  //   gst: "",
  //   companyName: "",
  //   address: "",
  //   panOfCompany: "",
  //   companyType: "",
  // };
  const builderData = {
    email: '',
    phoneNumber: '',
    gst: '',
    companyName: '',
    address: '',
    panOfCompany: '',
    companyType: '',
  };
  console.log(builderData);
  const builderId = useSelector((state) => state.auth.builderId);

  useEffect(() => {
    dispatch(getBuilderDetail(builderId));
  }, [dispatch]);
  return (
    <>
      <h3 className='mb-2'>Terms & Conditions</h3>
      <div
        style={{
          overflowX: 'hidden',
          height: '80vh',
          overflowY: 'auto',
        }}
      >
        <div className='container t-c_container'>
          <div className='row'>
            <div className='col-lg-12'>
              <Fade up duration={1000} delay={100}>
                <span className='t-c_span'>
                  These terms of use ("Terms") constitute a
                  legally binding agreement between you and
                  NestoHub Proptech Private Limited (the
                  "Company") regarding your use of the website
                  www.nestohub.in (the "Site") and any services
                  offered by the Company via the Site, any mobile
                  or internet connected device or otherwise (the
                  "the Service").
                </span>
              </Fade>
              <Fade up duration={1000} delay={100}>
                <span className='t-c_span'>
                  Your use of the Site and services and tools are
                  governed by the following Terms as applicable
                  to the Company including the applicable
                  policies which are incorporated herein by way
                  of reference. By mere use of the Site, You
                  shall be contracting with NetoHub Proptech
                  Private Limited, the owner of the Platform.
                  These terms and conditions including the
                  policies constitute Your binding obligations,
                  with the Company.
                </span>
              </Fade>
              <Fade up duration={1000} delay={100}>
                <span className='t-c_span'>
                  When You use any of the services provided by Us
                  through the Platform, You will be subject to
                  the rules, guidelines, policies, terms, and
                  conditions applicable to such service, and they
                  shall be deemed to be incorporated into this
                  Terms and shall be considered as part and
                  parcel of this Terms.
                </span>
              </Fade>
              <Terms />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsConditions;
