import React, { useState } from 'react';
import Header from '../../../broker/Common/Header/Header';

import { Box, Container, Grid } from '@mui/material';
import { Card, Form, Row, Col, FloatingLabel, Image } from 'react-bootstrap';
import { Footer, Ribbon } from '../../../broker/features';
import './BoughtProperty.css';
import Filter from '../../../components/search-filter/Filter/Filter';
import SearchFilterBox from '../../../components/search-filter/SearchFilter';
import AboutTheBuilder from '../../../broker/Pages/AboutBuilder/AboutTheBuilder';
import BuilderPDapartment from '../../../informative/PropertyDetails/BuilderPD/BuilderPDapartment/BuilderPDapartment';
import BuilderPDbanner from '../../../informative/PropertyDetails/BuilderPD/BuilderPDbanner/BuilderPDbanner';
const BoughtProperty = () => {
  const [fileType, setFileType] = useState('all');

  const [fileList, setFileList] = useState([]);
  const [selectedImage, setSelectedImage] = useState('');
  const [isSectionClicked, setIsSectionClicked] = useState(false);
  const [showModal, setShowModal] = useState(false); // state for controlling modal visibility
  const [allCustomerGet, setAllCustomerGet] = useState([]);
  const brokerID = localStorage.getItem('brokerID');

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleSectionClick = () => {
    setIsSectionClicked(!isSectionClicked);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // Add visual indication that the area can be dropped
    e.target.classList.add('drag-over');
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // Remove visual indication when the drag leaves the area
    e.target.classList.remove('drag-over');
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // Handle dropped files
    const files = e.dataTransfer.files;
    // Process the files here
    console.log(files);
    // Remove visual indication after dropping files
    e.target.classList.remove('drag-over');
  };

  return (
    <>
      <Header />
      <SearchFilterBox addBtn={true} />
      <Container>
        <section>
          <h1 className='broker__section__title'>Bought</h1>
          <div
            className='hero-section nes__hero d-flex flex-column   '
            style={{
              border: '10px solid white',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            }}
          >
            <img
              src='/assets/informative/propertyImage.jpg'
              className='BuilderPDbanner-col_div_img'
              alt='property-banner'
            />

            {/* Your existing content */}
          </div>
          {/* Image navigation */}
          <div
            className='hero-caption-img-navigation'
            style={{
              position: 'absolute',
              bottom: '10',
            }}
          >
            <Image
              className='ms-3'
              width={'66.92px'}
              height={'66.92px'}
              style={{
                border: '4px solid white',
                borderRadius: '20.08px',
              }}
            />
          </div>
        </section>

        <BuilderPDapartment />
        <Box mt={4}>
          <Form.Label>
            Last Visit Date<span className='text-dark'>*</span>
          </Form.Label>
          <Form.Control
            className='form-control-sm inputCustom '
            type='date'
            placeholder='Last Visit Date'
            required
          />
        </Box>
        <Box mt={4}>
          <Grid container spacing={2}>
            <Grid item xl={6}>
              {' '}
              <Form.Label>
                Unit Number<span className='text-dark'>*</span>
              </Form.Label>
              <Form.Control
                className='form-control-sm inputCustom '
                type='text'
                placeholder='Unit Number'
                required
              />
            </Grid>
            <Grid item xl={6}>
              {' '}
              <Form.Label>
                Unit Type<span className='text-dark'>*</span>
              </Form.Label>
              <Form.Control
                className='form-control-sm inputCustom '
                type='text'
                placeholder='Unit Type'
                required
              />
            </Grid>
          </Grid>
        </Box>
        <Box mt={4}>
          <Form.Label>
            Property Name<span className='text-dark'>*</span>
          </Form.Label>
          <Form.Control
            className='form-control-sm inputCustom '
            type='text'
            placeholder='Unit Number'
            required
          />
        </Box>
        <Box mt={4}>
          <Form.Label>
            Customer Name<span className='text-dark'>*</span>
          </Form.Label>
          <Form.Control
            className='form-control-sm inputCustom '
            type='text'
            placeholder='name'
            required
          />
        </Box>
        <Box mt={4}>
          <Form.Label>
            Builder Representative Name<span className='text-dark'>*</span>
          </Form.Label>
          <Form.Control
            className='form-control-sm inputCustom '
            type='text'
            placeholder='Builder Representative Name'
            required
          />
        </Box>
        <Box my={4}>
          <Grid container spacing={2}>
            <Grid item xl={6}>
              {' '}
              <Form.Label>
                Brokerage Value<span className='text-dark'>*</span>
              </Form.Label>
              <Box display={'flex'}>
                <Box
                  textAlign={'center'}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '5px',
                    height: '3rem',
                    width: '8%',
                    marginRight: '1rem',
                    bgcolor: '#F8F8F8',
                  }}
                >
                  ₹
                </Box>

                <Box xl={10} sx={{ width: '100%' }}>
                  <Form.Control
                    className='form-control-sm inputCustom inputCustom '
                    type='text'
                    placeholder='Brokerage Value'
                    required
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xl={6}>
              {' '}
              <Form.Label>
                Brokerage Value<span className='text-dark'>*</span>
              </Form.Label>
              <Box display={'flex'}>
                <Box
                  textAlign={'center'}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '5px',
                    height: '3rem',
                    width: '8%',
                    marginRight: '1rem',
                    bgcolor: '#F8F8F8',
                  }}
                >
                  ₹
                </Box>

                <Box xl={10} sx={{ width: '100%' }}>
                  <Form.Control
                    className='form-control-sm inputCustom inputCustom '
                    type='text'
                    placeholder='Brokerage Value'
                    required
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Form.Label>
          Upload a Document<span className='text-dark'>*</span>
        </Form.Label>
        <Box
          class=' inputFile'
          sx={{ height: '2rem' }}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          {fileList.length === 0 ? (
            <>
              {' '}
              <Box>
                {' '}
                <img
                  src='/assets/Uploadicon.svg'
                  alt='Uploadicon'
                  style={{ width: '4rem' }}
                />
              </Box>
              <Box mt={2}>
                Drag & drop files or
                <span
                  style={{
                    textDecoration: 'underline',
                    color: '#278FD9',
                    marginLeft: '3px',
                  }}
                >
                  {' '}
                  Browse
                </span>{' '}
              </Box>
              <Box mt={2} sx={{ color: '#676767' }}>
                Supported formats: JPEG, PNG
              </Box>{' '}
            </>
          ) : (
            <ul>
              {fileList.map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          )}
          <input
            className='form-control'
            type='file'
            id='formFileMultiple'
            multiple
          />
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default BoughtProperty;
