import React, { useEffect, useState } from 'react';
import { Box, Typography, InputAdornment } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import { IconButton } from '@mui/material';

export const FormInputField = ({
  required = false,
  label,
  Type,
  textarea = false,
  value,
  onChange,
  name,
  width,
  country = false,
  showMessage,
  endIcon,
  line,
  rows = { line },
}) => {
  const [focus, setFocus] = useState('');

  useEffect(() => {
    if (value && String(value).trim().length > 0) {
      setFocus(true);
    } else {
      setFocus(false);
    }
  }, [value]);

  const lineHeight = `${line * 1}rem`;
  return (
    <Box
      className='inputBox'
      sx={{
        position: 'relative',
        borderRadius: '.3rem',
        width: width || '100%',
        '&:hover': {
          borderColor: 'grey',
        },
        '& label': {
          width: 'auto !important',
          opacity: focus ? '0' : '1',
          position: 'absolute',
          filter: focus ? 'blur(5)' : 'blur(0)',
          top: textarea ? 23 : '50%',
          px: 2,
          color: 'black',
          '& span': { color: 'gray' },
          transform: 'translateY(-50%)',
          span: { ml: 0.2 },
          zIndex: -1,
          transition: '0.15s',
          fontWeight: 700,
        },
        '& input, textarea': {
          fontSize: 15,
          px: 2,
          width: '100%',
          backgroundColor: '#F2F2F2',
          border: 'none !important ',
          opacity: 1,
          color: 'black !important',
          borderRadius: '.3rem',
          '&:hover': {
            borderColor: 'grey',
            width: width || 'auto',
          },
          '&:focus': {
            outline: 'none',
            border: `1px solid gray !important`,
            width: width || 'auto',
          },
          '&::placeholder': {
            color: `gray !important`,
            width: width || 'auto',
          },
        },
        '& textarea': {
          height: textarea ? lineHeight : 100,

          pt: 1,
          width: width || '100%',
        },
        '& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button':
          {
            '-webkit-appearance': 'none',
            margin: 0,
          },
        '& input[type=number]': {
          '-moz-appearance': 'textfield',
        },
        '& input': {
          height: 49,
          width: width || '100%',
        },
      }}
    >
      <Typography component={'label'}>
        {label}
        {required && <span>*</span>}
      </Typography>

      {textarea ? (
        <textarea
          name={name}
          value={value}
          onChange={onChange}
          required={required}
          rows={line}
        />
      ) : country ? (
        <>
          <PhoneInput
            country={'in'}
            value={value}
            onChange={onChange}
            inputProps={{
              name: name,
              required: required,
            }}
            containerStyle={{ width: '100%' }}
            inputStyle={{
              width: '100%',
              height: '45px',
              fontSize: 15,
              borderRadius: '.3rem',
              paddingLeft: '3rem',
              fontWeight: 600,
              color: 'black',
              bgcolor: '#F8F8F8',
              borderColor: '#ECEFF5 !important',
              border: '2px solid ',
              opacity: 0.5,
              '& label': {
                opacity: focus ? '0' : '.75',
                position: 'absolute',
                filter: focus ? 'blur(5)' : 'blur(0)',
                top: textarea ? 23 : '50%',
                px: 8,
                color: 'black',
                '& span': { color: 'gray' },
                transform: 'translateY(-50%)',
                span: { ml: 0.2 },
                zIndex: -1,
                transition: '0.15s',
                fontFamily: 'gray',
                fontWeight: 600,
                marginLeft: '3rem',
              },
              '&:hover': {
                borderColor: 'grey',
                width: width || 'auto',
              },
              '&:focus': {
                outline: 'none',
                border: `1px solid ${'gray'} !important`,
                width: width || 'auto',
              },
            }}
          />
        </>
      ) : (
        <Box sx={{ position: 'relative' }}>
          <input
            name={name}
            type={Type || 'text'}
            value={value}
            onChange={onChange}
            required={required}
            rows={line}
            label={label}
            placeholder={label}
          />
          {endIcon && (
            <InputAdornment
              position='end'
              sx={{
                position: 'absolute',
                top: '50%',
                right: '1%',
              }}
            >
              <IconButton>{endIcon}</IconButton>
            </InputAdornment>
          )}
        </Box>
      )}
    </Box>
  );
};
