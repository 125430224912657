// eslint-disable-next-line import/no-anonymous-default-export

export default {
  BROKER_REGISTER: '/v1/broker/registerName',
  SEND_OTP: '/v1/broker/sendotp',
  LIST_PROPERTY: '/v1/builder/addShareYourIntent',
  GET_BROKER_BY_ID: '/v1/builder/addShareYourIntent',
  UPDATE_PERSONAL_INFO: '/v1/broker/updatePersonalInfo',
  UPDATE_BANK_INFO: '/v1/broker/updateBankInfo',
  UPDATE_BROKER_PREFRENCES: '/v1/broker/updatePreferences',
  UPDATE_PERSONAL_INFO_BUILDER: '/v1/builder/updatePersonalInfo',
  UPDATE_PERSONAL_INFO_BROKER: '/v1/broker/updatePersonalInfo',
  GET_ALL_NOTIFICATION: '/v1/notification/getAllNotification',
  GET_ALL_FAQ: '/v1/faqAndSupport/getAllfaqandsupport',
  UPLOAD_DOC: '/v1/globals/docUplaods',
  GET_ALL_BUILDERS: '/v1/builder/getAllBuilder',
  GET_ALL_PROPERTY: '/v1/property/getAllproperty',
  GET_BUILDER_BY_ID: '/v1/builder/getBuilderById',
  GET_BUILDER_PROPERTY_BY_ID:
    '/v1/builderPastProperty/getbuilderPastProperty',
  ADD_CUSTOMER: '/v1/customer/addCustomer',
  GET_ALL_CUSTOMER: '/v1/customer/getAllCustomer',
  UPDATE_CUSTOMER: '/v1/customer/updateCustomer',
  ADD_REQUIREMENT: '/v1/requirement/addRequirement',
  ADD_VISIT: '/v1/visit/addVisit',
  ADD_CLAIM: '/v1/claim/addClaim',
  ADD_BOUGHT_PROPERTY: '/v1/boughtProperty/addBoughtProperty',
  GET_ALL_REQUIREMENT:'/v1/requirement/getAllRequirement',
  GET_REQUIREMENT_BY_ID:'/v1/requirement/getRequirementById',
  SHARE_YOUR_INTENT:'/v1/builder/addShareYourIntent',
  GET_ALL_CLAIMS: '/v1/claim/getAllClaim',
  GET_ALL_ELIGIBLE_CLAIMS: '/v1/claim/getAllEligibleClaimsForMobile',
  GET_CLAIM_BY_ID: '/v1/claim/getClaimById',
  GET_ALL_BLOG: '/v1/blog/getAllBlog',
  GET_ALL_TUTORIAL:'/v1/tutorials/getalltutorials',
  GET_ALL_RAISEQUERY: '/v1/raiseQuery/getAllRaiseQuery',
  GET_ALL_GLOBAL_BUILDER: 'v1/globals/search/builders',
  UPDATE_VISIT_BY_ID: 'v1/visit/updateVisitById',
  GET_ALL_VISIT:"/v1/visit/getAllVisit",
  SEARCH_PROPERTY:"/v1/property/search",
  FILTER_PROPERTY:"/v1/property/properties-filter-apis",
  GET_ALL_PROPERTY_CATEGORY: "/v1/propertyCategory/getallPropertyCategory",
  GET_ALL_EARNING_PDF: "/v1/brokerEarnings/getAllEarningsPdf",
  GET_TOTAL_EARNING_PDF: "/v1/brokerEarnings/getTotalEarningsPdf",
  GET_TOTAL_EARNING_PDF: "/v1/brokerEarnings/getTotalEarningsPdf",
  GET_UPCOMING_EARNING_PDF: "/v1/brokerEarnings/getUpcomingEarningsPdf",
  GET_VISITS_PDF: "/v1/brokerEarnings/visitDetailsPdfView",
  GET_CLAIM_APPROVAL_PDF: "/v1/brokerEarnings/claimApprovalPdfView",
  GET_TOTAL_REFERAL_EARNINGS_PDF: "/v1/brokerEarnings/totalReferEarningPdfView",
  SHARE_BROCHURE:'/v1/share/addShare',
  GET_ALL_BROKER_EARNING: "/v1/brokerEarnings/getAllBrokerEarnings",
  GET_TOTAL_EARNING: "/v1/claim/getAllClaim",
  GET_UPCOMING_EARNING: "/v1/claim/getAllClaim",
  GET_REFERAL_EARNING: "/v1/refer/getAllReferalEarnings",
  UPDATE_PROPERTY: '/v1/requestProperty/addRequestProperty',
  GET_ALL_AMENTIES: '/v1/amenities/getAllAmenities',
  ADD_VISIT_CLAIM: "/v1/claim/addVisitClaim",
  VERIFY_VISIT_OTP: "/v1/claim/verifyVisitOtp",
  GET_CLIAM_PDF: "v1/claim/getClaimPaymentView",
  GET_CLIAM_PDF: "v1/claim/getPaymentPdfView",
  GET_ALL_GLOBAL_BUILDER: 'v1/globals/search/builders',
};
