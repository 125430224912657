import React, { useState } from "react";
import "./BrokerForm.css";
import { Fade } from "react-reveal";
import { postAPI } from "../../../builder/Api/ApiRequest";
import { apiEndpoints } from "../../../builder/Api/ApiEndpoint";
import { brokerRegister } from "../../../API/action";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";

const BrokerForm = () => {
  const [name, setName] = useState(""); // Initialize with empty string
  const [phoneNumber, setPhoneNumber] = useState(""); // Initialize with empty string
  const [referralCode, setReferralCode] = useState(""); // Initialize with empty string
  const dispatch = useDispatch();

  // const handleSubmit = (e) => {
  //     e.preventDefault(); // Prevent the default form submission behavior

  //     // You can access the state variables (name, phoneNumber, referralCode) here
  //     console.log('Name:', name);
  //     console.log('Phone Number:', phoneNumber);
  //     console.log('Referral Code:', referralCode);

  //     // You can perform further actions like sending data to a server, etc.
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    const phoneRegex = /^\d{10}$/;

    if (name === "") {
      enqueueSnackbar("Name cannot be empty.", { variant: "warning" });
      return;
    }

    if (!phoneRegex.test(phoneNumber)) {
      enqueueSnackbar("Phone Number is not valid!", { variant: "warning" });
      return;
    }
    const payload = {
      name,
      phoneNumber,
      referalCode: referralCode,
    };
    dispatch(
      brokerRegister(
        payload,
        (data) => {
          console.log("Registeration Successful", data);
          setPhoneNumber("");
          setName("");
          setReferralCode("");
          enqueueSnackbar("Registration Successful", { variant: "success" });
        },
        (err) => {
          enqueueSnackbar(err, { variant: "warning" });
          console.log("There is some error");
        }
      )
    );
  };

  // const handleSubmit = async () => {
  //     const response = await postAPI(apiEndpoints.brokerRegister, {
  //         phoneNumber,
  //         name,
  //         referalCode: referralCode,
  //     });
  //     console.log(response);

  // };

  return (
    <Fade bottom duration={1000} distance="100px" delay={100}>
      <img
        //   className="about-us_box1-img"
        className="m-hide"
        style={{ objectFit: "contain", width: "40%" }}
        src="/500x/informative/NestoAbout.webp"
        alt="about"
      />
    </Fade>
    // <Fade bottom duration={1000} distance="100px" delay={100}>
    //     <div className='col-lg-4 broker-banner-col-3 align-items-end'>
    //         <div className='broker-banner-col-3-div'>
    //             <div className='broker-banner-col-3_title_div'>
    //                 <span className='broker-banner-col-3_title'>Best Platform For Broker</span>
    //                 <span className='broker-banner-col-3_sub_title'>Register Now For Free!</span>
    //             </div>
    //             <form className='broker-banner-col-3_form' onSubmit={handleSubmit}>
    //                 <input
    //                     type='text'
    //                     placeholder='Name*'
    //                     className='broker-banner-col-3_input'
    //                     value={name}
    //                     onChange={(e) => setName(e.target.value)}
    //                     required
    //                 />
    //                 <input
    //                     type='tel'
    //                     placeholder='Phone Number*'
    //                     className='broker-banner-col-3_input'
    //                     value={phoneNumber}
    //                     onChange={(e) => setPhoneNumber(e.target.value)}
    //                     required
    //                 />
    //                 <input
    //                     type='text'
    //                     placeholder='Referral Code'
    //                     className='broker-banner-col-3_input'
    //                     value={referralCode}
    //                     onChange={(e) => setReferralCode(e.target.value)}
    //                 // required
    //                 />

    //                 <button type='submit' className='broker-banner-col-3_btn'>Submit</button>
    //             </form>
    //         </div>
    //     </div>
    // </Fade>
  );
};

export default BrokerForm;
