import { Card, Col, Container, Image, Row } from "react-bootstrap";
import productImg from "../../assets/images/product-img.png";
import { SearchFilter } from "../../../../../components";

import "../promoted-property/PromotedProperty.css";
import { Header } from "../../../../features";
import ProductCard from "../product-card/ProductCard";
import { useAuth } from "../../../../services/api";
import { useGetAllPropertyQuery } from "../../../../hooks/LoginQuery";
import SearchFilterBox from "../../../../../builder/dashboard/search-filter/SearchFilter";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  getAllproperties,
  getAllpropertyCategory,
} from "../../../../../API/action";
import { useNavigate, useParams } from "react-router-dom";
import HeaderCategory from "./HeaderCategory";
const Properties = () => {
  const [properties, setProperties] = useState([]);
  const dispatch = useDispatch();
  const { propertyType } = useParams();

  useEffect(() => {
    dispatch(
      getAllproperties(
        { propertyType: propertyType },
        (data) => {
          setProperties(data.data.data);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }, [propertyType]);
  return (
    <>
      <Header />
      <Container fluid="lg">
        <HeaderCategory />

        <section className="mt-3">
          <SearchFilterBox />
        </section>

        <section className="nes__product__section mt-5">
          <Row className="gy-3">
            <Col
              sm={12}
              className="d-flex justify-content-between align-items-center mb-3"
            >
              <h2 className="nes__product__section__title">
                Properties
              </h2>
              {/* <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                />
              </div> */}
            </Col>

            {properties.map((property) => (
              <Col
                key={property?._id}
                xs={12}
                sm={12}
                md={4}
                className="mb-4 gap-4"
              >
                <ProductCard {...property} pagination />
              </Col>
            ))}
          </Row>
        </section>
      </Container>
    </>
  );
};

export default Properties;
