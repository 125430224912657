import React, { useEffect, useState } from "react";
import SearchFilterBox from "../../../dsa/dashboard/search-filter/SearchFilter";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Form, Image } from "react-bootstrap";
import { Footer, Header } from "../../features";
import "../../../dsa/dashboard/property-details/BoughtProperty.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./BrokerPropertyClaim.css";
import {
  addClaim,
  getAllEligibleClaims,
  getClaimById,
  getClaimPDFByID,
} from "../../../API/action";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import formatDate from "../../helper/formatDate";
import { enqueueSnackbar } from "notistack";
import { SearchFilter } from "../../../components";
import html2pdf from "html2pdf.js";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

const dummyData = {
  visitId: "12324443",
  visitDate: "20 Nov 2022",
  builderName: "Lorem Ipsum",
  customerName: "Lorem Ipsum",
  unitType: "1233212432",
  unitNumber: "324234324",
  sellingPrice: "₹ 3.94 L",
  sellingDate: "20 Nov 2022",
};
const dummyData1 = {
  brokeragePercentage: "Brokerage %",
  brokerageAmount: "Brokerage Amount",
  claimedId: "Claimed ID",
  paymentDate: "Payment Date",
  paymentAmount: "₹ 3.94 L",
  paid: "Paid",
  claimPdf: "Claim PDF",
  paymentPdf: "Payment PDF",
  paymentId: "Payment ID",
};

const steps = [
  { title: "Claim", color: "#FC5C67" },
  { title: "Submitted", color: "#D64D00" },
  { title: "Approved", color: "#278FD9" },
  { title: "Payment Received  ", color: "#88C879" },
  { title: "Paid ", color: "#88C879" },
];
const PropertyEligibleClaim = () => {
  let claimHistory = localStorage.getItem("claimHistory");
  const [claim, setClaim] = useState([]);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const brokerId = localStorage.getItem("brokerID");
    let payload = {
      brokerId: brokerId,
    };
    dispatch(
      getAllEligibleClaims(
        payload,
        (data) => {
          setClaim(data.data.data.find((item) => item._id === id));
          localStorage.setItem(
            "eligibleClaims",
            JSON.stringify(data.data.data)
          );
        },
        (error) => {
          // Handle error
          console.error("Error fetching all claims:", error);
        }
      )
    );
  }, []);

  const handleDownload = (pdfUrl) => {
    const anchor = document.createElement("a");
    anchor.href = pdfUrl;
    anchor.setAttribute("download", "");
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const handleClaim = (index) => {
    if (!claim) {
      enqueueSnackbar("Please add all the required values", {
        variant: "warning",
      });
    } else {
      let payload = {
        claimType: "property",
        milestoneNumber: index.toString(),
        brokeragePercentage:
          claim?.propertyId?.milestonesTermsConditions[index]?.brokerage,
        brokerageAmount: parseInt(Math.floor(claim?.brokerageValue)).toString(),
        visitId: claim?.visitId?._id,
        propertyId: claim?.propertyId?._id,
        boughtPropertyId: claim?._id,
        builderId: claim?.builderId?._id,
        brokerId: claim?.brokerId?._id,
      };

      dispatch(
        addClaim(
          payload,
          (data) => {
            enqueueSnackbar("Claim has been raised successfully", {
              variant: "success",
            });
            window.location.reload();
          },
          (err) => {
            const message = err?.response?.data
              ? err?.response?.data
              : "Error occured While creating claim";
            enqueueSnackbar(message, { variant: "error" });
          }
        )
      );
    }
  };

  const handleClaimPDFDownload = (claimId) => {
    dispatch(
      getClaimPDFByID(
        claimId,
        (data) => {
          const invoiceHtml = data; // Assuming data contains the HTML content
          const options = {
            filename: "claim.pdf",
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { scale: 2, useCORS: true },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          };

          html2pdf().from(invoiceHtml).set(options).save();
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  const handlePaymentPDFDownload = (claimId) => {
    dispatch(
      getClaimPDFByID(
        claimId,
        (data) => {
          const invoiceHtml = data; // Assuming data contains the HTML content
          const options = {
            filename: "claim.pdf",
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { scale: 2, useCORS: true },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          };

          html2pdf().from(invoiceHtml).set(options).save();
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  return (
    <>
      <Header />
      <SearchFilter addBtn={true} />
      <Container>
        <div className="d-flex justify-content-between mt-5">
          <h3 className="heading">Property Claim</h3>
          {claimHistory === null && (
            <h6 className="text-secondary claim-id">Eligible Claim ID: {id}</h6>
          )}
        </div>
        <Paper sx={{ minHeight: "427.21px", mt: 2, mb: 2 }}>
          <Box p={4}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Typography className="subText2">Property Name</Typography>
                <Typography className="subText1">
                  {claim?.propertyId?.name}
                </Typography>
              </Box>
              <Box>
                <img src="/assets/location-1.svg" alt="location-1" />
              </Box>
            </Box>
            <Grid container spacing={"auto"}>
              <Grid container spacing={4} mt={2}>
                {/* {Object.entries(dummyData).map(([key, value]) => ( */}
                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Visit Id</Typography>
                  <Typography className="subText1">
                    {claim?.visitId?._id}
                  </Typography>
                </Grid>
                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Visit Date</Typography>
                  <Typography className="subText1">
                    {claim?.visitId?.date}
                  </Typography>
                </Grid>
                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Builder Name</Typography>
                  <Typography className="subText1">
                    {claim?.builderId?.companyName}
                  </Typography>
                </Grid>
                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Customer Name</Typography>
                  <Typography className="subText1">
                    {claim?.customerName ? claim?.customerName : "-"}
                  </Typography>
                </Grid>
                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Unit Type</Typography>
                  <Typography className="subText1">
                    {claim?.unitType ? claim?.unitType[0] : "-"}
                  </Typography>
                </Grid>
                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Unit Number</Typography>
                  <Typography className="subText1">
                    {claim?.unitNumber ? claim?.unitNumber : "-"}
                  </Typography>
                </Grid>
                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Selling Price</Typography>
                  <Typography className="subText1">
                    {claim?.sellingPrice
                      ? (claim?.sellingPrice / 100000).toFixed(2) + "L"
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Selling Date</Typography>
                  <Typography className="subText1">
                    {claim?.bookingDate ? claim?.bookingDate : "-"}
                  </Typography>
                </Grid>
                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">
                    Booking Reference Number
                  </Typography>
                  <Typography className="subText1">
                    {claim?.bookingReferenceNumber
                      ? claim?.bookingReferenceNumber
                      : "-"}
                  </Typography>
                </Grid>
                {/* ))} */}
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <h3 className="heading mt-5">Brokerage plan</h3>
        <Paper sx={{ height: "150.59px" }}>
          <Box p={4} sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                ₹ {claim?.brokerageValue}
              </Typography>
              <Typography variant="p" className="brokerage-head">
                *Brokerage Percentage -
                {((claim?.brokerageValue * 100) / claim?.sellingPrice).toFixed(
                  2
                )}
                %
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" color="textSecondary">
                Builder Form
              </Typography>
              <Box sx={{ width: "100%", cursor: "pointer" }}>
                {" "}
                <Box
                  component="img"
                  src="/assets/pdf-file.svg"
                  alt="img"
                  onClick={() => handleDownload(claim?.documents[0])}
                />
              </Box>
            </Box>
          </Box>
        </Paper>
        <h3 className="heading mt-4">Milestone</h3>

        {claim?.propertyId?.milestonesTermsConditions?.map(
          (milestone, index) => (
            <Paper sx={{ minHeight: "298.24px", height: "auto", my: 3 }}>
              <Box p={4} position={"relative"}>
                <Stack
                  flexGrow={1}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  mb={3}
                >
                  <Typography className="subText">
                    {" "}
                    {milestone.condition}
                  </Typography>
                  {/* <Typography className="subText2">{formatDate(claim?.propertyId?.createdAt)}</Typography> */}
                </Stack>

                <Grid container spacing={"auto"}>
                  <Grid container spacing={2}>
                    <Grid item xl={3} sm={6} xs={6}>
                      <Typography className="subText2">Brokerage %</Typography>
                      <Typography className="subText1">
                        {" "}
                        {milestone?.brokerage}
                      </Typography>
                    </Grid>
                    <Grid item xl={3} sm={6} xs={6}>
                      <Typography className="subText2">
                        Brokerage Amount
                      </Typography>
                      <Typography className="subText1">
                        ₹ {(claim?.brokerageValue * milestone?.brokerage) / 100}
                      </Typography>
                    </Grid>
                    <Grid item xl={3} sm={6} xs={6}>
                      <Typography className="subText2">Claimed ID</Typography>
                      <Typography className="subText1">
                        {claim?.claims?.length > 0 && claim?.claims[index]
                          ? claim?.claims[index]?._id
                          : "-"}
                      </Typography>
                    </Grid>
                    <Grid item xl={3} sm={6} xs={6}>
                      <Typography className="subText2">Payment Date</Typography>
                      <Typography className="subText1">
                        {claim?.paymentDate ? claim?.paymentDate : "-"}
                      </Typography>
                    </Grid>
                    <Grid item xl={3} sm={6} xs={6}>
                      <Typography className="subText2">Claim PDF</Typography>
                      <Typography className="subText1">
                        {claim?.claims[index] ? (
                          <IconButton
                            onClick={() =>
                              handleClaimPDFDownload(claim?.claims[index]?._id)
                            }
                          >
                            <InsertDriveFileIcon
                              sx={{
                                width: {
                                  md: "2rem",
                                },
                                height: {
                                  md: "2rem",
                                },
                              }}
                            />
                          </IconButton>
                        ) : (
                          "-"
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xl={3} sm={6} xs={6}>
                      <Typography className="subText2">Payment PDF</Typography>
                      <Typography className="subText1">
                        {claim?.claims[index] &&
                        claim?.claims[index]?.paymentId ? (
                          <IconButton
                            onClick={() =>
                              handlePaymentPDFDownload(
                                claim?.claims[index]?.paymentId
                              )
                            }
                          >
                            <InsertDriveFileIcon
                              sx={{
                                width: {
                                  md: "2rem",
                                },
                                height: {
                                  md: "2rem",
                                },
                              }}
                            />
                          </IconButton>
                        ) : (
                          "-"
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xl={3} sm={6} xs={6}>
                      <Typography className="subText2">Payment ID</Typography>
                      <Typography className="subText1">
                        {claim?.claims[index] && claim?.claims[index]?.paymentId
                          ? claim?.claims[index]?.paymentId
                          : "-"}
                      </Typography>
                    </Grid>
                    <Grid item xl={3} sm={6} xs={6}>
                      {claimHistory !== null ? (
                        <Button
                          variant="outlined"
                          sx={{
                            borderRadius: "20px",
                            border: "1px solid",
                            borderColor:
                              claim?.claims[index]?.claimStatus === "approved"
                                ? "#278fd999"
                                : "red",
                            background:
                              claim?.claims[index]?.claimStatus === "approved"
                                ? "#278fd999"
                                : "#FFE4D4",
                            color: "#000",
                            "&:hover": {
                              color: "#000",
                              border: "1px solid #FFA500",
                              background:
                                claim?.claims[index]?.claimStatus === "approved"
                                  ? "#278fd9"
                                  : "#D64D00",
                            },
                          }}
                        >
                          {claim.claims[index]
                            ? claim?.claims[index]?.claimStatus
                            : "Claim"}
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          sx={{
                            borderRadius: "20px",
                            border: "1px solid",
                            borderColor:
                              claim?.claims[index]?.claimStatus === "approved"
                                ? "#278fd999"
                                : "red",
                            background:
                              claim?.claims[index]?.claimStatus === "approved"
                                ? "#278fd999"
                                : "#FFE4D4",
                            color: "#000",
                            "&:hover": {
                              color: "#000",
                              border: "1px solid #FFA500",
                              background:
                                claim?.claims[index]?.claimStatus === "approved"
                                  ? "#278fd9"
                                  : "#D64D00",
                            },
                          }}
                          disabled={
                            (index !== 0 &&
                              claim?.claims[index - 1]?.claimStatus !=
                                "paid") ||
                            claim?.claims[index] !== undefined
                          }
                          onClick={() => handleClaim(index)}
                        >
                          {claim.claims[index]
                            ? claim?.claims[index]?.claimStatus
                            : "Claim"}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          )
        )}
        <h3 className="heading mt-4">Payment Flow</h3>
        <Paper sx={{ minHeight: "320.24px", height: "auto", mt: 4 }}>
          <Grid container spacing={2} p={{ xl: 0, xs: 2 }}>
            <Grid item xl={4} mt={2}>
              <div className="timeline">
                {steps.map((step, index) => (
                  <div
                    style={{
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "center",
                      color: step.color,
                    }}
                  >
                    <div>
                      <h6>{step.title}</h6>
                      {index !== steps.length - 1 && (
                        <div className="dotted-line">
                          <KeyboardArrowDownIcon
                            style={{ fontSize: 20, color: "black" }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </Grid>
            <Grid item xl={8} lg={8} md={8} xs={6}>
              <Box
                mt={1}
                sx={{
                  width: "95%",
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#F8F8F8",
                  borderRadius: "10px",
                }}
              >
                <p className="flex-fill m-0 p-2">
                  The claim should be filed once the Milestone Condition has
                  been satisfactorily accomplished.
                </p>
              </Box>
              <Box
                mt={1.5}
                sx={{
                  width: "95%",
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#F8F8F8",
                  borderRadius: "10px",
                }}
              >
                <p className="flex-fill m-0 p-2">
                  Claim has been successfully submitted by the Broker, refer
                  Claim PDF for more details.
                </p>
              </Box>
              <Box
                mt={1.5}
                sx={{
                  width: "95%",
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#F8F8F8",
                  borderRadius: "10px",
                }}
              >
                <p className="flex-fill m-0 p-2">
                  The Nestoriub team has reviewed and approved the claim.
                  Consequently, the invoice has been forwarded to the builder
                  for payment.
                </p>
              </Box>
              <Box
                mt={1.5}
                sx={{
                  width: "95%",
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#F8F8F8",
                  borderRadius: "10px",
                }}
              >
                <p className="flex-fill m-0 p-2">
                  NestoHub has received the payment for the claim from the
                  Builder.
                </p>
              </Box>
              <Box
                mt={1.5}
                sx={{
                  width: "95%",
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#F8F8F8",
                  borderRadius: "10px",
                }}
              >
                <p className="flex-fill m-0 p-2">
                  Claim amount has been successfully deposited into the
                  registered Bank Account of the Broker, please refer Payment
                  PDF for more details.
                </p>
              </Box>
            </Grid>
          </Grid>
        </Paper>
        {claim?.terms && (
          <>
            <h3 className="heading mt-4">Terms & Conditions</h3>

            <Box
              sx={{
                border: "2.52px solid #E3E3E7",
                borderRadius: "25.19px",
                minHeight: "",
                mb: 5,
              }}
            >
              <Typography className="subText2" p={4}>
                {claim?.terms}
              </Typography>
            </Box>
          </>
        )}
        {/* <Box mt={4} sx={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <Button
            variant="contained"
            sx={{
              bgcolorcolor: "#278FD9",
              borderRadius: "146.63px",
              color: "white",
              width: "40%",
              textTransform: "none",
            }}
          >
            {" "}
            Download PDF
          </Button>
        </Box> */}
      </Container>
      <Footer />
    </>
  );
};

export default PropertyEligibleClaim;
