import React, { useMemo } from 'react';
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  ResponsiveContainer,
} from 'recharts';

const PriceCharts = ({ data }) => {
  // console.log('graph data', data);
  const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7f0e', '#ff0000']; // Define array of colors

  const chartData = useMemo(() => {
    if (!data || data.length === 0) {
      return [];
    }

    const calculateChartData = () => {
      const chartData = [];
      const currentYear = new Date().getFullYear();
      data.forEach((locality, index) => {
        const priceChange = {
          locality: locality.withLocality,
          data: [],
          color: colors[index % colors.length], // Assign color from colors array
        };
        const lastYearPrices = [
          parseInt(locality.currentPrice),
          parseInt(locality.last1Year),
          parseInt(locality.last2year),
          parseInt(locality.last3Year),
          parseInt(locality.last4Year),
        ];
        lastYearPrices.forEach((price, index) => {
          const year = currentYear - index;
          if (!isNaN(price) && year <= currentYear) {
            // Check if price is a valid number and year is not after the current year
            priceChange.data.push({ year, price });
          }
        });
        chartData.push(priceChange);
      });
      return chartData;
    };

    return calculateChartData();
  }, [data, colors]);

  const allYears = useMemo(() => {
    const yearsSet = new Set();
    chartData.forEach((localityData) => {
      localityData.data.forEach((entry) => yearsSet.add(entry.year));
    });
    return Array.from(yearsSet).sort((a, b) => a - b);
  }, [chartData]);

  const minYear = allYears[0];
  const maxYear = allYears[allYears.length - 1];

  const ticks = allYears.filter((year) => year >= minYear && year <= maxYear);

  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  const tooltipFormatter = (value, name, props) => {
    if (name === 'price') {
      const { payload } = props;
      const index = payload.findIndex((entry) => entry.value === value);
      if (index >= 2) {
        const sum =
          payload[index].value +
          payload[index - 1].value +
          payload[index - 2].value;
        const average = sum / 3;
        return `Average: ${average.toFixed(2)}`;
      }
    }
    return value;
  };

  return (
    <ResponsiveContainer width={'100%'} height={400}>
      <AreaChart margin={{ top: 20, right: 3, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis
          scaleType='linear'
          dataKey='year'
          tick={{ fontSize: 12 }}
          ticks={ticks}
        />
        <YAxis />
        <Tooltip formatter={tooltipFormatter} />
        {chartData.map((localityData, index) => (
          <Area
            key={index}
            type='monotone'
            dataKey='price'
            stroke={localityData.color}
            fill='none'
            data={localityData.data}
            name={localityData.locality}
            stackId='1'
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default PriceCharts;
