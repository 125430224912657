import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import './DashboardTabs.css';

const DashboardTabs = ({
  children,
  tabsKey,
  activeState,
  onTabClick,
  renderChildren = true,
}) => {
  return (
    <div className='dashboard-tabs-container'>
      <Tabs
        id='dashboard-tabs'
        activeKey={activeState}
        onSelect={(k) => onTabClick(k)}
        className='mb-3 p-5 rounded flex-nowrap overflow-auto tabwidth'
        style={{flexDirection:'row'}}
      >
        {tabsKey &&
          tabsKey.map((item, index) => (
            <Tab
              eventKey={item}
              title={item}
              key={index}
              className={activeState === item ? 'active-tab' : ''}
            >
              {renderChildren ? (
                <div className='bg-white p-3 sdef'>{children}</div>
              ) : null}
            </Tab>
          ))}
      </Tabs>
    </div>
  );
};

export default DashboardTabs;
