/* eslint-disable react-hooks/exhaustive-deps */
import { BsSearch, BsMic, BsGeoAlt } from 'react-icons/bs';
import { InputGroup, FormControl, Container, Form } from 'react-bootstrap';
import './SearchFilter.css';
import { RiAddFill } from 'react-icons/ri';
import { useEffect, useState } from 'react';
import Filter from '../../../components/search-filter/Filter/Filter';
import { MdLocationSearching, MdOutlineArrowDropDown } from 'react-icons/md';
import Dropdown from 'react-bootstrap/Dropdown';
import { Modal } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import { getAPI, postAPI } from '../../Api/ApiRequest';
import { apiEndpoints } from '../../Api/ApiEndpoint';
import { useSelector } from 'react-redux';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import EditModal from '../Builderprofile/queries-history/EditModal';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import { Add } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import { CustomBtn } from '../../../broker/components/mui/CustomBtn';
import { useLocation, useNavigate } from 'react-router-dom';

const SearchFilterBox = (props) => {
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  // const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [typeOfProperty, setTypeOfProperty] = useState('');
  const [locationProperty, setLocationProperty] = useState('');
  const [project, setProject] = useState('');
  const [description, setDescription] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [showAnotherPopup, setShowAnotherPopup] = useState(false);
  // const [showRole, setShowRole] = useState(false);
  const [showRoleProperty, setShowRoleProperty] = useState(false);
  // const [showRoleOption,setShowRoleModal ] = useState(false);

  const [data, setData] = useState([]);
  const [reason, setReason] = useState({});
  const [comments, setComments] = useState({});

  const [add, setAdd] = useState('');
  // const [name, setName] = useState("");
  const [mail, setMail] = useState('');
  const [number, setNumber] = useState('');
  const [property, setProperty] = useState([]);
  const [propertyOptions, setPropertyOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const getAllProperty = async () => {
      // debugger;
      const response = await getAPI(apiEndpoints?.getAllproperty);

      const propertySelectData = [];
      if (response?.data) {
        response?.data?.forEach((element) => {
          propertySelectData?.push({ value: element._id, label: element.name });
        });
      }
      setPropertyOptions(propertySelectData);
    };

    getAllProperty();
  }, []);

  const role = [
    { value: 'property', label: 'Property Manager' },
    { value: 'finance', label: 'Finance Manager' },
  ];
  const properties = [
    { value: 'Flats/Apartment', label: 'Flats/Apartment' },
    { value: 'Farmhouse/Villa', label: 'Farmhouse/Villa' },
    { value: 'Plots/Lands', label: 'Plots/Lands' },
    { value: 'Commercial', label: 'Commercial' },
  ];
  const handleVoiceSearch = () => {
    const recognition = new window.webkitSpeechRecognition();
    recognition.lang = 'en-IN';
    recognition.start();
    recognition.onresult = (event) => {
      const speechResult = event?.results[0][0]?.transcript;
      setTranscript(speechResult);
      handleSearch(speechResult);
    };
  };
  const handleSearch = (query) => {
    const filteredData = data?.filter((item) =>
      item?.name?.toLowerCase()?.includes(query?.toLowerCase())
    );
    setSearchResults(filteredData);
  };
  const handleRaiseDispute = async () => {
    setShowPopup(true);
    const formData = {
      reason: {},
      comments: {},
    };
    // console.log(formData);

    const response = await postAPI(apiEndpoints?.addRaiseDispute, formData);
  };
  const handleAnotherAction = async () => {
    setShowAnotherPopup(true);
    if (email.length <= 1) {
      //Set Error true
      return;
    }
    const formData = {
      name: name,
      phoneNumber: phone,
      email: email,
      typeOfProperty: [],
      // locationProperty: locationProperty,
      builderId: builderId,
      projectName: project,
      description: description,
    };
    const response = await postAPI(
      apiEndpoints.addRequestNewProperty,

      formData
    );
    debugger;
    setData(response?.data ?? []);
  };

  // const handleToggle = () => setShowRole(!showRole);
  const handleShowModalRole = () => {
    setShowRoleProperty(true);
  };
  const builderId = useSelector((state) => state?.auth?.builderId);
  const onAddRole = async () => {
    const formData = {
      add: add,
      name: name,
      email: mail,
      mobileNumber: number,
      selectProperties: property,
      builderId: builderId,
    };
    const response = await postAPI(apiEndpoints.addRole, formData);
    if (response?.code === 200) {
      setShowRoleProperty(false);
      setName('');
      setMail('');
      setNumber('');
    }
  };
  const handleCloseEditModal = () => {
    setShowRoleProperty(false);
  };
  const handleMenuItemClick = (event, action) => {
    if (action === 'requestProperty') {
      handleAnotherAction();
    } else if (action === 'addRole') {
      handleShowModalRole();
    }
    setAnchorEl(null);
  };

  const handleFilterClose = (selectedData) => {
    setShowFilter(false);
    navigate('filteredproperty', { selectedData: selectedData });
  };


  const [searchInput, setSearchInput] = useState("");

  const changeHandler = (e) => {
    setSearchInput(e.target.value);
  };
  const location = useLocation();

  
  const onSubmit = (e) => {
    e.preventDefault();
    if(searchInput){
      navigate(`/builder/search-property/${searchInput}`);
    }
  };




  return (
    <Container>
      <Box my={4} sx={{ width: '100%' }}>
        <Grid container justifyContent={'space-between'} spacing={1}>
          <Grid item md={12} xs={12}>
            <Grid container spacing={2}>
              <Box
                pl={1}
                component={"form"}
              onSubmit={onSubmit}
                className=' search-shadow d-flex align-items-center  rounded-2 '
                sx={{ width: '100%' }}
              >
                <Grid item md={2} xs={2}>
                  <Stack
                    pl={{ md: 3, xs: 0.3 }}
                    component={'button'}
                    // onClick={() => {
                    //   setShowFilter(true);
                    // }}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      borderRight: { md: '2px solid #EBECF0', xs: 'none' },
                      width: '100%',
                    }}
                  >
                    <Typography sx={{ fontSize: { md: '1rem', xs: '.8rem' } }}>
                      Filter
                    </Typography>
                    <ArrowDropDownIcon />
                  </Stack>
                </Grid>

                <Grid item md={8} xs={3}>
                  <Box component={'div'} className='flex-grow-1'>
                    <InputGroup>
                      <InputGroup.Text className='border-0 bg-transparent'>
                        <BsSearch color='#B3B4B8' />
                      </InputGroup.Text>
                      <FormControl
                        placeholder='Search'
                        className='border-0'
                        style={{ background: '#fff' }}
                        // value={transcript}
                        // onChange={(event) => {
                        //   setTranscript(event?.target.value);
                        //   handleSearch(event?.target.value);
                        // }}

                        value={searchInput?.query}
                        onChange={changeHandler}

                      />
                    </InputGroup>
                  </Box>
                </Grid>
                <Grid item md={0.5} xs={2}>
                  {/* <IconButton
                    sx={{
                      color: '#278FD9',
                      bgcolor: '#ECF7FF',
                      width: '1.5rem',
                      height: '1.5rem',
                    }}
                  >
                    <LocationSearchingIcon sx={{ width: '1rem' }} />
                  </IconButton> */}
                </Grid>
                <Grid item md={0.5} xs={2}>
                  {' '}
                  {/* <IconButton
                    onClick={handleVoiceSearch}
                    sx={{
                      color: '#278FD9',
                      bgcolor: '#ECF7FF',
                      width: '1.5rem',
                      height: '1.5rem',
                    }}
                  >
                    <KeyboardVoiceIcon sx={{ width: '1rem' }} />
                  </IconButton> */}
                </Grid>
                <Grid item md={1} xs={2}>
                  <Button
                    variant='contained'
                    sx={{
                      textTransform: 'none',
                      width: { md: 'auto', xs: '2rem' },
                      height: { md: 'auto', xs: '2rem' },
                    }}
                    // onClick={() => {
                    //   handleSearch(transcript);
                    // }}
                    type="submit"
                  >
                    Search
                  </Button>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          {/* <Grid item md={1} xs={1.5} mt={{ md: -2, xs: -1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <IconButton
                onClick={handleMenuClick}
                sx={{
                  color: 'white',
                  bgcolor: '#278FD9',
                  width: { md: '3rem', xs: '2rem' },
                  height: { md: '3rem', xs: '2rem' },
                }}
              >
                <Typography sx={{ fontSize: { md: '2.5rem', xs: '2rem' } }}>
                  +
                </Typography>
              </IconButton>
            </Box>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              {props?.isRoleScreen && (
                <MenuItem onClick={handleAnotherAction}>
                  Request Property
                </MenuItem>
              )}
              <MenuItem onClick={handleShowModalRole}>Add role</MenuItem>
            </Menu>
            <Modal show={showPopup} onHide={() => setShowPopup(false)}>
              <Modal.Header className='justify-content-center' closeButton>
                <Modal.Title>Raise Dispute</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container className=' dashboard__wrapper'>
                  <Form className='profile__form ps-2'>
                    <Form.Group className='mb-4' controlId='email'>
                      <Form.Label>
                        <h5>Reason *</h5>
                      </Form.Label>
                      <CreatableSelect
                        isMulti
                        placeholder='Choose'
                        // options={reason}
                        value={reason}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setReason(e.target.value);
                        }}
                        className='rounded-0'
                        styles={{ background: '#F8F8F8' }}
                      />
                      <br />
                      <Form.Label>
                        <h5>Any Comment</h5>
                      </Form.Label>

                      <Form.Control
                        className='rounded-0 d-flex justify-content-start'
                        as='textarea'
                        placeholder='Lorem Ipsum'
                        value={comments}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setComments(e.target.value);
                        }}
                        style={{
                          fontFamily: 'Bahnschrift',
                          height: '241px',
                        }}
                      />
                    </Form.Group>
                  </Form>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant='primary'
                  className=' align-items-center'
                  onClick={() => setShowPopup(false)}
                >
                  Submit
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={showAnotherPopup}
              onHide={() => setShowAnotherPopup(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>Request Property</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form className='profile__form'>
                  <Form.Group className='mb-3' controlId='email'>
                    <Form.Label className='mb-0'>Builder Name</Form.Label>
                    <Form.Control
                      className='rounded-2'
                      type='text'
                      placeholder='Builder Name'
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label className='mb-0'>Enter Phone Number</Form.Label>
                    <Form.Control
                      className='rounded-2'
                      maxLength='10'
                      type='text'
                      placeholder='Enter Phone Number'
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label className='mb-0'>
                      Enter Email Address
                    </Form.Label>
                    <Form.Control
                      className='rounded-2'
                      type='email'
                      placeholder='Enter Email Address'
                      // value={name}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label className='mb-0'>Type Of Property</Form.Label>
                    <CreatableSelect
                      isMulti
                      placeholder='Choose Type Of Property'
                      // options={add}
                      // className="rounded-2"
                      // styles={{ background: "#F8F8F8" }}
                      // onChange={(e) => {
                      //   setTypeOfProperty(e.target.value);
                      // }}
                      options={propertyOptions}
                      onChange={(e) => {
                        debugger;
                        console.log(e);
                        const selected = [];
                        e?.forEach((element) => {
                          selected?.push(element?.value);
                        });
                        setTypeOfProperty(selected);
                      }}
                    />
                  </Form.Group>

                  <Form.Group className='mb-3'>
                    <Form.Label className='mb-0'>Name Of Project</Form.Label>
                    <Form.Control
                      className='rounded-2'
                      type='text'
                      placeholder='Name Of Project'
                      onChange={(e) => {
                        setProject(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label className='mb-0'>Brief Description</Form.Label>
                    <Form.Control
                      className='rounded-2'
                      as='textarea'
                      placeholder='Write Brief Description'
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant='secondary'
                  onClick={() => setShowAnotherPopup(false)}
                >
                  Close
                </Button>
                <Button variant='primary' onClick={handleAnotherAction}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
            <EditModal open={showRoleProperty} onClose={handleCloseEditModal} />
          </Grid> */}
        </Grid>
        <Filter open={showFilter} setOpen={setShowFilter} handleClose={handleFilterClose} />
      </Box>
    </Container>
  );
};

export default SearchFilterBox;
