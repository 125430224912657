import React from 'react';
import Slider from 'react-slick';
import './BannerSlider.css';
import { heroData } from '../data';

const CommonData = () => {
  return (
    <>
      <img
        src="/500x/icons/curve-arrow.webp"
        className="arrow-img"
        alt="arrow-img"
      />
      <img
        src="/500x/character-new.webp"
        className="cartoon_img"
        alt="cartoon_img"
      />
    </>
  );
};

const BannerSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: 'linear',
  };
  return (
    <>
      <div>
        <div className="slider-div">
          <Slider {...settings}>
            {heroData.map((item) => (
              <div className="d-flex slider-div-2" key={item._id}>
                <CommonData />
                <img src={item.image} className="slider-img" alt={item.name} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default BannerSlider;
