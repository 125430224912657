import React, { useEffect, useState } from "react";
import SearchFilterBox from "../../../components/search-filter/SearchFilter";
import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";

import { Footer, Header } from "../../features";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDispatch } from "react-redux";
import html2pdf from "html2pdf.js";
import { getAllVisits, getVisitsPdf } from "../../../API/action";
const dummyData = [
  {
    claimId: "2111321321",
    transactionId: "866656554444",
    paymentDate: "12 Jan 2023",
    amount: "20,000",
  },
  {
    claimId: "2111321321",
    transactionId: "866656554444",
    paymentDate: "12 Jan 2023",
    amount: "20,000",
  },
  {
    claimId: "2111321321",
    transactionId: "866656554444",
    paymentDate: "12 Jan 2023",
    amount: "20,000",
  },
  {
    claimId: "2111321321",
    transactionId: "866656554444",
    paymentDate: "12 Jan 2023",
    amount: "20,000",
  },
  {
    claimId: "2111321321",
    transactionId: "866656554444",
    paymentDate: "12 Jan 2023",
    amount: "20,000",
  },
  {
    claimId: "2111321321",
    transactionId: "866656554444",
    paymentDate: "12 Jan 2023",
    amount: "20,000",
  },
  {
    claimId: "2111321321",
    transactionId: "866656554444",
    paymentDate: "12 Jan 2023",
    amount: "20,000",
  },
  {
    claimId: "2111321321",
    transactionId: "866656554444",
    paymentDate: "12 Jan 2023",
    amount: "20,000",
  },
  {
    claimId: "2111321321",
    transactionId: "866656554444",
    paymentDate: "12 Jan 2023",
    amount: "20,000",
  },
  {
    claimId: "2111321321",
    transactionId: "866656554444",
    paymentDate: "12 Jan 2023",
    amount: "20,000",
  },
];

const Visits = () => {
  const dispatch = useDispatch();
  const [allVisit, setAllVisit] = useState([]);

  const handleDownloadClick = () => {
    dispatch(
      getVisitsPdf(
        {},
        (data) => {
          const earningHTML = data.data;
          const options = {
            filename: "Visits.pdf",
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { scale: 2, useCORS: true },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          };

          html2pdf().from(earningHTML).set(options).save();
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  useEffect(() => {   
  
    dispatch(
      getAllVisits(
        {
          brokerId: localStorage.getItem("brokerID"),
        },
        (data) => {
          setAllVisit(data.data.data);
        },
        (err) => {
          console.log(err);
        }
      )
    );

  }, []);

console.log(allVisit)



  return (
    <>
      {" "}
      <Header />;
      <SearchFilterBox addBtn={false} />;
      <Container>
        <Typography
          my={2}
          className="primary-heading"
          sx={{ fontWeight: "bold" }}
        >
          Wallet
        </Typography>
        <Paper
          style={{
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "15px",
          }}
        >
          <Box
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              height: "5rem",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>Visit Details</Typography>
            <Typography sx={{ fontWeight: "bold" }}>{allVisit?.length} Visits</Typography>
          </Box>
        </Paper>

        <Box mt={4}>
          <Table>
            <Box mb={4}>
              <TableHead>
                <TableRow sx={{ fontWeight: "bold" }}>
                  <TableCell
                    align="center"
                    sx={{
                      borderBottom: "none",
                      width: "10%",
                      fontWeight: "bold",
                    }}
                  >
                    Visit ID
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderBottom: "none",
                      width: "45%",
                      fontWeight: "bold",
                    }}
                  >
                    Property Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderBottom: "none",
                      width: "45%",
                      fontWeight: "bold",
                    }}
                  >
                    Visit Date
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderBottom: "none",
                      width: "35%",
                      fontWeight: "bold",
                    }}
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
            </Box>
            <TableBody>
              {allVisit.map((row, index) => (
                <Box key={index} sx={{ marginBottom: "1rem" }}>
                  <TableRow
                    key={index}
                    style={{
                      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
                      borderRadius: "15px",
                      marginBottom: "2rem !important",
                    }}
                  >
                    <TableCell
                      align="center"
                      sx={{ borderBottom: "none", width: "10%" }}
                    >
                      {row._id}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ borderBottom: "none", width: "45%" }}
                    >
                      {row.propertyName}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ borderBottom: "none", width: "45%" }}
                    >
                      {new Date(row.updatedAt).toLocaleDateString()}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ borderBottom: "none", width: "25%" }}
                    >
                      {row.visitStatus}
                    </TableCell>
                  </TableRow>
                </Box>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Box mt={4} sx={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <Button
            variant="contained"
            sx={{
              bgcolorcolor: "#278FD9",
              borderRadius: "146.63px",
              color: "white",
              width: "40%",
              textTransform: "none",
              height: "3rem",
            }}
            onClick={handleDownloadClick}
          >
            {" "}
            Download PDF
          </Button>
        </Box>
        <Box mt={4}></Box>
      </Container>
      <Footer />
    </>
  );
};

export default Visits;
