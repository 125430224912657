import CreatableSelect from "react-select/creatable";
// import RangeSlider from '../../range-slider/RangeSlider';
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { CustomModal } from "../../../../../components";
import RangeSlider from "../../../../../components/range-slider/RangeSlider";
import DashboardTabs from "../../../../../components/dashboard-tabs/DashboardTabs";
import {
  useAddVisitMutation,
  useGetAllCustomer,
  useGetAllPropertyQuery,
} from "../../../../hooks/LoginQuery";
import { useEffect, useState } from "react";
import VisitVerification from "./VisitVerification";
import { useDispatch, useSelector } from "react-redux";
import { hideBookAVisit } from "../../../../store/ModalsSlice";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Grid, Autocomplete, TextField, Box, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import {
  addVisit,
  getAllCustomers,
  getAllRequirement,
  getLocationByInput,
} from "../../../../../API/action";
import formatNumber from "../../../../helper/common";
import {
  formatNumberWithIndianRupee,
  inWords,
} from "../../../../../components/functions";
import PromotedVisitVerification from "./PromotedVisitVerification";

const tabKey = ["Old Customer", "New Customer"];

const BookAVisitModal = ({
  show,
  onHide,
  proprtyName,
  BuilderId,
  propertyId,
  maximumPrice,
  minimumPrice,
  customers,
  requirementId,
  isPromoted = false,
}) => {
  const brokerID = localStorage.getItem("brokerID");

  const {
    isAddVisitLoading,
    isAddVisitSuccess,
    isAddIsError,
    isAddVisitError,
    isAddVisitResponse,
  } = useAddVisitMutation();

  const [values, setValues] = useState([0, 160]);
  const [activeTab, setActiveTab] = useState("Old Customer");
  const [loading, setLoading] = useState(false);
  const [propertyName, setPropertyName] = useState(proprtyName || "");
  const [isPropertyPromoted, setIsPropertyPromoted] = useState(isPromoted);
  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [unitType, setUnitType] = useState([]);
  const [chooseSlot, setChooseSlot] = useState(null);
  const [preferredLocation, setPreferredLocation] = useState([]);
  const [propId, setPropId] = useState(propertyId || "");
  const [propBuilderId, setPropBuilderId] = useState(BuilderId || "");
  const [locationSearch, setLocationSearch] = useState([]);
  const [propertyLocation, setPropertyLocation] = useState([]);

  const [visitData, setVisitData] = useState({
    propertyName: "",
    date: "",
    phoneNumber: "",
    email: "",
    chooseSlot: null,
    minPrice: "",
    maxPrice: "",
    unitType: [],
    preferredLocation: [],
    clientName: "",
    customerId: null,
    requirementId: null,
    builderId: BuilderId,
    brokerId: brokerID,
    propertyId: propertyId,
  });

  const handleClientChange = (selectedOption) => {
    if (selectedOption) {
      const selectedCustomer = customers.find(
        (customer) => customer.clientName === selectedOption.value
      );
      if (selectedCustomer) {
        setVisitData({
          ...visitData,
          clientName: selectedCustomer.clientName,
          phoneNumber: selectedCustomer.phoneNumber,
          email: selectedCustomer.email,
          customerId: selectedCustomer._id ? selectedCustomer._id : null,
        });
      }
    } else {
      setVisitData({
        ...visitData,
        clientName: "",
        phoneNumber: "",
        email: "",
      });
    }
  };

  const locationOptions = [{ value: "gurgaon", label: "gurgaon" }];

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedValue = value;
    setVisitData({ ...visitData, [name]: updatedValue });
  };

  const handlePhoneChange = (value, country, e, formattedValue) => {
    setPhoneNumber(value);
  };

  const handleUnitTypeChange = (selectedOptions) => {
    // const values = selectedOptions
    //   ? selectedOptions.map((option) => String(option.value))
    //   : [];
    // setVisitData({
    //   ...visitData,
    //   unitType: values,
    // });
    setUnitType(selectedOptions);
  };

  const handlePreferredLocationChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions?.map((option) => option.value)
      : [];
    setPreferredLocation(values);
  };
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setClientName("");
    setPhoneNumber("");
    setEmail("");
    setIsDisable(false);
  };
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Reset the state to hide the modal when it's closed
    if (!show) {
      setShowModal(false);
    }
  }, [show]);

  const [unitTypesOptions, setUnitTypesOptions] = useState([
    // { value: "1 RK", label: "1 RK" },
    // { value: "2 BHK", label: "2 BHK" },
    // { value: "3 BHK", label: "3 BHK" },
    // { value: "4 BHK", label: "4 BHK" },
    // { value: "5 BHK", label: "5 BHK" },
    // { value: "5+ BHK", label: "5+ BHK" },
    // { value: "Low Rise", label: "Low Rise" },
    // { value: "High Rise", label: "High Rise" },
    // { value: "Farmhouse Land", label: "Farmhouse Land" },
    // { value: "SCO Plot", label: "SCO Plot" },
    // { value: "Commercial Land", label: "Commercial Land" },
    // { value: "Residential Plot", label: "Residential Plot" },
    // { value: "Villa", label: "Villa" },
    // { value: "Farmhouse", label: "Farmhouse" },
    // { value: "Duplex House", label: "Duplex House" },
    // { value: "Builder Floor", label: "Builder Floor" },
    // { value: "Commercial Space", label: "Commercial Space" },
    // { value: "SCO", label: "SCO" },
    // { value: "Warehouse", label: "Warehouse" },
  ]);

  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const handleSlotChange = (slotOption, slotTime) => {
    // console.log("Selected Slot:", slotTime);
    setSelectedSlot((prevSelectedSlot) =>
      prevSelectedSlot === slotTime ? null : slotTime
    );
    setSelectedOption(slotOption);
    setChooseSlot(String(slotTime));

    // setVisitData({
    //   ...visitData,
    //   chooseSlot: String(slotTime),
    // });
  };

  const [isDisable, setIsDisable] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [visitId, setVisitId] = useState("");

  // const handleCustomerChange = (selectedOption) => {
  //   const customer = allCustomers.find(
  //     (customer) => customer._id == selectedOption.value
  //   );
  //   setIsDisable(true);
  //   setPhoneNumber(customer.phoneNumber);
  //   setEmail(customer.email);
  //   setCustomerId(customer._id)
  //   console.log(customer);
  // };

  const dispatch = useDispatch();
  const phoneNumberRegex = /^[0-9]{12}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [showVisitOtp, setShowVisitOtp] = useState("");

  const handleHide = () => {
    setShowVisitOtp(false);
  };
  const handleSubmit = () => {
    if (
      propertyName === "" ||
      clientName === "" ||
      phoneNumber === "" ||
      email === "" ||
      unitType.length === 0 ||
      locationSelected.length === 0 ||
      minPrice === "" ||
      minPrice == 0 ||
      maxPrice === "" ||
      maxPrice == 0 ||
      date === "" ||
      chooseSlot === ""
    ) {
      enqueueSnackbar("Please add all the required values", {
        variant: "warning",
      });
    } else if (minPrice > maxPrice) {
      enqueueSnackbar("Please enter valid price range", { variant: "warning" });
    } else if (!emailRegex.test(email)) {
      enqueueSnackbar("Please enter a valid email", { variant: "warning" });
    } else {
      setLoading(true);
      let payload = {
        propertyName,
        date: new Date(date).toLocaleDateString('en-GB').split('/').reverse().join('-') + " " + new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', hour12: false}),
        phoneNumber,
        email,
        chooseSlot: selectedOption + " Slot-"+chooseSlot,
        minPrice,
        maxPrice,
        unitType: unitType.map((option) => String(option.value)),
        preferredLocation: locationSelected,
        clientName,
        builderId: propBuilderId,
        brokerId: brokerID,
        propertyId: propId,
      };
      if (activeTab === "Old Customer") {
        payload.customerId = customerId;
      }
      if (requirement != "new") {
        payload.requirementId = requirement;
      }
      dispatch(
        addVisit(
          payload,
          (data) => {
            setVisitId(data.data.data._id);
            enqueueSnackbar("Visit has been created successfully", {
              variant: "success",
            });
            setLoading(false);
            onHide();
            if (isPropertyPromoted) {
              setShowVisitOtp(true);
            }
          },
          (err) => {
            const message =
              err?.response?.data?.message ||
              "Error occurred while creating visit";
            enqueueSnackbar(message, { variant: "error" });
            setLoading(false);
          }
        )
      );
    }
  };

  const {
    getAllPropertyIsLoading,
    getAllPropertyResponse, // Changed to use getAllPropertyResponse directly
  } = useGetAllPropertyQuery();

  // console.log("prop data is", getAllPropertyResponse);

  // No need for filtering, directly use getAllPropertyResponse
  const allProperties = getAllPropertyResponse || [];

  useEffect(() => {
    const selectedProperty = allProperties.find(
      (item) => item._id == propertyId
    );
    if (selectedProperty) {
      var allUnitTypes = [];
      selectedProperty.floorPlanAndPricing.map((item) => {
        allUnitTypes.push(...item.unitType);
      });
      allUnitTypes = [...new Set(allUnitTypes)];
      setUnitTypesOptions(
        allUnitTypes.map((item) => {
          return { value: item, label: item };
        })
      );
    }
  }, [show]);

  const [locationSelected, setLocationSelected] = useState([]);

  const AddOrUpdateLocalStorage = (formData) => {
    if (localStorage.getItem("formData")) {
      var obj = JSON.parse(localStorage.getItem("formData"));
      var final = { ...obj, ...formData };
      localStorage.setItem("formData", JSON.stringify(final));
    } else {
      localStorage.setItem("formData", JSON.stringify(formData));
    }
  };

  // const handleSetFormValues = (name, value) => {
  //   if (name === 'location') {
  //     getLocationByInput({ searchText: value }).then((res) => {
  //       if (res.data.data.length > 0) {
  //           var arr = [];
  //           for (let index = 0; index < res.data.data.length; index++) {
  //               let element = { label: res.data.data[index].location };
  //               arr.push(element);
  //           }
  //           setLocationSearch(arr);
  //       }
  //   }).catch((err) => {
  //       console.log(err)
  //   })
  //   }
  //   console.log(name, value);
  //   setLocationSelected((prevFormValues) => ({
  //     ...prevFormValues,
  //     [name]: value,
  //   }));
  // };

  const handleSetFormValues = (name, value) => {
    if (name === "location") {
      getLocationByInput({ searchText: value })
        .then((res) => {
          if (res.data.data.length > 0) {
            const locations = res.data.data.map((item) => item.location);
            setLocationSearch(locations);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setLocationSelected((prevFormValues) => ({
        ...prevFormValues,
        [name]: value,
      }));
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#7c82ff" : "white",
      color: state.isSelected ? "white" : "black",
    }),
  };

  const [allCustomers, setAllCustomers] = useState([]);
  const getData = () => {
    // Fetch builder details using builderId
    dispatch(
      getAllCustomers(
        brokerID,
        (data) => {
          setAllCustomers(data.data.data);
        },
        (error) => {
          // Handle error
          console.error("Error fetching all contact queries:", error);
        }
      )
    );
  };

  useEffect(() => {
    getData();
  }, [brokerID]);

  const [allRequirements, setAllRequirements] = useState([]);
  const [requirement, setRequirement] = useState("new");
  const handleAutocompleteChange = (event, newValue) => {
    if (newValue) {
      const selectedClient = allCustomers.find(
        (item) => item?._id === newValue._id
      );
      setClientName(selectedClient?.clientName || "");
      setIsDisable(true);
      setPhoneNumber(selectedClient?.phoneNumber || "");
      setEmail(selectedClient?.email || "");
      setCustomerId(selectedClient?._id || "");
      dispatch(
        getAllRequirement(
          { brokerId: brokerID, customerId: newValue._id },
          (data) => {
            setAllRequirements(data.data.data);
          },
          (err) => {}
        )
      );
    } else {
      // Handle if no option is selected
    }
  };

  return (
    <>
      {isAddVisitSuccess && (
        <VisitVerification
          show={show}
          visitId={isAddVisitResponse?._id}
          visitOTP={isAddVisitResponse?.otp}
        />
      )}
      {!isAddVisitSuccess && (
        <CustomModal
          show={show}
          title="Book a Visit"
          border
          closeBtn
          onHide={onHide}
          cssClassName="nes__dashboard__modal"
          size="lg"
          modalHeader
        >
          <DashboardTabs
            tabsKey={["Old Customer", "New Customer"]}
            activeState={activeTab}
            onTabClick={handleTabChange}
          >
            <Form
              className="profile__form ps-2 py-3 custom__modal__form"
              onSubmit={(e) => e.preventDefault()}
            >
              <Form.Group className="flex-grow-1 mb-4">
                <Form.Label>
                  Property Name<span className="text-dark">*</span>
                </Form.Label>
                <Form.Select
                  value={propertyId}
                  disabled={proprtyName}
                  onChange={(e) => {
                    const selectedPropertyId = e.target.value;
                    const selectedProperty = allProperties.find(
                      (item) => item?._id === selectedPropertyId
                    );

                    const selectedPropertyName = selectedProperty
                      ? selectedProperty.name
                      : "";
                    const selectedBuilderId = selectedProperty
                      ? selectedProperty.builderId._id
                      : "";

                    setPropId(selectedPropertyId);
                    setPropBuilderId(selectedBuilderId);
                    setPropertyName(selectedPropertyName);
                    setIsPropertyPromoted(selectedProperty?.isPromoted);
                    var allUnitTypes = [];
                    selectedProperty.floorPlanAndPricing.map((item) => {
                      allUnitTypes.push(...item.unitType);
                    });
                    allUnitTypes = [...new Set(allUnitTypes)];
                    setUnitTypesOptions(
                      allUnitTypes.map((item) => {
                        return { value: item, label: item };
                      })
                    );
                    // setMinPrice(selectedProperty);
                    // setMaxPrice();
                  }}
                >
                  <option value="" disabled selected>
                    Select
                  </option>
                  {allProperties?.map((item) => (
                    <option key={item?._id} value={item?._id}>
                      {item?.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              {activeTab === "Old Customer" ? (
                <>
                  <Form.Group className="flex-grow-1 mb-4">
                    <Form.Label>
                      Name <span className="text-dark">*</span>
                    </Form.Label>

                    <Autocomplete
                      options={allCustomers}
                      getOptionLabel={(option) => option.clientName}
                      onChange={handleAutocompleteChange}
                      sx={{
                        "& .MuiAutocomplete-tag": {
                          borderRadius: 0,
                        },
                        "& .MuiOutlinedInput-root": {
                          border: "none !important",
                          height: "3rem",

                          "&:hover": {
                            borderColor: "grey",
                          },
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          sx={{ bgcolor: "#F8F8F8" }}
                          {...params}
                          label=""
                        />
                      )}
                    />

                    {/* <Form.Select
                    
                      onChange={(e) => {
                        const selectedClientId = e.target.value;
                        const selectedClient = allCustomers.find(
                          (item) => item?._id === selectedClientId
                        );
                        const selectedClientName = selectedClient
                          ? selectedClient.clientName
                          : "";
                        setClientName(selectedClientName);
                        setIsDisable(true);
                        setPhoneNumber(selectedClient.phoneNumber);
                        setEmail(selectedClient.email);
                        setCustomerId(selectedClient._id);

                        // handleCustomerChange({ value: selectedClientId });
                      }}
                      value={clientName}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      {allCustomers?.map((item) => (
                        <option key={item?._id} value={item?._id}>
                          {item?.clientName}
                        </option>
                      ))}
                    </Form.Select> */}
                  </Form.Group>
                </>
              ) : (
                <Form.Group
                  className="flex-grow-1 mb-4"
                  controlId="companyName"
                >
                  <Form.Label>
                    Name<span className="text-dark">*</span>
                  </Form.Label>
                  <Form.Control
                    className="rounded-0 border-0"
                    type="text"
                    placeholder="Enter Name"
                    name="clientName"
                    onChange={(e) => setClientName(e.target.value)}
                    value={clientName}
                  />
                </Form.Group>
              )}

              {/* {activeTab === 'Old Customer' && (
                <>
                  <Form.Group className='mb-4' controlId='email'>
                    <Form.Label>
                      Property Name<span className='text-dark'>*</span>
                    </Form.Label>
                    <Form.Control
                      className='rounded-0 border-0'
                      type='text'
                      name='propertyName'
                      onChange={handleChange}
                      value={visitData.propertyName}
                    />
                  </Form.Group>
                  <Form.Group className='flex-grow-1 mb-4'>
                    <Form.Label>
                      Client Name<span className='text-dark'>*</span>
                    </Form.Label>
                    <Autocomplete
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Form.Group>
                </>
              )} */}

              {/* {activeTab === 'New Customer' && (
                <>
                  <Form.Group
                    className='flex-grow-1 mb-4'
                    controlId='companyName'
                  >
                    <Form.Label>
                      Client Name<span className='text-dark'>*</span>
                    </Form.Label>
                    <Form.Control
                      className='rounded-0 border-0'
                      type='text'
                      placeholder='Enter client name'
                      name='clientName'
                      onChange={handleChange}
                      value={visitData.clientName}
                    />
                  </Form.Group>
                </>
              )} */}
              <Form.Group className="flex-grow-1 mb-4" controlId="phoneNumber">
                <Form.Label>
                  Client Phone Number<span className="text-dark">*</span>
                </Form.Label>
                {/* <PhoneInput
                  value={`+91${phoneNumber}`}
                  onChange={handlePhoneChange}
                  disabled={isDisable}
                  country={"in"}
                  inputStyle={{
                    width: "100%",
                    height: "40px",
                    backgroundColor: "#F8F8F8",
                    border: "none",
                  }}
                  buttonStyle={{ border: "none" }}
                /> */}
                {/* <PhoneInput
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  disabled={isDisable}
                  country={"in"}
                  inputStyle={{
                    width: "100%",
                    height: "40px",
                    backgroundColor: "#F8F8F8",
                    border: "none",
                  }}
                  buttonStyle={{ border: "none" }}
                /> */}
                <InputGroup className="mb-3">
                  <InputGroup.Text className="rounded-0 border-0">
                    +91
                  </InputGroup.Text>
                  <Form.Control
                    className="rounded-0 border-0"
                    type="number"
                    name="phoneNumber"
                    onChange={(e) =>
                      setPhoneNumber(e.target.value.substring(0, 10))
                    }
                    value={phoneNumber}
                    disabled={activeTab === "Old Customer"}
                  />
                </InputGroup>
              </Form.Group>

              {/* </InputGroup> */}

              <Form.Group className="mb-4" controlId="email">
                <Form.Label>
                  Client Email Address<span className="text-dark">*</span>
                </Form.Label>
                <Form.Control
                  className="rounded-0 border-0"
                  disabled={isDisable}
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </Form.Group>

              {activeTab == "Old Customer" && (
                <Form.Group>
                  <Form.Label>Requirements</Form.Label>
                  <br />
                  <Box
                    disabled={clientName === ""}
                    component={"select"}
                    className="rounded-0 border-0"
                    mb={2}
                    sx={{ width: "100%" }}
                    value={requirement}
                    onChange={(e) => {
                      if (e.target.value == "new") {
                        setMaxPrice("");
                        setMinPrice("");
                        setUnitType([]);
                        setLocationSearch([]);
                        setLocationSelected([]);
                      } else {
                        const selectedRequirement = allRequirements.filter(
                          (item) => item._id == e.target.value
                        );
                        if (selectedRequirement?.length > 0) {
                          setMaxPrice(selectedRequirement[0]?.maxPrice);
                          setMinPrice(selectedRequirement[0]?.minPrice);
                          handleSetFormValues(
                            "location",
                            selectedRequirement[0]?.preferredLocation[0]
                          );
                          setLocationSelected((prev) => [
                            ...prev,
                            selectedRequirement[0]?.preferredLocation[0],
                          ]);
                          setUnitType(
                            selectedRequirement[0]?.unitType.map((item) => {
                              return { label: item, value: item };
                            })
                          );
                        }
                      }
                      setRequirement(e.target.value);
                    }}
                  >
                    <option value={"new"}>Add New Requirement</option>
                    {allRequirements.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item?.unitType?.join(", ")}{" "}
                        {formatNumber(item?.minPrice)} -{" "}
                        {formatNumber(item?.maxPrice)}{" "}
                        {item?.preferredLocation[0]}
                      </option>
                    ))}
                  </Box>
                </Form.Group>
              )}

              {/* <InputGroup className="mb-4 gap-3"> */}
              <Form.Group className="flex-grow-1 mb-4">
                <Form.Label>
                  Unit Type<span className="text-dark">*</span>
                </Form.Label>
                <CreatableSelect
                  isMulti
                  // options={Array.from(
                  //   new Set(floorType?.map((item) => item.unitType))
                  // ).map((unitType) => ({
                  //   value: unitType,
                  //   label: unitType,
                  // }))}
                  options={unitTypesOptions}
                  value={unitType}
                  placeholder="Add more"
                  name="unitType"
                  onChange={handleUnitTypeChange}
                  styles={customStyles}
                  isDisabled={requirement !== "new"}
                />
              </Form.Group>

              <Form.Group className="flex-grow-1 mb-4">
                <Form.Label>
                  Preferred Location<span className="text-dark">*</span>
                </Form.Label>
                {/* <CreatableSelect
                  isMulti
                  placeholder="Add more"
                  options={locationSearch}
                  name="preferredLocation"
                  onChange={handlePreferredLocationChange}
                /> */}

                <Autocomplete
                  onChange={(event, value1) => {
                    console.log(value1);
                    setLocationSelected(value1);
                  }}
                  multiple
                  disablePortal
                  name="location"
                  id="combo-box-demo"
                  options={locationSearch}
                  value={locationSelected}
                  disabled={requirement !== "new"}
                  sx={{
                    "& .MuiAutocomplete-tag": {
                      borderRadius: 0,
                    },
                    "& .MuiOutlinedInput-root": {
                      border: "none !important",
                      minHeight: "3rem",

                      "&:hover": {
                        borderColor: "grey",
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="inputBox"
                      {...params}
                      name="location"
                      // value={locationSelected}
                      onChange={(e) =>
                        handleSetFormValues(e.target.name, e.target.value)
                      }
                      sx={{ bgcolor: "#F8F8F8" }}
                    />
                  )}
                />
              </Form.Group>

              {/* </InputGroup> */}

              {/* range slider */}
              {/* <RangeSlider
                title='Price Range'
                min='0'
                max='160'
                values={values}
                setValues={setValues}
              /> */}
              {/* range slider code end here */}

              <Grid container spacing={2}>
                <Grid item md={6} xs={6} sm={6} className="mb-4">
                  <Form.Group controlId="companyName">
                    <Form.Label>
                      Min Budget<span className="text-dark">*</span>
                    </Form.Label>
                    <Form.Control
                      className="rounded-0 border-0"
                      type="number"
                      // disabled={minimumPrice}
                      disabled={requirement !== "new"}
                      placeholder="enter value"
                      onChange={(e) => setMinPrice(e.target.value)}
                      value={minPrice}
                    />
                    <Typography variant="body2">{inWords(minPrice)}</Typography>
                  </Form.Group>
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                  <Form.Group className="mb-4" controlId="companyName">
                    <Form.Label>
                      Max Budget<span className="text-dark">*</span>
                    </Form.Label>
                    <Form.Control
                      className="rounded-0 border-0"
                      type="number"
                      disabled={requirement !== "new"}
                      // disabled={maximumPrice}
                      placeholder="enter value"
                      onChange={(e) => setMaxPrice(e.target.value)}
                      value={maxPrice}
                    />
                    <Typography variant="body2">{inWords(maxPrice)}</Typography>
                  </Form.Group>
                </Grid>
              </Grid>

              <Form.Group className="mb-4">
                <Form.Label>
                  Date<span className="text-dark">*</span>
                </Form.Label>
                <Form.Control
                  className="form-control-sm rounded-0 border-0"
                  type="date"
                  // required
                  name="date"
                  onChange={(e) => setDate(e.target.value)}
                  value={date}
                  min={new Date().toISOString().split("T")[0]}
                />
              </Form.Group>

              {/* time slots */}

              {/* <div>
                <Form.Group className="mb-1">
                  <Form.Label>
                    Choose Slot<span className="text-dark">*</span>
                  </Form.Label>
                </Form.Group>
                <Row className="mb-3 g-3">
                  <Col xs={12}>
                    <Form.Label>Morning Slot</Form.Label>
                    <Row className="mb-3 mb-md-0">
                      {morningSlots?.map(slot => (
                        <Col key={slot} className="my-2 my-lg-0" md={4} xs={12}>
                          <Form.Label htmlFor={`${slot}`}>
                            <Form.Check
                              type="radio"
                              id={slot}
                              name="chooseSlot"
                              value={slot}
                              checked={visitData.chooseSlot === slot}
                              onChange={handleChange}
                            />
                            {slot}
                          </Form.Label>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                  <Col xs={12}>
                    <Form.Label>Afternoon Slot</Form.Label>
                    <Row className="mb-3 mb-md-0">
                      {afternoonSlots?.map(slot => (
                        <Col key={slot} className="my-2 my-lg-0" md={4} xs={12}>
                          <Form.Label htmlFor={`${slot}`}>
                            <Form.Check
                              type="radio"
                              id={slot}
                              name="chooseSlot"
                              value={slot}
                              checked={visitData.chooseSlot === slot}
                              onChange={handleChange}
                            />
                            {slot}
                          </Form.Label>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                  <Col xs={12}>
                    <Form.Label>Evening Slot</Form.Label>
                    <Row className="mb-3 mb-md-0">
                      {eveningSlots?.map(slot => (
                        <Col key={slot} className="my-2 my-lg-0" md={4} xs={12}>
                          <Form.Label htmlFor={`${slot}`}>
                            <Form.Check
                              type="radio"
                              id={slot}
                              name="chooseSlot"
                              value={slot}
                              checked={visitData.chooseSlot === slot}
                              onChange={handleChange}
                            />
                            {slot}
                          </Form.Label>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </div> */}

              <div>
                <Form.Group className="mb-1">
                  <Form.Label>
                    Choose Slot<span className="text-dark">*</span>
                  </Form.Label>
                </Form.Group>

                <Row>
                  <Col xs={12} className="mb-4">
                    <Form.Label>Morning Slot</Form.Label>
                    <div className="d-flex flex-wrap justify-content-between gap-3">
                      {["10:00 AM", "11:00 AM", "12:00 AM"].map((slotTime) => (
                        <Button
                          key={slotTime}
                          className="px-5 py-2"
                          variant={
                            selectedSlot === slotTime
                              ? "primary"
                              : "outline-primary"
                          }
                          onClick={() => handleSlotChange("Morning", slotTime)}
                          // disabled={selectedSlot && selectedSlot !== slotTime}
                        >
                          {slotTime}
                        </Button>
                      ))}
                    </div>
                  </Col>
                  <Col xs={12} className="mb-4">
                    <Form.Label>Afternoon Slot</Form.Label>
                    <div className="d-flex flex-wrap justify-content-between gap-3">
                      {["1:00 PM", "2:00 PM", "3:00 PM"].map((slotTime) => (
                        <Button
                          key={slotTime}
                          className="px-5 py-2"
                          variant={
                            selectedSlot === slotTime
                              ? "primary"
                              : "outline-primary"
                          }
                          onClick={() => handleSlotChange("Afternoon",slotTime)}
                          // disabled={selectedSlot && selectedSlot !== slotTime}
                        >
                          {slotTime}
                        </Button>
                      ))}
                    </div>
                  </Col>
                  <Col xs={12} className="mb-4">
                    <Form.Label>Evening Slot</Form.Label>
                    <div className="d-flex flex-wrap justify-content-between gap-3">
                      {["4:00 PM", "5:00 PM", "6:00 PM"].map((slotTime) => (
                        <Button
                          key={slotTime}
                          className="px-5 py-2"
                          variant={
                            selectedSlot === slotTime
                              ? "primary"
                              : "outline-primary"
                          }
                          onClick={() => handleSlotChange("Evening",slotTime)}
                          // disabled={selectedSlot && selectedSlot !== slotTime}
                        >
                          {slotTime}
                        </Button>
                      ))}
                    </div>
                  </Col>
                </Row>
              </div>

              {/* time slots code end here */}

              <div className="d-flex gap-4 mt-5">
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  className="btn-color-primary rounded-pill btn-rounded w-100"
                  disabled={isAddVisitLoading}
                >
                  {isAddVisitLoading ? "Booking..." : "Book"}
                </Button>
              </div>
            </Form>
          </DashboardTabs>
        </CustomModal>
      )}
      <PromotedVisitVerification onHide={handleHide} show={showVisitOtp} visitId={visitId}/>
    </>
  );
};

export default BookAVisitModal;
