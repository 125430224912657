import { Container, Col, Row } from 'react-bootstrap';
// import { RiStarSFill } from "react-icons/ri";
import { Link } from 'react-router-dom';
import sky from '../../Images/Sky.png';
import build from '../../Images/build.png';
import { BsPencilFill } from 'react-icons/bs';
// import { useState } from "react";
import { FaStar } from 'react-icons/fa';
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Rating,
  Typography,
  Stack,
} from '@mui/material';
import formatNumber from '../../../broker/helper/common';
import { CustomBtn } from '../../../broker/components/mui/CustomBtn';

const Danelions = ({ data }) => {
  return (
    <>
      <Container className='dashboard__wrapper border border-light rounded shadow-sm my-4'>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {' '}
            <Box p={2}>
              <Box sx={{ display: 'flex' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <Avatar
                    sx={{
                      height: { xl: '100px', sm: '100px' },
                      width: { xl: '100px', sm: '100px' },
                    }}
                    src={data.propertyLogo[0]}
                  ></Avatar>
                </Box>
                <Box ml={4} sx={{ flex: '1' }}>
                  <Box>
                    <Typography
                      variant='p'
                      component={'h5'}
                      sx={{
                        fontWeight: 'bold',

                        fontFamily: 'Bahnschrift',
                      }}
                    >
                      {data.name}
                    </Typography>{' '}
                  </Box>

                  <Box>
                    <Typography
                      my={2}
                      variant='p'
                      component={'h6'}
                      sx={{
                        fontWeight: '400',

                        color: '#7D7F88',
                      }}
                    >
                      {data.propertyLiner}
                    </Typography>
                  </Box>
                  <Box>
                    <Grid container spacing={1}>
                      {data.unitTypeNew.map((unitType, index) => (
                        <Grid item xs={3} key={index}>
                          <img
                            src={'/home.svg'}
                            alt='home'
                            width={'auto'}
                            height={'29.94px'}
                          />
                          <Box mt={1} color={'#7D7F88'}>
                            {unitType}
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            md={3}
            sx={{
              flex: '1',

              display: { md: 'block', xs: 'none' },
            }}
          ></Grid>
          <Grid xs={12} md={3} item>
            <Box p={2} sx={{}}>
              <Box>
                <Box
                  mr={{ md: 4, sx: 0 }}
                  display={'flex'}
                  flexDirection={'column'}
                  sx={{ justifyContent: { md: 'end', xs: 'center' } }}
                >
                  <Stack flex={1}>
                    {' '}
                    <Typography
                      textAlign={'center'}
                      sx={{ fontSize: '20px', fontWeight: 'bold' }}
                    >
                      {`${formatNumber(data.minPrice)} - ${formatNumber(
                        data.maxPrice
                      )}`}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography
                      mt={2}
                      mb={4}
                      sx={{
                        fontSize: '16px',
                        color: '#7D7F88',
                        textAlign: 'center',
                      }}
                    >
                      {data.builderDiscount}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Link
                      to={`/builder/home-dashboard/propertyedit/${data?._id}`}
                    >
                      {' '}
                      <CustomBtn variant='outlined' width='100%'>
                        <BsPencilFill
                          style={{
                            width: '1.25rem',
                            height: '1.25rem',
                            color: '#fff',
                            background: '#278fd9',
                            padding: '4px',
                            borderRadius: '50%',
                            marginRight: '8px',
                          }}
                        />
                        Edit
                      </CustomBtn>
                    </Link>
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Danelions;
