import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import propertyImage from '../../Images/propertyimage.png';
import { Link } from 'react-router-dom';
import './SoldOut.css';
import { useEffect, useState } from 'react';
import { getAPI } from '../../Api/ApiRequest';
import { apiEndpoints } from '../../Api/ApiEndpoint';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import LoadingSpinner from '../../../broker/Common/loading-spinner/LoadingSpinner';

const SoldOut = ({ builderID }) => {
  const [soldData, setSoldData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log('builder id is here', builderID);
    const getBoughtProperties = async () => {
      setLoading(true);
      const response = await getAPI(apiEndpoints?.getAllSoldOut + builderID);

      console.log(response.data);

      setSoldData(response?.data);
      setLoading(false);
    };

    getBoughtProperties();
  }, []);

  return (
    <Container className='mt-5'>
      <Box
        mt={4}
        mb={4}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
      >
        <h3 className='col-8 heading'>Recently Sold</h3>
        <Link
          to='/builder/home-dashboard/recentlysold'
          className='col-4 text-danger text-end w-auto cursor'
        >
          <Typography mb={4}>View All</Typography>
        </Link>
      </Box>
      {loading ? (
        <Box textAlign={'center'} my={5}>
          <CircularProgress />
        </Box>
      ) : soldData?.length > 0 ? (
        <Grid container spacing={8}>
          {soldData?.slice(0, 6).map((itm) => (
            <Grid item xs={12} md={4} sm={6} key={itm?._id}>
              <Card className=' shadow-sm rounded-4' style={{ border: 'none' }}>
                <Link
                  to={`/builder/home-dashboard/description/${itm?.propertyId?._id}`}
                >
                  <Card.Img
                    className='w-100 bl-prop-img'
                    variant='top'
                    src={itm?.propertyId?.images[0]}
                    style={{ height: '12rem', borderRadius: '1rem' }}
                  />
                </Link>
                <Card.Body>
                  <div>
                    <div>
                      {' '}
                      <Link
                        className='sold-heading'
                        to={`/builder/home-dashboard/description/${itm?.propertyId?._id}`}
                      >
                        {itm?.propertyId === null
                          ? 'no data Name'
                          : itm?.propertyId?.name}
                      </Link>
                    </div>
                    <div style={{ opacity: 0.5, fontFamily: 'Bahnschrift' }}>
                      {itm?.propertyId === null
                        ? 'no data'
                        : itm?.propertyId?.location}
                    </div>
                  </div>
                  <Row className='bl-p-2'>
                    <Col>
                      <Row style={{ opacity: 0.5 }}>Unit Type</Row>
                      <Row>{itm?.unitType}</Row>
                    </Col>
                    <Col>
                      <Row style={{ opacity: 0.5 }}>Unit Number</Row>
                      <Row>{itm?.unitNumber}</Row>
                    </Col>
                    <Col>
                      <Row style={{ opacity: 0.5 }}>Selling Price</Row>
                      <Row>₹ {itm?.sellingPrice}</Row>
                    </Col>
                  </Row>
                  <Row className='p-2'>
                    <Col>
                      <Row style={{ opacity: 0.5 }}>Client Name</Row>
                      <Row>{itm?.customerId?.clientName}</Row>
                    </Col>
                    <Col>
                      <Row style={{ opacity: 0.5 }}>Broker ID</Row>
                      <Row>{itm?.brokerId?._id}</Row>
                    </Col>
                    <Col>
                      <Row style={{ opacity: 0.5 }}>Selling Date</Row>
                      <Row>{itm?.bookingDate}</Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <>
          <Grid container>
            {soldData?.length > 0 ? (
              soldData?.slice(0, 6).map((itm) => (
                <Grid item xs={12} md={4} key={itm?._id}>
                  <Card
                    className='bl-card mb-4 shadow-sm rounded-4'
                    style={{ border: 'none' }}
                  >
                    <Link
                      to={`/builder/home-dashboard/description/${itm?.propertyId?._id}`}
                    >
                      <Card.Img
                        className='w-100 bl-prop-img'
                        variant='top'
                        src={itm?.propertyId?.images[0]}
                      />
                    </Link>
                    <Card.Body>
                      <div>
                        <div>
                          {' '}
                          <Link
                            className='sold-heading'
                            to={`/builder/home-dashboard/description/${itm?.propertyId?._id}`}
                          >
                            {itm?.propertyId === null
                              ? 'no data Name'
                              : itm?.propertyId?.name}
                          </Link>
                        </div>
                        <div
                          style={{ opacity: 0.5, fontFamily: 'Bahnschrift' }}
                        >
                          {itm?.propertyId === null
                            ? 'no data'
                            : itm?.propertyId?.location}
                        </div>
                      </div>
                      <Row className='bl-p-2'>
                        <Col>
                          <Row style={{ opacity: 0.5 }}>Unit Type</Row>
                          <Row>{itm?.unitType}</Row>
                        </Col>
                        <Col>
                          <Row style={{ opacity: 0.5 }}>Unit Number</Row>
                          <Row>{itm?.unitNumber}</Row>
                        </Col>
                        <Col>
                          <Row style={{ opacity: 0.5 }}>Selling Price</Row>
                          <Row>₹ {itm?.sellingPrice}</Row>
                        </Col>
                      </Row>
                      <Row className='p-2'>
                        <Col>
                          <Row style={{ opacity: 0.5 }}>Client Name</Row>
                          <Row>{itm?.customerId?.clientName}</Row>
                        </Col>
                        <Col>
                          <Row style={{ opacity: 0.5 }}>Broker ID</Row>
                          <Row>{itm?.brokerId?._id?.slice(-10)}</Row>
                        </Col>
                        <Col>
                          <Row style={{ opacity: 0.5 }}>Selling Date</Row>
                          <Row>{itm?.bookingDate}</Row>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Grid>
              ))
            ) : (
              <Typography
                my={6}
                textAlign={'center'}
                color={'var(--primary-color-darker)'}
                fontWeight={'bold'}
              >
                No recently added properties available
              </Typography>
            )}
          </Grid>
        </>
      )}
    </Container>
  );
};

export default SoldOut;
