import React, { useState } from 'react'
import './BuilderForm.css'
import { Fade } from 'react-reveal'
import { enqueueSnackbar } from 'notistack';
import { listProperty } from '../../../API/action';
import { useDispatch } from 'react-redux';

const BuilderForm = () => {

    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [propertyType, setPropertyType] = useState('');
    const [location, setLocation] = useState('');
    const [description, setDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const handleSubmit = (e) => {
        e.preventDefault();
        const phoneRegex = /^\d{10}$/;
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

        if (name === "") {
            enqueueSnackbar("Name cannot be empty.", { variant: 'warning' });
            return
        }

        if (!phoneRegex.test(phoneNumber)) {
            enqueueSnackbar("Phone Number is not valid!", { variant: 'warning' });
            return
        }

        if (!emailRegex.test(email)) {
            enqueueSnackbar("Email Address is not valid!", { variant: 'warning' });
            return;
        }

        setIsLoading(true);

        const payload =
        {
            name,
            phoneNumber,
            email,
            typeOfProperty: propertyType,
            locationProperty: location,
            description,
        }
        dispatch(
            listProperty(payload,
                (data) => {
                    setIsLoading(false);
                    // console.log("Registeration Successful", data)
                    setName("")
                    setPhoneNumber("")
                    setEmail("")
                    setPropertyType("")
                    setLocation("")
                    setDescription("")
                    enqueueSnackbar("Registration Successful", { variant: 'success' });
                },
                (err) => {
                    setIsLoading(false);
                    enqueueSnackbar(err, { variant: 'warning' });
                    console.log("There is some error")
                }
            )
        );
    };

    return (
        <Fade bottom duration={1000} distance="100px" delay={100}>
            <div className='col-lg-4 builder-banner-col-3'>
                <div className='builder-banner-col-3_title_div'>
                    <span className='builder-banner-col-3_title'>List Your Property / Requirement</span>
                </div>
                <div>
                    <form className='builder-banner-col-3_form' onSubmit={handleSubmit}>
                        {/* Name input */}
                        <input
                            type='text'
                            placeholder='Name'
                            className='builder-banner-col-3_input'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />

                        {/* Phone Number input */}
                        <input
                            type='number'
                            placeholder='Phone Number'
                            className='builder-banner-col-3_input'
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            required
                        />

                        {/* Email input */}
                        <input
                            type='email'
                            placeholder='Email Address'
                            className='builder-banner-col-3_input'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />

                        {/* Property Type select */}
                        <select
                            className='builder-banner-col-3_input builder-banner-col-3_select'
                            value={propertyType}
                            onChange={(e) => setPropertyType(e.target.value)}
                            required
                        >
                            <option disabled value="">
                                Property Type
                            </option>
                            <option>Flats / Apartment</option>
                            <option>Farmhouse / Villa</option>
                            <option>Plots / Lands</option>
                            <option>Commercial</option>
                        </select>

                        {/* Location input */}
                        <input
                            type='text'
                            placeholder='Location of Property'
                            className='builder-banner-col-3_input'
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            required
                        />

                        {/* Description input */}
                        <input
                            type='text'
                            placeholder='Brief Description'
                            className='builder-banner-col-3_input'
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                        />

                        {/* Submit button */}
                        <button type='submit' className='builder-banner-col-3_btn'>
                            {isLoading ?
                                <div class="spinner-border" role="status" style={{height:'16px', width:'16px'}}>
                                    <span class="sr-only"></span>
                                </div>
                                : 'Submit'}
                        </button>
                    </form>
                </div>
            </div>
        </Fade>
    )
}

export default BuilderForm