// updated code with routes
import React, { lazy, Suspense } from 'react';
import HomeBanner from './informative/HomePage/HomeBanner/HomeBanner';
import ContactUs from './informative/ContactUs/ContactUs';
import PartnerBanner from './informative/PartnersPage/Partnerbanner/partnerbanner';
import TermsCondition from './informative/TermsCondition/TermsCondition';
import PrivacyPolicy from './informative/PrivacyPolicy/PrivacyPolicy';
import BlogListMain from './informative/BlogList/BlogListMain';
import BlogDetailMain from './informative/BlogDetail.js/BlogDetailMain';
import AboutUsMain from './informative/AboutUs/AboutUsMain';
import Faq from './informative/Faq/Faq';
import BuilderPD from './informative/PropertyDetails/BuilderPD/BuilderPD';
import BrokerPD from './informative/PropertyDetails/BrokerPD/BrokerPD';
import AgentPD from './informative/PropertyDetails/AgentPD/AgentPD';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import LoadingScreen from './components/loading-screen/LoadingScreen';
import BuilderPageMain from '../src/informative/BuilderPage/BuilderPageMain';
import PartnersDetailsMain from '../src/informative/PartnersDetail/PartnersDetailsMain';
import BrokerPageMain from '../src/informative/BrokerPage/BrokerPageMain';
import ScrollToTop from './dsa/components/ScrollToTop';
import CopyrightText from './dsa/dashboard/footer/CopyrightText';
import Chat from './dsa/dashboard/chat/Chat';
import HomeDashboardContent from './dsa/dashboard/home-dashnoard-content/HomeDashboardContent';
import { RouteData } from '../src/dsa/utils/Routes';
import '../src/index.css';
import '../src/dsa/App.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import store from './builder/redux/store';
import { persistor } from './broker/store/store';
import BrokerLogin from './broker/Pages/Login';
import BrokerRegister from './broker/Pages/Register';
import DashboardPage from './broker/Pages/dashboard/DashboardPage';
import BrokerProfilePage from './broker/dashboard-containers/broker-profile/Profile';
import ClientManagement from './broker/features/Dashboard/components/client-cards/ClientManagement';
import PropertyDescription from './broker/Pages/dashboard/property-details/PropertyDescription';
import {
  BrokerageManagement,
  Claim,
  PromotedProperty,
} from './broker/features';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Property from './admin-page/property/Property';
import PropertyManagement from './admin-page/property/PropertyManagement';
import BuilderLogin from './builder/login/Login';
import BuilderRegisterLogin from './builder/login/Register/RegisterLogin';
import Subscription from './builder/dashboard/dashboard-soldout-properties/Subscription/Subscription';
import RecentlyAddedProperty from './builder/dashboard/dashboard-soldout-properties/dashboard-tabs/RecentlyAddedProperty';
import RecentlySoldOutProperty from './builder/dashboard/dashboard-soldout-properties/dashboard-tabs/RecentlySoldOutProperty';
import VisitDetails from './builder/dashboard/dashboard-property-analytics/Visit Details/VisitDetails';
import PropertyClaim from './builder/dashboard/dashboard-property-analytics/Visit Details/Property claim/PropertyClaim';
import BrokerageSettled from './builder/dashboard/Brokerage/BrokerageSettled';
import Brokerage from './builder/dashboard/Brokerage/Brokerage';
import NotificationDropdown from './builder/dashboard/Builderprofile/notification/NotificationDropdown';
import BuilderProfilePage from './builder/dashboard/Builderprofile/Profile';
import PropertyEdit from './builder/dashboard/dashboard-info-cards/Property Edit/PropertyEdit';
import SoldPage from './builder/dashboard/dashboard-soldout-properties/Sold-page';
import AllProperty from './builder/dashboard/dashboard-soldout-properties/dashboard-tabs/AllProperty';
import HomeDashBoard from './builder/dashboard/HomeDashBoard';
import BuilderProperty from '../src/builder/dashboard/property/Property';
import SearchResult from './components/search-filter';
import BestSellingProperty from './broker/features/Dashboard/components/promoted-property/BestSellingProperty';
import RecentlyAddedProperties from './broker/features/Dashboard/components/promoted-property/RecentlyAddedProperties';
import FloorPlan from './broker/Pages/dashboard/property-details/floorPlans/FloorPlan';
import PartnersDetailLeft from './informative/PartnersDetail/PartnersDetailLeft/PartnersDetailLeft';
import CurrentProjects from './informative/PartnersDetail/PartnersDetailLeft/CurrentProjects/CurrentProjects';
import AboutBuilder from './informative/PartnersDetail/PartnersDetailLeft/AboutBuilder/AboutBuilder';
import AboutTheBuilder from './broker/Pages/AboutBuilder/AboutTheBuilder';
import AboutTheProject from './broker/Pages/aboutTheProject/AboutTheProject';
import PropertyDetailsForm from './dsa/dashboard/property-details/PropertyDetailsForm';
import Rating from './builder/dashboard/dashboard-property-analytics/Visit Details/Property claim/Rating';
import Filter from './components/search-filter/Filter/Filter';
import BoughtProperty from './dsa/dashboard/property-details/BoughtProperty';
import MaturedModal from './broker/features/Dashboard/components/modal/MaturedModal';
import BrokerPropertyClaim from './broker/Pages/BrokerPropertyClaim/BrokerPropertyClaim';
import TotalEarnings from './broker/Pages/TotalEarnings/TotalEarnings';
import UpcomingEarnings from './broker/Pages/dashboard/UpcomingEarnings';
import Page from './assets/Exe';
import Exe from './assets/Exe';
import Payment from './builder/dashboard/dashboard-property-analytics/Visit Details/Property claim/Payment';
import RequirementManagement from './broker/features/Dashboard/components/requment-management/RequirementManagement';
import PrivacyPolicyMobile from './informative/PrivacyPolicy/PrivacyPolicyMobile';
import RefundPolicy from './informative/PrivacyPolicy/RefundPolicy';
import PersonalDetailsForm from './builder/dashboard/Builderprofile/personal-details/PersonalDetailsForm';
import RequirementVisitDetails from './broker/features/Dashboard/components/requment-management/RequirementVisitDetails';
import FilterProperty from './builder/dashboard/search-filter/FilterProperty';
import BrokerVisitClaim from './broker/Pages/BrokerPropertyClaim/BrokerVisitClaim';
import PropertyEligibleClaim from './broker/Pages/BrokerPropertyClaim/PropertyEligibleClaim';
import VisitEligibleClaim from './broker/Pages/BrokerPropertyClaim/VisitEligibleClaim';
import SearchProperty from './broker/Pages/dashboard/SearchProperty';
import SearchPropertyFilter from './broker/Pages/dashboard/SearchPropertyFilter';
import Properties from './broker/features/Dashboard/components/properties/Property';
import Wallet from './broker/Pages/Wallet';
import ClaimApprovals from './broker/Pages/dashboard/ClaimApprovals';
import TotalReferalEarnings from './broker/Pages/dashboard/TotalReferalEarnings';
import Visits from './broker/Pages/dashboard/Visits';
import SearchPropertyBuilder from './builder/dashboard/property/SearchPropertyBuilder';
import FloorPlanBuilder from './builder/dashboard/property/FloorPlansBuilder';
import VisiteDetailsForBL from './builder/dashboard/dashboard-property-analytics/Visit Details/VisiteDetailsForBL';

const queryClient = new QueryClient();

// const DsaWebsite = lazy(() => import("./dsa/App"));
// const PartnersDetailsMain = lazy(() => import('./informative/App'));
// const BuilderWeb = lazy(() => import("./builder/App"));
// const BrokerWeb = lazy(() => import("./broker/App"));
// const AdminPage = lazy(() => import("./admin-page/App"));

const App = () => {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <ScrollToTop />
                <Routes>
                  {/* Broker */}
                  <Route path='/broker/login' element={<BrokerLogin />} />
                  <Route path='/broker/register' element={<BrokerRegister />} />
                  <Route path='/broker/dashboard' element={<DashboardPage />} />
                  <Route
                    path='/broker/search-property/:search'
                    element={<SearchProperty />}
                  />
                  <Route
                    path='/broker/filter-property'
                    element={<SearchPropertyFilter />}
                  />
                  <Route
                    path='/broker/dashboard/1'
                    element={<BoughtProperty />}
                  />
                  <Route
                    path='/broker/profile'
                    element={<BrokerProfilePage />}
                  />
                  <Route
                    path='/broker/search/:cat/:query'
                    element={<SearchResult />}
                  />
                  <Route
                    path='/broker/clientManagement'
                    element={<ClientManagement />}
                  />
                  <Route
                    path='/broker/requirementManagement/:id'
                    element={<RequirementManagement />}
                  />
                  <Route
                    path='/broker/requirement/visit-details/:id'
                    element={<RequirementVisitDetails />}
                  />
                  <Route
                    path='/broker/property-details/:id'
                    element={<PropertyDescription />}
                  />
                  <Route
                    path='/broker/brokerageManagement'
                    element={<BrokerageManagement />}
                  />
                  <Route
                    path='/broker/about-builder/:builderId'
                    element={<AboutTheBuilder />}
                  />
                  <Route
                    path='/broker/about-project/:projectId'
                    element={<AboutTheProject />}
                  />
                  <Route path='/hello' element={<MaturedModal />} />

                  {/* Builder */}
                  <Route
                    exact
                    path='/builder/login'
                    element={<BuilderLogin />}
                  />
                  <Route
                    exact
                    path='/builder/register'
                    element={<BuilderRegisterLogin />}
                  />
                  <Route
                    exact
                    path='/builder/home-dashboard/subscription'
                    element={<Subscription />}
                  />
                  <Route
                    exact
                    path='/builder/home-dashboard/recentlyadded'
                    element={<RecentlyAddedProperty />}
                  />
                  <Route
                    exact
                    path='/builder/home-dashboard/recentlysold'
                    element={<RecentlySoldOutProperty />}
                  />
                  <Route
                    exact
                    path='/builder/home-dashboard/visit/:propertyId'
                    element={<VisitDetails />}
                  />
                  <Route
                    exact
                    path='/builder/home-dashboard/visits/:builder'
                    element={<VisiteDetailsForBL />}
                  />
                  <Route
                    exact
                    path='/builder/home-dashboard/promoted/claim/:claimID'
                    element={<PropertyClaim />}
                  />
                  <Route exact path='hello' element={<Payment />} />
                  <Route
                    exact
                    path='/broker/claim-details/:id'
                    element={<BrokerPropertyClaim />}
                  />
                  <Route
                    exact
                    path='/broker/visit-claim-details/:id'
                    element={<BrokerVisitClaim />}
                  />
                  <Route
                    exact
                    path='/broker/property-eligible-claim-details/:id'
                    element={<PropertyEligibleClaim />}
                  />
                  <Route
                    exact
                    path='/broker/visit-eligible-claim-details/:id'
                    element={<VisitEligibleClaim />}
                  />
                  <Route exact path='/broker/wallet' element={<Wallet />} />
                  <Route
                    exact
                    path='/broker/total-earnings'
                    element={<TotalEarnings />}
                  />
                  <Route
                    exact
                    path='/broker/upcoming-earnings'
                    element={<UpcomingEarnings />}
                  />
                  <Route
                    path='/builder/floor-plan/:id'
                    element={<FloorPlanBuilder />}
                  />
                  {/* <Route
                    exact
                    path='/broker/visit-details'
                    element={<VisitDetails />}
                  /> */}
                  <Route
                    exact
                    path='/broker/visit-details'
                    element={<Visits />}
                  />
                  <Route
                    exact
                    path='/broker/claim-approvals'
                    element={<ClaimApprovals />}
                  />
                  <Route
                    exact
                    path='/broker/total-referal-earnings'
                    element={<TotalReferalEarnings />}
                  />
                  <Route exact path='/page' element={<Exe />} />
                  <Route
                    exact
                    path='/builder/home-dashboard/Brokerage/settled'
                    element={<BrokerageSettled />}
                  />
                  <Route
                    exact
                    path='/builder/home-dashboard/Brokerage/:brokerID'
                    element={<Brokerage />}
                  />
                  <Route
                    path='/builder/search-property/:search'
                    element={<SearchPropertyBuilder />}
                  />
                  <Route
                    exact
                    path='/builder/home-dashboard/notification'
                    element={<NotificationDropdown />}
                  />
                  <Route
                    exact
                    path='/personal-details'
                    element={
                      <BuilderProfilePage selelement='PersonalDetailsForm' />
                    }
                  />
                  <Route
                    exact
                    path='/transaction'
                    element={
                      <BuilderProfilePage selelement='TransactionHistory' />
                    }
                  />

                  <Route
                    exact
                    path='/manage-roles'
                    element={<BuilderProfilePage selelement='QueriesHistory' />}
                  />
                  <Route
                    exact
                    path='/past-properties'
                    element={<BuilderProfilePage selelement='PastProperty' />}
                  />
                  <Route
                    exact
                    path='/terms-conditions'
                    element={
                      <BuilderProfilePage selelement='TermsConditions' />
                    }
                  />
                  <Route
                    exact
                    path='/support'
                    element={<BuilderProfilePage selelement='Support' />}
                  />
                  <Route
                    exact
                    path='/raise-query'
                    element={<BuilderProfilePage selelement='RaiseQuery ' />}
                  />
                  <Route
                    exact
                    path='/builder/builder-profile/:builderID'
                    element={<BuilderProfilePage />}
                  />
                  <Route
                    path='/profile/personal-details'
                    component={PersonalDetailsForm}
                  />
                  <Route
                    exact
                    path='/builder/home-dashboard/propertyedit/:propertyId'
                    element={<PropertyEdit />}
                  />
                  <Route
                    exact
                    path='/builder/home-dashboard/description/:propertyId'
                    element={<SoldPage />}
                  />
                  <Route
                    exact
                    path='/builder/home-dashboard/property'
                    element={<AllProperty />}
                  />
                  <Route
                    exact
                    path='/builder/home-dashboard/filteredproperty'
                    element={<FilterProperty />}
                  />
                  <Route
                    exact
                    path='/builder/home-dashboard'
                    element={<HomeDashBoard />}
                  >
                    <Route
                      exact
                      path='property'
                      element={<BuilderProperty />}
                    />
                    <Route exact spath='HOME' />
                    <Route exact bpath='Brokerage' />
                  </Route>

                  {/* Informative */}
                  <Route path='/' element={<HomeBanner />} />
                  <Route
                    path='/partners/details'
                    element={<PartnersDetailsMain />}
                  />

                  <Route path='/contact-us' element={<ContactUs />} />
                  <Route path='/partner-page' element={<PartnerBanner />} />
                  <Route path='/broker-page' element={<BrokerPageMain />} />
                  <Route path='/builder-page' element={<BuilderPageMain />} />
                  <Route path='/terms-condition' element={<TermsCondition />} />
                  <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                  <Route path='/refund-policy' element={<RefundPolicy />} />
                  <Route
                    path='/privacy-policy/mobile'
                    element={<PrivacyPolicyMobile />}
                  />
                  <Route path='/blog-list' element={<BlogListMain />} />
                  <Route
                    path='/blog-detail/:slug'
                    element={<BlogDetailMain />}
                  />
                  <Route path='/about-us' element={<AboutUsMain />} />
                  <Route path='/faq' element={<Faq />} />
                  <Route
                    path='/builder-property-details'
                    element={<BuilderPD />}
                  />
                  <Route
                    path='/broker-property-details'
                    element={<BrokerPD />}
                  />
                  <Route path='/agent-property-details' element={<AgentPD />} />
                  <Route
                    path='/promoted-property'
                    element={<PromotedProperty />}
                  />
                  <Route
                    path='/best-selling-property'
                    element={<BestSellingProperty />}
                  />
                  <Route
                    path='/broker/properties/:propertyType'
                    element={<Properties />}
                  />
                  <Route
                    path='/recently-added-properties'
                    element={<RecentlyAddedProperties />}
                  />
                  <Route
                    path='/broker/floor-plan/:id'
                    element={<FloorPlan />}
                  />

                  {/* DSA */}
                  {RouteData.map(({ id, path, element, page, routes }) => {
                    if (page === 'login') {
                      return <Route key={id} path={path} element={element} />;
                    } else if (page === 'dashboard' && routes) {
                      return (
                        <Route
                          key={id}
                          path={path}
                          element={
                            <>
                              {element} <CopyrightText /> <Chat />
                            </>
                          }
                        >
                          <Route index element={<HomeDashboardContent />} />
                          {routes.map(({ id, path, element }) => (
                            <Route key={id} path={path} element={element} />
                          ))}
                        </Route>
                      );
                    } else {
                      return <Route key={id} path={path} element={element} />;
                    }
                  })}

                  {/* Admin */}
                  <Route path='/nesto/admin' element={'test'} />
                  <Route path='/nesto/psroperty' element={<Property />} />
                  <Route
                    path='/nesto/property-management'
                    element={<PropertyManagement />}
                  />
                </Routes>
                {/* <Footer /> */}
              </BrowserRouter>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </PersistGate>
        </Provider>

        {/* Admin Page */}
        {/* <AdminPage /> */}
      </Suspense>
    </>
  );
};
export default App;
