import { Box } from '@mui/material';
import React from 'react';
import Heading from '../heading/Heading';
import { Container } from 'react-bootstrap';
import './OurPartner.css';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';

import { Autoplay } from 'swiper/modules';

const breakpoints = {
  0: {
    slidesPerView: 3,
  },
  100: {
    slidesPerView: 1,
  },
  450: {
    slidesPerView: 2,
  },
  900: {
    slidesPerView: 3,
  },
};
const review = [
  {
    id: 1,
    img: './assets/informative/partners/partner1.svg',
  },
  {
    id: 2,
    img: './assets/informative/partners/partner2.svg',
  },
  {
    id: 3,
    img: '/500x/partner/logo-3.webp',
  },
];
export default function OurPartner() {
  return (
    <Box>
      <Heading
        title="Our Partners"
        sub="The Leading Collaborations On NestoHub"
      />
      <Container>
        <Box sx={{ mt: 2 }}>
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            loop={true}
            speed={1000}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 5000,

              disableOnInteraction: false,
            }}
            breakpoints={breakpoints}
            modules={[Autoplay]}
            className="mySwiper"
          >
            {review.map((item) => (
              <SwiperSlide key={item.id}>
                <Box
                  sx={{
                    borderRadius: '5px 15px',
                    boxShadow: '0 0 6px #e9e9e9',
                    display: 'flex',
                    justifyContent: 'center',
                    '& img': {
                      height: 90,
                      p: 2,
                    },
                  }}
                >
                  <img src={item.img} class="partner-image" alt="partner" />
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Container>
    </Box>
  );
}
