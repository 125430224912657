import axios from "axios";
import { baseURL, deleteAPI, getAPI, patchAPI, postAPI, putAPI } from "./api";
import endPoint from "./endpoint";
import endpoint from "./endpoint";

export const login = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endPoint.brokerRegister,
      payload,
      (response) => {
        console.log("Response For Login", response);
        callBack(response);
      },
      (error) => {
        console.log("Error While Login", error);
        errorCallback(error);
      }
    );
  };
};

export const brokerRegister = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endPoint?.BROKER_REGISTER,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const sendOtp = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endPoint?.SEND_OTP,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const listProperty = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endPoint?.LIST_PROPERTY,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const getBrokerById = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endPoint?.GET_BROKER_BY_ID,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const updateBankInfo = (payload, callBack) => {
  return (dispatch) => {
    putAPI(
      endPoint.UPDATE_BANK_INFO,
      payload,
      (response) => {
        console.log("Response For Updating bank information", response);
        callBack(response);
      },
      (error) => {
        console.log("Error While Updating bank information", error);
      }
    );
  };
};

export const getAllPropertyManager = (payload, callBack) => {
  return (dispatch) => {
    getAPI(
      endPoint.GET_ALL_ROLES_PROPERTY,
      payload,
      (response) => {
        console.log("Response For getting manager", response);
        callBack(response);
      },
      (error) => {
        console.log(
          "Error While getting managerUpdating bank information",
          error
        );
      }
    );
  };
};

export const getAllFaq = (payload, callBack) => {
  return (dispatch) => {
    getAPI(
      endPoint.GET_ALL_FAQ,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        console.log(
          "Error While getting managerUpdating bank information",
          error
        );
      }
    );
  };
};

export const getLocationByInput = (payload) => {
  try {
    const response = axios.get(`${baseURL}v1/globals/search-location`, {
      params: {
        searchQuery: payload.searchText,
      },
    });

    return response;
  } catch (error) {
    console.log("Error fetching suggestions:", error);
    throw error;
  }
};

export const getAllBuilders = (payload, callBack) => {
  return (dispatch) => {
    getAPI(
      endPoint.GET_ALL_BUILDERS,
      payload,
      (response) => {
        console.log("Response For getting all builders", response);
        callBack(response);
      },
      (error) => {
        console.log("Error While getting all builders", error);
      }
    );
  };
};

export const uploadDocToS3 = (payload, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      endPoint?.UPLOAD_DOC,
      payload,
      (response) => {
        console.log("response of upload doc to S3", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of upload doc to S3", error);
        //
      }
    );
  };
};

export const updateBrokerPrefrences = (payload, callBack) => {
  return (dispatch) => {
    putAPI(
      endPoint.UPDATE_BROKER_PREFRENCES,
      payload,
      (response) => {
        console.log("Response For Updating bank information", response);
        callBack(response);
      },
      (error) => {
        console.log("Error While Updating bank information", error);
      }
    );
  };
};

export const updatePersonalInfoForBuilder = (payload, callBack) => {
  return (dispatch) => {
    putAPI(
      endPoint.UPDATE_PERSONAL_INFO_BUILDER,
      payload,
      (response) => {
        console.log("Response For Updating personal information", response);
        callBack(response);
      },
      (error) => {
        console.log("Error While Updating bank personal information", error);
      }
    );
  };
};
export const updatePersonalInfoForBroker = (payload, callBack) => {
  return (dispatch) => {
    putAPI(
      endPoint.UPDATE_PERSONAL_INFO_BROKER,
      payload,
      (response) => {
        console.log("Response For Updating personal information", response);
        callBack(response);
      },
      (error) => {
        console.log("Error While Updating  personal information", error);
      }
    );
  };
};

export const getAllBlogs = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      endPoint.GET_ALL_BLOG,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const getBuilderDetailsById = (Id, callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      `${endpoint.GET_BUILDER_BY_ID}?id=${Id}`,
      {},
      (response) => {
        callBack(response);
      },
      (error) => {
        console.log("error while Getiing Builder Details", error);
      }
    );
  };
};
export const getBuilderPropertiesById = (Id, callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      `${endpoint.GET_BUILDER_PROPERTY_BY_ID}?builderId=${Id}`,
      {},
      (response) => {
        callBack(response);
      },
      (error) => {
        console.log("error while Getiing Builder Details", error);
      }
    );
  };
};

export const getAllCustomers = (Id, callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      `${endpoint.GET_ALL_CUSTOMER}?brokerId=${Id}`,
      {},
      (response) => {
        callBack(response);
      },
      (error) => {
        console.log("error while Getiing Builder Details", error);
      }
    );
  };
};
export const getAllRequirement = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      endPoint.GET_ALL_REQUIREMENT,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        console.log("error while Getiing Builder Details", error);
      }
    );
  };
};

export const getRequirementById = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      endPoint.GET_REQUIREMENT_BY_ID,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        console.log("error while Getiing Requirement Details", error);
      }
    );
  };
};

export const getProperty = (data) => ({
  type: "GET_PROPERTY",
  payload: data,
});

export const addCustomer = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endPoint.ADD_CUSTOMER,
      payload,
      (response) => {
        console.log("Response For adding customer", response);
        callBack(response);
      },
      (error) => {
        errorCallback(error);
        console.log("Error For adding customer", error);
      }
    );
  };
};

export const addRequirement = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endPoint.ADD_REQUIREMENT,
      payload,
      (response) => {
        console.log("Response For adding Requirement", response);
        callBack(response);
      },
      (error) => {
        errorCallback(error);
        console.log("Error For adding Requirement", error);
      }
    );
  };
};

export const updateCustomer = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    putAPI(
      endPoint.UPDATE_CUSTOMER,
      payload,
      (response) => {
        console.log("Response For updating customer", response);
        callBack(response);
      },
      (error) => {
        errorCallback(error);
        console.log("Error For updating customer", error);
      }
    );
  };
};

export const addVisit = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endPoint.ADD_VISIT,
      payload,
      (response) => {
        console.log("Response For adding Visit", response);
        callBack(response);
      },
      (error) => {
        errorCallback(error);
        console.log("Error For adding Visit", error);
      }
    );
  };
};

export const addBoughtProperty = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endPoint.ADD_BOUGHT_PROPERTY,
      payload,
      (response) => {
        console.log("Response For adding bought property", response);
        callBack(response);
      },
      (error) => {
        errorCallback(error);
        console.log("Error For adding bought property", error);
      }
    );
  };
};

export const shareYourIntent = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endPoint.SHARE_YOUR_INTENT,
      payload,
      (response) => {
        console.log("Response For share Your Intent", response);
        callBack(response);
      },
      (error) => {
        errorCallback(error);
        console.log("Error For share Your Intent", error);
      }
    );
  };
};

export const getAllClaims = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint.GET_ALL_CLAIMS,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        console.log("error while Getiing Claims Details", error);
      }
    );
  };
};

export const getAllEligibleClaims = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint.GET_ALL_ELIGIBLE_CLAIMS,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        console.log("error while Getiing Claims Details", error);
      }
    );
  };
};

export const getClaimById = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint.GET_CLAIM_BY_ID,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        console.log("error while Getiing Claim Details", error);
      }
    );
  };
};

export const addClaim = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endPoint.ADD_CLAIM,
      payload,
      (response) => {
        console.log("Response For adding claim", response);
        callBack(response);
      },
      (error) => {
        errorCallback(error);
        console.log("Error For adding claim", error);
      }
    );
  };
};

export const getAllTutorials = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      endPoint.GET_ALL_TUTORIAL,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};
export const getAllRaiseQuery = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      endPoint.GET_ALL_RAISEQUERY,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const getAllGlobalBuilders = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_GLOBAL_BUILDER,
      payload,
      (response) => {
        callBack(response?.data);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const updateVisitById = (payload, callBack) => {
  return (dispatch) => {
    putAPI(
      endPoint.UPDATE_VISIT_BY_ID,
      payload,
      (response) => {
        console.log("Response For Updating Visit by Id", response);
        callBack(response);
      },
      (error) => {
        console.log("Error While Updating Visit by Id", error);
      }
    );
  };
};

export const getAllVisits = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint.GET_ALL_VISIT,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        console.log("error while Getiing Claims Details", error);
        errorCallback(error);
      }
    );
  };
};

export const getAllproperties = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      endPoint?.GET_ALL_PROPERTY,
      payload,
      (response) => {
        console.log("Response For Getting All Properties", response);
        callBack(response);
      },
      (error) => {
        console.log("error while Getiing Claims Details", error);
        errorCallback(error);
      }
    );
  };
};

export const getAllpropertyCategory = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      endPoint?.GET_ALL_PROPERTY_CATEGORY,
      payload,
      (response) => {
        console.log("Response For Getting All Properties Category", response);
        callBack(response);
      },
      (error) => {
        console.log("error while Getiing Claims Details Category", error);
        errorCallback(error);
      }
    );
  };
};

export const getAllEarningsPdfView = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      endpoint?.GET_ALL_EARNING_PDF,
      payload,
      (data) => {
        callBack(data);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const getTotalEarningsPdfView = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      endpoint?.GET_TOTAL_EARNING_PDF,
      payload,
      (data) => {
        callBack(data);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const getUpcomingEarningPdfView = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      endpoint?.GET_UPCOMING_EARNING_PDF,
      payload,
      (data) => {
        callBack(data);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const getVisitsPdf = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      endpoint?.GET_VISITS_PDF,
      payload,
      (data) => {
        callBack(data);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const getClaimApprovalPDF = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      endpoint?.GET_CLAIM_APPROVAL_PDF,
      payload,
      (data) => {
        callBack(data);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const getTotalReferalEarningPdf = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      endpoint?.GET_TOTAL_REFERAL_EARNINGS_PDF,
      payload,
      (data) => {
        callBack(data);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const getAllNotifications = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      endpoint?.GET_ALL_NOTIFICATION,
      payload,
      (data) => {
        callBack(data);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const searchProperty = (search, payload, callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      `${endPoint.SEARCH_PROPERTY}/${search}`,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const FilterProperty = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoint.FILTER_PROPERTY,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const getAllBrokerEarnings = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      endpoint?.GET_ALL_BROKER_EARNING,
      payload,
      (data) => {
        callBack(data);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const getTotalEarnings = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      endpoint?.GET_TOTAL_EARNING,
      payload,
      (data) => {
        callBack(data);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const getUpcomingEarnings = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      endpoint?.GET_UPCOMING_EARNING,
      payload,
      (data) => {
        callBack(data);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const getReferalEarnings = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      endpoint?.GET_REFERAL_EARNING,
      payload,
      (data) => {
        callBack(data);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const updateProperty = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endPoint.UPDATE_PROPERTY,
      payload,
      (response) => {
        console.log("Response For update Property", response);
        callBack(response);
      },
      (error) => {
        errorCallback(error);
        console.log("Error For update Property", error);
      }
    );
  };
};

export const shareBrochure = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endPoint.SHARE_BROCHURE,
      payload,
      (response) => {
        callBack(response);
        console.log("Response For share Brochure", response);
      },
      (error) => {
        errorCallback(error);
        console.log("Error For update Property", error);
      }
    );
  };
};

export const getAllAmenties = (callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      endPoint.GET_ALL_AMENTIES,
      {},
      (response) => {
        callBack(response);
        console.log("Response For get all amenties", response);
      },
      (error) => {
        errorCallback(error);
        console.log("Error For get all amenties", error);
      }
    );
  };
};

export const addVisitClaim = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endPoint.ADD_VISIT_CLAIM,
      payload,
      (response) => {
        console.log("Response For adding visit claim", response);
        callBack(response);
      },
      (error) => {
        errorCallback(error);
        console.log("Error For adding visit claim", error);
      }
    );
  };
};

export const verifyVisitOtp = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endPoint.VERIFY_VISIT_OTP,
      payload,
      (response) => {
        console.log("Response For Verifying Visit OTP", response);
        callBack(response);
      },
      (error) => {
        errorCallback(error);
        console.log("Error For Verifying Visit OTP", error);
      }
    );
  };
};

export const getClaimPDFByID = (id, callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_CLIAM_PDF,
      { id },
      (response) => {
        callBack(response?.data);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const getPaymentPDFByID = (id, callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_PAYMENT_PDF,
      { claimId: id },
      (response) => {
        callBack(response?.data);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const getAllBuilders1 = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_GLOBAL_BUILDER,
      payload,
      (response) => {
        callBack(response?.data);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};