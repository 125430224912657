import { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from '@mui/material';
import { apiEndpoints } from '../../../Api/ApiEndpoint';
import { getAPI } from '../../../Api/ApiRequest';
import './TransactionHistory.css';

const TransactionHistory = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getTransaction = async () => {
      try {
        const response = await getAPI(apiEndpoints?.getAllPayment);
        setData(response?.data ?? []);
      } catch (error) {
        console.error('Error fetching transaction data:', error);
      }
    };
    getTransaction();
  }, []);

  const numRowsToShow = Math.min(10, data.length);

  const paymentData = data?.slice(0, numRowsToShow).map((itm, index) => (
    <TableRow key={index}>
      <TableCell>{itm?.invoiceId}</TableCell>
      <TableCell>{itm?.transactionId}</TableCell>
      <TableCell>{itm?.transactionDate}</TableCell>
      <TableCell>{itm?.transactionAmount}</TableCell>
    </TableRow>
  ));

  return (
    <>
      <h3 className='mb-2'>Transactions</h3>

      <TableContainer
        component={Grid}
        sx={{ borderRadius: '1rem', border: '2px solid #E9E9E9' }}
      >
        <Table className='profile__transaction'>
          <TableHead>
            <TableRow>
              <TableCell>Invoice ID</TableCell>
              <TableCell>Transaction ID</TableCell>
              <TableCell>Payment Date</TableCell>
              <TableCell>Amount ₹</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{paymentData}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TransactionHistory;
