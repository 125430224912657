import React from 'react';
import { Card, CardHeader, CardContent, Box, Typography } from '@mui/material';
import './ShadowCard.css';

function ShadowCard({ itemLength, title, text, timing }) {
  return (
    <>
      {Array.from({ length: itemLength }, (_, index) => (
        <Box
          px={4}
          py={4}
          m={2}
          key={index}
          sx={{
            background: '#F8F8F8',
            borderRadius: '2rem',
            position: 'relative',
            height: 'auto',
          }}
        >
          <Typography
            variant='p'
            mb={1}
            component={'p'}
            className='border-0 bg-transparent'
          >
            {' '}
            {title}
            {text}
          </Typography>
          <Typography></Typography>
          <Typography
            mb={1}
            sx={{
              position: 'absolute',
              bottom: '3%',
              right: '4%',
              opacity: 0.5,
            }}
          >
            {timing}
          </Typography>
        </Box>
      ))}
    </>
  );
}

export default ShadowCard;
