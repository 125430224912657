import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import { Card, Container } from 'react-bootstrap';
// import DashboardTabs from '../dashboard-tabs/DashboardTabs';
import DashboardTabs from '../../../components/dashboard-tabs/DashboardTabs';
import 'swiper/css';
import 'swiper/css/bundle';

import 'swiper/css/navigation';

import SwiperMain from './SwiperMain';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const tabKey = ['All', '2BHK ', ' 3BHK', '4BHK', '5BHK'];

const Slider = ({ floorPlansAndPricing, propertyType, propertyId }) => {
  const [activeTab, setActiveTab] = useState(tabKey[0]);
  const navigation = useNavigate();
  const location = useLocation();
 

  const formatPrice = (price) => {
    if (price >= 10000000) {
      return `₹ ${(price / 10000000).toFixed(2)} Cr`;
    } else if (price >= 100000) {
      return `₹ ${(price / 100000).toFixed(2)} L`;
    } else {
      return `₹ ${price}`;
    }
  };

  const calculatePricePerSqft = (price, areaSqft) => {
    const pricePerSqft = price / areaSqft;
    return pricePerSqft.toFixed(2);
  };

  const filteredProperties =
    activeTab === 'All'
      ? floorPlansAndPricing
      : floorPlansAndPricing.filter((property) =>
          property.unitType.includes(activeTab.trim())
        );

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [unitTypes, setUnitTypes] = useState([]);

  useEffect(() => {
    const uniqueUnitTypes = new Set(
      floorPlansAndPricing.map((item) => item.unitType[0])
    );

    // If you need the unique unit types as an array, you can convert the Set back to an array
    const uniqueUnitTypesArray = ['All', ...uniqueUnitTypes];
    setUnitTypes(uniqueUnitTypesArray);
  }, []);

  const handleCardClick = (id) => {
    if(location.pathname.includes("builder")){
      navigation(`/builder/floor-plan/${id}`);
    }
    else{
      navigation(`/broker/floor-plan/${id}`);
    }
  };

  //
  return (
    <>
      <div className='mb-4 mt-5'>
        <h5 className='swiper-title'>Floor Plans & Pricing</h5>
        {/* <span className='swiper-subtitle'>in Arocon Rainbow</span> */}
      </div>
      <DashboardTabs
        tabsKey={unitTypes}
        activeState={activeTab}
        onTabClick={handleTabClick}
      >
        <Swiper
          modules={[Navigation, Autoplay]}
          spaceBetween={30}
          slidesPerView={3}
          navigation
          pagination={{ clickable: true }}
          className='mt-0 mb-5'
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            900: {
              slidesPerView: 3,
            },
          }}
        >
          {!!filteredProperties.length &&
            filteredProperties.map(
              ({
                floorPlanImageUrl,
                _id,
                areaSquareFeet,
                areaSquareMeter,
                price,
                unitType,
                unitSize,
                carpetSize,
              }) => (
                <SwiperSlide
                  key={_id}
                  onClick={() => handleCardClick(_id)}
                  style={{ cursor: 'pointer' }}
                >
                  <Card className='h-100 border-0 shadow-sm swiper__card p-3'>
                    <div className='ribbon'>
                      <span>{unitType} </span>
                    </div>

                    <Card.Img
                      variant='top'
                      src={floorPlanImageUrl[0]}
                      style={{ height: '15rem' }}
                    />
                    <Card.Body className='px-0'>
                      {propertyType?.name == 'Commercial' ? (
                        <>
                          <div className='swiper__card__row d-flex justify-content-between align-items-center'>
                            <span>Built-Up Area</span>
                            <span className='text-right'>Price</span>
                          </div>
                          <div className='swiper__card__row d-flex justify-content-between align-items-center'>
                            <span>
                              {unitSize?.unitSize
                                ? `${unitSize.unitSize} sqft`
                                : '--'}
                            </span>
                            <span>
                              {formatPrice(price)}
                              {unitSize && (
                                <>
                                  (
                                  {calculatePricePerSqft(
                                    price,
                                    unitSize.unitSize
                                  ).toLocaleString()}
                                  /sq.ft.)
                                </>
                              )}
                            </span>
                          </div>
                          <div className='swiper__card__row d-flex justify-content-between align-items-center mt-2'>
                            <span>
                              {unitSize?.unitSize
                                ? `(${(unitSize?.unitSize * 0.092903).toFixed(
                                    2
                                  )} sq.m.)`
                                : '--'}
                            </span>

                            <span>+Govt. Charges</span>
                          </div>
                        </>
                      ) : propertyType?.name == 'Flats / Apartment' ? (
                        <>
                          <div className='swiper__card__row d-flex justify-content-between align-items-center'>
                            <span>Built-Up Area</span>
                            <span className='text-right'>Price</span>
                          </div>
                          <div className='swiper__card__row d-flex justify-content-between align-items-center'>
                            <span>
                              {carpetSize ? `${carpetSize} sqft` : '--'}
                            </span>
                            <span>
                              {formatPrice(price)}
                              {carpetSize && (
                                <>
                                  (
                                  {calculatePricePerSqft(
                                    price,
                                    carpetSize
                                  ).toLocaleString()}
                                  /sq.ft.)
                                </>
                              )}
                            </span>
                          </div>
                          <div className='swiper__card__row d-flex justify-content-between align-items-center mt-2'>
                            <span>
                              {carpetSize
                                ? `(${(carpetSize * 0.092903).toFixed(
                                    2
                                  )} sq.m.)`
                                : '--'}
                            </span>

                            <span>+Govt. Charges</span>
                          </div>
                        </>
                      ) : propertyType?.name == 'Land/Plots' ? (
                        <>
                          <div className='swiper__card__row d-flex justify-content-between align-items-center'>
                            <span>Plot Size</span>
                            <span className='text-right'>Price</span>
                          </div>
                          <div className='swiper__card__row d-flex justify-content-between align-items-center'>
                            <span>
                              {unitSize?.unitSize
                                ? `${unitSize.unitSize} sqft`
                                : '--'}
                            </span>
                            <span>
                              {formatPrice(price)}
                              {unitSize && (
                                <>
                                  (
                                  {calculatePricePerSqft(
                                    price,
                                    unitSize.unitSize
                                  ).toLocaleString()}
                                  /sq.ft.)
                                </>
                              )}
                            </span>
                          </div>
                          <div className='swiper__card__row d-flex justify-content-between align-items-center mt-2'>
                            <span>
                              {unitSize?.unitSize
                                ? `lxb: ${unitSize?.length}ft. (${(
                                    unitSize?.length * 0.3048
                                  ).toFixed(2)} mt.) x ${
                                    unitSize?.breadth
                                  }ft. (${(unitSize?.breadth * 0.3048).toFixed(
                                    2
                                  )} mt.)`
                                : '--'}
                            </span>

                            <span>+Govt. Charges</span>
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </Card.Body>
                  </Card>
                </SwiperSlide>
              )
            )}
        </Swiper>
      </DashboardTabs>
    </>
  );
};

export default Slider;
