export const heroData = [
  { _id: '1', name: 'Luxury Flats', image: '/500x/hero-bar/img-1.webp' },
  {
    _id: '2',
    name: 'Commercial Dedicated',
    image: '/500x/hero-bar/img-2.webp',
  },
  { _id: '3', name: 'Plot Deen', image: '/500x/hero-bar/img-3.webp' },
  { _id: '4', name: 'Premium Villas', image: '/500x/hero-bar/img-4.webp' },
  { _id: '5', name: 'River Front', image: '/500x/hero-bar/img-5.webp' },
];
