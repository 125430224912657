import { Col, Row } from 'react-bootstrap';
import { ProductCard } from '../../features';
import { Link, useNavigate } from 'react-router-dom';
import './ProductContainers.css';

const PropertySection = (props) => {
  const navigate = useNavigate();

  const handleCardClick = (productId) => {
    navigate(`/broker/property-details/${productId}`);
  };

  return (
    <section className='nes__product__section mt-5'>
      <Row className='gy-3'>
        <Col
          sm={12}
          className='d-flex justify-content-between align-items-center mb-3'
        >
          <h2 className='nes__product__section__title'>{props.sectionTitle}</h2>
          {props.view === 'promoted' && (
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
              />
            </div>
          )}

          {props.view !== 'promoted' && (
            <Link
              to={'/recently-added-properties'}
              className='nes__product__section__view'
            >
              View All
            </Link>
          )}
        </Col>

        {props.sliceData.length > 0 ? 
          props.sliceData.map((property) => (
            <Col key={property?._id} xs={12} sm={12} md={4} className='mb-4'>
              <ProductCard
                {...property}
                view={props.view}
                pagination
                onClick={() => handleCardClick(property._id)}
                isOpen={props.isOpen}
              />
            </Col>
          ))
        : 
        <p
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '24px',
                    }}
                  >
                    No data available yet
                  </p>
        }
      </Row>
    </section>
  );
};

// const PromotedProperty = (props) => {
//   return (
//     <section className='nes__product__section mt-5'>
//       <Row className='gy-3'>
//         <Col
//           sm={12}
//           className='d-flex justify-content-between align-items-center mb-3'
//         >
//           <h2 className='nes__product__section__title'>{props.sectionTitle}</h2>
//           {props.view === 'promoted' && (
//             <div className='form-check form-switch'>
//               <input
//                 className='form-check-input'
//                 type='checkbox'
//                 role='switch'
//                 id='flexSwitchCheckDefault'
//               />
//             </div>
//           )}

//           {props.view !== 'promoted' && (
//             <Link className='nes__product__section__view'>View All</Link>
//           )}
//         </Col>

//         {/* <ProductCard {...property} view={view} /> */}

//         {props?.isPromoted == true ? (
//           props.propertyData &&
//           props.sliceData.map((property) => (
//             <Col key={property?._id} xs={12} sm={6} md={4} className='mb-4'>
//               <ProductCard {...property} view={props.view} pagination />
//             </Col>
//           ))
//         ) : (
//           <p
//             style={{
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center',
//               fontSize: '24px',
//             }}
//           >
//             No data available yet
//           </p>
//         )}
//       </Row>
//     </section>
//   );
// };

const PromotedProperty = (props) => {
  const navigate = useNavigate();

  const handleCardClick = (productId) => {
    navigate(`/broker/property-details/${productId}`);
    console.log('id is', productId);
  };
  const { sectionTitle, view, sliceData } = props;

  return (
    <section className='nes__product__section mt-5'>
      <Row className='gy-3'>
        <Col
          sm={12}
          className='d-flex justify-content-between align-items-center mb-3'
        >
          <h2 className='nes__product__section__title'>{sectionTitle}</h2>
          {view === 'promoted' && (
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
              />
            </div>
          )}

          {view !== 'promoted' && (
            <Link
              to='/promoted-property'
              className='nes__product__section__view'
            >
              View All
            </Link>
          )}
        </Col>

        {sliceData.length > 0 ? (

          sliceData.map((property) => (
            <Col key={property._id} xs={12} sm={12} md={4} className='mb-4'>
              <ProductCard
                {...property}
                view={view}
                pagination
                onClick={() => handleCardClick(property._id)}
                isOpen={props.isOpen}
              />
            </Col>
          ))
        ) : (
          <p
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '24px',
            }}
          >
            No data available yet
          </p>
        )}
      </Row>
    </section>
  );
};

const BestSelling = (props) => {
  const { sectionTitle, view, sliceData } = props;
  console.log('first,prop', props);
  const navigate = useNavigate();

  const handleCardClick = (productId) => {
    navigate(`/broker/property-details/${productId}`);
    console.log('id is', productId);
  };
  return (
    <section className='nes__product__section mt-5'>
      <Row className='gy-3'>
        <Col
          sm={12}
          className='d-flex justify-content-between align-items-center mb-3'
        >
          <h2 className='nes__product__section__title'>{sectionTitle}</h2>
          {view === 'promoted' && (
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
              />
            </div>
          )}

          {view !== 'promoted' && (
            <Link
              to={'/best-selling-property'}
              className='nes__product__section__view'
            >
              View All
            </Link>
          )}
        </Col>

        {/* Render best selling properties or message */}
        {sliceData.length > 0 ? (
          sliceData.map((property) => (
            <Col key={property._id} xs={12} sm={12} md={4} className='mb-4'>
              <ProductCard
                {...property}
                view={view}
                pagination
                onClick={() => handleCardClick(property._id)}
                isOpen={props.isOpen}
              />
            </Col>
          ))
        ) : (
          <p
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '24px',
            }}
          >
            No best selling properties available
          </p>
        )}
      </Row>
    </section>
  );
};

const ProductContainers = ({ propertyData, view, children, isOpen, setIsOpen }) => {
  const promotedProperties = propertyData.filter(
    (property) => property.isPromoted
  );
  const bestSellingProperties = propertyData.filter(
    (property) => property.isBestSelling
  );
  return (
    <>
      <PromotedProperty
        sectionTitle='Promoted Property'
        propertyData={propertyData}
        // sliceData={propertyData.slice(0, 6)}
        sliceData={promotedProperties.slice(0, 6)}
        view={view}
        isOpen={isOpen} setIsOpen={setIsOpen}
      />
      {children}
      <PropertySection
        sectionTitle='Recently Added'
        propertyData={propertyData}
        sliceData={propertyData.slice(0, 6)}
        view={view}
        isOpen={isOpen} setIsOpen={setIsOpen}
      />
      <BestSelling
        sectionTitle='Best Selling'
        propertyData={propertyData}
        // sliceData={propertyData.slice(0, 6)}
        sliceData={bestSellingProperties.slice(0, 6)}
        view={view}
        isOpen={isOpen} setIsOpen={setIsOpen}
      />
    </>
  );
};

export default ProductContainers;
