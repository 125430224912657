import React from 'react'
import Information from './Information/Information';
import './PrivacyPolicy.css'
import InformativeFooter from '../InformativeFooter/InformativeFooter';
import InformativeNavbar from '../Navbar/Navbar';
import { Fade } from 'react-reveal';

const PrivacyPolicyMobile = () => {
    return (
        <>
            <InformativeNavbar />
            <div style={{ overflowX: 'hidden', margin:'1rem', fontFamily:'Bahnschrift' }}>
                <h2>Privacy Policy</h2>
                <p>
                Thank you for using our app. Your privacy is important to us. This Privacy Policy explains how we collect, use, and disclose your information when you use our app and the choices you have associated with that information.
                </p>

                <h4>Information We Collect</h4>
                <p>
                We do not collect any personal information when you use our app for voice search functionality. The app only accesses the microphone when you explicitly initiate a voice search. The voice data is processed locally on your device and is not stored or transmitted to any servers.
                </p>

                <h4>How We Use Your Information</h4>
                <p>
                The voice data collected during a voice search is used solely for the purpose of processing your voice query and providing you with search results within the app. We do not use this data for any other purpose.
                </p>

                <h4>Information Sharing and Disclosure</h4>
                <p>
                We do not share or disclose your voice data to any third parties. The data is processed locally on your device and is not transmitted over the internet.
                </p>

                <h4>Security</h4>
                <p>
                We take reasonable measures to protect your voice data from unauthorized access, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee its absolute security.
                </p>
                <h4>Changes to This Privacy Policy</h4>
                <p>
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                </p>

                <h4>Contact Us</h4>
                <p>
                If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at <a href="mailto:info@nestohub.in">info@nestohub.in</a>.
                <br/>
                By using our app, you agree to the collection and use of information in accordance with this Privacy Policy.
                </p>
            </div>
            <InformativeFooter />
        </>
    )
}

export default PrivacyPolicyMobile