import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllpropertyCategory } from "../../../../../API/action";
import flatsIcon from "../../assets/images/flats.svg";
import farmHouseIcon from "../../assets/images/farmhouse.svg";
import plotsIcon from "../../assets/images/plots.svg";
import commercialIcon from "../../assets/images/commercial.svg";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const HeaderCategory = () => {
  const [propertyCategory, setPropertyCategory] = useState([]);

  const smallCardSectionData = [
    {
      label: "Flats / Apartment",
      imgPath: flatsIcon,
    },
    {
      label: "Farmhouse / Villa",
      imgPath: farmHouseIcon,
    },
    {
      label: "Land/Plots",
      imgPath: plotsIcon,
    },
    {
      label: "Commercial",
      imgPath: commercialIcon,
    },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(
      getAllpropertyCategory(
        {},
        (data) => {
          setPropertyCategory(data.data.data);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }, []);
  return (
    <>
      <section className="nes__about mt-5">
        <Container>
          <Row className="justify-content-center">
            {smallCardSectionData.map((data, index) => (
              <Col
                xs={12}
                md={6}
                lg={3}
                className="mb-4"
                key={index}
                onClick={() => {
                  const id = propertyCategory.filter(
                    (item) => item.name == data.label
                  )[0]._id;
                  navigate("/broker/properties/" + id);
                }}
                style={{cursor:'pointer'}}
              >
                <Card className="border-0">
                  <Card.Body>
                    <div className="d-flex align-items-center justify-content-center gap-2">
                      <div className="rounded-circle d-flex">
                        <Image src={data.imgPath} fluid className="m-auto" />
                      </div>
                      <h5 className="ml-3 mb-0">{data.label}</h5>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default HeaderCategory;