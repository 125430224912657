import { BrokerageManagementCard } from '../../../../components';
import { Col, Container, Row } from 'react-bootstrap';
import DashboardTabs from '../../../../dashboard-containers/broker-profile/dashboard-tabs/DashboardTabs';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import SearchFilterBox from '../../../../../components/search-filter/SearchFilter';
import useToken from '../../../../hooks/useToken';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetAllClaim,
  useGetQueryWithId,
} from '../../../../hooks/LoginQuery';
import { apiEndpoints } from '../../../../config/apiEndpoints';
import LoadingSpinner from '../../../../Common/loading-spinner/LoadingSpinner';
import ErrorMessage from '../../../../Common/error-message/ErrorMessage';
import { getAllClaims, getAllEligibleClaims } from '../../../../../API/action';
import { useEffect, useState } from 'react';
const tabKey = ['Eligible Claims', 'Claim History'];

const BrokerageManagement = () => {
  useToken();
  // const brokerId = useSelector(state => state.auth.brokerID);
  const brokerId = localStorage.getItem("brokerID");

  // const {
  //   isLoading: isGetAllEligibleClaimLoading,
  //   isError: isGetAllEligibleClaimError,
  //   data: getAllEligibleClaimResponse,
  //   error: getAllEligibleClaimError,
  //   isSuccess: isGetAllEligibleClaimSuccess,
  // } = useGetQueryWithId(
  //   'getAllEligibleClaims',
  //   apiEndpoints.getAllEligibleClaims,
  //   brokerId
  // );

  // console.log(getAllEligibleClaimResponse)


  const dispatch = useDispatch();


  const [eligibleClaims, setEligibleClaims] = useState([]);
  const [allClaims, setAllClaims] = useState([]);

  const getData = () => {
    let payload ={
      brokerId:brokerId,
    }
    dispatch(
      getAllEligibleClaims(
        payload,
        (data) => {
          setEligibleClaims(data.data.data);
          localStorage.setItem('eligibleClaims', JSON.stringify(data.data.data));
        },
        (error) => {
          // Handle error
          console.error("Error fetching all claims:", error);
        }
      )
    );
  };


  const getAllData = () => {
    let payload ={
      brokerId:brokerId,
    }
    dispatch(
      getAllClaims(
        payload,
        (data) => {
          setAllClaims(data.data.data);
        },
        (error) => {
          // Handle error
          console.error("Error fetching all claims:", error);
        }
      )
    );
  };

  useEffect(() => {
    getData();
    getAllData();
  }, []);

  // get all claim
  // const {
  //   getAllClaimIsLoading,
  //   getAllClaimIsError,
  //   getAllClaimResponse,
  //   getAllClaimError,
  //   getAllClaimIsSuccess,
  // } = useGetAllClaim('640c30902cdf3673a29ae71d');
  // get all claim code end here

  // if (isGetAllEligibleClaimLoading) {
  //   return <LoadingSpinner />;
  // }
  
  // if (isGetAllEligibleClaimError) {
  //   return <ErrorMessage />;
  // }
  
  // if (isGetAllEligibleClaimSuccess && getAllClaimIsSuccess) {
    return (
      <>
        <Header />
        <SearchFilterBox addBtn />
        <section className="mt-3 brokerage__management">
          <Container>
            <Row>
              <Col xs={12}>
                <h1 className="section-title mb-4">Brokerage Management</h1>
              </Col>
              <Col>
                <DashboardTabs tabsKey={tabKey} activeState={tabKey[0]}>
                  <Row tabKey={tabKey[0]}>
                    {eligibleClaims.map(
                      (eligibleClaim, index) => (
                        <Col lg={6} md={6} className="mb-3" key={index}>
                          <BrokerageManagementCard
                            text="Raise the brokerage claim"
                            type="raise"
                            eligibleClaim={eligibleClaim}
                          />
                        </Col>
                      )
                    )}
                  </Row>
                  <Row tabKey={tabKey[1]}>
                    {
                    // (Array.isArray(getAllClaimResponse) ? getAllClaimResponse : [])
                    allClaims?.map(
                      (claimHistory, index) => (
                        <Col lg={6} md={6} className="mb-3" key={index}>
                          <BrokerageManagementCard
                            text="Raise the brokerage claim"
                            type="claimHistory"
                            claimHistory={claimHistory}
                          />
                        </Col>
                      )
                    )}
                  </Row>
                  {/* <Row tabKey={tabKey[2]}>
                    <Col lg={6} md={6} className="mb-3">
                      <BrokerageManagementCard
                        text="Claim Submitted"
                        type="claimSubmitted"
                      />
                    </Col>
                    <Col lg={6} md={6} className="mb-3">
                      <BrokerageManagementCard
                        text="Payment Received"
                        type="paymentReceived"
                      />
                    </Col>
                    <Col lg={6} md={6} className="mb-3">
                      <BrokerageManagementCard text="Paid" type="paid" />
                    </Col>
                    <Col lg={6} md={6} className="mb-3">
                      <BrokerageManagementCard
                        text="Claim Approved"
                        type="claimApproved"
                      />
                    </Col>
                    <Col lg={6} md={6} className="mb-3">
                      <BrokerageManagementCard text="Assigned" type="assigned" />
                    </Col>
                  </Row> */}
                </DashboardTabs>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
      </>
    );
  }
// };

export default BrokerageManagement;
