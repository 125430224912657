import { Container, Tab, Tabs } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import DashboardHeader from '../../header/DashboardHeader';
import SearchFilterBox from '../../search-filter/SearchFilter';
import AddedProperty from './AddedProperty';
import SoldProperty from './SoldProperty';

const AllProperty = () => {
  const location = useLocation();
  const [key, setKey] = useState('soldOut');

  useEffect(() => {
    if (location.state?.tab) {
      setKey(location.state.tab);
    }
  }, [location.state]);

  return (
    <>
      <DashboardHeader />
      <br />

      <Container className='pt-2 pb-4 dashboard__wrapper'>
        <SearchFilterBox />
        <h3 className='heading'>All Property </h3>

        <Tabs
          activeKey={key}
          onSelect={(k) => setKey(k)}
          id='fill-tab-example'
          className='property-tab mb-3 mx-auto '
          fill
        >
          <Tab eventKey='soldOut' title='Soldout Property'>
            <SoldProperty />
          </Tab>

          <Tab eventKey='added' title='Added Property'>
            <AddedProperty />
          </Tab>
        </Tabs>
      </Container>
    </>
  );
};

export default AllProperty;
