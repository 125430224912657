export default function formatNumber(input) {
    let number = parseFloat(input || "0.0");
  
    if (number >= 10000000) {
      let cr = number / 10000000.0;
      return '₹' + cr.toFixed(2) + 'Cr';
    } else if (number >= 100000) {
      let l = number / 100000.0;
      return '₹' + l.toFixed(2) + 'L';
    } else if (number >= 1000) {
      let thousand = number / 1000.0;
      return '₹' + thousand.toFixed(2) + 'K';
    } else {
      return '₹' + number.toFixed(2);
    }
  }
  