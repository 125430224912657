import { Box } from '@mui/material';
import React from 'react';
import { rotateNumber, SuperImageSize } from './SuperImageSize';

export default function SuperImageBox({
  sectionActive,
  activeImage,
  iconDataArray,
}) {
  return (
    <Box
      sx={{
        scrollSnapAlign: 'center',
        position: 'relative',
        opacity: activeImage ? '1' : '0',
        top: { md: '50%', xs: activeImage ? '-10%' : '-20%' },
        right: { md: activeImage ? '10%' : '-20%', xs: '50%' },
        transform: {
          md: `translate(25%, -50%) `,
          xs: `translate(50%, -50%) `,
        },
        zIndex: 8,
        transition: '0.7s',
        '& .animation-cartoon-img': {
          position: 'absolute',
          display: { md: 'block', xs: 'none' },
          top: '14%',
          left: '25%',
          transform: `translate(-25%, 50%) `,
          maxWidth: 280,
        },
      }}
    >
      <Box sx={{ rotate: { md: '0deg', xs: '-90deg' } }}>
        <img
          src="/500x/character-2.webp"
          className="animation-cartoon-img img-fluid"
        ></img>

        <Box
          sx={{
            width: '100%',
            height: '100%',
            width: SuperImageSize(),
            height: SuperImageSize(),
            mx: 'auto',
            border: '1px solid #aaa',
            borderRadius: '100%',
            rotate: `${rotateNumber(sectionActive)}deg`,
            transition: '0.7s',
            '.icon-box': {
              width: 120,
              height: 120,
              bgcolor: '#1371e2',
              borderRadius: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              transition: '0.7s',
              '& img': {
                width: 70,
                height: 'auto',
                rotate: `-${rotateNumber(sectionActive)}deg`,
              },
            },
          }}
        >
          <Box
            className="icon-box"
            sx={{
              top: '50%',
              left: '0%',
              transform: `translate(-50%, -50%)  ${
                sectionActive === 1 ? 'scale(1)' : 'scale(0.1)'
              }`,
            }}
          >
            <img src={iconDataArray[0]} className="icon" />
          </Box>
          <Box
            className="icon-box"
            sx={{
              top: '0%',
              left: '50%',
              transform: `translate(-50%, -50%) ${
                sectionActive === 4 ? 'scale(1)' : 'scale(0.1)'
              }`,
            }}
          >
            <img src={iconDataArray[1]} className="icon" />
          </Box>
          <Box
            className="icon-box"
            sx={{
              top: '50%',
              right: '0%',
              transform: `translate(50%, -50%) 
        ${sectionActive === 3 ? 'scale(1)' : 'scale(0.1)'}
        `,
            }}
          >
            <img src={iconDataArray[2]} className="icon" />
          </Box>
          <Box
            className="icon-box"
            sx={{
              bottom: '0%',
              left: '50%',
              transform: `translate(-50%, 50%) ${
                sectionActive === 2 ? 'scale(1)' : 'scale(0.1)'
              }
        `,
            }}
          >
            <img src={iconDataArray[3]} className="icon" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
