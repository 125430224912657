import { Card } from 'react-bootstrap';
import './TermsConditions.css';
import { useGetAllTermsAndConditionsBroker } from '../../../hooks/LoginQuery';
import { Fade } from 'react-reveal';

const TermsConditions = () => {
  const {
    getAllTermsAndConditionsBrokerIsLoading,
    getAllTermsAndConditionsBrokerIsError,
    getAllTermsAndConditionsBrokerResponse,
    getAllTermsAndConditionsBrokerError,
    getAllTermsAndConditionsBrokerIsSuccess,
  } = useGetAllTermsAndConditionsBroker();

  console.log(getAllTermsAndConditionsBrokerResponse)
  if (getAllTermsAndConditionsBrokerIsLoading) {
    return <div>loading</div>;
  }

  if (getAllTermsAndConditionsBrokerIsError) {
    return <div>{getAllTermsAndConditionsBrokerIsError.message}</div>;
  }
  return (
    <Card.Text className="refer__terms__and__conditions">
      <ul>
        {getAllTermsAndConditionsBrokerResponse[0]?.description
          .split('\n')
          .map((sentence, index) => (
            <li key={index}>
              {sentence}
              <br />
            </li>
          ))}
      </ul>

      <div style={{ overflowX: 'hidden' }}>
        <div className='t-c_heading-box'>
          <div className='container'>
            <span className='t-c_heading'>Terms of Use</span>
          </div>
        </div>
        <div className='container t-c_container'>
          <div className='row'>
            <div className='col-lg-12'>
              <Fade up duration={1000} delay={100}>
                <span className='t-c_span'>
                  These terms of use ("Terms") constitute a legally binding agreement between you and NestoHub Proptech Private Limited (the "Company")
                  regarding your use of the website www.nestohub.in (the "Site") and any services offered by the Company via the Site, any mobile or internet connected device or otherwise (the "the Service").
                </span>
              </Fade>
              <Fade up duration={1000} delay={100}>
                <span className='t-c_span'>
                </span>
              </Fade>

              <Fade up duration={1000} delay={100}>
                <span className='t-c_span'>
                  Your use of the Site and services and tools are governed by the following Terms as applicable to the Company including the applicable policies which are incorporated herein by way of reference. By mere use of the Site, You shall be contracting with NetoHub Proptech Private Limited, the owner of the Platform. These terms and conditions including the policies constitute Your binding obligations, with the Company.
                </span>
              </Fade>

              <Fade up duration={1000} delay={100}>
                <span className='t-c_span'>
                  Acceptance of Terms:<br />
                  Users of the platform must agree to these terms and conditions before accessing or using the services provided.
                </span>
              </Fade>

              <Fade up duration={1000} delay={100}>
                <span className='t-c_span'>
                  User Eligibility:<br />
                  Users must be of legal age and capable of entering into a legally binding agreement. If the platform allows user registration, accurate and complete information must be provided.
                </span>
              </Fade>

              <Fade up duration={1000} delay={100}>
                <span className='t-c_span'>
                  License and Usage:<br />
                  The platform grants users a non-exclusive, non-transferable license to access and use the services provided, subject to compliance with the terms and conditions.
                </span>
              </Fade>

              <Fade up duration={1000} delay={100}>
                <span className='t-c_span'>
                  Intellectual Property:<br />
                  All intellectual property rights associated with the platform, including trademarks, copyrights, and proprietary information, are owned by the platform or its licensors.
                </span>
              </Fade>

              <Fade up duration={1000} delay={100}>
                <span className='t-c_span'>
                  Prohibited Activities:<br />
                  Users must not engage in any activities that violate laws, infringe upon the rights of others, or disrupt the platform's operations. This may include unauthorized access, spamming, or uploading malicious content.
                </span>
              </Fade>

              <Fade up duration={1000} delay={100}>
                <span className='t-c_span'>
                  Privacy and Data Protection:<br />
                  Please refer to platforms Privacy policy for details around Privacy and Data Protection.
                </span>
              </Fade>

              <Fade up duration={1000} delay={100}>
                <span className='t-c_span'>
                  Cookies:<br />
                  The platform may use cookies or similar technologies to enhance the user experience and collect certain information about users. By using the platform, users consent to the use of cookies in accordance with the platform's privacy policy. Users may have the option to manage cookie preferences through their browser settings, but disabling cookies may affect the functionality of the platform.
                </span>
              </Fade>

              <Fade up duration={1000} delay={100}>
                <span className='t-c_span'>
                  Content Ownership:<br />
                  Users are responsible for the content they upload to the platform. By submitting content, users grant the platform a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, and distribute the content for the purpose of operating and promoting the platform.
                </span>
              </Fade>

              <Fade up duration={1000} delay={100}>
                <span className='t-c_span'>
                  Limitation of Liability:<br />
                  The platform should not be held liable for any direct, indirect, incidental, or consequential damages arising out of the use or inability to use the services provided.
                </span>
              </Fade>


              <Fade up duration={1000} delay={100}>
                <span className='t-c_span'>
                  Indemnification:<br />
                  Users agree to indemnify and hold the platform harmless from any claims, losses, or damages resulting from their use of the platform or violation of the terms and conditions.
                </span>
              </Fade>


              <Fade up duration={1000} delay={100}>
                <span className='t-c_span'>
                  Modifications to Terms:<br />
                  The platform reserves the right to modify or update the terms and conditions at any time. Users should regularly review the terms to stay informed about any changes.
                </span>
              </Fade>


              <Fade up duration={1000} delay={100}>
                <span className='t-c_span'>
                  Termination:<br />
                  The platform reserves the right to terminate or suspend user accounts or access to the services at its discretion, with or without cause.
                </span>
              </Fade>


              <Fade up duration={1000} delay={100}>
                <span className='t-c_span'>
                  Governing Law and Jurisdiction:<br />
                  All dispute related to these terms shall be exclusively resolved in the courts located in Gurgaon, India.
                </span>
              </Fade>
              {/* <Terms /> */}
            </div>
          </div>
        </div>
      </div>
    </Card.Text>
  );
};

export default TermsConditions;
