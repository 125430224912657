import { BsSearch, BsMic, BsGeoAlt } from "react-icons/bs";
import {
  InputGroup,
  FormControl,
  Button,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import "./SearchFilter.css";
import { IoMdAdd } from "react-icons/io";
import { useState } from "react";
import { useGetAllPropertyCategory } from "../../broker/hooks/LoginQuery";
import { Box } from "@mui/material";
import { useHistory, useLocation, useNavigate } from "react-router-dom";
import { RiAddFill } from "react-icons/ri";
import Dropdown from "react-bootstrap/Dropdown";
import { BookAVisitModal } from "../../broker/features";
import Filter from "./Filter/Filter";

const SearchFilterBox = ({
  addBtn = true,
  onAddButtonClick,
  requirementId,
  proprtyName,
  BuilderId,
  maximumPrice,
  minimumPrice,
  propertyId,
  selected,
  setSelected,
  setSearch,
  requirementClick,
  hideRequirement = false,
  showEmiCalculator,
  showClientOnly,
  showRequirementOnly,
  showVisitOnly,
  visitClick
}) => {
  const {
    getAllPropertyCategoryIsLoading,
    getAllPropertyCategoryIsError,
    getAllPropertyCategoryResponse,
    getAllPropertyCategoryError,
    getAllPropertyCategoryIsSuccess,
  } = useGetAllPropertyCategory();

  const [searchInput, setSearchInput] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const navigate = useNavigate();
  const changeHandler = (e) => {
    setSearchInput(e.target.value);
  };
  const location = useLocation();

  const onSubmit = (e) => {
    e.preventDefault();
    if (searchInput) {
      navigate(`/broker/search-property/${searchInput}`);
    }
  };

  const [showBookVisit, setShowBookVisit] = useState(false);

  const onHideBookVisitModal = () => {
    setShowBookVisit(false);
  };

  const handleFilterClose = (selectedData) => {
    setShowFilter(false);
    navigate("/broker/filter-property");
    if (location.pathname === "/broker/filter-property") {
      window.location.reload();
    }
  };

  const handleDownload = () => {
    const element = document.createElement("a");
    element.href = `/Sale Confirmation Form.pdf`;
    element.download = "Sale Confirmation Form.pdf";
    document.body.appendChild(element); // Append the element to the DOM
    element.click(); // Programmatically click the element
    document.body.removeChild(element); // Remove the element from the DOM
  };

  return (
    <>
      <Container>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Col>
            <Box
              component={"form"}
              onSubmit={onSubmit}
              className="dashboard__wrapper__filter nes__dashboard__filter bg-white shadow-sm d-flex flex-column flex-md-row align-items-center mb-5 mt-4"
            >
              <div
                className="px-3 py-2 cursor"
                onClick={() => setShowFilter(true)}
              >
                {/* <Form.Select
                  // onChange={(e) => handleCardClick(e.target.value)}
                  value={selected}
                  name="cat"
                  defaultValue="all"
                  className="border-0 rounded-0 border-end"
                >
                  <option value="">All Residential</option>

                  {getAllPropertyCategoryResponse?.length > 0
                    ? getAllPropertyCategoryResponse?.map((item, index) => (
                        <option
                          key={index}
                          value={item?._id
                            // ?.toLowerCase()
                            // .replace(/[ \/]+/g, "-and-")
                          }
                        >
                          {item.name}
                        </option>
                      ))
                    : ""}
                </Form.Select> */}
                Filter
              </div>
              <div className="flex-grow-1 order-first order-md-2 px-1 my-2 my-md-0 w-100">
                <InputGroup>
                  <InputGroup.Text className="border-0 bg-transparent">
                    <BsSearch color="#B3B4B8" />
                  </InputGroup.Text>
                  <FormControl
                    placeholder="Search"
                    className="border-0"
                    name="query"
                    value={searchInput?.query}
                    onChange={changeHandler}
                  />
                </InputGroup>
              </div>
              <div className="px-2 py-2 voice-location-icons order-last d-flex align-items-center">
                {/* <BsGeoAlt className="rounded-circle me-2 p-2" size={16} />
                <BsMic className="rounded-circle me-2 p-2" size={16} /> */}
                <Button
                  type="submit"
                  variant="primary"
                  className="ms-2 px-5 bg-color-primary"
                >
                  Search
                </Button>
              </div>
            </Box>
          </Col>
          {showVisitOnly === true && (
            <Col
              xl={1}
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0 0 2% 0",
              }}
            >
              <Button
                variant="primary"
                className="rounded-circle p-2"
                style={{ boxShadow: "0px 6px 26px rgba(0, 0, 0, 0.25)" }}
                onClick={visitClick}
              >
                <RiAddFill size={30} />
              </Button>
            </Col>
          )}
          {showRequirementOnly === true && (
            <Col
              xl={1}
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0 0 2% 0",
              }}
            >
              <Button
                variant="primary"
                className="rounded-circle p-2"
                style={{ boxShadow: "0px 6px 26px rgba(0, 0, 0, 0.25)" }}
                onClick={requirementClick}
              >
                <RiAddFill size={30} />
              </Button>
            </Col>
          )}

          {showClientOnly === true && (
            <Col
              xl={1}
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0 0 2% 0",
              }}
            >
              <Button
                variant="primary"
                className="rounded-circle p-2"
                style={{ boxShadow: "0px 6px 26px rgba(0, 0, 0, 0.25)" }}
                onClick={onAddButtonClick}
              >
                <RiAddFill size={30} />
              </Button>
            </Col>
          )}
          {addBtn === true && (
            <Col
              xl={1}
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0 0 2% 0",
              }}
            >
              <Dropdown>
                <Dropdown.Toggle
                  variant="primary"
                  id="dropdown-basic"
                  className="rounded-circle p-2"
                  style={{ boxShadow: "0px 6px 26px rgba(0, 0, 0, 0.25)" }}
                >
                  <RiAddFill size={30} />
                </Dropdown.Toggle>

                <Dropdown.Menu className="px-2">
                  <Dropdown.Item
                    className="py-2"
                    onClick={() => setShowBookVisit(true)}
                  >
                    Book a Visit
                  </Dropdown.Item>
                  <Dropdown.Item className="py-2" onClick={onAddButtonClick}>
                    Add New Customer
                  </Dropdown.Item>
                  {!hideRequirement && (
                    <Dropdown.Item className="py-2" onClick={requirementClick}>
                      Add New Requirement
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    className="py-2"
                    onClick={() => navigate("/broker/profile")}
                  >
                    Raise Query
                  </Dropdown.Item>
                  <Dropdown.Item className="py-2" onClick={showEmiCalculator}>
                    EMI Calculator
                  </Dropdown.Item>
                  <Dropdown.Item className="py-2" onClick={handleDownload}>
                    Download Sale Confirmation Form
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          )}
        </Row>
      </Container>
      <BookAVisitModal
        show={showBookVisit}
        onHide={onHideBookVisitModal}
        requirementId={requirementId}
        proprtyName={proprtyName}
        maximumPrice={maximumPrice}
        minimumPrice={minimumPrice}
        BuilderId={BuilderId}
        propertyId={propertyId}
      />
      <Filter
        open={showFilter}
        setOpen={setShowFilter}
        handleClose={handleFilterClose}
      />
    </>
  );
};

export default SearchFilterBox;
