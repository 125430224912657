import { Col, Container, Row } from 'react-bootstrap';
import SearchFilterBox from '../../../search-filter/SearchFilter';
import { BsFileText, BsFiletypePdf } from 'react-icons/bs';
import Footer from '../../../Footer/Footer';
import {
  Table,
  TableRow,
  TableCell,
  Modal,
  TableHead,
  TableBody,
  TableContainer,
  Button,
  Paper,
} from '@mui/material';
import { TbMap2 } from 'react-icons/tb';
import '../AllVisite.css';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Payment from './Payment';
import { Link, useParams } from 'react-router-dom';
import { getAPI } from '../../../../Api/ApiRequest';
import { apiEndpoints } from '../../../../Api/ApiEndpoint';
import { Grid, Typography, Box } from '@mui/material';
import Header from '../../../../../broker/Common/Header/Header';
import { SearchFilter } from '../../../../../components';
import DashboardHeader from '../../../header/DashboardHeader';

const PropertyClaim = () => {
  const [data, setData] = useState([]);
  const params = useParams();
  console.log('claim id is', params.claimID);

  useEffect(() => {
    const getClaim = async () => {
      // const response = await axios.get(
      //   `https://apis.nestohub.in/api/v1/claim/getAllClaim?claimType=property&claimStatus=pending`,

      //     {
      //       headers: {
      //         Authorization:
      //           "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDBjMzA5MDJjZGYzNjczYTI5YWU3MWQiLCJpYXQiOjE2NzkzOTM1NDksImV4cCI6MTY4NzE2OTU0OX0.doSWScAsJZyCJk62uM7rBbsS8ipkpLZ-FjuYrfYJmu8",
      //       },${params.propertyId}
      //     }64353a693f099266360cd724
      //   );
      //   ;
      // try {
      const response = await getAPI(
        `${apiEndpoints.getClaimById}${params.claimID}`
      );
      console.log('claim response', response.data[0]);
      setData(response?.data[0] ?? []);
    };

    getClaim();
  }, []);

  const [showPayment, setShowPayment] = useState(false);

  const onPayClick = () => {
    setShowPayment(true);
  };

  const onHide = () => {
    setShowPayment(false);
  };

  const handleDownload = (pdfUrl) => {
    console.log('handle download started');
    const anchor = document.createElement('a');
    anchor.href = pdfUrl;
    anchor.setAttribute('download', '');
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  return (
    <>
      <DashboardHeader />
      {/* <Header /> */}
      <Container className='pt-5 pb-4 dashboard__wrapper'>
        <SearchFilter />
        <div className='d-flex justify-content-between'>
          <h3 className='heading'>Property Claim</h3>
          <h6 className='text-secondary claim-id'>
            Eligible Claim ID: {params.claimID}
          </h6>
        </div>
        <Container className='dashboard__wrapper__filter border border-light rounded shadow-sm px-5 py-3'>
          <br />
          <div className='d-flex col justify-content-between align-items-center'>
            <div>
              <h6 className='text-secondary'>Property Name</h6>
              <h5>{data?.visitId?.propertyName}</h5>
            </div>
            <div>
              <TbMap2 size='40' color='#278fd9' />
            </div>
          </div>
          <br />

          <Grid container spacing={3}>
            <Grid item md={4} xs={6}>
              <Typography
                variant='body1'
                color='textSecondary'
                gutterBottom
              >
                Visit ID
              </Typography>
              <Typography variant='h6'>
                {data?.visitId?._id}
              </Typography>
            </Grid>
            <Grid item md={4} xs={6}>
              <Typography
                variant='body1'
                color='textSecondary'
                gutterBottom
              >
                Visit Date
              </Typography>

              <Typography variant='h6'>
                {data?.visitId?.date[0]}
              </Typography>
            </Grid>
            <Grid item md={4} xs={6}>
              <Typography
                variant='body1'
                color='textSecondary'
                gutterBottom
              >
                Client Name
              </Typography>
              <Typography variant='h6'>
                {data?.visitId?.clientName}
              </Typography>
            </Grid>
            <Grid item md={4} xs={6}>
              <Typography
                variant='body1'
                color='textSecondary'
                gutterBottom
              >
                Unit Type
              </Typography>
              <Typography variant='h6'>
                {data?.visitId?.unitType[0]}
              </Typography>
            </Grid>
            <Grid item md={4} xs={6}>
              <Typography
                variant='body1'
                color='textSecondary'
                gutterBottom
              >
                Unit Number
              </Typography>
              <Typography variant='h6'>
                {data?.unitNumber}
              </Typography>
            </Grid>
            <Grid item md={4} xs={6}>
              <Typography
                variant='body1'
                color='textSecondary'
                gutterBottom
              >
                Selling Price
              </Typography>
              <Typography variant='h6'>
                {data?.boughtPropertyId?.sellingPrice}
              </Typography>
            </Grid>
            <Grid item md={4} xs={6}>
              <Typography
                variant='body1'
                color='textSecondary'
                gutterBottom
              >
                Broker ID
              </Typography>
              <Typography variant='h6'>
                {data?.visitId?.brokerId}
              </Typography>
            </Grid>
            <Grid item md={4} xs={6}>
              <Typography
                variant='body1'
                color='textSecondary'
                gutterBottom
              >
                Broker Name
              </Typography>
              <Typography variant='h6'>
                {data?.brokerId?.name}
              </Typography>
            </Grid>
            <Grid item md={4} xs={6}>
              <Typography
                variant='body1'
                color='textSecondary'
                gutterBottom
              >
                Selling Date
              </Typography>
              <Typography variant='h6'>
                {data?.boughtPropertyId?.bookingDate}
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <h3 className='heading mt-5'>Brokerage plan</h3>
        <Box className='dashboard__wrapper__filter border border-light rounded shadow-sm px-5 py-4'>
          <Grid container className='align-items-center'>
            <Grid item xs={9} md={10.5}>
              <Typography
                variant='h4'
                sx={{ fontWeight: 'bold' }}
              >
                ₹ {data?.brokerageAmount}
              </Typography>
              <Typography variant='p' className='brokerage-head'>
                *Brokerage Percentage -
                {data?.brokeragePercentage}
              </Typography>
            </Grid>
            <Grid item xs={3} md={1.5} className=''>
              <Typography variant='h6' color='textSecondary'>
                Builder Form
              </Typography>
              <Box sx={{ width: '100%' }}>
                {' '}
                <Box
                  component='img'
                  src='/assets/pdfIcon.svg'
                  alt='img'
                  onClick={() =>
                    handleDownload(
                      // Builder form to be downloaded here, currently available pdf is downloaded
                      data?.boughtPropertyId?.documents[0]
                    )
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mt={8}>
          <h3 className='heading mt-4'>Milestone</h3>

          <Box className='rounded shadow-sm ' p={3}>
            {data?.propertyId?.milestonesTermsConditions?.map(
              (milestone, index) => (
                <div key={index}>
                  <Grid container spacing={2} mb={2}>
                    <Grid item md={10} xs={8}>
                      <Typography variant='h5' fontWeight={600}>
                        {milestone.condition}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={2}>
                      <Typography
                        variant='subtitle2'
                        color='textSecondary'
                      >
                        {data?.propertyId?.createdAt}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={4}>
                      <Typography
                        variant='h6'
                        className='milestone-head'
                        color='textSecondary'
                      >
                        Brokerage %
                      </Typography>
                      <Typography
                        variant='h6'
                        className='milestone-head'
                        fontWeight={600}
                      >
                        {milestone.brokerage}
                      </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography
                        variant='h6'
                        className='milestone-head'
                        color='textSecondary'
                      >
                        Brokerage Amount
                      </Typography>
                      <Typography
                        variant='h6'
                        className='milestone-head'
                        fontWeight={600}
                      >
                        Rs. {data?.brokerageAmount}
                      </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography
                        variant='h6'
                        className='milestone-head'
                        color='textSecondary'
                      >
                        Claimed ID
                      </Typography>
                      <Typography
                        variant='h6'
                        className='milestone-head'
                        fontWeight={600}
                      >
                        {data._id}
                      </Typography>
                    </Grid>
                  </Grid>

                  <TableContainer
                    mt={4}
                    component={Box}
                    sx={{
                      border: '1px solid #E9E9E9',
                      borderRadius: '20px !important',
                      borderCollapse: 'collapse',
                    }}
                  >
                    <Table
                      responsive
                      aria-label='simple table'
                      style={{}}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight: '600',
                              fontSize: '1rem',
                            }}
                          >
                            Invoice ID
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: '600',
                              fontSize: '1rem',
                            }}
                          >
                            Invoice Amount
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: '600',
                              fontSize: '1rem',
                            }}
                          >
                            Status
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: '600',
                              fontSize: '1rem',
                            }}
                          >
                            View Invoice
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: '600',
                              fontSize: '1rem',
                            }}
                          >
                            Pay
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.invoiceIds.map(
                          (invoiceId, index) => (
                            <TableRow key={index}>
                              <TableCell
                                sx={{
                                  fontWeight: '500',
                                  fontSize: '1rem',
                                }}
                              >
                                {invoiceId._id}
                              </TableCell>

                              <TableCell
                                sx={{
                                  fontWeight: '500',
                                  fontSize: '1rem',
                                }}
                              >
                                ₹ {invoiceId.invoiceAmount}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: '500',
                                  fontSize: '1rem',
                                }}
                              >
                                <Button
                                  variant='outlined'
                                  disableElevation
                                  sx={{
                                    color:
                                      invoiceId.status ===
                                      'pending'
                                        ? 'red'
                                        : '#88C879',
                                    borderColor:
                                      invoiceId.status ===
                                      'pending'
                                        ? 'red'
                                        : '#88C879',
                                    borderRadius: '2rem',
                                    bgcolor:
                                      invoiceId.status ===
                                      'pending'
                                        ? '#FFE1E1'
                                        : '#EAF5E7',
                                    textTransform: 'none',
                                    width: '8rem',
                                  }}
                                >
                                  {invoiceId.status}
                                </Button>
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: '500',
                                  fontSize: '1rem',
                                }}
                              >
                                <Box
                                  component='img'
                                  src='/assets/pdfIcon.svg'
                                  alt='img'
                                  sx={{ width: '1.5rem' }}
                                  onClick={() =>
                                    handleDownload(
                                      // Invoice pdf to be set here
                                      data?.boughtPropertyId
                                        ?.documents[0]
                                    )
                                  }
                                />
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: '500',
                                  fontSize: '1rem',
                                }}
                              >
                                <Typography
                                  variant='h6'
                                  className='text-primary milestone-pay'
                                  onClick={onPayClick}
                                  sx={{
                                    textDecoration:
                                      'underline !important',
                                  }}
                                >
                                  Pay Now
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )
            )}
          </Box>
          {showPayment && (
            <Payment onHide={onHide} show={onPayClick} />
          )}
        </Box>
      </Container>
      <Footer />
    </>
  );
};
export default PropertyClaim;
