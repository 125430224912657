import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getAllTutorials } from "../../../../../API/action";

import { Link } from "react-router-dom";
import { Box, Grid, Paper } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import LinkPreview from "./LinkPreview";
import YouTube from "react-youtube";
import "./YoutubePreview.css";
const Tutorial = () => {
  const [tutorials, setTutorials] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getAllTutorials(
        {},
        (data) => {
          setTutorials(data.data.data);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }, []);

  const opts = {
    height: "330",
    width: "100%",
    playerVars: {
      autoplay: 0,
    },
  };

  function getYouTubeVideoId(url) {
    const urlObj = new URL(url);
    return urlObj.searchParams.get("v");
  }

  return (
    <>
      {tutorials.map((item, index) => (
        <Box mt={5} key={index}>
          {/* <iframe className="rounded" width="100%" height="315" src={item?.link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
          {/* {!isPlaying ? (
            <div className="thumbnail" onClick={handlePlay}>
              <img
                src={`https://img.youtube.com/vi/${getYouTubeVideoId(item?.link)}/hqdefault.jpg`}
                alt="YouTube Thumbnail"
                className="thumbnail-image"
              />
              <div className="play-button">▶</div>
            </div>
          ) : ( */}
          {/* <Paper elevation={4}> */}
          <Card sx={{ maxWidth: '100%' }}>
            <CardMedia>
              <YouTube videoId={getYouTubeVideoId(item?.link)} opts={opts} />
            </CardMedia>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {item?.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {item?.description?.length > 300 ? `${item?.description?.substring(0, 300)}...` : item?.description}
              </Typography>
            </CardContent>
          </Card>
          {/* </Paper> */}
          {/* )} */}
        </Box>
      ))}

      {/* <Grid container spacing={2} mt={5}>

   {tutorials.map((item, index)=>(
        <Link to={item.link}>
         <Grid item md={12}>

<Card sx={{ width:'100%' }}>
    <CardActionArea>
      <CardMedia
        component="img"
        height="140"
        image="https://images.unsplash.com/photo-1521302200778-33500795e128?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        alt={item?.title}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {item?.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {item?.description}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
</Grid>
    </Link>
    ))}

  </Grid> */}
    </>
  );
};

export default Tutorial;
