import { Link } from 'react-router-dom';
import './news.css';
import Heading from '../heading/Heading';
import { CiCalendar } from 'react-icons/ci';
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';
import { Fade } from 'react-reveal';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllBlogs } from '../../../API/action';

export default function News() {
  const [blogs, setBlogs] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllBlogs({}, (data) => {
      setBlogs(data.data.data);
    }, (err) => {
      console.log(err);
    }));
  }, []);
  const data = [
    {
      img: '/500x/blogs/img-1.webp',
      date: '12 May 2023',
      heading: 'How Can New Brokers Contact Well-Known Builders?',
      desc: 'The real estate market is a deep pool based on trust and sources. Everyone is attracted to this ...',
    },
    {
      img: '/assets/informative/blogs/property-sales.webp',
      date: '1 May 2023',
      heading: 'Looking To Skyrocket Your Property Sales?',
      desc: "Who doesn't want to earn more? Who doesn't want to invest more? Who doesn't want ...",
    },
    {
      img: '/assets/informative/blogs/earning-higher.webp',
      date: '25 April 2023',
      heading: 'Learn The Secret To Earning Higher Brokerage With NestoHub',
      desc: 'NestoHub is an all-around platform for all builders and brokers to connect and professionalize ...',
    },
  ];
  return (
    <>
      <div class="news">
        <Heading title="Blogs & News" sub="Latest Blogs and News" />
        <Fade up duration={1000} distance="100px" delay={100}>
          <div className="container news-container">
            <div className="row align-items-stretch">
              {blogs.slice(0, 3).map((item, index) => {
                return (
                  <div className="col-lg-4 col-md-6 text-left" key={index}>
                    <div className="news-box" style={{ minHeight: '100%' }}>
                      <Link to={`/blog-detail/${item.slug}`}>
                        <img src={item.thumbnailImage} className="news-image" alt="news" />
                      </Link>
                      <div className="news-box-2">
                        <div className="news-date align-items-end">
                          <span>
                            <CiCalendar className="date-icon" />
                          </span>
                          <span className="news-date-span"> {item.blogDate}</span>
                        </div>
                        <div className="">
                          <Link
                            to={`/blog-detail/${item.slug}`}
                            className="news-heading text-line-1"
                          >
                            {item.blogName}
                          </Link>
                        </div>
                        <div>
                          <p className="news-desc text-line-2" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                        </div>
                        <Link to={`/blog-detail/${item.slug}`} className="primary_button">
                          Read More
                          <MdKeyboardDoubleArrowRight
                            size={20}
                            className="move-arrow"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* <div className="text-center" data-aos="fade-up">
              <Link to="/blog-list" className="primary_button">
                Explore More
                <MdKeyboardDoubleArrowRight size={20} className="move-arrow" />
              </Link>
            </div> */}
          </div>
        </Fade>
      </div>
    </>
  );
}
