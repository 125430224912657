import { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { CustomModal } from "../../../../../components";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { enqueueSnackbar } from "notistack";
import {
  getAllCustomers,
  shareBrochure,
  shareYourIntent,
} from "../../../../../API/action";
import { useDispatch } from "react-redux";
import { Autocomplete, TextField } from "@mui/material";
import DashboardTabs from "../../../../../components/dashboard-tabs/DashboardTabs";

const ShareBrochureModal = ({
  show,
  onHide,
  propertyType,
  proprtyName,
  location,
  brochureUrl,
  id,
}) => {
  const brokerID = localStorage.getItem("brokerID");
  const [loading, setLoading] = useState(false);
  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [activeTab, setActiveTab] = useState("Old Customer");
  const [allCustomers, setAllCustomers] = useState([]);

  const dispatch = useDispatch();
  const phoneNumberRegex = /^[0-9]{12}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    dispatch(
      getAllCustomers(
        brokerID,
        (data) => {
          setAllCustomers(data.data.data);
        },
        (error) => {
          // Handle error
          console.error("Error fetching all contact queries:", error);
        }
      )
    );
  }, []);
  const handlePhoneChange = (value, country, e, formattedValue) => {
    setPhoneNumber(value);
  };

  const handleAutocompleteChange = (event, newValue) => {
    if (newValue) {
      const selectedClient = allCustomers.find(
        (item) => item?._id === newValue._id
      );
      setClientName(selectedClient?.clientName || "");
      setPhoneNumber(selectedClient?.phoneNumber || "");
      setEmail(selectedClient?.email || "");
    } else {
      // Handle if no option is selected
    }
  };

  const handleSubmit = () => {
    if (!email || !phoneNumber) {
      enqueueSnackbar("Please add all the required values", {
        variant: "warning",
      });
    } else if (!phoneNumberRegex.test(phoneNumber)) {
      enqueueSnackbar("Please enter a valid number", { variant: "warning" });
    } else if (!emailRegex.test(email)) {
      enqueueSnackbar("Please enter a valid email", { variant: "warning" });
    } else {
      setLoading(true);
      let payload = {
        name: clientName,
        email: email,
        phoneNumber: phoneNumber.replace(/\D/g, "").slice(-10),
        propertyId: id,
        brokerId: localStorage.getItem("brokerID"),
      };
      dispatch(
        shareBrochure(
          payload,
          (data) => {
            // window.open(brochureUrl, "_blank");
            onHide();
            setLoading(false);
            enqueueSnackbar("Brochure has been shared successfully", {
              variant: "success",
            });
            setClientName("");
            setEmail("");
            setPhoneNumber("");
          },
          (err) => {
            const message = err?.response?.data
              ? err?.response?.data
              : "Error occured While share intent";
            enqueueSnackbar(message, { variant: "error" });
            setLoading(false);
          }
        )
      );
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <CustomModal
        show={show}
        title="Share"
        border
        closeBtn
        onHide={onHide}
        cssClassName="nes__dashboard__modal"
        size="md"
        modalHeader
      >
        <DashboardTabs
          tabsKey={["Old Customer", "New Customer"]}
          activeState={activeTab}
          onTabClick={handleTabChange}
        >
          <Form
            className="profile__form p-3 custom__modal__form"
            onSubmit={(e) => e.preventDefault()}
          >
            {activeTab === "Old Customer" ? (
              <>
                <Form.Group
                  className="flex-grow-1 mb-4"
                  controlId="companyName"
                >
                  <Form.Label>
                    Client Name<span className="text-dark">*</span>
                  </Form.Label>
                  <Autocomplete
                    options={allCustomers}
                    getOptionLabel={(option) => option.clientName}
                    onChange={handleAutocompleteChange}
                    sx={{
                      "& .MuiAutocomplete-tag": {
                        borderRadius: 0,
                      },
                      "& .MuiOutlinedInput-root": {
                        border: "none !important",
                        height: "3rem",

                        "&:hover": {
                          borderColor: "grey",
                        },
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{ bgcolor: "#F8F8F8" }}
                        {...params}
                        label=""
                      />
                    )}
                  />
                </Form.Group>

                <Form.Group
                  className="flex-grow-1 mb-4"
                  controlId="phoneNumber"
                >
                  <Form.Label>
                    Phone Number<span className="text-dark">*</span>
                  </Form.Label>
                  {/* <PhoneInput
            value={phoneNumber}
            onChange={handlePhoneChange}
            country={"in"}
            inputStyle={{
              width: "100%",
              height: "40px",
              backgroundColor: "#F8F8F8",
              border: "none",
            }}
            buttonStyle={{ border: "none" }}
          /> */}
                  <InputGroup className="mb-3">
                    <InputGroup.Text className="rounded-0 border-0">
                      +91
                    </InputGroup.Text>
                    <Form.Control
                      className="rounded-0 border-0"
                      type="number"
                      name="phoneNumber"
                      onChange={(e) =>
                        setPhoneNumber(e.target.value.substring(0, 10))
                      }
                      value={phoneNumber}
                      disabled
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-4" controlId="email">
                  <Form.Label>
                    Email<span className="text-dark">*</span>
                  </Form.Label>
                  <Form.Control
                    className="rounded-0 border-0"
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    disabled
                  />
                </Form.Group>
              </>
            ) : (
              <>
                <Form.Group
                  className="flex-grow-1 mb-4"
                  controlId="companyName"
                >
                  <Form.Label>
                    Client Name<span className="text-dark">*</span>
                  </Form.Label>
                  <Form.Control
                    className="rounded-0 border-0"
                    type="text"
                    placeholder="Enter client name"
                    name="clientName"
                    onChange={(e) => setClientName(e.target.value)}
                    value={clientName}
                  />
                </Form.Group>

                <Form.Group
                  className="flex-grow-1 mb-4"
                  controlId="phoneNumber"
                >
                  <Form.Label>
                    Phone Number<span className="text-dark">*</span>
                  </Form.Label>
                  {/* <PhoneInput
              value={phoneNumber}
              onChange={handlePhoneChange}
              country={"in"}
              inputStyle={{
                width: "100%",
                height: "40px",
                backgroundColor: "#F8F8F8",
                border: "none",
              }}
              buttonStyle={{ border: "none" }}
            /> */}
                  <InputGroup className="mb-3">
                    <InputGroup.Text className="rounded-0 border-0">
                      +91
                    </InputGroup.Text>
                    <Form.Control
                      className="rounded-0 border-0"
                      type="number"
                      name="phoneNumber"
                      onChange={(e) =>
                        setPhoneNumber(e.target.value.substring(0, 10))
                      }
                      value={phoneNumber}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-4" controlId="email">
                  <Form.Label>
                    Email<span className="text-dark">*</span>
                  </Form.Label>
                  <Form.Control
                    className="rounded-0 border-0"
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </Form.Group>
              </>
            )}
            <div className="d-flex gap-4 mt-5">
              <Button
                onClick={handleSubmit}
                type="submit"
                className="btn-color-primary rounded-pill btn-rounded w-100"
              >
                Share Brochure
              </Button>
            </div>
          </Form>
        </DashboardTabs>
      </CustomModal>
    </>
  );
};

export default ShareBrochureModal;
