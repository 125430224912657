import React, { useEffect, useRef } from 'react';

export default function IconVideos({ src }) {
  const videoRef = useRef(null);
  useEffect(() => {
    const video = videoRef.current;

    // Event listener to restart the video when it ends
    const handleVideoEnd = () => {
      video.currentTime = 0;
      video.play();
    };

    video.addEventListener('ended', handleVideoEnd);

    return () => {
      video.removeEventListener('ended', handleVideoEnd);
    };
  }, []);
  return (
    <video
      ref={videoRef}
      className="video how-work_img"
      autoPlay
      loop
      muted
      src={src}
    ></video>
  );
}
