import React, { useState } from 'react';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import flatsIcon from '../../assets/images/flats.svg';
import farmHouseIcon from '../../assets/images/farmhouse.svg';
import plotsIcon from '../../assets/images/plots.svg';
import commercialIcon from '../../assets/images/commercial.svg';
import './SmallCardSection.css';
import { useGetAllPropertyCategory } from '../../../../hooks/LoginQuery';
import LoadingSpinner from '../../../../Common/loading-spinner/LoadingSpinner';
import ErrorMessage from '../../../../Common/error-message/ErrorMessage';
import { useNavigate } from 'react-router-dom';

function SmallCardSection({selected, setSelected, selectedProp}) {
  
  const navigate = useNavigate();

  const {
    getAllPropertyCategoryIsLoading,
    getAllPropertyCategoryIsError,
    getAllPropertyCategoryResponse,
    getAllPropertyCategoryError,
    getAllPropertyCategoryIsSuccess,
  } = useGetAllPropertyCategory();
  if (getAllPropertyCategoryIsLoading) {
    return <LoadingSpinner />;
  }

  if (getAllPropertyCategoryIsError) {
    return <ErrorMessage />;
  }

  return (
    <section className='nes__about mt-5'>
      <Container>
        <Row className='justify-content-center'>
          {getAllPropertyCategoryIsSuccess &&
            getAllPropertyCategoryResponse.map(({_id, name, iconUrl }, index) => (
              <Col xs={12} md={6} lg={3} sm={6} className='mb-4' key={index}>
                <Card 
                className={`border-0
                 ${selected === _id ? 'selected-card' : 'card'
                }`}
                // onClick={() => handleCardClick(_id)}
                >
                  <Card.Body>
                    <div className='d-flex align-items-center justify-content-center gap-2'>
                      <div className='rounded-circle d-flex'>
                        <Image src={iconUrl} fluid className='m-auto' />
                      </div>
                      <h5 className='ml-3 mb-0 hFont'>{name}</h5>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
      </Container>
    </section>
  );
}

export default SmallCardSection;
