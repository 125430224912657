import React, { useEffect, useState } from 'react';
import {
  Box,
  Modal,
  IconButton,
  Typography,
  Stack,
  FormControl,
  TextField,
} from '@mui/material';
import { Clear } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
import { CustomBtn } from '../../../../broker/components/mui/CustomBtn';

const LocationPop = (props) => {
  const [selectedLocations, setSelectedLocations] = useState([]);

  useEffect(() => {
    setSelectedLocations(props.locationData2);
  }, [props]);

  console.log('selected locations are', selectedLocations);
  console.log('hi', props.locationData2);
  const modalContentStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: '1rem',
    borderRadius: '20px',
  };

  const onSubmitHandler = () => {
    props.onLocationSubmit(selectedLocations);
    props.onHide();
  };

  const onModalClose = () => {
    props.onHide();
  };

  return (
    <Modal
      open={props.show}
      onClose={() => {
        props.onHide(false);
      }}
    >
      <Box
        style={modalContentStyle}
        sx={{
          width: { md: '40%', xs: '80%' },
          Height: { md: '50%', xs: '100%' },
        }}
      >
        <IconButton
          onClick={onModalClose}
          sx={{
            marginLeft: '95%',
            top: '0.1rem',
            color: 'white',
            background: 'black',
            height: '2rem',
            width: '2rem',
            fontWeight: '10px',
            '&:hover': {
              background: 'black',
            },
          }}
        >
          <Clear />
        </IconButton>
        <Typography
          textAlign={'center'}
          variant='h4'
          fontWeight={'bold'}
        >
          Add Location Advantage
        </Typography>
        <Stack pt={6} px={4}>
          <FormControl variant='standard'>
            <Typography mb={3} variant='h6'>
              Add Locations
            </Typography>
            <Autocomplete
              multiple
              limitTags={2}
              id='locations-autocomplete'
              options={props.locationData}
              getOptionLabel={(option) => option.name}
              value={selectedLocations}
              onChange={(event, newValue) => {
                const uniqueLocation = newValue.filter(
                  (location, index, self) =>
                    index ===
                    self.findIndex(
                      (loca) =>
                        loca.name === location.name &&
                        loca.distance === location.distance
                    )
                );
                setSelectedLocations(uniqueLocation);
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder='Add more' />
              )}
              sx={{
                width: '100%',
                '& .MuiAutocomplete-tag': {
                  borderRadius: 0,
                },
              }}
            />
          </FormControl>
        </Stack>
        <Box
          mt={5}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CustomBtn
            variant='contained'
            color='primary'
            onClick={onSubmitHandler}
            width='60vh'
          >
            Submit
          </CustomBtn>
        </Box>
      </Box>
    </Modal>
  );
};

export default LocationPop;
