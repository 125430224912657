import { Box } from '@mui/material';
import React from 'react';

import { Footer } from '../components';
import { Header } from '../broker/features';
import { useAuth } from '../broker/services/api';

export default function AdminLayout({ children }) {
  useAuth();

  return (
    <>
      <Header />
      <Box component={'main'}>{children}</Box>
      <Footer />
    </>
  );
}
