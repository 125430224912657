import { Card, Col, Container, Image, Row } from "react-bootstrap";
// import HeroSection from '../hero-section/HeroSection';
// import SmallCardSection from '../small-card-section/SmallCardSection';
import productImg from "../../assets/images/product-img.png";
// import { ProductContainers } from '../../../../dashboard-containers';
// import SwiperSliderDashboard from '../swiper-slider-dashboard/SwiperSliderDashboard';
import { SearchFilter } from "../../../../../components";
import flatsIcon from "../../assets/images/flats.svg";
import farmHouseIcon from "../../assets/images/farmhouse.svg";
import plotsIcon from "../../assets/images/plots.svg";
import commercialIcon from "../../assets/images/commercial.svg";

import "./PromotedProperty.css";
// import Header from '../../../../Common/Header/Header';
import { Header, ProductCard } from "../../../../features";
import { useAuth } from "../../../../services/api";
import { useGetAllPropertyQuery } from "../../../../hooks/LoginQuery";
import SearchFilterBox from "../../../../../builder/dashboard/search-filter/SearchFilter";
import HeaderCategory from "../properties/HeaderCategory";
import { useEffect, useState } from "react";
const PromotedProperty = () => {
  useAuth();
  const {
    getAllPropertyIsLoading,
    getAllPropertyIsError,
    getAllPropertyResponse,
    getAllPropertyError,
    getAllpropertyIsSuccess,
  } = useGetAllPropertyQuery();

  const [promotedProperties, setPromotedProperties] = useState([]);
  useEffect(() => {
    if (getAllpropertyIsSuccess) {
      setPromotedProperties(
        getAllPropertyResponse.filter((property) => property.isPromoted)
      );
    }
  }, [getAllpropertyIsSuccess]);

  return (
    <>
      <Header />
      <Container fluid="lg">
        {/* <HeroSection />
      <SmallCardSection />
      <section className="mt-3">
        <SearchFilter addBtn />
      </section>
      <ProductContainers
        sectionTitle="Promoted Property"
        productData={productData}
        view="promoted"
      /> */}

        {/* #########Smart card section start########### */}
        <HeaderCategory />
        {/* #########Smart card section end########### */}

        <section className="mt-3">
          <SearchFilter addBtn={false} />
        </section>

        {/* ########promoted property start############# */}

        <section className="nes__product__section mt-5">
          <Row className="gy-3">
            <Col
              sm={12}
              className="d-flex justify-content-between align-items-center mb-3"
            >
              <h2 className="nes__product__section__title">
                Promoted Property
              </h2>
              {/* <div className='form-check form-switch'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  role='switch'
                  id='flexSwitchCheckDefault'
                />
              </div> */}
            </Col>

            {promotedProperties.map((property) => (
              <Col
                key={property?._id}
                xs={12}
                sm={12}
                md={4}
                className="mb-4 gap-4"
              >
                <ProductCard {...property} pagination  view={"broker"} isOpen/>
              </Col>
            ))}
          </Row>
        </section>

        {/* ########promoted property end############# */}
      </Container>
    </>
  );
};

export default PromotedProperty;
