import React from 'react';
import { Container } from 'react-bootstrap';
import { GrDocumentPdf } from 'react-icons/gr';
import Accordion from 'react-bootstrap/Accordion';
import { useEffect, useState } from 'react';
import { apiEndpoints } from '../../../Api/ApiEndpoint';
import { getAPI } from '../../../Api/ApiRequest';
import CustomAccordion from './CustomAccordion';
import { Box, CircularProgress, Typography } from '@mui/material';

const SupportMenu = () => {
  const [gdata, setData] = useState([]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getSupport = async () => {
      setLoading(true);
      const response = await getAPI(apiEndpoints.getAllFaqAndSupport);
      console.log('hloo i shrer', response);
      setData(response.data);
      setLoading(false);
    };
    getSupport();
  }, []);

  return (
    <>
      {loading ? (
        <Box textAlign={'center'}>
          <CircularProgress></CircularProgress>
        </Box>
      ) : gdata?.length > 0 ? (
        <CustomAccordion data={gdata} />
      ) : (
        <Box textAlign={'center'}>
          <Typography
            mt={6}
            variant='p'
            component={'h5'}
            sx={{ color: 'var(--primary-color)' }}
          >
            No data available......
          </Typography>
        </Box>
      )}
    </>
  );
};

export default SupportMenu;
