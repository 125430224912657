import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  InputGroup,
  Figure,
} from "react-bootstrap";
import { useRegisterMutation } from "../../../hooks/LoginQuery";
import "./Register.css";
import { enqueueSnackbar } from "notistack";
import { Checkbox, FormControlLabel } from "@mui/material";

const RegisterForm = () => {
  // const { phoneNumber } = useSelector(state => state.login);
  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(
    localStorage.getItem("phoneNumber")
  );
  const [referCode, setReferCode] = useState("");
  const [isAgreed, setIsAgreed] = useState(false);

  const {
    register,
    registerResponse,
    isLoadingRegister,
    isRegisterSuccess,
    isRegisterIsError,
    isRegisterError,
  } = useRegisterMutation();

  // const handleInputChange = event => {
  //   const username = event.target.value;
  //   if (username) {
  //     setUsername(username);
  //   }
  // };

  const handleUsernameChange = (event) => {
    const newUsername = event.target.value;
    setUsername(newUsername);
  };

  const handlePhoneNumberChange = (event) => {
    const newPhoneNumber = event.target.value;
    setPhoneNumber(newPhoneNumber);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
      register({ name: username, phoneNumber: phoneNumber, referalCode: referCode });
    // if (!isRegisterSuccess) {
    //   enqueueSnackbar("Error Occured! Please try after sometime", {
    //     variant: "error",
    //   });
    // }
  };

  return (
    <>
      <Container fluid="lg" className="container-md my-5 login-shadow">
        <Row className="g-0 login__form">
          <Col md={6} className="d-none d-md-block">
            <Image
              className="h-100 nes__dsa__login__img"
              src="/assets/dsa/login-page.jpg"
              fluid
              alt="login img"
            />
          </Col>
          <Col
            md={6}
            className="px-4 nes__dsa__login__formwrapper d-flex flex-column justify-content-center"
          >
            <div className="mt-4">
              <h2 className="fw-bold text-center mb-3">
                Hello! Register to get started
              </h2>
            </div>
            <Form onSubmit={handleSubmit}>
              <InputGroup className="mb-0 mt-3">
                <Form.Control
                  name="username"
                  className="rounded-2"
                  required
                  type="text"
                  value={username}
                  maxLength="20"
                  placeholder="Name"
                  onChange={handleUsernameChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid name
                </Form.Control.Feedback>
                <Form.Control.Feedback type="valid">
                  Valid Name!
                </Form.Control.Feedback>
              </InputGroup>

              <InputGroup className="mb-0 mt-3">
                <Form.Control
                  name="phoneNumber"
                  className="rounded-2"
                  required
                  type="text"
                  value={phoneNumber}
                  maxLength="10"
                  placeholder="Enter your phone number"
                  onChange={handlePhoneNumberChange}
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid phone number.
                </Form.Control.Feedback>
                <Form.Control.Feedback type="valid">
                  Valid Phone Number!
                </Form.Control.Feedback>
              </InputGroup>

              <InputGroup className="mb-0 mt-3">
                <Form.Control
                  name="referCode"
                  className="rounded-2"
                  required
                  type="text"
                  value={referCode}
                  maxLength="20"
                  placeholder="Referal code"
                  onChange={(e) => setReferCode(e.target.value)}
                />
              </InputGroup>

              <FormControlLabel
                checked={isAgreed}
                onChange={(e) => setIsAgreed(e.target.checked)}
                control={<Checkbox />}
                label="I agree to the Terms of Service and Privacy Policy"
              />

              <div className="d-grid mt-3">
                <Button
                  disabled={!isAgreed || isLoadingRegister}
                  type="submit"
                  variant="primary"
                  size="md"
                  className="rounded-pill border-0 bg-color-primary"
                >
                  {isLoadingRegister ? `loading` : "Continue"}
                </Button>
              </div>
            </Form>
            <div className="d-flex align-items-center my-4">
              <div className="divider flex-grow-1"></div>
              <p className="m-0 px-1">Or Login</p>
              <div className="divider flex-grow-1"></div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="d-grid mb-5">
                <Button
                  variant="primary"
                  size="md"
                  className="rounded-pill border-0 bg-color-primary py-2 wd-200 px-5 btn-shadow"
                >
                  <Image
                    src="/assets/profile.svg"
                    fluid
                    className="rounded-circle mx-2 user-img"
                    alt="builder"
                  />
                  Builder
                </Button>
              </div>
              <div className="d-grid mb-5">
                <Button
                  variant="primary"
                  size="md"
                  className="rounded-pill border-0 bg-color-primary py-2 wd-200 px-5 btn-shadow"
                >
                  <Image
                    src="/assets/profile.svg"
                    fluid
                    className="rounded-circle mx-2 user-img"
                    alt="broker"
                  />
                  Broker
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RegisterForm;
