import CreatableSelect from "react-select/creatable";
import { Button, Form } from "react-bootstrap";
import { CustomModal } from "../../../../../components";
import { Autocomplete, CircularProgress, Grid, TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addRequirement, getLocationByInput } from "../../../../../API/action";
import { useGetAllRequirement } from "../../../../hooks/LoginQuery";
import { useParams } from "react-router-dom";

const options = [
  { value: "2 BHK", label: "2 BHK" },
  { value: "3 BHK", label: "3 BHK" },
];

const AddRequirementModal = ({ show, onHide, fromRequirement }) => {
  const [name, setName] = useState("");
  const [clientId, setClientId] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [unitType, setUnitType] = useState([]);
  const [preferredLocation, setPreferredLocation] = useState([]);
  const [locationSearch, setLocationSearch] = useState([]);
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [loading, setLoading] = useState(false);
  const unitTypes = [
    { value: "1 RK", label: "1 RK" },
    { value: "2 BHK", label: "2 BHK" },
    { value: "3 BHK", label: "3 BHK" },
    { value: "4 BHK", label: "4 BHK" },
    { value: "5 BHK", label: "5 BHK" },
    { value: "5+ BHK", label: "5+ BHK" },
    { value: "Low Rise", label: "Low Rise" },
    { value: "High Rise", label: "High Rise" },
    { value: "Farmhouse Land", label: "Farmhouse Land" },
    { value: "SCO Plot", label: "SCO Plot" },
    { value: "Commercial Land", label: "Commercial Land" },
    { value: "Residential Plot", label: "Residential Plot" },
    { value: "Villa", label: "Villa" },
    { value: "Farmhouse", label: "Farmhouse" },
    { value: "Duplex House", label: "Duplex House" },
    { value: "Builder Floor", label: "Builder Floor" },
    { value: "Commercial Space", label: "Commercial Space" },
    { value: "SCO", label: "SCO" },
    { value: "Warehouse", label: "Warehouse" },
  ];

  const dispatch = useDispatch();
  const phoneNumberRegex = /^[0-9]{10}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleSaveChanges = () => {
    if (!name || !email || !number || unitType?.length == 0 || !min || !max) {
      enqueueSnackbar("Please add all the required values", {
        variant: "warning",
      });
    } else if (!phoneNumberRegex.test(number)) {
      enqueueSnackbar("Please enter a valid number", { variant: "warning" });
    } else if (!emailRegex.test(email)) {
      enqueueSnackbar("Please enter a valid email", { variant: "warning" });
    } else {
      setLoading(true);
      let payload = {
        brokerId: localStorage.getItem("brokerID"),
        customerId: clientId,
        unitType: unitType.map((item) => item.value),
        preferredLocation: preferredLocation.map((item) => item.value),
        minPrice: min,
        maxPrice: max,
      };

      dispatch(
        addRequirement(
          payload,
          (data) => {
            enqueueSnackbar("Requirement has been created successfully", {
              variant: "success",
            });
            setLoading(false);
          },
          (err) => {
            const message = err?.response?.data
              ? err?.response?.data
              : "Error occured While creating Requirement";
            enqueueSnackbar(message, { variant: "error" });
            setLoading(false);
          }
        )
      );
    }
  };

  const { id } = useParams();
  const { getAllRequirementResponse } = useGetAllRequirement(
    localStorage.getItem("brokerID"),
    id
  );

  useEffect(() => {
    if (getAllRequirementResponse) {
      setName(getAllRequirementResponse[0]?.customerId?.clientName);
      setClientId(getAllRequirementResponse[0]?.customerId?._id);
      setEmail(getAllRequirementResponse[0]?.customerId?.email);
      setNumber(getAllRequirementResponse[0]?.customerId?.phoneNumber);
    }
  }, [getAllRequirementResponse]);

  const handleSetFormValues = (name, value) => {
    if (name === "location") {
      getLocationByInput({ searchText: value })
        .then((res) => {
          if (res.data.data.length > 0) {
            const locations = res.data.data.map((item) => item.location);
            setLocationSearch(locations);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setPreferredLocation((prevFormValues) => ({
        ...prevFormValues,
        [name]: value,
      }));
    }
  };

  return (
    <CustomModal
      show={show}
      title="Add New Requirement"
      modalHeader
      border
      closeBtn
      onHide={onHide}
      cssClassName="nes__dashboard__modal"
      // size="lg"
    >
      <Form className="profile__form ps-2 py-3 custom__modal__form">
        <Form.Group className="mb-4" controlId="companyName">
          <Form.Label>
            Client Name<span className="text-dark">*</span>
          </Form.Label>
          <Form.Control
            className="rounded-0 border-0"
            type="text"
            placeholder="Enter client name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled={fromRequirement}
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>
            Client Id<span className="text-dark">*</span>
          </Form.Label>
          <Form.Control
            className="rounded-0 border-0"
            type="text"
            placeholder="Enter client Id"
            value={clientId}
            onChange={(e) => setClientId(e.target.value)}
            disabled={fromRequirement}
          />
        </Form.Group>

        <Form.Group className="mb-4" controlId="email">
          <Form.Label>
            Email<span className="text-dark">*</span>
          </Form.Label>
          <Form.Control
            className="rounded-0 border-0"
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={fromRequirement}
          />
        </Form.Group>

        <Form.Group className="mb-4" controlId="phoneNumber">
          <Form.Label>
            Phone Number<span className="text-dark">*</span>
          </Form.Label>
          <Form.Control
            className="rounded-0 border-0"
            type="number"
            placeholder="Enter phone number"
            value={number}
            maxLength={10}
            onChange={(e) => setNumber(e.target.value.substring(0, 10))}
            disabled={fromRequirement}
          />
        </Form.Group>

        <Form.Group className="mb-4">
          <Form.Label>
            Unit Type<span className="text-dark">*</span>
          </Form.Label>
          <CreatableSelect
            isMulti
            options={unitTypes}
            onChange={(values) => setUnitType(values)}
            placeholder="Add more"
          />
        </Form.Group>

        <Form.Group className="mb-4">
          <Form.Label>
            Preferred Location<span className="text-dark">*</span>
          </Form.Label>
          {/* <CreatableSelect isMulti placeholder='Add more' onChange={(values) => setPreferredLocation(values)} /> */}

          <Autocomplete
            onChange={(event, value1) => {
              setPreferredLocation(value1);
            }}
            multiple
            disablePortal
            value={preferredLocation}
            name="location"
            id="combo-box-demo"
            options={locationSearch}
            sx={{
              "& .MuiAutocomplete-tag": {
                borderRadius: 0,
              },
              "& .MuiOutlinedInput-root": {
                border: "none !important",
                height: "3rem",

                "&:hover": {
                  borderColor: "grey",
                },
              },
            }}
            renderInput={(params) => (
              <TextField
                className="inputBox"
                {...params}
                name="location"
                value={preferredLocation}
                onChange={(e) =>
                  handleSetFormValues(e.target.name, e.target.value)
                }
                sx={{ bgcolor: "#F8F8F8" }}
              />
            )}
          />
        </Form.Group>
        <Grid container spacing={2}>
          <Grid item md={6} xs={6} sm={6}>
            <Form.Group className="mb-4" controlId="companyName">
              <Form.Label>
                Min Budget<span className="text-dark">*</span>
              </Form.Label>
              <Form.Control
                className="rounded-0 border-0"
                type="number"
                placeholder="enter value"
                value={min}
                onChange={(e) => setMin(e.target.value)}
              />
            </Form.Group>
          </Grid>
          <Grid item md={6} sm={6} xs={6}>
            <Form.Group className="mb-4" controlId="companyName">
              <Form.Label>
                Max Budget<span className="text-dark">*</span>
              </Form.Label>
              <Form.Control
                className="rounded-0 border-0"
                type="number"
                placeholder="enter value"
                value={max}
                onChange={(e) => setMax(e.target.value)}
              />
            </Form.Group>
          </Grid>
        </Grid>
        {/* <Box display='flex' flexDirection='row' justifyContent='space-between'>
          

          
        </Box> */}

        <div className="d-flex gap-4 mt-2">
          <Button
            className="btn-color-primary rounded-pill btn-rounded w-100"
            onClick={handleSaveChanges}
            disabled={loading}
          >
            {loading ? <CircularProgress /> : "Add"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

export default AddRequirementModal;
