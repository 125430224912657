// import { useState } from 'react';
// import { Button, Container, Form, Modal, Stack } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { apiEndpoints } from '../../../../Api/ApiEndpoint';
// import { postAPI } from '../../../../Api/ApiRequest';
// import { CustomBtn } from '../../../../../broker/components/mui/CustomBtn';
// import { Box, FormControl, Typography } from '@mui/material';
// import { FormInputField } from '../../../../../components/common/FormInputField';

// const Payment = (props) => {
//   const navigate = useNavigate();
//   const [bankName, setBankName] = useState('');
//   const [accountNumber, setAccountNumber] = useState('');
//   const [ifscCode, setIfscCode] = useState('');
//   const [recipientName, setRecipientName] = useState('');
//   const [transactionDate, setTransactionDate] = useState('');
//   const [transactionId, setTransactionId] = useState('');
//   const [transactionAmount, setTransactionAmount] = useState('');

//   const [moreOption, setMoreOption] = useState(false);
//   const update = () => {
//     setMoreOption(true);
//   };
//   const submitHandler = async () => {
//     const formData = {
//       bankName,
//       accountNumber,
//       ifscCode,
//       recipientName,
//       transactionDate,
//       transactionId,
//       transactionAmount,
//     };
//     console.log(formData);
//     const response = await postAPI(
//       apiEndpoints.addPayment,

//       formData
//     );

//     console.log(response);

//     navigate('/builder/home-dashboard/promoted/claim');
//   };

//   return (
//     <>
//       <Modal
//         // size="sm"
//         show={props.show}
//         onHide={() => {
//           props.onHide();
//         }}
//       >
//         <br />
//         <Container className=' dashboard__wrapper'>
//           <Typography textAlign={'center'} variant='h6' fontWeight={'bold'}>
//             Payment Using Below Details
//           </Typography>

//           <Form.Group className='mb-3'>
//             <Typography mt={3} mb={1}>
//               Pay to Account
//             </Typography>

//             <Typography mt={3} mb={1}>
//               Bank Name
//             </Typography>

//             <FormInputField
//               width={1}
//               label='Bank Name'
//               required={true}
//               value={bankName}
//               onChange={(e) => setBankName(e.target.value)}
//             />
//             <Typography mt={3} mb={1}>
//               Account No.
//             </Typography>

//             <FormInputField
//               width={1}
//               label='Account No.'
//               required={true}
//               value={accountNumber}
//               onChange={(e) => setAccountNumber(e.target.value)}
//             />
//             <Typography mt={3} mb={1}>
//               IFSC Code
//             </Typography>

//             <FormInputField
//               width={1}
//               label='IFSC Code'
//               required={true}
//               value={ifscCode}
//               onChange={(e) => setIfscCode(e.target.value)}
//             />
//             <Typography mt={3} mb={1}>
//               Recipient Name
//             </Typography>

//             <FormInputField
//               width={1}
//               label='Recipient Name'
//               required={true}
//               value={recipientName}
//               onChange={(e) => setRecipientName(e.target.value)}
//             />

//             {moreOption && (
//               <>
//                 <Form.Label>
//                   <h5>Transaction Date*</h5>
//                 </Form.Label>
//                 <Form.Control
//                   className='rounded-0'
//                   type='text'
//                   placeholder='Lorem Ipsum'
//                   value={transactionDate}
//                   onChange={(e) => {
//                     console.log(e.target.value);
//                     setTransactionDate(e.target.value);
//                   }}
//                 />
//                 <br />

//                 <Form.Label>
//                   <h5>Transaction ID*</h5>
//                 </Form.Label>
//                 <Form.Control
//                   className='rounded-0'
//                   type='text'
//                   placeholder='Lorem Ipsum'
//                   value={transactionId}
//                   onChange={(e) => {
//                     console.log(e.target.value);
//                     setTransactionId(e.target.value);
//                   }}
//                 />
//                 <br />
//                 <Form.Label>
//                   <h5>Transaction Amount*</h5>
//                 </Form.Label>
//                 <Form.Control
//                   className='rounded-0'
//                   type='text'
//                   placeholder='Lorem Ipsum'
//                   value={transactionAmount}
//                   onChange={(e) => {
//                     console.log(e.target.value);
//                     setTransactionAmount(e.target.value);
//                   }}
//                 />
//                 <br />
//               </>
//             )}
//             <div className='d-flex justify-content-center align-items-center my-4'>
//               {!moreOption && (
//                 <Box
//                   sx={{
//                     width: '100%',
//                     display: 'flex',
//                     justifyContent: 'center',
//                   }}
//                 >
//                   <CustomBtn
//                     variant='contained'
//                     className=' rounded-pill bg-color-primary'
//                     onClick={submitHandler}
//                     width={'80%'}
//                     // onClick={(submitHandler) => {
//                     //   navigate("/builder/home-dashboard/promoted/claim");
//                     // }}
//                   >
//                     Update Payment Status
//                   </CustomBtn>
//                 </Box>
//               )}
//               {moreOption && (
//                 <Box
//                   sx={{
//                     width: '100%',
//                     display: 'flex',
//                     justifyContent: 'center',
//                   }}
//                 >
//                   <CustomBtn
//                     variant='contained'
//                     className=' rounded-pill bg-color-primary'
//                     onClick={submitHandler}
//                     width={'80%'}
//                     // onClick={(submitHandler) => {
//                     //   navigate("/builder/home-dashboard/promoted/claim");
//                     // }}
//                   >
//                     Update
//                   </CustomBtn>
//                 </Box>
//               )}
//             </div>
//           </Form.Group>
//         </Container>
//       </Modal>
//     </>
//   );
// };
// export default Payment;

import { useState } from 'react';
import { Button, Container, Form, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { apiEndpoints } from '../../../../Api/ApiEndpoint';
import { postAPI } from '../../../../Api/ApiRequest';
import { FormInputField } from '../../../../../components/common/FormInputField';
import { Typography } from '@mui/material';

const Payment = (props) => {
  const navigate = useNavigate();
  const [bankName, setBankName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [recipientName, setRecipientName] = useState('');
  const [transactionDate, setTransactionDate] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [transactionAmount, setTransactionAmount] = useState('');

  const [moreOption, setMoreOption] = useState(false);
  const update = () => {
    setMoreOption(true);
  };
  const submitHandler = async () => {
    const formData = {
      bankName,
      accountNumber,
      ifscCode,
      recipientName,
      transactionDate,
      transactionId,
      transactionAmount,
    };
    console.log(formData);
    const response = await postAPI(
      apiEndpoints.addPayment,

      formData
    );

    console.log(response);

     
    props.onHide();
  };

  const handleClose = () => {
    props.onHide();
  };

  return (
    <>
      <Modal show={props.show}>
        <Modal.Header
          className='justify-content-center'
          closeButton
          onClick={handleClose}
        >
          <Modal.Title
            style={{
              textAlign: 'center',
              margin: 'auto',
              paddingLeft: '1.5rem',
            }}
          >
            Payment Using Below Details
          </Modal.Title>
        </Modal.Header>
        <br />
        <Container className=' dashboard__wrapper'>
          <Form.Group className='mb-3'>
            <Typography mt={3} mb={1}>
              Pay to Account
            </Typography>

            <Typography mt={3} mb={1}>
              Bank Name
            </Typography>

            <FormInputField
              width={1}
              label='Bank Name'
              required={true}
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
            />
            <Typography mt={3} mb={1}>
              Account No.
            </Typography>

            <FormInputField
              width={1}
              label='Account No.'
              required={true}
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
            />
            <Typography mt={3} mb={1}>
              IFSC Code
            </Typography>

            <FormInputField
              width={1}
              label='IFSC Code'
              required={true}
              value={ifscCode}
              onChange={(e) => setIfscCode(e.target.value)}
            />
            <Typography mt={3} mb={1}>
              Recipient Name
            </Typography>

            <FormInputField
              width={1}
              label='Recipient Name'
              required={true}
              value={recipientName}
              onChange={(e) => setRecipientName(e.target.value)}
            />
            {moreOption && (
              <>
                <Typography mt={3} mb={1}>
                  Transaction Date*
                </Typography>

                <FormInputField
                  width={1}
                  label=' Transaction Date'
                  required={true}
                  value={transactionDate}
                  onChange={(e) => setTransactionDate(e.target.value)}
                />

                <Typography mt={3} mb={1}>
                  Transaction ID*
                </Typography>

                <FormInputField
                  width={1}
                  label=' Transaction ID'
                  required={true}
                  value={transactionId}
                  onChange={(e) => setTransactionId(e.target.value)}
                />

                <Typography mt={3} mb={1}>
                  Transaction Amount*
                </Typography>

                <FormInputField
                  width={1}
                  label=' Transaction Amount'
                  required={true}
                  value={transactionAmount}
                  onChange={(e) => setTransactionAmount(e.target.value)}
                />
              </>
            )}
            <div className='d-flex justify-content-center align-items-center my-4'>
              {!moreOption && (
                <Button
                  variant='primary'
                  className='w-100 rounded-pill bg-color-primary'
                  onClick={update}
                >
                  Update Payment Status
                </Button>
              )}
              {moreOption && (
                <Button
                  variant='primary'
                  className='w-100 rounded-pill bg-color-primary'
                  onClick={submitHandler}
                  // onClick={(submitHandler) => {
                  //   navigate("/builder/home-dashboard/promoted/claim");
                  // }}
                >
                  Update
                </Button>
              )}
            </div>
          </Form.Group>
        </Container>
      </Modal>
    </>
  );
};
export default Payment;
