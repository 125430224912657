import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Tab, Nav, Button, Image } from "react-bootstrap";
import { FiUser, FiClipboard, FiBookOpen, FiHelpCircle } from "react-icons/fi";
import "./Profile.css";
import { RiArrowRightSLine, RiPencilFill } from "react-icons/ri";
import { BsBoxArrowRight } from "react-icons/bs";
import PersonalDetailsForm from "./personal-details/PersonalDetailsForm";
import TermsConditions from "./terms-and-conditions/TermsConditions";
import RaiseQuery from "./raise-query/RaiseQuery";
import CardWrapper from "./card-wrapper/CardWrapper";
import Support from "./support/Support";
import Preferences from "./preferences/Preferences";
import Notification from "./notification/Notification";
import Refer from "./refer/Refer";
import { useDispatch, useSelector } from "react-redux";
import { setBrokerID } from "../../store/authSlice";
import { useGetBrokerById } from "../../hooks/LoginQuery";
import ErrorMessage from "../../Common/error-message/ErrorMessage";
import LoadingSpinner from "../../Common/loading-spinner/LoadingSpinner";
import { Footer, Header } from "../../features";
import useToken from "../../hooks/useToken";
import {
  getBrokerById,
  updatePersonalInfoForBroker,
  uploadDocToS3,
} from "../../../API/action";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Tutorial from "./raise-query/tutorial/Tutorial";
const tabs = [
  { title: "Personal Details", icon: <FiUser /> },

  { title: "Preferences", icon: <FiClipboard /> },
  // { title: "Notification", icon: <FiClipboard /> },
  { title: "Refer & Earn", icon: <FiClipboard /> },
  { title: "Terms & Conditions", icon: <FiBookOpen /> },
  { title: "Support", icon: <FiHelpCircle /> },
  { title: "Tutorial", icon: <FiHelpCircle /> },
  { title: "Raise Query", icon: <FiHelpCircle /> },
];

const ProfilePage = () => {
  useToken();
  const [profileImage, setProfileImage] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  // const brokerID = useSelector(state => state.auth.brokerID);
  const brokerID = localStorage.getItem("brokerID");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [panNumber, setPanNumber] = useState("");

  const { getBrokerByIdResponse } = useGetBrokerById(brokerID);


  useEffect(() => {
    // Set initial values when getBrokerByIdResponse changes
    if(getBrokerByIdResponse){
      setName(getBrokerByIdResponse[0]?.name || "");
      setId(getBrokerByIdResponse[0]?._id || "");
      setEmail(getBrokerByIdResponse[0]?.email || "");
      setPhoneNumber(getBrokerByIdResponse[0]?.phoneNumber || "");
      setAddress(getBrokerByIdResponse[0]?.address || "");
      setPanNumber(getBrokerByIdResponse[0]?.panNumber || "");
      setProfileImage(getBrokerByIdResponse[0]?.profilePicture || "");
      
    }
  
  }, [getBrokerByIdResponse]);

  const profileSidebarNavContent = [
    {
      element: <PersonalDetailsForm profileImage={profileImage} />,
    },
    {
      element: <Preferences />,
      title: "Preferences",
    },
    // {
    //   element: <Notification notification />,
    //   title: "Notification",
    // },
    {
      element: <Refer />,
      title: "Refer & Earn",
    },
    {
      element: <TermsConditions />,
      title: "Terms & Conditions",
    },
    {
      element: <Support />,
      title: "Support",
    },
    {
      element: <Tutorial />,
      title: "Tutorial",
    },
    {
      element: <RaiseQuery />,
      title: "Your Recent Queries",
    },
  ];

  // function handleImageChange(event) {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const imageUrl = URL.createObjectURL(file);
  //     setProfileImage(imageUrl);
  //   }
  // }

  const AddOrUpdateLocalStorage = (formData) => {
    if (localStorage.getItem("formData")) {
      var obj = JSON.parse(localStorage.getItem("formData"));
      var final = { ...obj, ...formData };
      localStorage.setItem("formData", JSON.stringify(final));
    } else {
      localStorage.setItem("formData", JSON.stringify(formData));
    }
  };


  const handleImageChange = (e) => {
    const file = e.target.files;
  
    if (file && file.length > 0) {
      for (let index = 0; index < file.length; index++) {
        let formData = new FormData();
        formData.append("documents", file[index]);
        dispatch(
          uploadDocToS3(formData, (data) => {
            // Update profileImage with the new image URL
            setProfileImage((oldArr) => {
              if (!Array.isArray(oldArr)) {
                // Initialize oldArr as an empty array if it's not an array
                return [data.data.documents[0]];
              }
              // Spread the existing elements and add the new data
              return [...oldArr, data.data.documents[0]];
            });
  
            // Once profileImage is updated, construct the payload and dispatch updatePersonalInfoForBroker
            setProfileImage((oldArr) => {
              let payload = {
                id: id,
                name: name,
                email: email,
                address: address,
                phoneNumber: phoneNumber,
                panNumber: panNumber,
                profilePicture: data.data.documents[0], // Use updated profileImage URL
              };
  
              dispatch(
                updatePersonalInfoForBroker(
                  payload,
                  (data) => {
                    enqueueSnackbar("Update successful", { variant: "success" });
                  },
                  (err) => {
                    const message =
                      err?.response?.data || "Error occurred while updating info";
                    enqueueSnackbar(message, { variant: "error" });
                  }
                )
              );
            });
          })
        );
      }
    }
  };





  // useEffect(() => {
  //   // setIsLoading(true)
  //   dispatch(getBrokerById({ brokerID }, (data) => {
  //     // setIsLoading(false)
  //     if (data) {
  //       console.log("Data",data)
  //       // setStandingState(data.data.data);
  //     }
  //   }))
  // }, [brokerID]);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  // if (getBrokerByIdIsLoading) {
  //   return (
  //     <>
  //       <LoadingSpinner />
  //     </>
  //   );
  // }
  // if (getBrokerByIdIsError) {
  //   return (
  //     <>
  //       <ErrorMessage />
  //     </>
  //   );
  // }

  return (
    <>
      <Header />
      <section className="mt-5">
        <Container fluid="lg" className="profile mb-5">
          <Row className="gy-5 gx-lg-5">
            <Col
              md={5}
              lg={3}
              className="profile-sidebar profile__sidebar__wrapper align-self-start"
            >
              <aside className="d-flex flex-column h-100 justify-content-between py-4">
                <div>
                  {/* profile img */}
                  <div className="text-center">
                    <div className="text-center position-relative profile__image mx-auto">
                      {profileImage ? (
                        <Image src={profileImage} roundedCircle />
                      ) : (
                        <Avatar src="/broken.png" sx={{width:"100%", height:"100%"}}/>
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                        ref={inputRef}
                      />
                      <div
                        className="profile__image__overlay"
                        onClick={() => inputRef.current.click()}
                      >
                        <RiPencilFill />
                      </div>
                    </div>
                    <h4 className="mt-3">
                      {getBrokerByIdResponse && getBrokerByIdResponse[0]?.name}
                    </h4>
                    <p className="text-muted">
                      {getBrokerByIdResponse && getBrokerByIdResponse[0]?._id}
                    </p>
                  </div>
                  {/* profile img code end here */}

                  <Nav className="flex-column mt-4">
                    {tabs.map((tab, index) => (
                      <Nav.Item key={index}>
                        <Nav.Link
                          active={activeTab === index}
                          onClick={() => handleTabChange(index)}
                          className="d-flex justify-content-between align-items-center gap-2"
                        >
                          <div className="d-flex align-items-center gap-2">
                            <div className="profile__sidebar__wrapper__icon shadow bg-white d-flex justify-content-center align-items-center">
                              <img
                                src={`/assets/profile-page/profile-icon-${
                                  index + 1
                                }.svg`}
                                alt="profile icon"
                                className="img-fluid"
                              />
                            </div>

                            {tab.title}
                          </div>

                          <RiArrowRightSLine size={28} />
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
                <div className="mt-4 d-grid">
                  <Button
                    variant="transparent"
                    className="d-flex justify-content-center align-items-center gap-2 profile__sidebar__wrapper__signout"
                    onClick={() => {
                      localStorage.clear();
                      enqueueSnackbar("Logged out", { variant: "success" });
                      navigate("/");
                    }}
                  >
                    <BsBoxArrowRight className="icon" size={24} /> Sign Out
                  </Button>
                </div>
              </aside>
            </Col>
            <Col md={7} lg={9} className="pt-0 ">
              <Tab.Container activeKey={activeTab}>
                <Tab.Content>
                  {tabs.map((_, index) => (
                    <Tab.Pane key={index} eventKey={index}>
                      <CardWrapper
                        title={profileSidebarNavContent[index]?.title}
                      >
                        {React.cloneElement(
                          profileSidebarNavContent[index]?.element,
                          {
                            getBrokerByIdResponse:
                              getBrokerByIdResponse && getBrokerByIdResponse[0],
                          }
                        )}
                      </CardWrapper>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default ProfilePage;
