import React from 'react';
import Header from '../../../../Common/Header/Header';
import { Footer, SmallCardSection } from '../../../../features';
import SearchFilterBox from '../../../../../dsa/dashboard/search-filter/SearchFilter';
import {
  useGetAllBanner,
  useGetAllPropertyQuery,
} from '../../../../hooks/LoginQuery';
import { useAuth } from '../../../../services/api';

import { Container, Row } from 'react-bootstrap';
import '../PropertyDescription.css';
import { Box, Button, Grid, Stack } from '@mui/material';

import { useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/bundle';

import 'swiper/css/navigation';
import HeaderCategory from '../../../../features/Dashboard/components/properties/HeaderCategory';

const FloorPlan = () => {
  useAuth();
  const { id } = useParams();
  console.log('id is', id);
  const {
    getAllPropertyIsLoading,
    getAllPropertyIsError,
    getAllPropertyResponse,
    getAllPropertyError,
    getAllpropertyIsSuccess,
  } = useGetAllPropertyQuery();

  const {
    getAllBannerIsLoading,
    getAllBannerIsError,
    getAllBannerResponse,
    getAllBannerError,
    getAllBannerIsSuccess,
  } = useGetAllBanner();

  if (!getAllpropertyIsSuccess || !getAllPropertyResponse) {
    return getAllBannerIsLoading;
  }
  console.log('all property ', getAllPropertyResponse);

  const findFloorPlanAndPricingById = (properties, id) => {
    for (const property of properties) {
      const floorPlan = property.floorPlanAndPricing.find(
        (plan) => plan._id === id
      );
      if (floorPlan) {
        return floorPlan;
      }
    }
    return null;
  };

  const formatedPrice = (price) => {
    if (price > 10000000) {
      return (price / 10000000).toFixed(2) + 'cr';
    } else if (price >= 1000000) {
      return (price / 1000000).toFixed(2) + 'L';
    } else {
      return price;
    }
  };

  const findFloorPlanAndPropertyTypeById = (properties, id) => {
    for (const property of properties) {
      const floorPlan = property.floorPlanAndPricing.find(
        (plan) => plan._id === id
      );
      if (floorPlan) {
        return { floorPlan, ...property };
      }
    }
    return null;
  };

  const { floorPlan, propertyType, constructionStatus, possessionDate } = findFloorPlanAndPropertyTypeById(
    getAllPropertyResponse,
    id
  );

  if (floorPlan) {
    console.log('floorPlan:', floorPlan);
    console.log('propertyType:', propertyType);
  }

  console.log('all property is 2', floorPlan);
  console.log('firstedrfgt', floorPlan.carpetSize);
  const calculatePricePerSqft = (price, areaSqft) => {
    return Math.floor(price / areaSqft);
  };

  return (
    <>
      {' '}
      <Header />
      <HeaderCategory/>
      {/* <SmallCardSection /> */}
      <Container>
        <SearchFilterBox addBtn />
        <>
          <div>
            <>
              <div>
                <Swiper
                  modules={[Navigation, Autoplay]}
                  spaceBetween={30}
                  slidesPerView={1}
                  navigation
                  pagination={{ clickable: true }}
                  autoplay={{ delay: 3000 }}
                  className='mt-0 mb-5'
                >
                  {floorPlan?.floorPlanImageUrl.map((imageUrl, index) => (
                    <SwiperSlide key={index}>
                      <img
                        src={imageUrl}
                        alt={` ${index}`}
                        width={'100%'}
                        height={500}
                        style={{ borderRadius: '20px' }}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <div className='mb-3'>
                {constructionStatus == "Under Construction" ? <div className='BrokerPDbanner-col_div-8 justify-content-start gap-2'>
                  <span className='BrokerPDbanner-col_div-8-span'>
                    {constructionStatus}
                  </span>
                  <span className='BrokerPDbanner-col_div-8-span'>
                    Possession date: {new Date(possessionDate).toLocaleDateString()}
                  </span>
                </div>
                  :
                  <div className='BrokerPDbanner-col_div-8 justify-content-start gap-2'>
                    <span className='BrokerPDbanner-col_div-8-span'>
                      {constructionStatus}
                    </span>
                  </div>
                }
              </div>
              <div className='container' key={floorPlan?._id}>
                <div>
                  <div className='BrokerPDPricingCard-div_1'>
                    <div>
                      <div className='d-flex justify-content-between mt-3'>
                        {
                          propertyType?.name == "Commercial"
                            ?
                            <>
                              <div>
                                <span className='BrokerPDPricingCard-span_1'>
                                  Built-Up Area
                                </span>
                                <span className='BrokerPDPricingCard-span_2'>
                                  {floorPlan?.carpetSize
                                    ? `${floorPlan.carpetSize} Sq.ft.`
                                    : `${floorPlan.unitSize.unitSize} Sq.ft.`}
                                </span>
                                <span className='BrokerPDPricingCard-span_4'>
                                  ({' '}
                                  {floorPlan?.carpetSize
                                    ? `${(floorPlan?.carpetSize * 0.092903).toFixed(
                                      2
                                    )} sq.m`
                                    : `${(
                                      floorPlan?.unitSize.unitSize * 0.092903
                                    ).toFixed(2)} sq.m`}
                                  )
                                </span>
                              </div>
                              <div className='text-right'>
                                <span className='BrokerPDPricingCard-span_1'>
                                  Price
                                </span>
                                <span className='BrokerPDPricingCard-span_2'>
                                  ₹{formatedPrice(floorPlan?.price)}
                                </span>

                                <span className='BrokerPDPricingCard-span_3'>
                                  {floorPlan.carpetSize ? (
                                    <>
                                      (
                                      {calculatePricePerSqft(
                                        floorPlan.price,
                                        floorPlan.carpetSize
                                      ).toLocaleString()}
                                      /sq.ft.)
                                    </>
                                  ) : (
                                    <>
                                      (
                                      {calculatePricePerSqft(
                                        floorPlan.price,
                                        floorPlan.unitSize.unitSize
                                      ).toLocaleString()}
                                      /sq.ft.)
                                    </>
                                  )}
                                </span>

                                <span className='BrokerPDPricingCard-span_4'>
                                  +Govt. Charges
                                </span>
                              </div>
                            </>
                            :
                            propertyType?.name == "Flats / Apartment"
                              ?
                              <>
                                <div>
                                  <span className='BrokerPDPricingCard-span_1'>
                                    Built-Up Area
                                  </span>
                                  <span className='BrokerPDPricingCard-span_2'>
                                    {floorPlan?.carpetSize
                                      ? `${floorPlan.carpetSize} Sq.ft.`
                                      : `${floorPlan.unitSize.unitSize} Sq.ft.`}
                                  </span>
                                  <span className='BrokerPDPricingCard-span_4'>
                                    ({' '}
                                    {floorPlan?.carpetSize
                                      ? `${(floorPlan?.carpetSize * 0.092903).toFixed(
                                        2
                                      )} sq.m`
                                      : `${(
                                        floorPlan?.unitSize.unitSize * 0.092903
                                      ).toFixed(2)} sq.m`}
                                    )
                                  </span>
                                </div>
                                <div className='text-right'>
                                  <span className='BrokerPDPricingCard-span_1'>
                                    Price
                                  </span>
                                  <span className='BrokerPDPricingCard-span_2'>
                                    ₹{formatedPrice(floorPlan?.price)}
                                  </span>

                                  <span className='BrokerPDPricingCard-span_3'>
                                    {floorPlan.carpetSize ? (
                                      <>
                                        (
                                        {calculatePricePerSqft(
                                          floorPlan.price,
                                          floorPlan.carpetSize
                                        ).toLocaleString()}
                                        /sq.ft.)
                                      </>
                                    ) : (
                                      <>
                                        (
                                        {calculatePricePerSqft(
                                          floorPlan.price,
                                          floorPlan.unitSize.unitSize
                                        ).toLocaleString()}
                                        /sq.ft.)
                                      </>
                                    )}
                                  </span>

                                  <span className='BrokerPDPricingCard-span_4'>
                                    +Govt. Charges
                                  </span>
                                </div>
                              </>
                              :
                              propertyType?.name == "Land/Plots"
                                ?
                                <>
                                  <div>
                                    <span className='BrokerPDPricingCard-span_1'>
                                      Plot size
                                    </span>
                                    <span className='BrokerPDPricingCard-span_2'>
                                      {floorPlan?.unitSize?.unitSize
                                        ? `${floorPlan.unitSize?.unitSize} Sq.ft.`
                                        : `--`}
                                    </span>
                                    <span className='BrokerPDPricingCard-span_4'>
                                      {floorPlan?.unitSize
                                        ?
                                        `lxb: ${floorPlan?.unitSize?.length}ft. (${(floorPlan?.unitSize?.length * 0.3048).toFixed(
                                          2
                                        )} mt.) x ${floorPlan?.unitSize?.breadth}ft. (${(floorPlan?.unitSize?.breadth * 0.3048).toFixed(
                                          2
                                        )} mt.)`
                                        : `--`}
                                    </span>
                                  </div>
                                  <div className='text-right'>
                                    <span className='BrokerPDPricingCard-span_1'>
                                      Price
                                    </span>
                                    <span className='BrokerPDPricingCard-span_2'>
                                      ₹{formatedPrice(floorPlan?.price)}
                                    </span>

                                    <span className='BrokerPDPricingCard-span_3'>
                                      {floorPlan.unitSize.unitSize ? (
                                        `(${calculatePricePerSqft(
                                          floorPlan?.price,
                                          floorPlan?.unitSize.unitSize
                                        ).toLocaleString()}/sq.ft.)`
                                      ) : (
                                        "--"
                                      )}
                                    </span>

                                    <span className='BrokerPDPricingCard-span_4'>
                                      +Govt. Charges
                                    </span>
                                  </div>
                                </>
                                :
                                ""
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
          <Stack my={4}>
            <h2 className='broker__section__title'>Specifications</h2>
            <Grid container spacing={2}>
              {floorPlan.specifications.filter(
                (specification) => specification !== ''
              ).length === 0 ? (
                <Grid item xs={12}>
                  <Box textAlign={'center'}>
                    Currently no information about specifications
                  </Box>
                </Grid>
              ) : (
                floorPlan.specifications.map(
                  (specification, index) =>
                    specification !== '' && (
                      <Grid item xs={6} key={index} xl={2} sm={6}>
                        <Box
                          sx={{
                            color: 'black',
                            border: '1px solid #E3E3E7',
                            borderRadius: '142px',
                            width: '100%',
                            p: 1,
                            fontStyle: 'Bahnschrift',
                          }}
                        >
                          {propertyType.name === 'Land/Plots' ? (
                            <Box textAlign={'center'}>{specification}</Box>
                          ) : (
                            <>
                              <Box textAlign={'center'}>
                                {specification.specification}
                              </Box>
                              <Box textAlign={'center'}>
                                {specification.specificationLength &&
                                  specification.specificationBreath && (
                                    <Box textAlign={'center'}>
                                      {`(${specification.specificationLength}m × ${specification.specificationBreath}m)`}
                                    </Box>
                                  )}
                              </Box>
                            </>
                          )}
                        </Box>
                      </Grid>
                    )
                )
              )}
            </Grid>
          </Stack>
        </>
        <Stack my={4}>
          <h2 className='broker__section__title'>Furnishing Details</h2>
          <Grid container spacing={2}>
            {floorPlan.furnishingDetails.length === 0 ||
              floorPlan.furnishingDetails.every((detail) => detail === '') ? (
              <Grid item xs={12}>
                <Box
                  sx={{
                    color: 'black',

                    width: '100%',
                    p: 1,
                    fontStyle: 'Bahnschrift',
                  }}
                >
                  <Box textAlign={'center'}>
                    No furnishing details available
                  </Box>
                </Box>
              </Grid>
            ) : (
              floorPlan.furnishingDetails
                .filter((furnishingDetails) => furnishingDetails !== '')
                .map((furnishingDetails, index) => (
                  <Grid item xs={6} key={index} xl={2} sm={6}>
                    <Box
                      sx={{
                        color: 'black',
                        border: '1px solid #E3E3E7',
                        borderRadius: '142px',
                        width: '100%',
                        p: 1,
                        fontStyle: 'Bahnschrift',
                      }}
                    >
                      <Box textAlign={'center'}>
                        {furnishingDetails.furnishing}{' '}
                      </Box>
                      <Box textAlign={'center'}>
                        {furnishingDetails.quantity}{' '}
                      </Box>
                    </Box>
                  </Grid>
                ))
            )}
          </Grid>
        </Stack>
      </Container>
      <Footer />
    </>
  );
};

export default FloorPlan;
