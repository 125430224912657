import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import './Preferences.css';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { getAllBuilders, getAllBuilders1, getAllGlobalBuilders, getLocationByInput, updateBrokerPrefrences } from '../../../../API/action';
import { useGetAllBuilders, useGetAllPropertyCategory } from '../../../hooks/LoginQuery';
import Select from 'react-select';
import { Autocomplete, Grid, TextField } from '@mui/material';

const Preferences = ({ getBrokerByIdResponse }) => {
  const [builders, setBuilders] = useState([]);
  const [locality, setLocality] = useState([]);
  const [propertyType, setPropertyType] = useState([]);
  const [top3Builders, setTop3Builders] = useState([]);
  const [experience, setExperience] = useState("");
  const [id, setId] = useState('');
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [locationSelected, setLocationSelected] = useState([]); // Initialize as an array
  const [locationSearch, setLocationSearch] = useState([]);
  const [propertyLocation, setPropertyLocation] = useState([]);
  const [labeldBuilder, setLabeldBuilder] = React.useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [value, setValue] = React.useState("");

  useEffect(() => {
    // Set initial values when getBrokerByIdResponse changes
    setLocationSelected(getBrokerByIdResponse?.locality || []);
    setPropertyType(getBrokerByIdResponse?.propertyType || []);
    setTop3Builders(getBrokerByIdResponse?.top3Builders || []);
    setExperience(getBrokerByIdResponse?.experience || "")
    setId(getBrokerByIdResponse?._id || "");
  }, [getBrokerByIdResponse]);

  useEffect(() => {
    if (searchQuery) {
      dispatch(getAllBuilders1({ searchQuery }, (data) => {
        setBuilders(data.map(x => x.builderName));
      }, (err) => {
        console.log(err)
      }))
    } else {
      setBuilders([]);
    }
  }, [searchQuery]);

  useEffect(()=>{
    setLabeldBuilder(builders);
  }, [builders]);

  const handleSetFormValues = (name, value) => {
    if (name === 'location') {
      getLocationByInput({ searchText: value }).then((res) => {
        if (res.data.data.length > 0) {
          const locations = res.data.data.map(item => item.location);
          setLocationSearch(locations);
        }
      }).catch((err) => {
        console.log(err);
      });
    } else {
      setLocationSelected((prevFormValues) => ({
        ...prevFormValues,
        [name]: value,
      }));
    }
  };
  

  const handleSubmit = e => {
    e.preventDefault();

    const formData = {
      id,
      locality: locationSelected,
      propertyType,
      top3Builders,
      experience: experience,
    };
    setIsLoading(true)

    dispatch(
      updateBrokerPrefrences(formData,
        (data) => {
          enqueueSnackbar("Preferences Updated Successfully", { variant: 'success' });
          setIsLoading(false)
          // navigateTo(navigate, '/profile')
        },
        (err) => {

          setIsLoading(false)
          if (err?.request?.response) {
            let errorMessage = JSON.parse(err?.request?.response)?.msg;
            enqueueSnackbar(errorMessage, { variant: 'error' });

          }
        }
      )
    );

  };


  const handleLocalityChange = (selectedOptions) => {
    const values = selectedOptions ? selectedOptions.map((option) => option.value) : [];
    setLocality(values);
  };

  const handlePropertyTypeChange = (selectedOptions) => {
    const values = selectedOptions ? selectedOptions.map((option) => option.value) : [];
    setPropertyType(values);
  };

  const handleTop3BuildersChange = (selectedOptions) => {
    const values = selectedOptions ? selectedOptions.map((option) => option.value) : [];
    setTop3Builders(values);
  };

  // useEffect(() => {
  //   dispatch(getAllBuilders({}, (data) => {
  //     setBuilders(data);
  //   }))
  // }, []);

  // console.log(builders)

  const {
    getAllPropertyCategoryIsLoading,
    getAllPropertyCategoryIsError,
    getAllPropertyCategoryResponse,
    getAllPropertyCategoryError,
    getAllPropertyCategoryIsSuccess,
  } = useGetAllPropertyCategory();

  const {
    getAllBuildersIsLoading,
    getAllBuildersIsError,
    getAllBuildersResponse,
    getAllBuildersError,
    getAllBuildersIsSuccess,
  } = useGetAllBuilders();
  console.log(getAllBuildersResponse)

//   useEffect(() => {
   
//     dispatch(getAllGlobalBuilders({}, (data) => {
//       setBuilders(data);
//     }, (err) => {
//       console.log(err)
//     }))

// }, []);

// console.log(builders)


  return (
    <Form className="profile__form">

<Form.Group className="mb-4">
        <Form.Label>Experience (in years)*</Form.Label>
        <Form.Control
          className="rounded-0"
          type="number"
          placeholder="Enter Experience" 
          value={experience}  
          onChange={(e) => setExperience(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-4">
        <Grid item xs={6}>
          <Form.Label>Preferred Location</Form.Label>
          <Autocomplete
            onChange={(event, value1) => {
              setLocationSelected(value1);
            }}
            multiple
            disablePortal
            value={locationSelected} // Make sure locationSelected is an array
            name="location"
            id="combo-box-demo"
            options={locationSearch}
            sx={{ width: '100%' }}
            renderInput={(params) => <TextField className='inputBox' {...params} name='location' value={locationSelected} onChange={(e) => handleSetFormValues(e.target.name, e.target.value)} />}
        />
        </Grid>
        </Form.Group>
        {/* <Form.Group className="mb-4">
        <Form.Label>Locality</Form.Label>
        <CreatableSelect
          isMulti
          placeholder="Add more"
          options={getBrokerByIdResponse?.locality.map((l) => ({
            label: l,
            value: l,
          }))}
          value={(locality || []).map((value) => ({
            label: value,
            value: value,
          }))}
          onChange={handleLocalityChange}
          name="locality"
        />
      </Form.Group> */}
      <Form.Group className="mb-4">
        <Form.Label>Property Type</Form.Label>
        <Select
          isMulti
          placeholder="Add more"
          options={(getAllPropertyCategoryResponse || []).map((category) => ({
            label: category.name,
            value: category.name,
          }))}
          value={(propertyType || []).map((value) => ({
            label: value,
            value: value,
          }))}
          onChange={handlePropertyTypeChange}
          name="propertyType"
        />
      </Form.Group>
 
      <Form.Group className="mb-4">
        <Form.Label>Top 3 Builder whom you worked with*</Form.Label>
        <Autocomplete
        multiple
        value={top3Builders}
        onChange={(event, newValue) => {
          if (newValue.length <= 3) {
            setTop3Builders(newValue);
          } else {
            enqueueSnackbar("you have already selected top 3 builders", { variant: 'warning' });
          }
        }}
        // inputValue={inputValue}
        // onInputChange={(event, newInputValue) => {
        //   setInputValue(newInputValue);
        // }}
        id="controllable-states-demo"
        sx={{ width: '100%' }}
        options={labeldBuilder}
        renderInput={(params) => <TextField {...params} value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
          label="Top 3 builders" variant="standard" />}
      />
      </Form.Group>
      <Button className="btn-color-primary rounded-100 btn-rounded w-100 mt-3" onClick={handleSubmit}>Done</Button>
    </Form>
  );
};

export default Preferences;
