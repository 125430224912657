export const HomeDashboardContentContentData = [
  {
    title: 'Overdue',
    cardType: 'overdue',
  },
  {
    title: 'Assigned Loan Queries',
    cardType: 'assigned',
  },
  {
    title: 'Recently Accepted Loan Queries',
    cardType: 'acceptedLoanQueries',
  },
];
