import { Button, Card, Col, Container, Row, Spinner } from 'react-bootstrap';
import propertyImage from '../../Images/propertyadded.png';
import build from '../../Images/build.png';
import { Link } from 'react-router-dom';
import { BsPencilFill } from 'react-icons/bs';
import { RiVipCrownFill } from 'react-icons/ri';
import { useEffect, useState } from 'react';
import { getAPI } from '../../Api/ApiRequest';
import { apiEndpoints } from '../../Api/ApiEndpoint';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { faL } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from '../../../broker/Common/loading-spinner/LoadingSpinner';

const RecentlyAdded = ({ builderID }) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [propertiesPerPage] = useState(9);
  console.log(' is djkjf', builderID);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(data?.length / propertiesPerPage); i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    const getAddedProperties = async () => {
      setLoading(true);
      const response = await getAPI(
        `${apiEndpoints?.getAllResentProperty}${builderID}`
      );
      setData(response?.data);
      setLoading(false);
      console.log('recently added', response.data);
    };
    getAddedProperties();
  }, []);

  const handlePageChange = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const formatPrice = (price) => {
    if (price >= 10000000) {
      return `₹ ${(price / 10000000).toFixed(2)} Cr`;
    } else if (price >= 100000) {
      return `₹ ${(price / 100000).toFixed(2)} L`;
    } else {
      return `₹ ${price}`;
    }
  };

  const compareData = (a, b) => {
    const dateA = new Date(a.createdAt).getTime();
    const dateB = new Date(b.createdAt).getTime();

    return dateB - dateA;
  };

  data?.sort(compareData);

  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
  const currentProperties = data?.slice(
    indexOfFirstProperty,
    indexOfLastProperty
  );

  
  return (
    <Container className='mt-2' style={{}}>
      <Box
        mt={4}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
      >
        <h3 className='col-8 heading'>Recently Added</h3>
        <Link
          to='/builder/home-dashboard/recentlyadded'
          className='col-4 text-danger text-end w-auto'
        >
          <Typography>View All</Typography>
        </Link>
      </Box>
      {loading ? (
        <Box textAlign={'center'} my={5}>
          {' '}
          <CircularProgress />
        </Box>
      ) : (
        <>
          {data?.length > 0 ? (
            <Box my={5}>
              <Grid container spacing={7}>
                {currentProperties?.map((itm, index) => (
                  <Grid
                    item
                    md={4}
                    sm={6}
                    xs={12}
                    key={index}
                    sx={{ textAlign: 'center' }}
                  >
                    <Box
                      className='card  mb-4 shadow-sm rounded-4 col-md-3 p-0 border-0 '
                      id={itm?._id}
                      key={itm?._id}
                      sx={{
                        width: { md: '21.75rem', sm: '100%', xs: '100%' },
                        margin: 'auto',
                      }}
                    >
                      <Link
                        to={`/builder/home-dashboard/description/${itm?._id}`}
                      >
                        <Box
                          sx={{
                            position: 'relative',
                            height: '11rem',
                          }}
                        >
                          <Card.Img
                            className='w-100 bl-prop-img '
                            variant='top'
                            src={itm?.images[0]}
                            style={{
                              height: '11rem',
                              borderRadius: '1rem',
                            }}
                          />
                          <Box
                            sx={{
                              bgcolor: '#EFEFEF',
                              position: 'absolute',
                              padding: '3px 5px',
                              top: 12,
                              right: 0,
                            }}
                          >
                            Listing Date {itm?.createdAt?.substring(0, 10)}
                          </Box>
                          <Box
                            px={{ md: 1, xs: 4 }}
                            py={{ md: 1, xs: 1 }}
                            sx={{
                              backgroundColor: '#FE5E6A',
                              color: '#fff',

                              bottom: 50,
                              borderRadius: '.3rem',
                              width: { md: '55%', xs: '100%' },
                            }}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={6}>
                                --
                              </Grid>
                              <Grid item xs={6}>
                                {itm?.visitCount}/{itm?.noOfVisits} visits
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Link>
                      <Card.Body>
                        <Card.Text>
                          <Grid container>
                            <Grid item md={10} sm={10} textAlign={'start'}>
                              <div>
                                {' '}
                                <Link
                                  className='recent-heading'
                                  to={`/builder/home-dashboard/description/${itm?._id}`}
                                >
                                  {itm?.name}
                                </Link>
                              </div>
                              <p style={{ opacity: 0.5 }}>{itm?.location}</p>
                            </Grid>
                            <Grid item md={2} sm={2}>
                              <RiVipCrownFill
                                style={{
                                  padding: '5px',
                                  borderRadius: '50%',
                                  background: '#ffd600',
                                  color: '#fff',
                                  width: '1.85em',
                                  height: '1.85em',
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Row className='mt-2 mb-2'>
                            {itm?.unitTypeNew?.slice(0, 4).map((item) => (
                              <Col md={3} sm={3} className='text-center'>
                                <img
                                  src={build}
                                  alt='build'
                                  className='build-icon'
                                />
                                <p style={{ opacity: 0.5 }}>{item}</p>
                              </Col>
                            ))}
                          </Row>

                          <Grid
                            container
                            spacing={2}
                            className='align-items-center mb-3'
                          >
                            <Grid
                              item
                              md={6}
                              style={{
                                color: '#000000',
                                fontSize: '1.35em',
                                fontWeight: 'bold',
                              }}
                            >
                              {formatPrice(itm?.minPrice)}-
                              {formatPrice(itm?.maxPrice)}
                            </Grid>
                            <Grid
                              item
                              md={6}
                              style={{
                                color: '#7D7F88',
                                fontSize: '0.8em',
                                textAlign: 'start',
                              }}
                            >
                              {itm?.builderDiscount}
                            </Grid>
                          </Grid>
                          <div className='d-flex justify-content-end gap-2 mt-2'>
                            <Button
                              variant='transparent'
                              type='button'
                              className='rounded-pill px-4 py-2 border border-primary'
                              style={{ color: '#278FD9', fontSize: '14px' }}
                              as={Link}
                              to={`/builder/home-dashboard/visit/${itm?._id}`}
                            >
                              {itm?.noOfVisits} Visits
                            </Button>
                            <Button
                              variant='transparent'
                              type='button'
                              className='primary rounded-pill px-4 py-2 border border-primary'
                              as={Link}
                              to={`/builder/home-dashboard/propertyedit/${itm?._id}`}
                            >
                              <BsPencilFill
                                style={{
                                  width: '0.9em',
                                  color: '#fff',
                                  background: '#278fd9',
                                  padding: '2px',
                                  borderRadius: '50%',
                                  marginRight: '4px',
                                  marginBottom: '5px',
                                }}
                              />
                              Edit
                            </Button>
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : (
            <Typography
              my={6}
              textAlign={'center'}
              color={'var(--primary-color-darker)'}
              fontWeight={'bold'}
            >
              No recently added properties available
            </Typography>
          )}
        </>
      )}
    </Container>
  );
};

export default RecentlyAdded;
