import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import { useDispatch } from 'react-redux';

import html2pdf from 'html2pdf.js';
import jsPDF from 'jspdf';
import { getInvoicePDFByID } from '../../Api/Action';

export default function InvoiceModal({ isOpen, setIsOpen, invoice, claim }) {
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  console.log('here invoice id', invoice);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: '100%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
  };

  const dispatch = useDispatch();
  console.log('invoice is', invoice);

  const downloadInvoiceAsPDF = () => {
    console.log('started');
    dispatch(
      getInvoicePDFByID(
        invoice,
        (data) => {
          const invoiceHtml = data;
          const options = {
            filename: 'invoice.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2, useCORS: true },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
          };

          html2pdf().from(invoiceHtml).set(options).save();
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <iframe
            id='invoiceIframe'
            title='invoice'
            style={{ width: '100vh', height: '100vh' }}
            src={`https://backend.nestohub.in/api/v1/claim/getClaimInvoicePdfView?id=${invoice}`}
          />
          <Button
            variant='contained'
            sx={{ m: 1, p: 1, float: 'right', mr: 3 }}
            onClick={downloadInvoiceAsPDF}
          >
            Download
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
