import React, { useEffect, useState } from "react";
import { CustomModal } from "../../../../../components";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useGetAllPropertyQuery } from "../../../../hooks/LoginQuery";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import CreatableSelect from "react-select/creatable";
import {
  getAllCustomers,
  getAllRequirement,
  getLocationByInput,
} from "../../../../../API/action";

const BookAVisitFromRequirementModal = ({
  show,
  onHide,
  propertyId,
  proprtyName,
  BuilderId,
  requirementID,
  customer,
  unitTypes,
  preferredLocations,
  min,
  max
}) => {
  const [propertyName, setPropertyName] = useState(proprtyName || "");
  const [propBuilderId, setPropBuilderId] = useState(BuilderId || "");
  const [unitTypesOptions, setUnitTypesOptions] = useState([]);
  const [locationSelected, setLocationSelected] = useState(preferredLocations || []);
  const [allRequirements, setAllRequirements] = useState([]);
  const [locationSearch, setLocationSearch] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [propId, setPropId] = useState(propertyId || "");
  const [requirement, setRequirement] = useState("new");
  const [allCustomers, setAllCustomers] = useState([]);
  const [chooseSlot, setChooseSlot] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(customer.phoneNumber || "");
  const [isDisable, setIsDisable] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [clientName, setClientName] = useState(customer.clientName ||"");
  const [maxPrice, setMaxPrice] = useState(max || "");
  const [minPrice, setMinPrice] = useState(min || "");
  const [unitType, setUnitType] = useState(unitTypes || []);
  const [email, setEmail] = useState(customer.email || "");
  const [date, setDate] = useState("");
  const dispatch = useDispatch();

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#7c82ff" : "white",
      color: state.isSelected ? "white" : "black",
    }),
  };

  const {
    getAllPropertyIsLoading,
    getAllPropertyResponse, // Changed to use getAllPropertyResponse directly
  } = useGetAllPropertyQuery();

  // No need for filtering, directly use getAllPropertyResponse
  const allProperties = getAllPropertyResponse || [];

  const brokerID = localStorage.getItem("brokerID");

  const getData = () => {
    // Fetch builder details using builderId
    dispatch(
      getAllCustomers(
        brokerID,
        (data) => {
          setAllCustomers(data.data.data);
        },
        (error) => {
          // Handle error
          console.error("Error fetching all contact queries:", error);
        }
      )
    );
  };

  useEffect(() => {
    getData();
  }, [brokerID]);

  const handleAutocompleteChange = (event, newValue) => {
    if (newValue) {
      const selectedClient = allCustomers.find(
        (item) => item?._id === newValue._id
      );
      setClientName(selectedClient?.clientName || "");
      setIsDisable(true);
      setPhoneNumber(selectedClient?.phoneNumber || "");
      setEmail(selectedClient?.email || "");
      setCustomerId(selectedClient?._id || "");
      dispatch(
        getAllRequirement(
          { brokerId: brokerID, customerId: newValue._id },
          (data) => {
            setAllRequirements(data.data.data);
          },
          (err) => {}
        )
      );
    } else {
      // Handle if no option is selected
    }
  };

  const handleUnitTypeChange = (selectedOptions) => {
    setUnitType(selectedOptions);
  };

  const handleSetFormValues = (name, value) => {
    if (name === "location") {
      getLocationByInput({ searchText: value })
        .then((res) => {
          if (res.data.data.length > 0) {
            const locations = res.data.data.map((item) => item.location);
            setLocationSearch(locations);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setLocationSelected((prevFormValues) => ({
        ...prevFormValues,
        [name]: value,
      }));
    }
  };

  const handleSlotChange = (slotTime) => {
    setSelectedSlot((prevSelectedSlot) =>
      prevSelectedSlot === slotTime ? null : slotTime
    );
    setChooseSlot(String(slotTime));
  };

  const handleSubmit = () => {

  }

  return (
    <>
      <CustomModal
        show={show}
        title="Book a Visit"
        border
        closeBtn
        onHide={onHide}
        cssClassName="nes__dashboard__modal"
        size="lg"
        modalHeader
      >
        <Form
          className="profile__form ps-2 py-3 custom__modal__form"
          onSubmit={(e) => e.preventDefault()}
        >
          <Form.Group className="flex-grow-1 mb-4">
            <Form.Label>
              Property Name<span className="text-dark">*</span>
            </Form.Label>
            <Form.Select
              value={propertyId}
              disabled={proprtyName}
              onChange={(e) => {
                const selectedPropertyId = e.target.value;
                const selectedProperty = allProperties.find(
                  (item) => item?._id === selectedPropertyId
                );

                const selectedPropertyName = selectedProperty
                  ? selectedProperty.name
                  : "";
                const selectedBuilderId = selectedProperty
                  ? selectedProperty.builderId._id
                  : "";

                setPropId(selectedPropertyId);
                setPropBuilderId(selectedBuilderId);
                setPropertyName(selectedPropertyName);
                var allUnitTypes = [];
                selectedProperty.floorPlanAndPricing.map((item) => {
                  allUnitTypes.push(...item.unitType);
                });
                allUnitTypes = [...new Set(allUnitTypes)];
                setUnitTypesOptions(
                  allUnitTypes.map((item) => {
                    return { value: item, label: item };
                  })
                );
                // setMinPrice(selectedProperty);
                // setMaxPrice();
              }}
            >
              <option value="" disabled selected>
                Select
              </option>
              {allProperties?.map((item) => (
                <option key={item?._id} value={item?._id}>
                  {item?.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="flex-grow-1 mb-4">
            <Form.Label>
              Client Name <span className="text-dark">*</span>
            </Form.Label>

            <Autocomplete
              options={allCustomers}
              getOptionLabel={(option) => option.clientName}
              disabled
              value={customer}
              onChange={handleAutocompleteChange}
              sx={{
                "& .MuiAutocomplete-tag": {
                  borderRadius: 0,
                },
                "& .MuiOutlinedInput-root": {
                  border: "none !important",
                  height: "3rem",

                  "&:hover": {
                    borderColor: "grey",
                  },
                },
              }}
              renderInput={(params) => (
                <TextField sx={{ bgcolor: "#F8F8F8" }} {...params} label="" />
              )}
            />
          </Form.Group>
          <Form.Group className="flex-grow-1 mb-4" controlId="phoneNumber">
            <Form.Label>
              Phone Number<span className="text-dark">*</span>
            </Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text className="rounded-0 border-0">
                +91
              </InputGroup.Text>
              <Form.Control
                className="rounded-0 border-0"
                type="number"
                disabled
                name="phoneNumber"
                onChange={(e) =>
                  setPhoneNumber(e.target.value.substring(0, 10))
                }
                value={phoneNumber}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-4" controlId="email">
            <Form.Label>
              Email<span className="text-dark">*</span>
            </Form.Label>
            <Form.Control
              className="rounded-0 border-0"
              disabled
              type="email"
              placeholder="Enter email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </Form.Group>
          <Form.Group className="flex-grow-1 mb-4">
            <Form.Label>
              Unit Type<span className="text-dark">*</span>
            </Form.Label>
            <CreatableSelect
              isMulti
              options={unitTypesOptions}
              value={unitType}
              placeholder="Add more"
              name="unitType"
              onChange={handleUnitTypeChange}
              styles={customStyles}
              isDisabled
            />
          </Form.Group>
          <Form.Group className="flex-grow-1 mb-4">
            <Form.Label>
              Preferred Location<span className="text-dark">*</span>
            </Form.Label>
            {/* <CreatableSelect
                  isMulti
                  placeholder="Add more"
                  options={locationSearch}
                  name="preferredLocation"
                  onChange={handlePreferredLocationChange}
                /> */}

            <Autocomplete
              onChange={(event, value1) => {
                console.log(value1);
                setLocationSelected(value1);
              }}
              multiple
              disablePortal
              name="location"
              id="combo-box-demo"
              options={locationSearch}
              value={locationSelected}
              disabled
              sx={{
                "& .MuiAutocomplete-tag": {
                  borderRadius: 0,
                },
                "& .MuiOutlinedInput-root": {
                  border: "none !important",
                  height: "3rem",

                  "&:hover": {
                    borderColor: "grey",
                  },
                },
              }}
              renderInput={(params) => (
                <TextField
                  className="inputBox"
                  {...params}
                  name="location"
                  // value={locationSelected}
                  onChange={(e) =>
                    handleSetFormValues(e.target.name, e.target.value)
                  }
                  sx={{ bgcolor: "#F8F8F8" }}
                />
              )}
            />
          </Form.Group>
          <Grid container spacing={2}>
            <Grid item md={6} xs={6} sm={6}>
              <Form.Group className="mb-4" controlId="companyName">
                <Form.Label>
                  Min Price<span className="text-dark">*</span>
                </Form.Label>
                <Form.Control
                  className="rounded-0 border-0"
                  type="number"
                  // disabled={minimumPrice}
                  placeholder="enter value"
                  onChange={(e) => setMinPrice(e.target.value)}
                  value={minPrice}
                  disabled
                />
              </Form.Group>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <Form.Group className="mb-4" controlId="companyName">
                <Form.Label>
                  Max Price<span className="text-dark">*</span>
                </Form.Label>
                <Form.Control
                  className="rounded-0 border-0"
                  type="number"
                  // disabled={maximumPrice}
                  placeholder="enter value"
                  onChange={(e) => setMaxPrice(e.target.value)}
                  value={maxPrice}
                  disabled
                />
              </Form.Group>
            </Grid>
          </Grid>
          <Form.Group className="mb-4">
            <Form.Label>
              Date<span className="text-dark">*</span>
            </Form.Label>
            <Form.Control
              className="form-control-sm rounded-0 border-0"
              type="date"
              // required
              name="date"
              onChange={(e) => setDate(e.target.value)}
              value={date}
              min={new Date().toISOString().split("T")[0]}
            />
          </Form.Group>
          <div>
                <Form.Group className="mb-1">
                  <Form.Label>
                    Choose Slot<span className="text-dark">*</span>
                  </Form.Label>
                </Form.Group>

                <Row>
                  <Col xs={12} className="mb-4">
                    <Form.Label>Morning Slot</Form.Label>
                    <div className="d-flex flex-wrap justify-content-between gap-3">
                      {["10am", "11am", "12am"].map((slotTime) => (
                        <Button
                          key={slotTime}
                          className="px-5 py-2"
                          variant={
                            selectedSlot === slotTime
                              ? "primary"
                              : "outline-primary"
                          }
                          onClick={() => handleSlotChange(slotTime)}
                          // disabled={selectedSlot && selectedSlot !== slotTime}
                        >
                          {slotTime}
                        </Button>
                      ))}
                    </div>
                  </Col>
                  <Col xs={12} className="mb-4">
                    <Form.Label>Afternoon Slot</Form.Label>
                    <div className="d-flex flex-wrap justify-content-between gap-3">
                      {["1pm", "2pm", "3pm"].map((slotTime) => (
                        <Button
                          key={slotTime}
                          className="px-5 py-2"
                          variant={
                            selectedSlot === slotTime
                              ? "primary"
                              : "outline-primary"
                          }
                          onClick={() => handleSlotChange(slotTime)}
                          // disabled={selectedSlot && selectedSlot !== slotTime}
                        >
                          {slotTime}
                        </Button>
                      ))}
                    </div>
                  </Col>
                  <Col xs={12} className="mb-4">
                    <Form.Label>Evening Slot</Form.Label>
                    <div className="d-flex flex-wrap justify-content-between gap-3">
                      {["4pm", "5pm", "6pm"].map((slotTime) => (
                        <Button
                          key={slotTime}
                          className="px-5 py-2"
                          variant={
                            selectedSlot === slotTime
                              ? "primary"
                              : "outline-primary"
                          }
                          onClick={() => handleSlotChange(slotTime)}
                          // disabled={selectedSlot && selectedSlot !== slotTime}
                        >
                          {slotTime}
                        </Button>
                      ))}
                    </div>
                  </Col>
                </Row>
              </div>

              {/* time slots code end here */}

              <div className="d-flex gap-4 mt-5">
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  className="btn-color-primary rounded-pill btn-rounded w-100"
                >
                  Book Visit
                </Button>
              </div>
        </Form>
      </CustomModal>
    </>
  );
};

export default BookAVisitFromRequirementModal;
