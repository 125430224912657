import React from 'react';
import Slider from 'react-slick';
import './MobileSlider.css';
import { heroData } from '../data';

const MobileSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: 'linear',
  };
  return (
    <>
      <div className="mobile-slider">
        <div className="mobile-slider-div">
          <Slider {...settings}>
            {heroData.map((item) => (
              <div className="d-flex mobile-slider-div-2" key={item._id}>
                <img
                  src={item.image}
                  className="mobile-slider-img"
                  alt={item.name}
                />
              </div>
            ))}
            {/* <div className='d-flex mobile-slider-div-2'>
                            <img src="/assets/informative/1.png" className='mobile-slider-img' />
                        </div>
                        <div className='d-flex mobile-slider-div-2'>
                            <img src="/assets/informative/2.png" className='mobile-slider-img' />
                        </div>
                        <div className='d-flex mobile-slider-div-2'>
                            <img src="/assets/informative/2.png" className='mobile-slider-img' />
                        </div>
                        <div className='d-flex mobile-slider-div-2'>
                            <img src="/assets/informative/2.png" className='mobile-slider-img' />
                        </div> */}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default MobileSlider;
