// import { Card, Col, Container, Row } from 'react-bootstrap';
// import propertyImage from '../../../Images/propertyimage.png';
// import { Link } from 'react-router-dom';

// import { useEffect, useState } from 'react';

// import { apiEndpoints } from '../../../Api/ApiEndpoint';
// import { getAPI } from '../../../Api/ApiRequest';
// import { Box, CircularProgress, Typography } from '@mui/material';

// const PastPropertyList = (props) => {
//   const builderID = localStorage.getItem('builderId');
//   // const data = useSelector((state) => state.soldPropertyDetail.properties);
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     const getBoughtProperties = async () => {
//       setLoading(true);
//       const response = await getAPI(
//         `${apiEndpoints.getAllResentProperty}${builderID}`
//       );

//       setData(response.data);
//       setLoading(false);
//     };

//     getBoughtProperties();
//   }, [builderID]);

//   const formatPrice = (price) => {
//     const priceInLakhs = price / 100000;
//     if (priceInLakhs < 100) {
//       return `${priceInLakhs.toFixed(2)} L`;
//     } else {
//       const priceInCrores = priceInLakhs / 100;

//       return `${priceInCrores.toFixed(2)} CR`;
//     }
//   };

//   const propertyListing = data?.map((itm, index) => {
//     return (
//       <Col
//         md={3}
//         className='card mb-4 shadow-sm rounded-4 col-md-3 p-0 border-0'
//         style={{
//           width: '21.75rem',
//         }}
//         id={itm?._id}
//         key={itm?._id}
//       >
//         <Card.Img
//           className='w-100'
//           variant='top'
//           src={itm?.images[0]}
//           style={{ height: '14rem' }}
//         />
//         <Card.Body>
//           <div>
//             <div>
//               {' '}
//               {/* <Link
//                 style={{
//                   color: "black",
//                   fontWeight: "bold",
//                   fontSize: "1.4em",
//                   textDecoration: "none",
//                 }}
//                 // to="/builder/home-dashboard/description"
//               > */}
//               <h4
//                 style={{
//                   color: 'black',
//                   fontWeight: 'bold',
//                   fontSize: '1.4em',
//                   textDecoration: 'none',
//                 }}
//               >
//                 {itm?.propertyId === null
//                   ? 'no data Name'
//                   : itm?.propertyId?.name}
//               </h4>
//               {itm?.name}
//               {/* </Link> */}
//             </div>
//             <div style={{ opacity: 0.5, fontFamily: 'Bahnschrift' }}>
//               {itm?.propertyId === null ? 'no data' : itm?.location}
//             </div>
//           </div>
//           <Row className='mt-2 mb-2'>
//             {itm.unitTypeNew.map((unitType, index) => (
//               <Col md={3} sm={3} className='text-center' key={index}>
//                 <img
//                   component={'img'}
//                   src='/assets/build.svg'
//                   alt='build'
//                   style={{ width: '1.5rem' }}
//                 />
//                 <p style={{ opacity: 0.5 }}>{unitType}</p>
//               </Col>
//             ))}
//           </Row>
//           <Row className='mt-2 mb-2'>
//             <h5>
//               {formatPrice(itm?.minPrice)} - {formatPrice(itm?.maxPrice)}
//             </h5>
//           </Row>
//         </Card.Body>
//       </Col>
//     );
//   });
//   return (
//     <>
//       <Container className='mt-5'>
//         <div className='mt-2 row justify-content-around'>
//           {loading ? (
//             <Box textAlign={'center'}>
//               <CircularProgress />
//             </Box>
//           ) : data?.length > 0 ? (
//             propertyListing
//           ) : (
//             <Box textAlign={'center'}>
//               <Typography variant='h5' color='primary'>
//                 No data available...
//               </Typography>
//             </Box>
//           )}
//         </div>
//       </Container>
//     </>
//   );
// };

// export default PastPropertyList;
import { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
} from '@mui/material';
import { apiEndpoints } from '../../../Api/ApiEndpoint';
import { getAPI } from '../../../Api/ApiRequest';

const PastPropertyList = (props) => {
  const builderID = localStorage.getItem('builderId');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getBoughtProperties = async () => {
      setLoading(true);
      const response = await getAPI(
        `${apiEndpoints.getAllResentProperty}${builderID}`
      );

      setData(response.data);
      setLoading(false);
    };

    getBoughtProperties();
  }, [builderID]);

  const formatPrice = (price) => {
    const priceInLakhs = price / 100000;
    if (priceInLakhs < 100) {
      return `${priceInLakhs.toFixed(2)} L`;
    } else {
      const priceInCrores = priceInLakhs / 100;

      return `${priceInCrores.toFixed(2)} CR`;
    }
  };

  return (
    <Container className='mt-5'>
      <Box
        className='mt-2'
        display='flex'
        justifyContent='center'
        flexWrap='wrap'
      >
        {loading ? (
          <Box textAlign='center'>
            <CircularProgress />
          </Box>
        ) : data?.length > 0 ? (
          <Grid container spacing={3}>
            {data.map((itm) => (
              <Grid item md={6} key={itm?._id}>
                <Card
                  className='mb-4 shadow-sm rounded-4'
                  style={{ width: '100%' }}
                >
                  <CardMedia
                    component='img'
                    image={itm?.images[0]}
                    alt={
                      itm?.propertyId === null
                        ? 'No Image'
                        : itm?.propertyId?.name
                    }
                    style={{ height: '14rem' }}
                  />
                  <CardContent>
                    <Typography
                      variant='h5'
                      component='h4'
                      style={{
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: '1.4em',
                        textDecoration: 'none',
                      }}
                    >
                      {' '}
                      {/* <Link
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "1.4em",
                textDecoration: "none",
              }}
              // to="/builder/home-dashboard/description"
            > */}
                      {itm?.name}
                      {/* </Link> */}
                    </Typography>
                    <Typography
                      variant='h5'
                      component='div'
                      style={{
                        fontWeight: 'bold',
                        textDecoration: 'none',
                      }}
                    >
                      {itm?.propertyId === null
                        ? 'No data Name'
                        : itm?.propertyId?.name}
                    </Typography>
                    <Typography
                      variant='h5'
                      component='div'
                      style={{
                        fontWeight: 'bold',
                        textDecoration: 'none',
                      }}
                    >
                      {itm?.propertyId === null
                        ? 'No data Name'
                        : itm?.propertyId?.name}
                    </Typography>
                    <Typography
                      variant='body2'
                      color='text.secondary'
                    >
                      {itm?.propertyId === null
                        ? 'No data'
                        : itm?.location}
                    </Typography>
                    <Grid
                      container
                      spacing={1}
                      className='mt-2 mb-2'
                    >
                      {itm.unitTypeNew.map((unitType, index) => (
                        <Grid item xs={3} key={index}>
                          <img
                            src='/assets/build.svg'
                            alt='build'
                            style={{ width: '1.5rem' }}
                          />
                          <Typography
                            variant='body2'
                            color='text.secondary'
                            mt={2}
                          >
                            {unitType}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                    <Typography
                      variant='h6'
                      className='mt-2 mb-2'
                      fontWeight={'bold'}
                    >
                      ₹ {formatPrice(itm?.minPrice)} - ₹{' '}
                      {formatPrice(itm?.maxPrice)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box textAlign='center'>
            <Typography variant='h5' color='primary'>
              No data available...
            </Typography>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default PastPropertyList;
