import { grey } from '@mui/material/colors';

export const otpStyle = {
  div: { justifyContent: 'space-between' },
  input: {
    border: `1px solid ${grey[300]}`,
    minHeight: 40,
    fontSize: 16,
    flexGrow: 1,
    '&+input': { ml: 2 },
    '-moz-appearance': 'textfield',
    appearance: 'textfield',
  },
  '& input::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
    display: 'none',
  },
  '& input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
    display: 'none',
  },
};
