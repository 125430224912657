import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const AllLocationModal = ({
  show,
  onHide,
  allLocationData,
  onLocationSubmit,
}) => {
  const [selectedLocations, setSelectedLocations] = useState(allLocationData);

  const handleLocationDelete = (index) => {
    const updatedLocations = [...selectedLocations];
    updatedLocations.splice(index, 1);
    setSelectedLocations(updatedLocations);
  };

  const handleLocationChange = (event, newValue) => {
    setSelectedLocations(newValue);
  };

  const handleLocationSubmit = () => {
    onLocationSubmit(selectedLocations);
    onHide();
  };

  return (
    <div style={{ display: show ? 'block' : 'none' }}>
      <h3>Select Locations</h3>
      <Autocomplete
        multiple
        id='tags-filled'
        options={allLocationData}
        value={selectedLocations}
        onChange={handleLocationChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Locations'
            placeholder='Select Locations'
          />
        )}
      />
      <Button variant='contained' onClick={handleLocationSubmit}>
        Submit
      </Button>
      <div>
        {selectedLocations.map((location, index) => (
          <div key={index}>
            <span>{location}</span>
            <Button
              variant='contained'
              onClick={() => handleLocationDelete(index)}
            >
              Delete
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllLocationModal;
