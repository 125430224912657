import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const CopyrightText = ({ broker }) => {
  return (
    <div className="bg-color-primary-darker py-3">
      <Container>
        <Row
          className={`justify-content-center ${
            broker && `justify-content-between`
          }`}
        >
          <Col xs="auto" className="login__footer__copyright">
          <span className='text-center'>
              {' '}
              © {new Date().getFullYear()}
              <span className='text-white fw-bold'>
                &nbsp;Nesto Hub&nbsp;
              </span>{' '}
              | All Rights Reserved | Design & Developed by
              <a
                href='https://quantmhill.com'
                target={'_blank'}
                className='text-white fw-bold'
              >
                {' '}
                &nbsp;Quantmhill{' '}
              </a>
            </span>
          </Col>

          {broker && (
            <Col className="flex-grow-1 text-end">
              <Link className="fw-light text-white text-decoration-none" to="/">
                Support
              </Link>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default CopyrightText;
