import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import { useParams } from 'react-router-dom';
import {
  Card,
  Col,
  Container,
  Image,
  Row,
  Stack,
  Table,
} from 'react-bootstrap';
import {
  BookAVisitModal,
  Footer,
  Header,
  Ribbon,
  SmallCardSection,
} from '../../../features';

import ViewDetailsCard from './ViewDetailsCard';
import { SwiperSlider } from '../../../components';
import CurrentlyComparing from './currently-comparing/CurrentlyComparing';
import RatingReviewCard from '../../../components/rating-review/RatingReviewCard';
import {
  useGetAllCustomer,
  useGetPropertyById,
} from '../../../hooks/LoginQuery';
import LoadingSpinner from '../../../Common/loading-spinner/LoadingSpinner';
import ErrorMessage from '../../../Common/error-message/ErrorMessage';
import PropertyDetailsCard from './PropertyDetailsCard';
import SearchFilterBox from '../../../../components/search-filter/SearchFilter';

import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/navigation';
import './PropertyDescription.css';
import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Rating,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { CustomBtn } from '../../../components/mui/CustomBtn';
import PriceChart from '../../../features/Dashboard/components/charts/PriceCharts';
import HTMLOutput from '../../../features/Dashboard/components/HtmlOutput';
import formatNumber from '../../../helper/common';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import { useDispatch } from 'react-redux';
import HeaderCategory from '../../../features/Dashboard/components/properties/HeaderCategory';
import ShareBrochureModal from '../../../features/Dashboard/components/share-brochure-modal/ShareBrochureModal';
import { MdShare } from 'react-icons/md';

const PropertyDescription = () => {
  const [selectedImage, setSelectedImage] = useState('');
  const [isSectionClicked, setIsSectionClicked] = useState(false);
  const [showModal, setShowModal] = useState(false); // state for controlling modal visibility
  const [allCustomerGet, setAllCustomerGet] = useState([]);
  const brokerID = localStorage.getItem('brokerID');
  const [isOpen, setIsOpen] = useState(true);
  const [showBrochureModal, setShowBrochureModal] = useState(false);

  const dispatch = useDispatch();
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const onHideBrochureModal = () => {
    setShowBrochureModal(false);
  };

  const { id } = useParams();

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleSectionClick = () => {
    setIsSectionClicked(!isSectionClicked);
  };

  const all_customers = useGetAllCustomer(brokerID);
  const customers = all_customers.getAllCustomerResponse;

  const {
    getPropertyByIdIsLoading,
    getPropertyByIdIsError,
    getPropertyByIdResponse,
    getPropertyByIdError,
    getPropertyByIdIsSuccess,
  } = useGetPropertyById(id);

  if (!getPropertyByIdIsSuccess || !getPropertyByIdResponse) {
    return <div>Loading...</div>;
  }

  if (getPropertyByIdIsLoading) {
    return <LoadingSpinner />;
  }

  if (getPropertyByIdIsError) {
    return <ErrorMessage />;
  }

  if (getPropertyByIdIsSuccess) {
    const [property] = getPropertyByIdResponse;
    const {
      propertyLiner,
      propertyLogo,
      thumbnail,
      images,
      brokerageType,
      brokerageValue,
      isRera,
      amenities,
      isBestSelling,
      propertyDescription,
      floorPlanAndPricing,
      locationAdvantages,
      propertyAdvertiseMentDetails,
      loanApprovedByIds,
      paymentPlan,
      termAndCondition,
      locationAdvantagesId,
      propertyType,
      latitude,
      longitude,
      brochureUrl,
      builderDiscount,
      milestonesTermsConditions,
      constructionStatus,
      name,
      location,
      amenitiesId,
      currentlyComparing,
      possessionDate,
      builderId: { description: aboutTheBuilder, rating },
      ...propertyDetailsCard
    } = property;

    const {
      builderId: { _id: builderId },
    } = property;

    // Extracting the unique unitTypes from floors information
    const uniqueUnitTypesMap = floorPlanAndPricing.reduce((acc, item) => {
      acc[item.unitType] = true;
      return acc;
    }, {});

    const uniqueUnitTypes = Object.keys(uniqueUnitTypesMap);

    const prices = floorPlanAndPricing.map((item) => item.price);
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);

    const defaultProps = {
      center: {
        lat: 10.99835602,
        lng: 77.01502627,
      },
      zoom: 11,
    };

   

    return (
      
      <>
        <Header isOpen={isOpen} setIsOpen={setIsOpen}/>
      <HeaderCategory/>
      {/* <SmallCardSection /> */}
        <SearchFilterBox addBtn  
         maximumPrice={maxPrice}
         minimumPrice={minPrice}
        proprtyName={name}
                    BuilderId={builderId}
                    propertyId={id}/>
        {getPropertyByIdIsSuccess && (
          <section className='broker__property__details'>
            <Container>
              <section>
                <h1 className='broker__section__title'>Property Details</h1>
                <div
                  className='hero-section nes__hero d-flex flex-column   '
                  style={{
                    backgroundImage: `url(${selectedImage || images[0]})`,
                    border: '10px solid white',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <div className='hero-caption '>
                    {/* {isRera && <Ribbon cssClass='ribbonStyle2' text='RERA' />} */}
                    {isBestSelling && (
                      <Ribbon cssClass='ribbonStyle' text='Best Seller' />
                    )}
                    {brokerageType && brokerageValue && isOpen && (
                      <Ribbon
                        cssClass='ribbonStyle2 rounded-pill  py-1 px-3'
                        text={`${brokerageValue}${
                          brokerageType === 'Percentage' ? '%' : ''
                        } Brokerage`}
                      />
                    )}
                  </div>
                </div>
                <div
                  className='hero-caption-img-navigation'
                  style={{
                    position: 'absolute',
                    bottom: '10',
                  }}
                >
                  {!!images.length &&
                    images.map((img, index) => {
                      if (index <= 1) {
                        return (
                          <Image
                            key={index}
                            src={img}
                            className='ms-3'
                            width={'66.92px'}
                            height={'66.92px'}
                            onClick={() => handleImageClick(img)}
                            style={{
                              border: '4px solid white',
                              borderRadius: '20.08px',
                            }}
                          />
                        );
                      } else if (index === 2 && images.length > 2) {
                        return (
                          <div
                            key={index}
                            className=' d-inline-block rounded ms-3'
                            onClick={() => setIsSectionClicked(true)}
                            style={{
                              position: 'relative',
                            }}
                          >
                            <Image
                              src={img}
                              width={70}
                              height={70}
                              onClick={() => handleImageClick(img)}
                              style={{
                                border: '4px solid white',
                                borderRadius: '20.08px',
                              }}
                            />
                            {!isSectionClicked && (
                              <div className='position-absolute top-0 left-0 w-100 h-100 rounded bg-dark opacity-75 text-white d-flex justify-content-center align-items-center h3'>
                                <span className='px-2'>
                                  {images.length - 2}+
                                </span>
                              </div>
                            )}
                          </div>
                        );
                      } else if (index > 1 && isSectionClicked) {
                        return (
                          <Image
                            key={index}
                            src={img}
                            className='ms-3'
                            width={'66.92px'}
                            height={'66.92px'}
                            onClick={() => handleImageClick(img)}
                            style={{
                              border: '4px solid white',
                              borderRadius: '20.08px',
                            }}
                          />
                        );
                      }
                    })}

                  {isSectionClicked && (
                    <IconButton
                      sx={{
                        background: 'white',
                        left: '10px',
                      }}
                      onClick={() => setIsSectionClicked(false)}
                    >
                      <Close />
                    </IconButton>
                  )}
                </div>
              </section>
              {/* hero section details code end here */}
              {constructionStatus == 'Under Construction' ? (
                <div className='BrokerPDbanner-col_div-8 justify-content-start gap-2'>
                  <span className='BrokerPDbanner-col_div-8-span'>
                    {constructionStatus}
                  </span>
                  <span className='BrokerPDbanner-col_div-8-span'>
                    Possession date:{' '}
                    {new Date(possessionDate).toLocaleDateString()}
                  </span>
                  <Button
                  style={{marginLeft:'auto'}}
            onClick={() => setShowBrochureModal(true)}
            variant="transparent"
          >
            <MdShare size={30} />
          </Button>
                </div>
              ) : (
                <div className='BrokerPDbanner-col_div-8 justify-content-start gap-2'>
                  <span className='BrokerPDbanner-col_div-8-span'>
                    {constructionStatus}
                  </span>
                  <span className='BrokerPDbanner-col_div-8-span'>
                    Possession date:{' '}
                    {new Date(possessionDate).toLocaleDateString()}
                  </span>
                  <Button
                  style={{marginLeft:'auto'}}
            onClick={() => setShowBrochureModal(true)}
            variant="transparent"
          >
            <MdShare size={30} />
          </Button>
                </div>
              )}
              

              <Paper sx={{ width: { xs: '100%', md: '100%', lg: '1236.19px' }, Height: '235px', mt: 6 }}>
                <Box py={3} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems:'center', gap:3 }}>
                  <Box sx={{ flex: '1' }} xs={7}>
                    <Box sx={{ display: 'flex' }}>
                      <Box
                        sx={{
                          flex: '1',
                          display: 'flex',
                          justifyContent: 'center',
                          textAlign: 'center',
                        }}
                      >
                        <Avatar
                          sx={{
                            height: { xl: '100px', sm: '70px' },
                            width: { xl: '100px', sm: '70px' },
                          }}
                          src={propertyLogo}
                        ></Avatar>
                      </Box>
                      <Box sx={{ flex: '1' }}>
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: 'bold',
                              fontSize: { xl: '20px', sm: '10px', xs: '10px' },
                            }}
                          >
                            {name}
                          </Typography>{' '}
                        </Box>
                        {/* <Box>
                          {' '}
                          <Stack spacing={1}>
                            <Rating
                              name='half-rating'
                              defaultValue={2.5}
                              precision={0.5}
                            />
                          </Stack>
                        </Box> */}
                        <Box>
                          <Typography
                            mb={2}
                            sx={{
                              fontWeight: '400',
                              fontSize: '10px',
                              color: '#7D7F88',
                              fontFamily: 'Bahnschrift',
                            }}
                          >
                            {propertyLiner}
                          </Typography>
                        </Box>
                        <Box>
                          <Grid container spacing={1}>
                            {uniqueUnitTypes.map((unitType, index) => (
                              <Grid item xs={3} key={index}>
                                <img
                                  src={'/home.svg'}
                                  alt='home'
                                  width={'auto'}
                                  height={'29.94px'}
                                />
                                <Box>{unitType}</Box>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ flex: '1' }} xs={2}></Box>
                  <Box xs={3}>
                    <Box>
                      <Box
                        mr={4}
                        display={'flex'}
                        flexDirection={'column'}
                        sx={{ justifyContent: 'end' }}
                      >
                        <Stack flex={1}>
                          {' '}
                          <Typography
                            textAlign={'start'}
                            sx={{ fontSize: '20px', fontWeight: 'bold' }}
                          >
                            {`${formatNumber(minPrice)} - ${formatNumber(
                              maxPrice
                            )}`}
                          </Typography>
                        </Stack>
                        <Stack>
                          <Typography
                            mt={2}
                            mb={4}
                            sx={{ fontSize: '16px', color: '#7D7F88' }}
                          >
                            {builderDiscount}
                          </Typography>
                        </Stack>
                        <Stack>
                          {' '}
                          <CustomBtn
                            variant='outlined'
                            onClick={handleShowModal}
                          >
                            Book visit
                          </CustomBtn>
                        </Stack>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {showModal && (
                  <BookAVisitModal
                    show={showModal}
                    onHide={handleCloseModal}
                    floorType={floorPlanAndPricing}
                    proprtyName={name}
                    maximumPrice={maxPrice}
                    minimumPrice={minPrice}
                    BuilderId={builderId}
                    propertyId={id}
                    customers={customers}
                  />
                )}
              </Paper>

              <div>
                <SwiperSlider
                  floorPlansAndPricing={floorPlanAndPricing}
                  propertyType={propertyType}
                />
              </div>
              <div className='nes__dashboard__largeicons'>
                {/* amenities */}
                <Card className='border-0 shadow-none bg-transparent property__details__iconcard mb-4'>
                  <Card.Header className='bg-transparent border-0 mt-3 px-0'>
                    <Card.Title className='fw-bold'>
                      Project Amenities
                    </Card.Title>
                  </Card.Header>
                  <Card.Body className='border rounded d-flex flex-row gap-1 justify-content-center align-items-center'>
                    <Grid container spacing={2}>
                      {!!amenitiesId.length &&
                        amenitiesId.map(({ image, name, _id }) => (
                          <Grid item xl={2} lg={2} md={3} xs={6} sm={6}>
                            {' '}
                            <div className='d-flex flex-column gap-1 justify-content-center align-items-center m-0 p-0'>
                              <img
                                src={image}
                                alt={name}
                                className='img-fluid mb-2'
                                width={30}
                                height={30}
                                style={{
                                  opacity: 0.5,
                                  marginTop: '1.5rem',
                                }}
                              />
                              <span className='property__details__iconcard__iconsubtitle'>
                                {name}
                              </span>
                            </div>
                          </Grid>
                        ))}
                    </Grid>
                  </Card.Body>
                </Card>
                {/* amenities code end here */}
              </div>
              <div className='nes__dashboard__smallicons'>
                <Card className='border-0 shadow-none bg-transparent property__details__iconcard mb-4'>
                  <Card.Header className='bg-transparent border-0 mt-3 px-0'>
                    <Card.Title className='fw-bold'>
                      Location Advantages
                    </Card.Title>
                  </Card.Header>
                  <Card.Body className='border rounded'>
                    <Row className='g-4'>
                      {!!locationAdvantagesId?.length &&
                        locationAdvantagesId.map(
                          (
                            { image, name, distance, _id } // Iterate over locationAdvantagesId array
                          ) => (
                            <Col
                              style={{
                                flex: `0 0 calc(100% / ${locationAdvantagesId.length})`,
                              }} // Adjust column width dynamically
                              key={_id}
                              className='d-flex  g-1 justify-content-center align-items-center'
                            >
                              <div className='d-flex flex-column gap-1 justify-content-center align-items-center'>
                                <img
                                  src={image}
                                  alt={name} // Use location advantage name as alt text
                                  className='img-fluid mb-2 '
                                  width={30}
                                  height={20}
                                  style={{ marginTop: '2rem' }}
                                />
                                <span className='property__details__iconcard__icontitle'>
                                  {name}
                                </span>
                                <span className='property__details__iconcard__iconsubtitle'>
                                  {distance}m
                                </span>
                              </div>
                            </Col>
                          )
                        )}
                    </Row>
                  </Card.Body>
                </Card>
              </div>
              {/* map  */}
              <APIProvider apiKey={'AIzaSyDvoazybsmW4NL7sIPV2WG_NfV8LGuvKko'}>
                <Map
                  style={{ width: '100%', height: '500px' }}
                  defaultCenter={{
                    lat: parseFloat(latitude),
                    lng: parseFloat(longitude),
                  }}
                  defaultZoom={10}
                  gestureHandling={'none'}
                  disableDefaultUI={true}
                  zoomControl={false}
                >
                  <Marker
                    position={{
                      lat: parseFloat(latitude),
                      lng: parseFloat(longitude),
                    }} // Position of the marker
                    // Optionally, you can add other props like icon, label, etc.
                  />
                </Map>
              </APIProvider>

              {/* property advertisement details code end here */}

              {/*    Brokerage Payout Plan code start here */}

{isOpen && 
  <div className='nes__dashboard__smallicons'>
  <Card className='border-0 shadow-none bg-transparent property__details__iconcard mb-4'>
    <Card.Header className='bg-transparent border-0 mt-3 px-0'>
      <Card.Title className='fw-bold'>
        Brokerage Payout Plan
      </Card.Title>
    </Card.Header>
    <Card.Body className='border rounded'>
      {/* Check if brokerageType and brokerageValue are available */}
      {brokerageType && brokerageValue && (
        <div className='d-flex flex-column gap-1 justify-content-center align-items-start'>
          <span className='property__details__iconcard__icontitle text-aligen-start'>
            Brokerage:{' '}
            {brokerageType === 'Amount'
              ? `₹${brokerageValue}`
              : `${brokerageValue}%`}
          </span>

          <span className='property__details__iconcard__iconsubtitle'>
            Platform charges & applicable taxes shall be deducted
          </span>
        </div>
      )}
      <Row className='g-4'>
        <Col className='d-flex justify-content-center align-items-center'></Col>
      </Row>
    </Card.Body>
  </Card>
</div>
}

            

              {/*    Brokerage Payout Plan code end here */}

              {/*    Milestone  code start here */}

{isOpen && 
<>
              <Card className='border-0 shadow-none bg-transparent property__details__iconcard mb-4'>
                <Card.Header className='bg-transparent border-0 mt-3 px-0'>
                  <Card.Title className='fw-bold'>Milestone</Card.Title>
                </Card.Header>
              </Card>
              <Grid container spacing={2}>
                {milestonesTermsConditions &&
                  milestonesTermsConditions.map((milestone, index) => (
                    <Grid item xs={12} md={4} xl={4} key={index}>
                      <Card
                        className='border-0 shadow-none bg-transparent property__details__iconcard mb-4'
                        style={{ minHeight: '8rem' }}
                      >
                        <Card.Body className='border rounded'>
                          {/* Display milestone condition and brokerage */}
                          <div className='property__details__iconcard__icontitle text-aligen-start mb-2'>
                            Milestone
                          </div>
                          <div className='d-flex justify-content-between align-items-center property__details__iconcard__iconsubtitle'>
                            <span>Condition: </span>
                            <span
                              className='d-flex justify-content-start'
                              style={{
                                textAlign: 'start',

                                width: '25%',
                              }}
                            >
                              Brokerage:
                            </span>
                          </div>
                          <div className='d-flex justify-content-between align-items-center '>
                            <span
                              className='property__details__iconcard__icontitle text-aligen-start'
                              style={{ fontSize: '.8rem', width: '70%' }}
                            >
                              {milestone.condition}
                            </span>

                            <span
                              className='property__details__iconcard__icontitle  justify-content-start'
                              style={{
                                fontSize: '.8rem',
                                textAlign: 'start',

                                width: '25%',
                              }}
                            >
                              {milestone.brokerage}
                            </span>
                          </div>
                        </Card.Body>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
              </>}
              {/*    Milestone  code end here */}

              <ViewDetailsCard
                title={'About The Project'}
                propertyDescription={propertyDescription}
                link={'View Details'}
                Id={id}
                about={'about-project'}
                bg
              />
              {/* payment plan */}
              <Card
                className='border-0 shadow-none bg-transparent property__details__iconcard mb-4'
                style={{ minHeight: '8rem' }}
              >
                <Card.Header className='bg-transparent border-0 mt-3 px-0'>
                  <Card.Title className='fw-bold'>Payment Plan</Card.Title>
                </Card.Header>
                <Table bordered>
                  <thead>
                    <tr>
                      <th style={{ display: 'flex', justifyContent: 'center' }}>
                        Payment %
                      </th>

                      <th>Milestone</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentPlan.map(({ payment, milestone }, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            color: '#7D7F88',
                          }}
                        >
                          {payment.includes('%') ? payment : `${payment}%`}
                        </td>
                        <td
                          style={{
                            color: '#7D7F88',
                          }}
                        >
                          {milestone}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
              {/* payment plan code end here */}
              <div className='nes__dashboard__banks'>
                <ViewDetailsCard
                  title='Loan Approved By'
                  img
                  loanApprovedByIds={loanApprovedByIds}
                />
              </div>
              <ViewDetailsCard
                title={'About the Builder'}
                link='View Details'
                aboutTheBuilder={aboutTheBuilder}
                Id={builderId}
                about={'about-builder'}
                bg
              />
              <ViewDetailsCard
                title='View official brochure'
                media
                brochureUrl={brochureUrl}
                propertyType={propertyType}
                location={location}
                proprtyName={name}
              />
              {currentlyComparing?.length > 0 ? (
                <>
                  <Card
                    className={`border-0 shadow-sm property__details__text mb-5 bg-white`}
                    style={{ borderRadius: '17.69px' }}
                  >
                    <Card.Header className='bg-transparent border-0 mt-3 px-0'>
                      {' '}
                      <Card.Title
                        className='fw-bold '
                        style={{ fontSize: '20px', marginLeft: '2%' }}
                      >
                        Price Trend Analysis
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Typography sx={{ marginLeft: '1%', mb: 3 }}>
                        The graph shows the quarterly average rates of
                        properties
                      </Typography>
                      <PriceChart data={currentlyComparing} />
                    </Card.Body>
                  </Card>
                  <CurrentlyComparing data={currentlyComparing} />
                </>
              ) : (
                ''
              )}

              <div className='nes__dashboard__largeicons'>
                <Card className='border-0 shadow-none bg-transparent property__details__iconcard mb-4'>
                  <Card.Header className='bg-transparent border-0 mt-3 px-0'>
                    <Card.Title className='fw-bold'>
                      Terms & Conditions
                    </Card.Title>
                  </Card.Header>
                  <Card.Body className='border rounded d-flex flex-row gap-1 justify-content-start align-items-center'>
                    <HTMLOutput data={termAndCondition} />
                  </Card.Body>
                </Card>
                {/* amenities code end here */}
              </div>
            </Container>
          </section>
        )}
        <Footer />
        <ShareBrochureModal
        show={showBrochureModal}
        onHide={onHideBrochureModal}
        propertyType={property?.propertyType}
        brochureUrl={property?.brochureUrl}
        location={property?.location}
        proprtyName={property?.name}
        id={id}
      />
      </>
    );
  }
};

export default PropertyDescription;
