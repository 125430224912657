import { Box, Container, Grid } from '@mui/material';
import React from 'react';

export default function SuperConatiner({ children }) {
  return (
    <Box
      sx={{
        height: '100vh ',
        scrollSnapAlign: 'center',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        background:
          'url(/assets/informative/circle-1.png) 0 0 no-repeat,url(/assets/informative/circle-1.png) bottom right no-repeat',
        backgroundSize: '250px, 250px',
      }}
      flexGrow={1}
    >
      {children}
    </Box>
  );
}
