import {
  Container,
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import axios from 'axios';
import VisitDetailItem from './VisitDetailItem';
import PromotedVisitItem from './PromotedVisitItem';
import { getAPI } from '../../../Api/ApiRequest';
import { apiEndpoints } from '../../../Api/ApiEndpoint';
import { CustomBtn } from '../../../../broker/components/mui/CustomBtn';
import './AllVisite.css';
import {
  FormControl,
  InputLabel,
  Modal,
  Select,
  Stack,
  MenuItem,
  Box,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/material';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { FaStar } from 'react-icons/fa';
import CountUp from 'react-countup';
import { useParams } from 'react-router-dom';
const statusLabels = [
  'Pending',
  'Completed',
  'Bought',
  'Follow Up',
  'Negotiation',
];

const AllVisiteBuilder = () => {
  const [visitData, setVisitData] = useState({});

  const [loading, setLoading] = useState(false);

  const builderID = localStorage.getItem('builderId');

  useEffect(() => {
    const getVisit = async () => {
      setLoading(true);
      const response = await getAPI(
        apiEndpoints.getVisitAnalytics + builderID
      );
      console.log('visit info analytics', response.data);
      setVisitData(response.data);
      setLoading(false);
    };

    getVisit();
  }, []);

  console.log(visitData);

  const [data1, setData1] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getAPI(
        apiEndpoints.getAllVisitBuilder + builderID
      );
      console.log('API Response:', response.data);
      setData1(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [builderID]);

  const filters = [
    (itm) => itm.visitStatus === 'pending',
    (itm) => itm.visitStatus === 'completed',
    (itm) => itm.visitStatus === 'bought',
    (itm) => itm.visitStatus === 'followup',
    (itm) => itm.visitStatus === 'negotiation',
  ];
  const filteredLengths = filters.map(
    (filterFn) => data1.filter(filterFn).length
  );

  const tabelData = filterData.map((itm, index) => (
    <VisitDetailItem
      itm={itm}
      className={'mb-3 py-3 border rounded'}
    />
  ));

  return (
    <>
      {loading ? (
        <Box textAlign={'center'}>
          <CircularProgress />
        </Box>
      ) : data1.length > 0 ? (
        <>
          <Container className='dashboard__wrapper__filter border border-light rounded shadow-sm mt-4'>
            <Box p={4}>
              <Grid
                container
                spacing={2}
                justifyContent={{
                  md: 'center',
                  xs: 'start',
                }}
                alignItems={'center'}
              >
                {filteredLengths.map((length, index) => (
                  <Grid item key={index} md={2.4} xs={6}>
                    <Stack
                      textAlign={{
                        md: 'center',
                        xs: 'start',
                      }}
                    >
                      <Typography
                        variant='h5'
                        fontWeight={'bold'}
                        textAlign={'center'}
                      >
                        <CountUp
                          start={4}
                          end={length}
                          duration={2}
                          separator=','
                          delay={0}
                        ></CountUp>
                      </Typography>

                      <Typography
                        variant='p'
                        component={'h6'}
                        sx={{ opacity: 0.6 }}
                        textAlign={'center'}
                      >
                        {statusLabels[index]}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Container>
          <Box
            mt={4}
            p={2}
            sx={{
              width: '100%',
              overflow: { md: 'auto', xs: 'scroll' },
            }}
          >
            <TableContainer sx={{ padding: '2rem' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align='center'
                      width='10%'
                      sx={{
                        border: 'none',

                        fontWeight: 'bold',
                      }}
                    >
                      Visit ID
                    </TableCell>
                    <TableCell
                      align='center'
                      width='20%'
                      sx={{
                        borderBottom: 'none',

                        fontWeight: 'bold',
                      }}
                    >
                      Broker Name
                    </TableCell>
                    <TableCell
                      align='center'
                      width='15%'
                      sx={{
                        borderBottom: 'none',

                        fontWeight: 'bold',
                      }}
                    >
                      Visit Date
                    </TableCell>
                    <TableCell
                      align='center'
                      width='16%'
                      sx={{
                        borderBottom: 'none',

                        fontWeight: 'bold',
                      }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      align='center'
                      width='16%'
                      sx={{
                        borderBottom: 'none',

                        fontWeight: 'bold',
                      }}
                    >
                      Client Name
                    </TableCell>
                    {/* <TableCell
                  align='center'
                  width='20%'
                  sx={{
                    borderBottom: 'none',
                    width: '15%',
                    fontWeight: 'bold',
                  }}
                >
                  Ratings & Reviews
                </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data1?.map((row, index) => (
                    <>
                      {' '}
                      <Box mt={2}></Box>
                      <TableRow
                        key={index}
                        style={{
                          boxShadow:
                            '0px 1px 4px rgba(0, 0, 0, 0.1)',
                          borderRadius: '15px',
                          marginBottom: '2rem !important',
                          marginTop: '2rem !important',
                          width: '100%',
                          border: 'none !important',
                        }}
                      >
                        <TableCell align='center'>
                          {row?._id}
                        </TableCell>
                        <TableCell align='center'>
                          {row?.brokerId?.name}
                        </TableCell>
                        <TableCell align='center'>
                          {row?.date}
                        </TableCell>
                        <TableCell align='center'>
                          {row?.visitStatus}
                        </TableCell>
                        <TableCell align='center'>
                          {row?.customerId?.clientName}
                        </TableCell>
                        {/* <TableCell align='center'>
                      <div className='star-rating'>
                        {[...Array(5)].map((_, index) => (
                          <FaStar
                            key={index}
                            size={25}
                            color={
                              index < row.RatingsReviews ? '#ffc107' : '#e4e5e9'
                            }
                          />
                        ))}
                      </div>
                    </TableCell> */}
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box className='d-flex justify-content-center mt-2'>
            <CustomBtn
              variant='contained'
              width='60%'

              //   onClick={onContinueHandler}
            >
              Download Pdf
            </CustomBtn>
          </Box>
        </>
      ) : (
        <Typography
          mt={6}
          textAlign={'center'}
          color={'#1980c9'}
        >
          Currently, there are no properties available.
        </Typography>
      )}
    </>
  );
};

export default AllVisiteBuilder;
