import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardBody } from 'react-bootstrap';
import HTMLOutput from '../../../features/Dashboard/components/HtmlOutput';
import ShareBrochureModal from '../../../features/Dashboard/components/share-brochure-modal/ShareBrochureModal';

const ViewDetailsCard = ({
  title,
  link,
  bg,
  media,
  img,
  propertyDescription,
  loanApprovedByIds,
  aboutTheBuilder,
  termAndCondition,
  brochureUrl,
  Id,
  about,
  propertyType,
  location,
  proprtyName
}) => {
  
  const [showBrochureModal, setShowBrochureModal] = useState(false);

    const onHideBrochureModal = () => {
      setShowBrochureModal(false);
    };

  

  return (
    <>
    <Card
      className={`border-0 shadow-sm property__details__text mb-5 ${
        bg ? 'bg-white' : 'bg-transparent shadow-none'
      }`}
      style={{ borderRadius: '17.69px', minHeight: '300px', overflow: 'auto' }}
    >
      <Card.Header
        className={`d-flex justify-content-between align-items-center ${
          bg ? 'bg-white' : 'bg-transparent border-0 px-0'
        }`}
      >
        <h6
          className='card-title'
          style={{
            paddingLeft: '10px',
            fontFamily: 'Bahnschrift',
            fontWeight: '700',
          }}
        >
          {title}
        </h6>
        {link && (
          <Link
            to={`/broker/${about}/${Id}`} // Pass builderId in the URL
            style={{ color: 'red' }}
          >
            {link}
          </Link>
        )}
      </Card.Header>
      <Card.Body>
        {img && (
          <div className='row'>
            {loanApprovedByIds.map(({ _id, name, image }) => (
              <div
                key={_id}
                className='col-md-3 d-flex justify-content-center align-items-center'
              >
                <img
                  src={image}
                  className='img-fluid'
                  alt={name}
                  width='100'
                  height='auto'
                />
              </div>
            ))}
          </div>
        )}
        {!media && !img && propertyDescription && (
          <h5 style={{ fontFamily: 'Bahnschrift' }}>
            <HTMLOutput data={propertyDescription} />
          </h5>
        )}
        {!media && !img && aboutTheBuilder && (
          <p>
            <HTMLOutput data={aboutTheBuilder} />
          </p>
        )}
        {media && (
          <>
            <iframe
              src={brochureUrl}
              title='Brochure'
              width='100%'
              height='500px'
              frameBorder='0'
            />
            <button onClick={() => setShowBrochureModal(true)}>Download</button>
          </>
        )}
        {termAndCondition && (
          <p>
            <HTMLOutput data={termAndCondition} />
          </p>
        )}
      </Card.Body>
    </Card>
     <ShareBrochureModal
     show={showBrochureModal}
     onHide={onHideBrochureModal}
     propertyType={propertyType}
     brochureUrl={brochureUrl}
     location={location}
     proprtyName={proprtyName}    
   />
   </>
  );
};

export default ViewDetailsCard;
