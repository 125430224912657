import React from 'react';
import {
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { Notifications as NotificationsIcon } from '@mui/icons-material';
import ShadowCard from '../shadow-card/ShadowCard';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
const NotificationDropdown = ({ notification }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  console.log('rae data is ', notification);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const formatTime = (timestamp) => {
    return new Intl.DateTimeFormat('en-IN', {
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
    }).format(new Date(timestamp));
  };
  return (
    <>
      <IconButton
        aria-label='show notifications'
        color='inherit'
        onClick={handleClick}
        sx={{
          bgcolor: 'var(--primary-color)',
          width: '1.8rem',
          height: '1.8rem',
          color: 'white',
          '&:hover': {
            bgcolor: 'var(--primary-color)',
          },
        }}
      >
        <Badge variant='dot' color='error'>
          <NotificationsIcon fontSize='small' />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        PaperProps={{ sx: { width: '25rem' } }}
      >
        <MenuItem>
          {' '}
          <Typography
            variant='p'
            component={'h5'}
            fontWeight={600}
          >
            Notifications
          </Typography>{' '}
        </MenuItem>
        {notification && notification.length > 0 ? (
          notification.map((notif, index) => (
            <ShadowCard
              itemLength={8}
              title={notif.title}
              text={notif.description}
              timing={formatTime(notif.createdAt)}
            />
          ))
        ) : (
          <MenuItem disabled>No new notifications</MenuItem>
        )}
        <MenuItem>
          <Link
            to='/notifications'
            className='text-decoration-none'
          >
            View All
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
};

export default NotificationDropdown;
