import { useEffect, useRef } from 'react';
import InformativeNavbar from '../../Navbar/Navbar';
import GetStarted from '../getStarted/GetStarted';
import News from '../news/News';
import AboutUs from '../AboutUs/AboutUs';
import ChooseUs from '../chooseus/Chooseus';
import Testimonials from '../testimonials/Testimonials';
import Stats from '../stats/Stats';
import Banner from './Banner/Banner';
import OurPartner from '../OurPartner/OurPartner';
import HowWork from '../../AboutUs/HowWork/HowWork';
import HomeVideo from '../HomeVideo/HomeVideo';
import InformativeFooter from '../../InformativeFooter/InformativeFooter';
import './circle-animation/CircleAnimation.css';

import { circleScrollData } from '../../data/constant';

import { useState } from 'react';

import { Box, Container } from '@mui/material';

import SuperSection from './SuperBox/SuperSection';

import SuperImageBox from './SuperBox/SuperImageBox';

const HomeBanner = () => {
  const containerRef = useRef(null);

  const [sectionActive, setSection] = useState(1);

  const testData = [
    {
      personName: 'Sudhir Yadav',
      desc: 'NestoHub is a unique platform for builders and brokers. It makes property listing and selling super easy and convenient. I highly recommend NestoHub to my fellow builders.',
      role: '',
      personImg: '/500x/testimonials/user-1.webp',
    },
    {
      personName: 'Sachin Kumar',
      desc: 'I was a new broker in the market, and NestoHub was the perfect platform for me to start selling properties with higher earnings and the finest properties. They advised me on gaining profits and quick sales.',
      role: '',
      personImg: '/500x/profile.webp',
    },
    {
      personName: 'Vinit Sharma',
      desc: 'I really like working with NestoHub, as they are skilled professionals and reliable for selling my properties. This digital platform is a big yes for every builder and broker from my side.',
      role: '',
      personImg: '/500x/profile.webp',
    },
  ];

  const chooseData = [
    {
      img: './assets/informative/chooseUs/no-fee.svg',
      title: 'Zero Joining Fee',
      desc: 'NestoHub is a free and open platform for all brokers and builders. We imply no cost or limitations on our services to ensure complete benefits.',
    },
    {
      img: './assets/informative/chooseUs/handshake.svg',
      title: 'No Customer Poaching',
      desc: 'We guarantee that you will retain complete ownership of your client and that we will not reveal their identity or use their data to poach them.',
    },
    {
      img: './assets/informative/chooseUs/social-care.svg',
      title: 'Community Benefits',
      desc: 'NestoHub connects users with professional Nesto Advisors and other like-minded people to encourage one another through strategic guidance.',
    },
    {
      img: './assets/informative/chooseUs/bullhorn.svg',
      title: 'Fluent Loan Support',
      desc: 'Close the deals quickly and precisely with financial assistance from credible institutions on NestoHub, and make property buying simple.',
    },
    {
      img: './assets/informative/chooseUs/loan.svg',
      title: 'Digital Marketing',
      desc: 'Brokers can easily and efficiently promote themselves free of cost as trusted fellows to their clients by creating and promoting a professional digital profile on NestoHub. ',
    },
    {
      img: './assets/informative/chooseUs/loan.svg',
      title: 'Platform For New Brokers',
      desc: 'NestoHub offers numerous opportunities for new brokers to gain access and empowerment in order to launch successful businesses.',
    },
    {
      img: './assets/informative/chooseUs/loan.svg',
      title: 'Eliminate The Mediator',
      desc: 'NestoHub removes the intermediaries, such as dealers, from the builder-broker connection, leaving the brokerage entirely to the broker.',
    },
  ];

  const [activeImage, setActiveImage] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        const isVisible =
          rect.top <= window.innerHeight / 2 &&
          rect.bottom >= window.innerHeight / 2;
        if (isVisible) {
          setActiveImage(true);
        } else {
          setActiveImage(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activeImage]);

  return (
    <>
      <div id="app">
        <InformativeNavbar />
        <Box
          sx={{
            '& .text-slider': {
              position: 'absolute',
              left: '24%',
              top: ' -520px',
            },
          }}
        >
          <Banner />
        </Box>
        <Box sx={{ scrollBehavior: 'smooth' }}>
          <Box
            sx={{
              scrollSnapAlign: 'center',
              position: 'relative',
              zIndex: 9,
            }}
          >
            <AboutUs />
            <Stats />
          </Box>
        </Box>{' '}
      </div>
      <Box ref={containerRef}>
        <Container>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              flexDirection: { md: 'row', xs: 'column-reverse' },
            }}
          >
            <Box flexGrow={1}>
              {circleScrollData.map((item, index) => (
                <SuperSection
                  number={index + 1}
                  setSection={setSection}
                  key={item.id}
                  title={item.circleSectionHeading}
                  src={item.circleSectionImg}
                  desc={item.circleSectionText}
                />
              ))}
            </Box>
            <Box
              sx={{
                position: 'sticky',
                top: 0,
                height: '100vh',
                display: { md: 'block', xs: 'none' },
              }}
            >
              <SuperImageBox
                sectionActive={sectionActive}
                activeImage={activeImage}
                iconDataArray={[
                  circleScrollData[0].icon,
                  circleScrollData[1].icon,
                  circleScrollData[2].icon,
                  circleScrollData[3].icon,
                ]}
              />
            </Box>
          </Box>
        </Container>
      </Box>

      <Box
        sx={{
          scrollSnapAlign: 'center',
          position: 'relative',
        }}
      >
        <ChooseUs
          chooseData={chooseData}
          title="WHY CHOOSE US"
          subtitle="We Make Real Estate Simple & Efficient"
        />
        <HomeVideo />
        <HowWork />
        <OurPartner />
        <GetStarted />
        <News />
        <Testimonials
          testData={testData}
          title="Builders & Brokers On NestoHub"
          subtitle="Our Achievements In Your Words"
        />
        <InformativeFooter />
      </Box>
    </>
  );
};

export default HomeBanner;
