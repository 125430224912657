import './DashboardHeader.css';
import { Navbar, Container, Nav } from 'react-bootstrap';
import profile from '../../Images/Profile.png';
// import bgImage from "../../Images/bg-image.png";
import { Link } from 'react-router-dom';
// import { BsFillBellFill } from "react-icons/bs";
import { GiWallet } from 'react-icons/gi';

// import notification from "../../Images/notification.png";

import property from '../../Images/property.png';
import Brokerage from '../../Images/brokrage.png';
import home from '../../Images/Home.png';
import NotificationDropdown from '../Builderprofile/notification/NotificationDropdown';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { getAPI } from '../../Api/ApiRequest';
import { apiEndpoints } from '../../Api/ApiEndpoint';

const DashboardHeader = () => {
  const builderID = localStorage.getItem('builderId');
  console.log('dash brk id', builderID);
  const [profilePic, setProfilePic] = useState('');
  const [notificationdata, setNotificationData] = useState([]);

  const [loading, setLoading] = useState(false);

  const fetchBuilderInfo = async () => {
    setLoading(true);
    try {
      const response = await getAPI(
        `${apiEndpoints?.getBuilderById}${builderID}`
      );
      console.log(
        ' fetching builder info:',
        response.data[0].profilePictureUrl
      );
      setProfilePic(response?.data[0].profilePictureUrl);
    } catch (error) {
      console.error('Error fetching builder info:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBuilderInfo();
  }, []);

  useEffect(() => {
    const getNotification = async () => {
      const response = await getAPI(
        apiEndpoints.GET_ALL_NOTIFICATION + builderID
      );
      console.log('not data is', response.data);
      setNotificationData(response.data);
    };

    getNotification();
  }, []);
  return (
    <>
      <Navbar expand='lg' className='nes__navbar'>
        <Container>
          <Navbar.Brand
            href='/builder/home-dashboard'
            className='d-flex justify-content-center'
          >
            <img
              src='/assets/nesto-logo.png'
              alt='Logo'
              height='30'
            />
          </Navbar.Brand>
          <Box mr={3}>
            {' '}
            <Navbar.Toggle aria-controls='navbar-nav' />
          </Box>

          <Navbar.Collapse id='navbar-nav'>
            <Nav className='mx-auto'>
              <Nav.Item
                as={Link}
                to='/builder/home-dashboard'
                className='mx-3 d-flex align-items-center gap-2'
                style={{ textDecoration: 'none' }}
              >
                <img src={home} alt='home' />
                Home
              </Nav.Item>
              <Nav.Link
                as={Link}
                to='/builder/home-dashboard/property'
                className='mx-3 d-flex align-items-center gap-2'
                style={{ color: 'black' }}
              >
                <img src={property} alt='property' />
                Property
              </Nav.Link>
              <Nav.Item
                className='mx-3 d-flex align-items-center gap-2'
                as={Link}
                to={`/builder/home-dashboard/Brokerage/${builderID}`}
                style={{
                  textDecoration: 'none',
                  color: '#282431',
                }}
              >
                <img src={Brokerage} alt='Brokerage' />
                Brokerage
              </Nav.Item>
            </Nav>
            {/* <Nav className="mx-auto gap-2 "> */}
            {/* <Nav className="d-flex col-3 ms-auto align-items-center justify-content-end gap-2"> */}
            {/* <div className="d-flex col-3 ms-auto align-items-center justify-content-end"> */}
            {/* <Nav.Item> */}
            {/* <div className="nes__navbar__right__circle rounded-circle d-flex align-items-center justify-content-center"> */}
            {/* <GiWallet /> */}
            {/* </div> */}
            {/* </Nav.Item> */}
            {/* <Nav.Item className="nes__navbar__right__circle rounded-circle d-flex align-items-center justify-content-center"> */}
            {/* <div className="d-flex align-items-center position-relative"> */}
            {/* <div className="nes__navbar__right__circle rounded-circle d-flex align-items-center justify-content-center"> */}
            {/* <BsFillBellFill /> */}
            {/* <NotificationDropdown notification={3} /> */}
            {/* </div> */}
            {/* </div> */}
            {/* </Nav.Item> */}
            {/* </div> */}
            {/* </Nav> */}

            <div className='d-flex col-md-3 col-sm-12 ms-auto align-items-center justify-content-md-end order-md-last'>
              {/* <Nav.Item className='me-2 p-2'>
                <img
                  src='/assets/subscription.png'
                  alt='subscription'
                  className='subscription'
                />
              </Nav.Item> */}
              <Nav.Item className='me-2'>
                <NotificationDropdown
                  notification={notificationdata}
                />
              </Nav.Item>
              <Nav.Item
                className='me-3 mx-1 my-2'
                as={Link}
                to={`/builder/builder-profile/${builderID}`}
              >
                <img
                  src={
                    profilePic ||
                    '/assets/profile-page/profile-mg-big.png'
                  }
                  alt='profile'
                  className='br-profile'
                  style={{ borderRadius: '50%' }}
                />
              </Nav.Item>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default DashboardHeader;
