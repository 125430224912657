import React, { useEffect, useState } from 'react';
import DashboardTabs from '../../../components/dashboard-tabs/DashboardTabs';
import Header from '../../Common/Header/Header';
import { Avatar, Box, Container, Grid } from '@mui/material';
import { MdOutlineStarPurple500, MdShare } from 'react-icons/md';
import { BrokerView, Footer, Ribbon } from '../../features';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import { RiBuilding2Fill } from 'react-icons/ri';
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/navigation';
import '../../../broker/features/Dashboard/components/product-card/ProductCard.css';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  getBuilderDetailsById,
  getBuilderPropertiesById,
} from '../../../API/action';
import HTMLOutput from '../../features/Dashboard/components/HtmlOutput';
const AboutTheBuilder = () => {
  const [activeTab, setActiveTab] = useState('Description');
  const [builderData, setBuilderData] = useState();
  const [builderPropertiesData, setBuilderPropertiesData] = useState([]);
  const dispatch = useDispatch();

  const { builderId } = useParams();
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const builder = (builderId) => {
    // Fetch builder details using builderId
    dispatch(
      getBuilderDetailsById(
        builderId,
        (data) => {
          // Handle successful response
          dispatch({
            type: 'GET',
            payload: data.data.data,
          });
          setBuilderData(data.data.data[0]);
        },
        (error) => {
          // Handle error
          console.error('Error fetching all contact queries:', error);
        }
      )
    );
  };
  const buildersPropertie = (builderId) => {
    // Fetch builder details using builderId
    dispatch(
      getBuilderPropertiesById(
        builderId,
        (data) => {
          // Handle successful response
          dispatch({
            type: 'GET',
            payload: data.data.data,
          });
          setBuilderPropertiesData(data.data.data);
        },
        (error) => {
          // Handle error
          console.error('Error fetching all contact queries:', error);
        }
      )
    );
  };

  useEffect(() => {
    builder(builderId);
    buildersPropertie(builderId);
  }, [builderId]);

  return (
    <>
      <Header />
      <Container>
        <Box
          my={6}
          display='flex'
          alignItems='center'
          minHeight='80vh'
          flexDirection='column'
        >
          <Box width={{ xl: '50%', md: '100%' }}>
            <DashboardTabs
              tabsKey={['Description', 'Projects']}
              activeState={activeTab}
              onTabClick={handleTabClick}
              renderChildren={false}
            />
          </Box>
          <Box width='100%'>
            {activeTab === 'Description' && (
              <Box className='about-builder_box'>
                <Box className='d-flex align-items-center gap-3'>
                  <Box>
                    <img
                      src={builderData?.logoUrl}
                      alt='builder'
                      style={{ width: '110px', objectFit: 'cover' }}
                    />
                  </Box>
                  <Box>
                    <Box className=' primary-heading '>{builderData?.companyName}</Box>
                    <Box className=' primary-heading '>{ }</Box>

                    {/* {[...Array(rating)].map((_, index) => (
                      <MdOutlineStarPurple500
                        key={index}
                        className='about-builder_star'
                      />
                    ))} */}
                  </Box>
                </Box>
                <Box className='secondary-heading' mt={4} mb={-2}>
                  Discription
                </Box>
                <span className='about-builder_span-3'>
                  <HTMLOutput
                    data={builderData?.description}
                    className={'about-builder_span-3'}
                  />
                </span>
              </Box>
            )}

            {/* Content for Projects tab */}
            {activeTab === 'Projects' && (
              <Box style={{ color: 'black' }}>
                <Box className='d-flex align-items-center gap-3'>
                  <Box>
                    <img
                      src={builderData?.logoUrl}
                      alt='builder'
                      style={{ width: '110px', objectFit: 'cover' }}
                    />
                  </Box>
                  <Box>
                    <Box className=' primary-heading '>{builderData?.companyName}</Box>
                    <Box className=' primary-heading '>{ }</Box>

                    {/* {[...Array(rating)].map((_, index) => (
                      <MdOutlineStarPurple500
                        key={index}
                        className='about-builder_star'
                      />
                    ))} */}
                  </Box>
                </Box>
                {/* <Box className='secondary-heading2' mt={6} mb={2}>
                  Current Projects
                </Box> */}
                <Box mt={4}>
                  <Grid container spacing={4}>
                    {builderPropertiesData.map((property, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Box className='mt-0 mb-5 position-relative'>
                          <Card
                            bg='white'
                            border='0'
                            className='custom-card nes__product__card'
                            style={{
                              background: '#FFFFFF',
                              boxShadow:
                                '0px 3.71188px 16.2395px rgba(0, 0, 0, 0.07)',
                              borderRadius: '13.94px',
                            }}
                          >
                            <div className='position-relative'>
                              <Card.Img
                                variant='top'
                                src={JSON.parse(property?.imageUrl)[0]}
                                alt='Product Image'
                                className='nes__product__card__image'
                                style={{
                                  width: '100%',
                                  objectFit: 'cover',
                                  height: '12rem',
                                  minHeight: '12rem',
                                }}
                              />
                            </div>
                            <Card.Body className='px-0'>
                              <div className='px-4'>
                                <Card.Title className='nes__product__card__title'>
                                  {property?.propertyName}
                                </Card.Title>
                                <Card.Subtitle className='mt-1 mb-2 text-muted nes__product__card__subtitle'>
                                  {property?.location}
                                </Card.Subtitle>
                              </div>
                              <div className='px-4 nes__product__card__price d-flex flex-wrap align-items-center gap-2'>
                                <h4 className='d-inline-block font-weight-bold m-0'>
                                  ₹ {property?.price}
                                </h4>
                              </div>

                              <div className='d-inline-block text-muted m-0 px-4'></div>
                            </Card.Body>

                            {/* <Card.Footer className='d-flex justify-content-end border-0 bg-white pt-0'>
                              <Button className='btn-color-outline-primary rounded-pill px-4'>
                                Book a visit
                              </Button>
                              <Button variant='transparent'>
                                <MdShare size={30} color='#278FD9' />
                              </Button>
                            </Card.Footer> */}
                          </Card>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
                {/* <Box className='secondary-heading2' mt={6} mb={2}>
                  Past Projects
                </Box>
                <Box>
                  <Grid container spacing={4}>
                    {[...Array(3)].map((_, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Box
                          className='mt-0 mb-5 position-relative'
                          style={{
                            boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.1)',
                            borderRadius: '1.5rem',
                          }}
                        >
                          <Card
                            bg='white'
                            border='0'
                            className='custom-card nes__product__card'
                            style={{
                              background: '#FFFFFF',
                              boxShadow:
                                '0px 3.71188px 16.2395px rgba(0, 0, 0, 0.07)',
                              borderRadius: '13.94px',
                            }}
                          >
                            <div
                              className='position-relative'
                              style={{
                                width: '100%',

                                textAlign: 'center',
                              }}
                            >
                              <Card.Img
                                variant='top'
                                src={'/imgg.svg'}
                                alt='Product Image'
                                className='nes__product__card__image'
                                style={{
                                  width: '100%',

                                  objectFit: 'cover',
                                  height: '12rem',
                                }}
                              />
                            </div>
                            <Card.Body className='px-0'>
                              <div className='px-4'>
                                <Card.Title className='nes__product__card__title'>
                                  Sky Dandelions Apartment
                                </Card.Title>
                                <Card.Subtitle className='mt-1 mb-2 text-muted nes__product__card__subtitle'>
                                  Luxury Apartment in Sector-29,Gurugram
                                </Card.Subtitle>
                              </div>

                              <div className='px-4 nes__product__card__price d-flex flex-wrap align-items-center gap-2'>
                                <h4 className='d-inline-block font-weight-bold m-0'>
                                  ₹ 3.94 - 6.01 Cr
                                </h4>
                              </div>
                              <div className='d-inline-block text-muted m-0 px-4'>
                                builderDiscount
                              </div>
                            </Card.Body>
                            <Card.Footer className='d-flex justify-content-end border-0 bg-white pt-0'>
                              <Button className='btn-color-outline-primary rounded-pill px-4'>
                                Book a visit
                              </Button>
                              <Button variant='transparent'>
                                <MdShare size={30} color='#278FD9' />
                              </Button>
                            </Card.Footer>
                          </Card>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box> */}
              </Box>
            )}
          </Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default AboutTheBuilder;
