import React, { useEffect, useState } from "react";
import "./LatestPostLeft.css";
import { Link } from "react-router-dom";
import LatestPostPagination from "../LatestPostPagination/LatestPostPagination";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { getAPI } from "../../BlogListApi/ApiRequest";
import { apiEndpoints } from "../../BlogListApi/ApiEndpoint";
import { Fade } from "react-reveal";
import { CiCalendar } from "react-icons/ci";
// import { log } from "console";

const LatestPostLeft = () => {
  const [data, setData] = useState([]);
  const [showPerPage, setShowPerPage] = useState(6);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  useEffect(() => {
    const getPage = async () => {
      const response = await getAPI(apiEndpoints.getAllBlog);
      console.log(response.data);
      setData(response?.data);
    };
    getPage();
  }, []);

  console.log("blog list--->", data);

  return (
    <div className="latest-post-left" style={{ overflowX: "hidden" }}>
      <Fade left duration={1000} delay={100}>
        <div>
          <span className="latest-left_heading">Latest Post</span>
          <span className="latest-left_para">Don't miss the trending news</span>
        </div>
      </Fade>
      <div className="row mt-3">
        {data?.slice(pagination.start, pagination.end).map((item, index) => {
          return (
            <div className="col-lg-4 col-md-6 text-left" key={index}>
                    <div className="news-box" style={{ minHeight: '100%' }}>
                      <Link to={`/blog-detail/${item.slug}`}>
                        <img src={item.thumbnailImage} className="news-image" alt="news" />
                      </Link>
                      <div className="news-box-2">
                        <div className="news-date align-items-end">
                          <span>
                            <CiCalendar className="date-icon" />
                          </span>
                          <span className="news-date-span"> {item.blogDate}</span>
                        </div>
                        <div className="">
                          <Link
                            to={`/blog-detail/${item.slug}`}
                            className="news-heading text-line-1"
                          >
                            {item.blogName}
                          </Link>
                        </div>
                        <div>
                          <p className="news-desc text-line-2" dangerouslySetInnerHTML={{__html: item.description}}></p>
                        </div>
                        <Link to={`/blog-detail/${item.slug}`} className="primary_button">
                          Read More
                          <MdKeyboardDoubleArrowRight
                            size={20}
                            className="move-arrow"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
          );
        })}
        <LatestPostPagination
          showPerPage={showPerPage}
          onPaginationChange={onPaginationChange}
          total={data?.length}
        />
      </div>
    </div >
  );
};

export default LatestPostLeft;
