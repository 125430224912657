import React, { useEffect, useState } from "react";
import Footer from "../../../components/footer/Footer";
import "./Tips.css";
import { Link, useLocation } from "react-router-dom";
import { BsFacebook, BsInstagram, BsTwitter, BsLinkedin } from "react-icons/bs";
import InformativeFooter from "../../InformativeFooter/InformativeFooter";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { Fade, Zoom } from "react-reveal";
import axios from "axios";
import { apiEndpoints } from "../../BlogList/BlogListApi/ApiEndpoint";
import { getAPI } from "../../BlogList/BlogListApi/ApiRequest";
import { useParams } from "react-router-dom";



const Tips = () => {
  const { state } = useLocation();
  const params = useParams();
  const [blogDetails, setBlogsDetails] = useState([]);

  // useEffect(() => {
  //   fetchBlogDetails();
  // }, [])

  const liData = [
    {
      para: "Connect with a wide range of brokers.",
    },
    {
      para: "Sell their inventory faster.",
    },
    {
      para: "Build their professional profile",
    },
    {
      para: "Market their properties and themselves digitally.",
    },
    {
      para: "Manage multiple brokers easily.",
    },
    {
      para: "Keep a record of customer visits to the properties",
    },
    {
      para: "Utilize their remaining time to increase their efficiency.",
    }
  ];
  const data = [
    {
      date: "Sept 05, 2022",
      para1: "Tips to buy your dream home in minutes",
      para2: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
      userImg: "/assets/informative/articleImg4.png",
      name: "Sarah Harding",
    },
    {
      date: "Sept 05, 2022",
      para1: "Tips to buy your dream home in minutes",
      para2:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
      userImg: "/assets/informative/articleImg4.png",
      name: "Sarah Harding",
    },
    {
      date: "Sept 05, 2022",
      para1: "Tips to buy your dream home in minutes",
      para2:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
      userImg: "/assets/informative/articleImg4.png",
      name: "Sarah Harding",
    },
  ];
  const liDataTwo = [
    {
      para: "Earn higher brokerage rates.",
    },
    {
      para: "Connect with renowned builders.",
    },
    {
      para: "Build a network and community with other brokers.",
    },
    {
      para: "Create their professional digital profile.",
    },
    {
      para: "Digitally manage their property visits.",
    },
    {
      para: "Keep digital records through a user-friendly dashboard.",
    },
    {
      para: "Get their client's financial assistance.",
    },
    {
      para: "Utilize their remaining time to grab more customers and close many deals together.",
    }
  ];

  // const fetchBlogDetails = async () => {
  //   await getAPI`$(apiEndpoints.getBlogByID)$()`
  //     .then(function (res) {
  //       setBlogsDetails(res?.data)
  //     })
  //     .catch(function (error) {
  //       // console.log(error);
  //     })
  // }

  useEffect(() => {
    const getBlogByID = async () => {
      const response = await getAPI(`${apiEndpoints.getBlogBySlug}?slug=${params.slug}`
      );
      setBlogsDetails(response?.data?.[0]);
    };
    getBlogByID();
  }, []);

  return (
    <>
      <Fade up duration={1000} delay={100}>
        <div
          className="mb-5 container tips-container"
          style={{ overflowX: "hidden" }}>
          <div className="tips-header">
            <div className="tips-header_box text-center">
              <span className="tips-header_heading">
                {blogDetails?.blogName}
              </span>
              <div className="tips_box-1">
                <div>
                  <img
                    className="tips_user-img"
                    src="/assets/profle.png"
                    alt="article"
                  />
                  <span className="tips_user-name"> {blogDetails?.postedByName}</span>
                </div>
                <div>
                  <span className="tips_user-date"> {blogDetails?.blogDate}</span>
                </div>
              </div>
            </div>
            <div className="tips_box-2">
              <div className="tips_box-3">
                <span className="tips_box-2-span">
                </span>
              </div>
              {/* <Zoom duration={1000} delay={100}>
                <div className="tips_box-4">
                  <img
                    className="tips_box-4-img"
                    src="/assets/informative/tipsImg.png"
                    alt="tips"
                  />
                </div>
              </Zoom> */}
              {/* <div className="tips_box-3">
                <span className="tips_box-2-span">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
                  ornare pellentesque sollicitudin. Suspendisse potenti. Fusce ex
                  risus, iaculis sit amet apien nec, finibus malesuada mi. Proin
                  at turpis eget sapien pulvinar luctus. estibulum bibendum
                  pharetra lorem eu aliquam. In feugiat placerat risus, ed rutrum
                  neque mattis sit amet. Nullam vestibulum ante ac quam tempor, d
                  venenatis velit eleifend. Duis id iaculis risus, quis
                  ullamcorper augue. Nunc ristique.
                </span>
              </div> */}
            </div>
            {/* <Fade left duration={1000} delay={100}>
              <div className="tips_box-5">
                <div>
                  <span className="tips_box-5-heading">Can a new broker be entrusted with a big builder?</span>
                </div>
                {liData.map((item) => {
                  return (
                    <>
                      <div className="d-flex gap-3 align-items-center mt-3">
                        <img
                          src="/assets/informative/line.png"
                          className="tips_box-5-line"
                          alt="line"
                        />
                        <li className="tips_box-5-li">
                          {item.para}
                        </li>
                      </div >
                    </>
                  );
                })}
              </div>
            </Fade> */}
            {/* <Fade left duration={1000} delay={100}>
              <div className="tips_box-3 mt-5">
                <span className="tips_box-2-span">
                  The larger the builder, the greater the risk of fraud on their property; thus, it is extremely difficult for builders to disclose their network to many random brokers. Otherwise, it may receive negative publicity and scare people about the scams on their property names, causing property prices to fall.
                </span>
                <span className="tips_box-2-span mt-2" style={{ display: 'block' }}>
                  As a result, builders require assurance that the brokers selling their inventory are trustworthy and will not commit fraud in the builder's name.
                </span>
              </div>
            </Fade> */}

            <Fade left duration={1000} delay={100}>
              <div className="tips_box-5" dangerouslySetInnerHTML={{ __html: blogDetails?.description }}>
              </div>
            </Fade>

            {/* <Fade left duration={1000} delay={100}>
              <div className="tips_box-6">
                <span className="tips_box-6-span">
                  Was this information helpful?
                </span>
                <div className="mt-3">
                  <button className="tips_box-6-btn">YES</button>
                  <button className="tips_box-6-btn">NO</button>
                </div>
              </div>
            </Fade> */}
          </div>
          {/* Share Box */}
          <Fade right duration={1000} delay={100}>
            <div className="share-box d-flex justify-content-between align-items-center">
              <div></div>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <span className="share-box_span1">Share :</span>
                </div>
                <div className="d-flex gap-3">
                  <a href="https://www.facebook.com/">
                    <span className="share-box_span2">
                      <BsFacebook />
                    </span>
                  </a>
                  <a href="https://www.instagram.com/">
                    <span className="share-box_span2">
                      <BsInstagram />
                    </span>
                  </a>
                  <a href="https://twitter.com/home?lang=en">
                    <span className="share-box_span2">
                      <BsTwitter />
                    </span>
                  </a>
                  <a href="https://www.linkedin.com/home?originalSubdomain=in">
                    <span className="share-box_span2">
                      <BsLinkedin />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Fade >
      <InformativeFooter />
    </>
  );
};

export default Tips;
