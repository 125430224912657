import React, { useState } from 'react';
import DashboardTabs from '../../../components/dashboard-tabs/DashboardTabs';
import Header from '../../Common/Header/Header';
import { Box, Container, Grid } from '@mui/material';
import { MdOutlineStarPurple500, MdShare } from 'react-icons/md';
import { BrokerView, Footer, Ribbon } from '../../features';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import { RiBuilding2Fill } from 'react-icons/ri';
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/navigation';
import '../../../broker/features/Dashboard/components/product-card/ProductCard.css';
import { Link, useParams } from 'react-router-dom';
import { useGetPropertyById } from '../../hooks/LoginQuery';
import PropertyDescription from '../dashboard/property-details/PropertyDescription';
import HTMLOutput from '../../features/Dashboard/components/HtmlOutput';
import './AboutTheProject.css';
const AboutTheProject = () => {
  const [activeTab, setActiveTab] = useState('Description');
  const { projectId } = useParams();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const {
    getPropertyByIdIsLoading,
    getPropertyByIdIsError,
    getPropertyByIdResponse,
    getPropertyByIdError,
    getPropertyByIdIsSuccess,
  } = useGetPropertyById(projectId);

  if (getPropertyByIdIsSuccess) {
    const [property] = getPropertyByIdResponse;
    const {
      propertyLogo,
      propertyDescription,
      floorPlanAndPricing,
      name,
      projectSpecification,
      location,
    } = property;


    const uniqueUnitTypesMap = floorPlanAndPricing.reduce((acc, item) => {
      acc[item.unitType] = true;
      return acc;
    }, {});

    const uniqueUnitTypes = Object.keys(uniqueUnitTypesMap);

    function extractUniqueSpecifications(floorPlanAndPricing) {
      const specificationsArray = [];

      floorPlanAndPricing.forEach((property) => {
        const { specifications } = property;

        // Loop through each specification and add to the array if not already present
        specifications.forEach((spec) => {
          if (!specificationsArray.includes(spec)) {
            specificationsArray.push(spec);
          }
        });
      });

      // Return an object with a 'specifications' property
      return { specifications: specificationsArray };
    }

    const floorPlan = extractUniqueSpecifications(floorPlanAndPricing);

    // Static propertyType value for testing
    const propertyType = { name: 'Apartment' };

    return (
      <>
        <Header />
        <Container>
          <Box
            my={6}
            display='flex'
            alignItems='center'
            minHeight='80vh'
            flexDirection='column'
          >
            <Box width={{ xl: '50%', md: '100%' }}>
              <DashboardTabs
                tabsKey={['Description', 'Specifications']}
                activeState={activeTab}
                onTabClick={handleTabClick}
                renderChildren={false}
              />
            </Box>
            <Box width='100%'>
              {activeTab === 'Description' && (
                <Box className='about-builder_box'>
                  {/* <Box className='d-flex align-items-center gap-3'>
                  <Box>
                    <img src='/assets/informative/builder1.png' alt='builder' />
                  </Box>
                  <Box>
                    <Box className=' primary-heading '>{name}</Box>
                  </Box>
                </Box>
                <Box className='secondary-heading' mt={4} mb={-2}>
                  Description
                </Box> */}
                  <span className='about-builder_span-3'>
                    <h5 style={{ fontFamily: 'Bahnschrift' }}>
                      <HTMLOutput
                        data={propertyDescription}
                        className={'about-project'}
                      />
                    </h5>
                  </span>
                </Box>
              )}

              {/* Content for Specifications tab */}
              {activeTab === 'Specifications' && (
                <Box style={{ color: 'black' }}>
                  <Box textAlign={'left'} dangerouslySetInnerHTML={{ __html: projectSpecification }} />
                </Box>
              )}
            </Box>
          </Box>
        </Container>
        <Footer />
      </>
    );
  }
};

export default AboutTheProject;
