import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { apiEndpoints } from '../../Api/ApiEndpoint';
import { getAPI } from '../../Api/ApiRequest';
import './PropertyAnalytics.css';
import LoadingSpinner from '../../../broker/Common/loading-spinner/LoadingSpinner';
import { Box, Typography } from '@mui/material';

const PropertyInvoice = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInvoice = async () => {
      setLoading(true);
      const response = await getAPI(apiEndpoints?.getPendingInvoice);
      console.log('data is', response?.data);
      setData(response?.data);
      setLoading(false);
    };

    getInvoice();
  }, []);

  return (
    <Container Container>
      <div className='row justify-content-between'>
        <h3 className='col-8 heading'>Pending Invoices</h3>
      </div>

      <Box
        className='dashboard__wrapper__filter border border-light rounded shadow-sm '
        style={{ boxShadow: '0px 10.7036px 46.8282px rgba(0, 0, 0, 0.07)' }}
      >
        {loading ? (
          LoadingSpinner
        ) : (
          <>
            {data ? (
              <Row className='p-2 align-items-center text-center'>
                <Col md={3} sm={3} className='d-grid my-2'>
                  <span
                    style={{
                      color: '#278FD9',
                      fontWeight: 'bold',
                      fontSize: '1.4em',
                    }}
                  >
                    {data?.pendingInvoice}
                  </span>
                  <span style={{ opacity: 0.5, fontFamily: 'Bahnschrift' }}>
                    Pending <br></br> Invoice
                  </span>
                </Col>
                <Col md={3} sm={3} className='d-grid my-2'>
                  <span
                    style={{
                      color: '#278FD9',
                      fontWeight: 'bold',
                      fontSize: '1.4em',
                    }}
                  >
                    ₹ {data?.totalAmount}
                  </span>
                  <span style={{ opacity: 0.5, fontFamily: 'Bahnschrift' }}>
                    Pending <br></br> Amount
                  </span>
                </Col>
                <Col md={3} sm={3} className='d-grid my-2'>
                  <span
                    style={{
                      color: '#278FD9',
                      fontWeight: 'bold',
                      fontSize: '1.4em',
                    }}
                  >
                    {data?.pendingDays} Days
                  </span>
                  <span style={{ opacity: 0.5, fontFamily: 'Bahnschrift' }}>
                    Pending <br></br> Since
                  </span>
                </Col>
                <Col md={3} sm={3} className='d-grid my-2'>
                  <span
                    style={{
                      color: '#278FD9',
                      fontWeight: 'bold',
                      fontSize: '1.4em',
                    }}
                  >
                    {data?.averagePayoutTime} Days
                  </span>
                  <span style={{ opacity: 0.5, fontFamily: 'Bahnschrift' }}>
                    Average <br></br> Payout Time
                  </span>
                </Col>
              </Row>
            ) : (
              <Typography
                my={5}
                textAlign={'center'}
                color={'var(--primary-color-darker)'}
                fontWeight={'bold'}
              >
                No pending invoices available
              </Typography>
            )}
          </>
        )}

        {/* <div className="p-4 d-flex justify-content-around">
          <div className="text-center d-flex flex-column">
            <span
              style={{
                color: "#278FD9",
                fontWeight: "bold",
                fontSize: "1.4em",
              }}
            >
              10
            </span>
            <span style={{ opacity: 0.5, fontFamily: "Bahnschrift" }}>
              Pending <br></br> Invoice
            </span>
          </div>
          <div className="text-center d-flex flex-column">
            <span
              style={{
                color: "#278FD9",
                fontWeight: "bold",
                fontSize: "1.4em",
              }}
            >
              ₹ 99 L
            </span>
            <span style={{ opacity: 0.5, fontFamily: "Bahnschrift" }}>
              Pending <br></br> Amount
            </span>
          </div>
          <div className="text-center d-flex flex-column">
            <span
              style={{
                color: "#278FD9",
                fontWeight: "bold",
                fontSize: "1.4em",
              }}
            >
              10 Days
            </span>
            <span style={{ opacity: 0.5, fontFamily: "Bahnschrift" }}>
              Pending <br></br> Since
            </span>
          </div>
          <div className="text-center d-flex flex-column">
            <span
              style={{
                color: "#278FD9",
                fontWeight: "bold",
                fontSize: "1.4em",
              }}
            >
              10 Days
            </span>
            <span style={{ opacity: 0.5, fontFamily: "Bahnschrift" }}>
              Average <br></br> Payout Time
            </span>
          </div>
        </div> */}
      </Box>
    </Container>
  );
};

export default PropertyInvoice;
