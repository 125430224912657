import { useDispatch } from "react-redux";
import SearchFilterBox from "../../../../../components/search-filter/SearchFilter";
import Header from "../header/Header";
import { useEffect, useState } from "react";
import AddRequirementModal from "../requirement-modal/AddRequirementModal";
import { useParams } from "react-router-dom";
import { getAllVisits, getRequirementById } from "../../../../../API/action";
import VisitCards from "./VisitCards";
import BookAVisitModal from "../book-a-visit-modal/BookAVisitModal";
import BookAVisitFromRequirementModal from "../book-a-visit-requirment-modal/BookAVisitFromRequirementModal";

const RequirementVisitDetails = () => {
  const [showVisitModal, setShowVisitModal] = useState(false);
  const [requirement, setRequirement] = useState([]);
  const [visits, setVisits] = useState([]);
  const brokerId = localStorage.getItem("brokerID");
  const { id } = useParams();
  const dispatch = useDispatch();

  const getData = () => {
    let payload = {
      id: id,
    };
    dispatch(
      getRequirementById(
        payload,
        (data) => {
          console.log(data.data.data);
          setRequirement(data.data.data);
        },
        (error) => {
          console.error("Error fetching requirement:", error);
        }
      )
    );
  };

  const getAllData = () => {
    let payload = {
      requirementId: id,
    };
    dispatch(
      getAllVisits(
        payload,
        (data) => {
          setVisits(data.data.data);
        },
        (error) => {
          console.error("Error fetching all claims:", error);
        }
      )
    );
  };

  useEffect(() => {
    getData();
    getAllData();
  }, [id]);

  const handleVisitClick = () => {
    setShowVisitModal(true);
  };

  const handleCloseModal = () => {
    setShowVisitModal(false);
  };
  return (
    <>
      <Header />
      <SearchFilterBox
        addBtn={false}
        showVisitOnly={true}
        visitClick={handleVisitClick}
      />
      <VisitCards requirementDetails={requirement} visits={visits} />
      {requirement?.length > 0 ? (
        <BookAVisitFromRequirementModal
          requirementID={id}
          show={showVisitModal}
          onHide={handleCloseModal}
          customer={requirement[0].customerId}
          unitTypes = {requirement[0]?.unitType?.map((item) => {return {label: item, value: item}})}
          preferredLocations = {requirement[0]?.preferredLocation}
          min = {requirement[0]?.minPrice}
          max = {requirement[0]?.maxPrice}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default RequirementVisitDetails;
