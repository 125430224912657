export function convertToOriginalValue(searchQuery) {
  const originalValue = searchQuery
    .replace(/-and-/g, ' ')
    .replace(/\b\w/g, (match) => match.toUpperCase());

  const originalValueWithSlashes = originalValue.replace(/-/g, '/');

  const resultElement = document.getElementById('result');
  if (resultElement) {
    resultElement.textContent = originalValueWithSlashes;
  }

  return originalValueWithSlashes;
}

export function formatNumberWithIndianRupee(number) {
  const lakh = 100000;
  const crore = 10000000;

  if (number >= crore) {
    const crorePart = Math.floor(number / crore);
    const remainingPart = formatNumberWithIndianRupee(number % crore);

    if (remainingPart === '') {
      return `${crorePart} Crore`;
    } else {
      return `${crorePart} Crore ${remainingPart}`;
    }
  } else if (number >= lakh) {
    const lakhPart = Math.floor(number / lakh);
    const remainingPart = formatNumberWithIndianRupee(number % lakh);

    if (remainingPart === '') {
      return `${lakhPart} Lakh`;
    } else {
      return `${lakhPart} Lakh ${remainingPart}`;
    }
  } else if (number >= 1000) {
    const thousandPart = Math.floor(number / 1000);
    const remainingPart = formatNumberWithIndianRupee(number % 1000);

    if (remainingPart === '') {
      return `${thousandPart} Thousand`;
    } else {
      return `${thousandPart} Thousand ${remainingPart}`;
    }
  } else {
    return number === 0 ? '' : `${number}`;
  }
}

var a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
var b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];

export function inWords (num) {
    if ((num = num.toString()).length > 9) return 'overflow';
    var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return; var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) : '';
    return str;
}
