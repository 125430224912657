import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import propertyImage from '../../../Images/propertyadded.png';
import build from '../../../Images/build.png';
import { Pagination } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BsPencilFill } from 'react-icons/bs';
import { RiVipCrownFill } from 'react-icons/ri';
import DashboardHeader from '../../header/DashboardHeader';
import SearchFilterBox from '../../search-filter/SearchFilter';
import Footer from '../../Footer/Footer';
import { useEffect, useState } from 'react';
import { getAPI } from '../../../Api/ApiRequest';
import { apiEndpoints } from '../../../Api/ApiEndpoint';
import RecentlyAdded from '../RecentlyAdded';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';

const RecentlyAddedProperties = (props) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [propertiesPerPage] = useState(9);
  const [loading, setLoading] = useState(false);
  // const unitType = data.unitType ? data.unitType : [];
const builderId = localStorage.getItem('builderId');
  // const dispatch = useDispatch();
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(data?.length / propertiesPerPage); i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    const getAddedProperties = async () => {
      setLoading(true);
      const response = await getAPI(
        `${apiEndpoints?.getAllResentProperty}${builderId}`
      );
      setData(response?.data);
      setLoading(false);
      console.log('recently added', response.data);
    };
    getAddedProperties();
  }, []);
  const handlePageChange = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const formatPrice = (price) => {
    if (price >= 10000000) {
      return `₹ ${(price / 10000000).toFixed(2)} Cr`;
    } else if (price >= 100000) {
      return `₹ ${(price / 100000).toFixed(2)} L`;
    } else {
      return `₹ ${price}`;
    }
  };

  const compareData = (a, b) => {
    const dateA = new Date(a.createdAt).getTime();
    const dateB = new Date(b.createdAt).getTime();

    return dateB - dateA;
  };

  data.sort(compareData);

  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
  const currentProperties = data?.slice(
    indexOfFirstProperty,
    indexOfLastProperty
  );

  return (
    <>
      <DashboardHeader />
      <Container className='mt-5 w-100'>
        <SearchFilterBox />

        <div className='row justify-content-between'>
          <h3 className='col-4 heading'>Recently Added</h3>
        </div>
        {loading ? (
          <Box textAlign={'center'}>
            <CircularProgress size={'3rem'}></CircularProgress>
          </Box>
        ) : data.length > 0 ? (
          <Grid container spacing={{ md: 0, sm: 4, xs: 2 }}>
            {currentProperties?.map((itm, index) => (
              <Grid item md={4} sm={6} xs={12} key={index}>
                <Box
                  className='card bl-card mb-4 shadow-sm rounded-4  p-0 border-0 '
                  id={itm?._id}
                  key={itm?._id}
                  style={{ margin: 'auto', width: 'auto' }}
                >
                  <Link to={`/builder/home-dashboard/description/${itm?._id}`}>
                    <Box
                      sx={{
                        position: 'relative',
                        height: '11rem',
                      }}
                    >
                      <Card.Img
                        className='w-100 bl-prop-img '
                        variant='top'
                        src={itm.images[0]}
                        style={{
                          height: '11rem',
                          borderRadius: '1rem',
                        }}
                      />
                      <Box
                        sx={{
                          bgcolor: '#EFEFEF',
                          position: 'absolute',
                          padding: '3px 5px',
                          top: 12,
                          right: 0,
                          color: 'black',
                        }}
                      >
                        Listing Date:{' '}
                        {new Date(itm?.createdAt).toLocaleDateString('en-IN')}
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: '#FE5E6A',
                          color: '#fff',
                          padding: '3px 3px',
                          bottom: 50,
                          borderRadius: '.3rem',
                          width: '45%',
                        }}
                      >
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            12 days left
                          </Grid>
                          <Grid item xs={6}>
                            {itm?.visitCount}/{itm?.noOfVisits} visits
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Link>
                  <Card.Body>
                    <Card.Text>
                      <Grid container justifyContent={'space-between'}>
                        <Grid item md={10}>
                          <div>
                            {' '}
                            <Link
                              className='recent-heading'
                              to={`/builder/home-dashboard/description/${itm?.propertyId?._id}`}
                            >
                              {itm?.name}
                            </Link>
                          </div>
                          <p style={{ opacity: 0.5 }}>{itm?.location}</p>
                        </Grid>
                        <Grid textAlign={'end'} item>
                          <RiVipCrownFill
                            style={{
                              padding: '5px',
                              borderRadius: '50%',
                              background: '#ffd600',
                              color: '#fff',
                              width: '1.85em',
                              height: '1.85em',
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Row className='mt-2 mb-2'>
                        {itm?.unitTypeNew?.slice(0, 4).map((item, index) => (
                          <Col
                            md={3}
                            sm={3}
                            className='text-center'
                            key={index}
                          >
                            <img
                              src={build}
                              alt='build'
                              className='build-icon'
                            />
                            <p style={{ opacity: 0.5 }}>{item}</p>
                          </Col>
                        ))}
                      </Row>

                      <Row className='align-items-center mb-3'>
                        <Col
                          md={6}
                          style={{
                            color: '#000000',
                            fontSize: '1.35em',
                            fontWeight: 'bold',
                          }}
                        >
                          {formatPrice(itm?.minPrice)}-
                          {formatPrice(itm?.maxPrice)}
                        </Col>
                        <Col
                          md={6}
                          style={{ color: '#7D7F88', fontSize: '0.8em' }}
                        >
                          {itm?.builderDiscount}
                        </Col>
                      </Row>
                      <div className='d-flex justify-content-end gap-2 mt-2'>
                        <Button
                          variant='transparent'
                          type='button'
                          className='rounded-pill px-4 py-2 border border-primary'
                          style={{ color: '#278FD9', fontSize: '14px' }}
                          as={Link}
                          to='/builder/home-dashboard/visit'
                        >
                          {itm?.noOfVisits} Visits
                        </Button>
                        <Button
                          variant='transparent'
                          type='button'
                          className='primary rounded-pill px-4 py-2 border border-primary'
                          as={Link}
                          to='/builder/home-dashboard/propertyedit'
                        >
                          <BsPencilFill
                            style={{
                              width: '0.9em',
                              color: '#fff',
                              background: '#278fd9',
                              padding: '2px',
                              borderRadius: '50%',
                              marginRight: '4px',
                              marginBottom: '5px',
                            }}
                          />
                          Edit
                        </Button>
                      </div>
                    </Card.Text>
                  </Card.Body>
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography>No Recently add property</Typography>
        )}

        {/* <Pagination className='mt-3 justify-content-center'>
          {pageNumbers.map((number) => (
            <Pagination.Item
              key={number}
              id={number}
              active={number === currentPage}
              onClick={handlePageChange}
            >
              {number}
            </Pagination.Item>
          ))}
        </Pagination> */}
      </Container>
      <Footer />
    </>
  );
};

export default RecentlyAddedProperties;
