import DashboardHeader from '../header/DashboardHeader';
import { Col, Container, Modal, Row, Image } from 'react-bootstrap';
import SearchFilterBox from '../search-filter/SearchFilter';
import homeImage from '../../Images/homeImage.jpg';
// import map from "../../Images/Map.png";
import Anlytics from '../dashboard-property-analytics/dashboard-property-details/anlytics';
import { RxCheck } from 'react-icons/rx';
import Aos from 'aos';
import Danelions from './Danelions';
import Footer from '../Footer/Footer';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getAPI } from '../../Api/ApiRequest';
import { apiEndpoints } from '../../Api/ApiEndpoint';
import './SoldOut.css';

import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';

import { Card, Table } from 'react-bootstrap';

import { Close } from '@mui/icons-material';
import { SwiperSlider } from '../../../broker/components';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import ViewDetailsCard from '../../../broker/Pages/dashboard/property-details/ViewDetailsCard';
import PriceCharts from '../../../broker/features/Dashboard/components/charts/PriceCharts';
import CurrentlyComparing from '../../../broker/Pages/dashboard/property-details/currently-comparing/CurrentlyComparing';
import HTMLOutput from '../../../broker/features/Dashboard/components/HtmlOutput';
import LoadingSpinner from '../../../broker/Common/loading-spinner/LoadingSpinner';
import { data } from 'jquery';

const SoldPage = () => {
  const params = useParams();
  const [showPopup, setShowPopup] = useState(false);
  const [property, setProperty] = useState([]);
  const [property1, setProperty1] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Aos.init({ duration: 1400 });
  }, []);

  const getPropertyById = async () => {
    try {
      setLoading(true);
      const response = await getAPI(
        `${apiEndpoints.getPropertyById}${params.propertyId}`
      );
      console.log('prop is', response.data);
      setProperty(response.data[0]);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPropertyById();
  }, []);

  const handleImage = () => {
    setShowPopup(true);
  };

  const [selectedImage, setSelectedImage] = useState('');
  const [isSectionClicked, setIsSectionClicked] = useState(false);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  return (
    <>
      <DashboardHeader />
      {loading ? (
        <Box textAlign={'center'} my={5}>
          {' '}
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Container className='pt-2 pb-4 '>
            <SearchFilterBox />
            {property ? (
              <>
                {' '}
                <Row>
                  <Col md={12}>
                    <div className='container sold-page' data-aos='zoom-in'>
                      <span className='PropertyDetails-heading'>
                        Property Details
                      </span>
                      <div className='row' style={{ position: 'relative' }}>
                        <div className='col-lg-12 col-md-12 col-sm-12 sold-page-col'>
                          <div className='sold-page-col_div-1'>
                            <img
                              src={selectedImage || property?.images[0]}
                              className='sold-page-col_div_img'
                              alt='Home'
                              onClick={handleImage}
                            />
                            <div
                              className='sold-page-col_div-2'
                              style={{
                                position: 'absolute',
                                top: '10px',
                                zIndex: 100,
                                width: '100%',
                              }}
                            >
                              <div className='sold-page-col_div-3'>
                                <span className='sold-page-col_div-3_span-1'>
                                  <RxCheck className='sold-page-col_div-3_span-1-icon' />{' '}
                                  Rera{property?.isRera}
                                </span>
                              </div>
                              <div className='sold-page-col_div-4'>
                                <span className='sold-page-col_div-4_span-1'>
                                  Listing Date:{' '}
                                  {new Date(
                                    property?.createdAt
                                  ).toLocaleDateString('en-IN')}
                                </span>
                              </div>
                            </div>

                            <div
                              className='hero-caption-img-navigation'
                              style={{
                                position: 'absolute',
                                bottom: '10',
                                cursor: 'pointer',
                              }}
                            >
                              {!!property?.images?.length &&
                                property?.images?.map((img, index) => {
                                  if (index <= 1) {
                                    return (
                                      <Image
                                        key={index}
                                        src={img}
                                        className='ms-3'
                                        width={'66.92px'}
                                        height={'66.92px'}
                                        onClick={() => handleImageClick(img)}
                                        style={{
                                          border: '4px solid white',
                                          borderRadius: '20.08px',
                                        }}
                                      />
                                    );
                                  } else if (
                                    index === 2 &&
                                    property?.images?.length > 2
                                  ) {
                                    return (
                                      <div
                                        key={index}
                                        className=' d-inline-block rounded ms-3'
                                        onClick={() =>
                                          setIsSectionClicked(true)
                                        }
                                        style={{
                                          position: 'relative',
                                        }}
                                      >
                                        <Image
                                          src={img}
                                          width={70}
                                          height={70}
                                          onClick={() => handleImageClick(img)}
                                          style={{
                                            border: '4px solid white',
                                            borderRadius: '20.08px',
                                          }}
                                        />
                                        {!isSectionClicked && (
                                          <div className='position-absolute top-0 left-0 w-100 h-100 rounded bg-dark opacity-75 text-white d-flex justify-content-center align-items-center h3'>
                                            <span className='px-2'>
                                              {property?.images?.length - 2}+
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  } else if (index > 1 && isSectionClicked) {
                                    return (
                                      <Image
                                        key={index}
                                        src={img}
                                        className='ms-3'
                                        width={'66.92px'}
                                        height={'66.92px'}
                                        onClick={() => handleImageClick(img)}
                                        style={{
                                          border: '4px solid white',
                                          borderRadius: '20.08px',
                                        }}
                                      />
                                    );
                                  }
                                })}

                              {isSectionClicked && (
                                <IconButton
                                  sx={{
                                    background: 'white',
                                    left: '10px',
                                  }}
                                  onClick={() => setIsSectionClicked(false)}
                                >
                                  <Close />
                                </IconButton>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className='sold-page-col_div-8 gap-2'>
                          <span className='sold-page-col_div-8-span'>
                            {property?.constructionStatus}
                          </span>
                          <span className='sold-page-col_div-8-span'>
                            Possession in :{' '}
                            {new Date(property?.possessionDate).toLocaleString(
                              'en-IN',
                              {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                              }
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Danelions data={property} />
                <Anlytics
                  propertyId={params?.propertyId}
                  data={property}
                  builderId={property?.builderId._id}
                />
                {/* <Floor data={property} /> */}
                <SwiperSlider
                  floorPlansAndPricing={property?.floorPlanAndPricing}
                  propertyType={property?.propertyType}
                />
                <Card className='border-0 shadow-none bg-transparent property__details__iconcard mb-4'>
                  <Card.Header className='bg-transparent border-0 mt-3 px-0'>
                    <Card.Title className='fw-bold'>
                      Project Amenities
                    </Card.Title>
                  </Card.Header>
                  <Card.Body className='border rounded d-flex flex-row gap-1 justify-content-center align-items-center'>
                    <Grid container spacing={2}>
                      {!!property?.amenitiesId?.length &&
                        property?.amenitiesId?.map(({ image, name, _id }) => (
                          <Grid item xl={2} lg={2} md={3} xs={6} sm={6}>
                            {' '}
                            <div className='d-flex flex-column gap-1 justify-content-center align-items-center m-0 p-0'>
                              <img
                                src={image}
                                alt={name}
                                className='img-fluid mb-2'
                                width={30}
                                height={30}
                                style={{
                                  opacity: 0.5,
                                  marginTop: '1.5rem',
                                }}
                              />
                              <span className='property__details__iconcard__iconsubtitle'>
                                {name}
                              </span>
                            </div>
                          </Grid>
                        ))}
                    </Grid>
                  </Card.Body>
                </Card>
                <div className='nes__dashboard__smallicons'>
                  <Card className='border-0 shadow-none bg-transparent property__details__iconcard mb-4'>
                    <Card.Header className='bg-transparent border-0 mt-3 px-0'>
                      <Card.Title className='fw-bold'>
                        Location Advantages
                      </Card.Title>
                    </Card.Header>
                    <Card.Body className='border rounded'>
                      <Row className='g-4'>
                        {!!property?.locationAdvantagesId?.length &&
                          property?.locationAdvantagesId?.map(
                            ({ image, name, distance, _id }) => (
                              <Col
                                style={{
                                  flex: `0 0 calc(100% / ${property?.locationAdvantagesId?.length})`,
                                }}
                                key={_id}
                                className='d-flex  g-1 justify-content-center align-items-center'
                              >
                                <div className='d-flex flex-column gap-1 justify-content-center align-items-center'>
                                  <img
                                    src={image}
                                    alt={name}
                                    className='img-fluid mb-2 '
                                    width={30}
                                    height={20}
                                    style={{ marginTop: '2rem' }}
                                  />
                                  <span className='property__details__iconcard__icontitle'>
                                    {name}
                                  </span>
                                  <span className='property__details__iconcard__iconsubtitle'>
                                    {distance}m
                                  </span>
                                </div>
                              </Col>
                            )
                          )}
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
                {/* map  */}
                <APIProvider apiKey={'AIzaSyDvoazybsmW4NL7sIPV2WG_NfV8LGuvKko'}>
                  <Map
                    style={{ width: '100%', height: '500px' }}
                    defaultCenter={{
                      lat: parseFloat(property?.latitude),
                      lng: parseFloat(property?.longitude),
                    }}
                    defaultZoom={10}
                    gestureHandling={'none'}
                    disableDefaultUI={true}
                    zoomControl={false}
                  >
                    <Marker
                      position={{
                        lat: parseFloat(property?.latitude),
                        lng: parseFloat(property?.longitude),
                      }}
                    />
                  </Map>
                </APIProvider>
                {/* <Row>
            <Col md={12}>
              <Payment data={property} />
            </Col>
            <Col md={12}>
              <h3 className='heading'> View offical brochure</h3>
              <Row className='gx-4 dashboard-cards align-items-center'>
                <img
                  src={property?.brochureUrl}
                  sizes='140'
                  className='rounded my-3'
                  alt='Home'
                />
              </Row>
            </Col>
            <Col md={12}>
              <h3 className='heading'> Terms & Conditions</h3>
              <Container className='dashboard__wrapper__filter border border-light rounded shadow-sm mt-4'>
                <div className='p-4 d-flex justify-content-between'>
                  <p style={{ color: '#7D7F88', fontFamily: 'Bahnschrift' }}>
                    {property?.termAndCondition}
                  </p>
                </div>
              </Container>
            </Col>
          </Row> */}
                <div className='nes__dashboard__smallicons'>
                  <Card className='border-0 shadow-none bg-transparent property__details__iconcard mb-4'>
                    <Card.Header className='bg-transparent border-0 mt-3 px-0'>
                      <Card.Title className='fw-bold'>
                        Brokerage Payout Plan
                      </Card.Title>
                    </Card.Header>
                    <Card.Body className='border rounded'>
                      {/* Check if property.brokerageType and brokerageValue are available */}
                      {property?.brokerageType && property?.brokerageValue && (
                        <div className='d-flex flex-column gap-1 justify-content-center align-items-start'>
                          <span className='property__details__iconcard__icontitle text-aligen-start'>
                            Brokerage:{' '}
                            {property?.brokerageType === 'Amount'
                              ? `₹${property?.brokerageValue}`
                              : `${property?.brokerageValue}%`}
                          </span>

                          <span className='property__details__iconcard__iconsubtitle'>
                            Platform charges & applicable taxes shall be
                            deducted
                          </span>
                        </div>
                      )}
                      <Row className='g-4'>
                        <Col className='d-flex justify-content-center align-items-center'></Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
                <Card className='border-0 shadow-none bg-transparent property__details__iconcard mb-4'>
                  <Card.Header className='bg-transparent border-0 mt-3 px-0'>
                    <Card.Title className='fw-bold'>Milestone</Card.Title>
                  </Card.Header>
                </Card>
                <Grid container spacing={2}>
                  {property?.milestonesTermsConditions &&
                    property?.milestonesTermsConditions?.map(
                      (milestone, index) => (
                        <Grid item xs={12} md={4} xl={4} key={index}>
                          <Card
                            className='border-0 shadow-none bg-transparent property__details__iconcard mb-4'
                            style={{ minHeight: '8rem' }}
                          >
                            <Card.Body className='border rounded'>
                              {/* Display milestone condition and brokerage */}
                              <div className='property__details__iconcard__icontitle text-aligen-start mb-2'>
                                Milestone
                              </div>
                              <div className='d-flex justify-content-between align-items-center property__details__iconcard__iconsubtitle'>
                                <span>Condition: </span>
                                <span
                                  className='d-flex justify-content-start'
                                  style={{
                                    textAlign: 'start',

                                    width: '25%',
                                  }}
                                >
                                  Brokerage:
                                </span>
                              </div>
                              <div className='d-flex justify-content-between align-items-center '>
                                <span
                                  className='property__details__iconcard__icontitle text-aligen-start'
                                  style={{ fontSize: '.8rem', width: '70%' }}
                                >
                                  {milestone?.condition}
                                </span>

                                <span
                                  className='property__details__iconcard__icontitle  justify-content-start'
                                  style={{
                                    fontSize: '.8rem',
                                    textAlign: 'start',

                                    width: '25%',
                                  }}
                                >
                                  {milestone?.brokerage}
                                </span>
                              </div>
                            </Card.Body>
                          </Card>
                        </Grid>
                      )
                    )}
                </Grid>
                <ViewDetailsCard
                  title={'About The Project'}
                  propertyDescription={property?.propertyDescription}
                  // link={'View Details'}
                  Id={property?._id}
                  about={'about-project'}
                  bg
                />
                {/* payment plan */}
                <Card
                  className='border-0 shadow-none bg-transparent property__details__iconcard mb-4'
                  style={{ minHeight: '8rem' }}
                >
                  <Card.Header className='bg-transparent border-0 mt-3 px-0'>
                    <Card.Title className='fw-bold'>Payment Plan</Card.Title>
                  </Card.Header>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          Payment %
                        </th>

                        <th>Milestone</th>
                      </tr>
                    </thead>
                    <tbody>
                      {property?.paymentPlan?.map(
                        ({ payment, milestone }, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                color: '#7D7F88',
                              }}
                            >
                              {payment?.includes('%') ? payment : `${payment}%`}
                            </td>
                            <td
                              style={{
                                color: '#7D7F88',
                              }}
                            >
                              {milestone}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </Card>
                {/* payment plan code end here */}
                <div className='nes__dashboard__banks'>
                  <ViewDetailsCard
                    title='Loan Approved By'
                    img
                    loanApprovedByIds={property?.loanApprovedByIds}
                  />
                </div>
                {property?.builderId?.description !== null && (
                  <ViewDetailsCard
                    title={'About the Builder'}
                    aboutTheBuilder={property?.builderId?.description}
                    Id={property?.builderId}
                    about={'about-builder'}
                    bg
                  />
                )}
                <ViewDetailsCard
                  title='View official brochure'
                  media
                  brochureUrl={property?.brochureUrl}
                />
                {property?.currentlyComparing?.length > 0 ? (
                  <>
                    {/* <Card
                      className={`border-0 shadow-sm property__details__text mb-5 bg-white`}
                      style={{ borderRadius: '17.69px' }}
                    >
                      <Card.Header className='bg-transparent border-0 mt-3 px-0'>
                        {' '}
                        <Card.Title
                          className='fw-bold '
                          style={{ fontSize: '20px', marginLeft: '2%' }}
                        >
                          Price Trend Analysis
                        </Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <Typography sx={{ marginLeft: '1%', mb: 3 }}>
                          The graph shows the quarterly average rates of
                          properties
                        </Typography>
                        <PriceCharts data={property?.currentlyComparing} />
                      </Card.Body>
                    </Card>
                    <CurrentlyComparing data={property?.currentlyComparing} /> */}
                  </>
                ) : (
                  ''
                )}
                {property?.termAndCondition !== null && (
                  <div className='nes__dashboard__largeicons'>
                    <Card className='border-0 shadow-none bg-transparent property__details__iconcard mb-4'>
                      <Card.Header className='bg-transparent border-0 mt-3 px-0'>
                        <Card.Title className='fw-bold'>
                          Terms & Conditions
                        </Card.Title>
                      </Card.Header>
                      <Card.Body className='border rounded d-flex flex-row gap-1 justify-content-start align-items-center'>
                        <HTMLOutput data={property?.termAndCondition} />
                      </Card.Body>
                    </Card>
                    {/* amenities code end here */}
                  </div>
                )}
                <Modal
                  show={showPopup}
                  onHide={() => setShowPopup(false)}
                  className='mt-5 align-item-center rounded'
                  size='md'
                  style={{
                    borderRadius: '63.8029px',
                  }}
                >
                  <Modal.Body>
                    {' '}
                    <img
                      src={homeImage}
                      alt='Home'
                      style={{ width: '100%', height: '100%' }}
                    />
                  </Modal.Body>
                </Modal>
              </>
            ) : (
              <Typography>no</Typography>
            )}
          </Container>
        </>
      )}

      <Footer />
    </>
  );
};

export default SoldPage;
