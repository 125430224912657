import React, { useRef, useState } from 'react';
import JoditEditor from 'jodit-react';
import { Box, styled } from '@mui/system';
import { Typography } from '@mui/material';
import { CustomBtn } from '../../broker/components/mui/CustomBtn';
import { FormInputField } from './FormInputField';

const JoditTextBox = ({
  PolicyTitle1,
  PolicyTitle2,
  MainTitle,
  label,
  showButtons = true,
  showTextBox = true,
  onSubmit,
  onUpdate,
  ...props
}) => {
  const editor = useRef(null);
  const [content, setContent] = useState('');
  const [inputFieldValue, setInputFieldValue] = useState('');

  const handleContentChange = (newContent) => {
    setContent(newContent);
  };

  const handleInputChange = (value) => {
    setInputFieldValue(value);
  };

  const handleSubmit = () => {
    onSubmit && onSubmit(content, inputFieldValue);
  };

  const handleUpdate = () => {
    onUpdate && onUpdate(content, inputFieldValue);
  };

  return (
    <Box className='wrapper'>
      <Typography variant='h6' mt={3} mb={2}>
        {PolicyTitle2}
      </Typography>
      <JoditEditor ref={editor} onChange={handleContentChange} {...props} />
      {showButtons && (
        <Box mt={4} sx={{ display: 'flex', justifyContent: 'end' }}>
          <CustomBtn
            variant='outlined'
            height='5.7vh'
            size='large'
            onClick={handleSubmit}
          >
            cancle
          </CustomBtn>
          <Box ml={2}>
            <CustomBtn
              variant='contained'
              size='large'
              height='5.7vh'
              onClick={handleUpdate}
            >
              Update
            </CustomBtn>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default JoditTextBox;
