export const apiEndpoints = {
  sendOtp: '/v1/broker/sendOtp',
  verifyOtp: '/v1/broker/verifyOtp',
  registerName: '/v1/broker/registerName',
  getAllPropertyCategory: '/v1/propertyCategory/getAllPropertyCategory',
  getAllBanner: '/v1/banner/getAllBanner',
  getAllPromotionBanner: '/v1/promotionBanner/getAllPromotionBanner',
  getAllproperty: '/v1/property/getAllproperty',
  getPropertyById: '/v1/property/getPropertyById',
  getAllTermsAndConditionsBroker: `/v1/termsAndConditionsBroker/getAllTermsAndConditionsBroker`,
  getAllFaqAndSupport: '/v1/faqAndSupport/getAllFaqAndSupport',
  addVisit: '/v1/visit/addVisit',
  visitVerifyOtp: '/v1/visit/verifyOtp',
  getBrokerById: `/v1/broker/getBrokerById`,
  updatePersonalInfo: '/v1/broker/updatePersonalInfo',
  getAllCustomer: '/v1/customer/getAllCustomer',
  getAllRequirement: '/v1/requirement/getAllRequirement',
  getAllEligibleClaims: '/v1/claim/getAllEligibleClaims',
  getAllClaim: '/v1/claim/getAllClaim',
  getAllBuilders: '/v1/builder/getAllBuilder',
  getAllVisit:"/v1/visit/getAllVisit?&pageNo=1&pageSize=10&requirementId="
  
};
