import { Box, Container, Grid, TextField, Typography } from '@mui/material';
import React from 'react';

const Exe = () => {
  // const arr="ruby";
  // const number =arr.filter(number=>number/2===0)



  return (
    <Container>
      <Typography variant='h3' textAlign={'center'}>
        Contact Us
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', direction: 'row' }}>
        <Box>
          <Grid container>
            <Grid
              item
              sx={{
                width: { xl: '50%', sm: '100%' },
                height: '60vh',
                border: '1px solid red',
              }}
            >
              <Typography>Get in touch</Typography>
              <Grid container spacing={2}>
                <Grid item xl={6}>
                  <TextField placeholder='name'></TextField>
                </Grid>
                <Grid item xl={6}>
                  <TextField placeholder='Email'></TextField>
                </Grid>
              </Grid>
              <Grid mt={2}>
                {' '}
                <TextField placeholder='Subject'></TextField>
              </Grid>
              <Grid>
                <TextField
                  sx={{ height: '4rem' }}
                  placeholder='Message'
                ></TextField>
              </Grid>
            </Grid>
            <Grid
              item
              sx={{ width: '40vh', height: '60vh', border: '1px solid red' }}
            >
              {' '}
            </Grid>
          </Grid>
         
        </Box>
      </Box>
    </Container>
  );
};

export default Exe;
