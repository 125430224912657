import {
  Box,
  CardContent,
  Container,
  Grid,
  Card,
  Typography,
  CardMedia,
  Stack,
  Button,
  IconButton,
} from '@mui/material';
import React, { useEffect } from 'react';
import AdminLayout from '../../assets/AdminLayout';
import { useParams } from 'react-router-dom';
import { useGetAllPropertyQuery } from '../../broker/hooks/LoginQuery';
import { useState } from 'react';
import { RiBuilding2Fill } from 'react-icons/ri';
import { Share, ShareRounded } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import {
  convertToOriginalValue,
  formatNumberWithIndianRupee,
} from '../functions';

export default function SearchResult() {
  const { cat, query } = useParams();
  const [catFilter, setCatFilter] = useState();
  const [searchResults, setSearchResults] = useState([]);
  const { getAllPropertyResponse } = useGetAllPropertyQuery();
  // console.log(getAllPropertyResponse);

  console.log(convertToOriginalValue(cat));
  useEffect(() => {
    const result = getAllPropertyResponse?.filter((property) => {
      const nameIncludes = property?.name
        ?.toLowerCase()
        ?.includes(query.toLowerCase());
      const locationIncludes = property?.location
        ?.toLowerCase()
        ?.includes(query.toLowerCase());
      const maxPriceIncludes = property?.maxPrice
        ?.toLowerCase()
        ?.includes(query.toLowerCase());
      const minPriceIncludes = property?.minPrice
        ?.toLowerCase()
        ?.includes(query.toLowerCase());
      const unitTypeIncludes = property?.unitTypeNew?.includes(query);

      const isPropertyTypeMatch =
        cat === 'all' ? true : property?.propertyType?.name === cat;

      return query === 'null'
        ? true
        : (nameIncludes ||
            locationIncludes ||
            maxPriceIncludes ||
            minPriceIncludes ||
            unitTypeIncludes) &&
            isPropertyTypeMatch;
    });
    console.log(result);
    setSearchResults(result);
  }, [getAllPropertyResponse]);
  return (
    <AdminLayout>
      <Box sx={{ my: 7 }}>
        <Container>
          <Grid container spacing={3}>
            {searchResults?.map((item, index) => (
              <Grid item md={4} key={item._id}>
                <Card
                  sx={{
                    boxShadow: '0px 8px 15px rgb(0,0,0,0.1)',
                    borderRadius: 2,
                    '.text-area': { mx: 2, mt: 1 },
                    '.img': { borderRadius: 2 },
                    h3: {
                      fontSize: 16,
                      fontWeight: 600,
                      textTransform: 'capitalize',
                    },
                    '& .location': {
                      color: grey[600],
                      fontSize: 12,
                    },
                    '.error': {
                      color: 'error.main',
                      textTransform: 'lowercase',
                    },
                    '.price': {
                      fontSize: 16,
                      fontWeight: 600,
                      small: {
                        position: 'relative',
                        top: -2,
                        left: 5,
                        fontSize: 11,
                        fontWeight: 400,
                        color: grey[700],
                      },
                    },
                    '& .icons': {
                      my: 1,
                      '& .p': {
                        color: grey[600],
                        fontSize: 13,
                      },
                    },
                    '& .book-btn': {
                      borderRadius: 25,
                      color: '#278FD9',
                      px: 3,
                      py: 1,
                      textTransform: 'unset',
                    },
                    '.icon-btn': {
                      color: '#278FD9',
                      svg: { fontSize: 32 },
                    },
                  }}
                >
                  <CardMedia
                    component={'img'}
                    src={item?.images[0]}
                    height={220}
                    alt={item.name}
                    className="img"
                  ></CardMedia>
                  <CardContent className="text-area">
                    <Typography component={'h3'}>
                      {item.name || (
                        <span className="error">
                          <i>{'{name-unavailable}'}</i>
                        </span>
                      )}
                    </Typography>
                    <Typography component={'p'} className="location">
                      {item?.location || (
                        <span className="error">
                          <i>{'{location-unavailable}'}</i>
                        </span>
                      )}
                    </Typography>
                    <Stack
                      direction={'row'}
                      justifyContent={'space-between'}
                      className="icons"
                    >
                      {[...Array(3)].map((_, i) => (
                        <Stack key={i} alignItems={'center'}>
                          <RiBuilding2Fill color="#939197" />
                          <Typography component={'p'} className="p">
                            {item?.unitTypeNew[i] || (
                              <span className="error">--</span>
                            )}
                          </Typography>
                        </Stack>
                      ))}
                    </Stack>
                    <Typography component={'p'} className="price">
                      {`₹${formatNumberWithIndianRupee(
                        item?.minPrice
                      )} - ₹${formatNumberWithIndianRupee(item?.maxPrice)}`}
                      <small> Book now & get 5% discount</small>
                    </Typography>
                    <Stack
                      direction={'row'}
                      justifyContent={'flex-end'}
                      spacing={2}
                      alignItems={'center'}
                    >
                      <Box>
                        <Button variant="outlined" className="book-btn">
                          Book Now
                        </Button>
                      </Box>
                      <Box>
                        <IconButton className="icon-btn">
                          <Share />
                        </IconButton>
                      </Box>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}{' '}
          </Grid>
        </Container>
      </Box>
    </AdminLayout>
  );
}
