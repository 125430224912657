import { useDispatch, useSelector } from "react-redux";
import SearchFilterBox from "../../../../../components/search-filter/SearchFilter";
import useToken from "../../../../hooks/useToken";
import Header from "../header/Header";
import ClientCards from "./RequirementCards";
import { useGetAllCustomer } from "../../../../hooks/LoginQuery";
import LoadingSpinner from "../../../../Common/loading-spinner/LoadingSpinner";
import ErrorMessage from "../../../../Common/error-message/ErrorMessage";
import { useEffect, useState } from "react";
import AddCustomerModal from "../customer-modal/AddCustomerModal";
import AddRequirementModal from "../requirement-modal/AddRequirementModal";
import { useGetAllRequirement } from "../../../../hooks/LoginQuery";
import { useParams } from "react-router-dom";
import { getAllRequirement } from "../../../../../API/action";

const RequirementManagement = () => {
  const [showModal, setShowModal] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenCustomerModal = () => {
    setShowCustomerModal(true);
  };

  const handleCloseCustomerModal = () => {
    setShowCustomerModal(false);
  };

  const { id } = useParams();
  const { getAllRequirementResponse } = useGetAllRequirement(
    localStorage.getItem("brokerID"),
    id
  );

  const dispatch = useDispatch();

  const [allRequirement, setAllRequirement] = useState([]);
  const [requirementId, setRequirementId] = useState("");
  const getData = () => {
    let payload = {
      brokerId: localStorage.getItem("brokerID"),
      customerId: id,
    };
    dispatch(
      getAllRequirement(
        payload,
        (data) => {
          setAllRequirement(data.data.data);
        },
        (error) => {
          // Handle error
          console.error("Error fetching all requirement:", error);
        }
      )
    );
  };

  useEffect(() => {
    getData();
  }, [id]);

  useToken();
  // const brokerId = useSelector(state => state.auth.brokerID);
  const brokerId = localStorage.getItem("brokerID");

  const {
    getAllCustomerIsLoading,
    getAllCustomerIsError,
    getAllCustomerResponse,
    getAllCustomerError,
    getAllCustomerIsSuccess,
  } = useGetAllCustomer(brokerId);
  if (getAllCustomerIsLoading) {
    return <LoadingSpinner />;
  }

  if (getAllCustomerIsError) {
    return <ErrorMessage />;
  }

  if (getAllCustomerIsSuccess) {
    return (
      <>
        <Header />
        <SearchFilterBox
          addBtn={false}
          showRequirementOnly={true}
          requirementClick={handleOpenModal}
          onAddButtonClick={handleOpenCustomerModal}
        />
        <ClientCards
          getAllCustomerResponse={getAllCustomerResponse}
          getAllCustomerIsSuccess={getAllCustomerIsSuccess}
        />
        <AddCustomerModal
          show={showCustomerModal}
          onHide={handleCloseCustomerModal}
        />
        <AddRequirementModal show={showModal} onHide={handleCloseModal} fromRequirement={true} />
      </>
    );
  }
};

export default RequirementManagement;
