import { Card, CardMedia } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';

export default function SuperCard({ src, title, desc }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 100, scale: 0.7 }}
      whileInView={{ opacity: 1, y: 0, scale: 1 }}
      transition={{ duration: 0.8 }}
      style={{
        maxWidth: 350,
        margin: 'auto',
      }}
    >
      <Card
        sx={{
          borderRadius: 0,
          bgcolor: 'rgb(0,0,0,0)',
          boxShadow: 0,
          '& img': { borderRadius: 1 },
          '& p': {
            color: '#666',
          },
        }}
      >
        <CardMedia
          component={'img'}
          src={src || '/assets/informative/circle/SuperiorEarnings.svg'}
          height={200}
        />
        <h3 className="mt-3">
          <strong>{title || 'Vast Property Pool'}</strong>
        </h3>
        <p className="load1-p">
          {desc ||
            ` In comparison to the open market, NestoHub offers higher
                  brokerage. Our brokers receive the same percentage of
                  commission on every property sale, regardless of the slab
                  system.`}
        </p>
      </Card>
    </motion.div>
  );
}
