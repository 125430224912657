import React from 'react'
import Information from './Information/Information';
import './PrivacyPolicy.css'
import InformativeFooter from '../InformativeFooter/InformativeFooter';
import InformativeNavbar from '../Navbar/Navbar';
import { Fade } from 'react-reveal';

const RefundPolicy = () => {
    return (
        <>
            <InformativeNavbar />
            <div style={{ overflowX: 'hidden' }}>
                <Fade up duration={1000} delay={100}>
                    <div className='p-p_heading-box'>
                        <div className='container'>
                            <span className='p-p_heading'>Refund & Delivery Policy for NestoHub Proptech Pvt Ltd</span>
                        </div>
                    </div>
                </Fade>
                <div className='info_title-box'>
                    <Fade up duration={1000} delay={100}>
                        <div className='container'>
                            <span className='info_title'>1. Purchase Options</span>
                        </div>
                    </Fade>
                </div>
                <div className='container p-p_info-container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <Fade up duration={1000} delay={100}>
                                <span className='info_span'>
                                    There are promotional packages available on our App which can be purchased by the users in order to
                                    promote their property.
                                </span>
                            </Fade>
                        </div>
                    </div>
                </div>
                <div className='info_title-box'>
                    <Fade up duration={1000} delay={100}>
                        <div className='container'>
                            <span className='info_title'>2. Refund Policy</span>
                        </div>
                    </Fade>
                </div>
                <div className='container p-p_info-container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <Fade up duration={1000} delay={100}>
                                <span className='info_span'>
                                    We do not provide any kind of refund for the purchases made on our platform.
                                </span>
                            </Fade>
                        </div>
                    </div>
                </div>
                <div className='info_title-box'>
                    <Fade up duration={1000} delay={100}>
                        <div className='container'>
                            <span className='info_title'>3. Delivery / Activation</span>
                        </div>
                    </Fade>
                </div>
                <div className='container p-p_info-container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <Fade up duration={1000} delay={100}>
                                <span className='info_span'>
                                    Once the package is bought online, it will take approximately 2 working days to activate the package for
                                    your property.
                                </span>
                            </Fade>
                        </div>
                    </div>
                </div>
                <div className='info_title-box'>
                    <Fade up duration={1000} delay={100}>
                        <div className='container'>
                            <span className='info_title'>4. Contact Information</span>
                        </div>
                    </Fade>
                </div>
                <div className='container p-p_info-container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <Fade up duration={1000} delay={100}>
                                <span className='info_span'>
                                    You may contact us at <a href="mailto:info@nestohub.in">info@nestohub.in</a> for any queries related to purchase, refund, and delivery.
                                </span>
                            </Fade>
                        </div>
                    </div>
                </div>
                <div className='info_title-box'>
                    <Fade up duration={1000} delay={100}>
                        <div className='container'>
                            <span className='info_title'>4. Effective Date</span>
                        </div>
                    </Fade>
                </div>
                <div className='container p-p_info-container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <Fade up duration={1000} delay={100}>
                                <span className='info_span'>
                                    1 April 2024
                                </span>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
            <InformativeFooter />
        </>
    )
}

export default RefundPolicy