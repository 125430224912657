import {
  useGetAllPropertyQuery,
  useGetAllBanner,
} from "../../../../hooks/LoginQuery";
import { Container } from "react-bootstrap";
import HeroSection from "../hero-section/HeroSection";
import SmallCardSection from "../small-card-section/SmallCardSection";
import { ProductContainers } from "../../../../dashboard-containers";
import SwiperSliderDashboard from "../swiper-slider-dashboard/SwiperSliderDashboard";
import { SearchFilter } from "../../../../../components";

import "./BrokerView.css";
import { useAuth } from "../../../../services/api";
import LoadingSpinner from "../../../../Common/loading-spinner/LoadingSpinner";
import ErrorMessage from "../../../../Common/error-message/ErrorMessage";
import SwiperSliderNew from "../swiper-slider-new/SwiperSliderNew";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import HeaderCategory from "../properties/HeaderCategory";
import AddRequirementModal from "../requirement-modal/AddRequirementModal";
import AddCustomerModal from "../customer-modal/AddCustomerModal";
import EmiCalcultorModal from "../../../../../components/common/EmiCalculatorModal";

const BrokerView = ({ isOpen, setIsOpen }) => {
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedProp = searchParams.get("selected");
  const [selected, setSelected] = useState(selectedProp || "");
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showEmiModal, setShowEmiModal] = useState(false);

  const handleOpenCustomerModal = () => {
    setShowCustomerModal(true);
  };

  const handleCloseCustomerModal = () => {
    setShowCustomerModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseEmiModal = () => {
    setShowEmiModal(false);
  }

  useAuth();
  const {
    getAllPropertyIsLoading,
    getAllPropertyIsError,
    getAllPropertyResponse,
    getAllPropertyError,
    getAllpropertyIsSuccess,
  } = useGetAllPropertyQuery();
  const {
    getAllBannerIsLoading,
    getAllBannerIsError,
    getAllBannerResponse,
    getAllBannerError,
    getAllBannerIsSuccess,
  } = useGetAllBanner();

  // const allProperties = getAllPropertyResponse;

  // const filteredProperties = selected != "" ?
  //   allProperties.filter(property => property.propertyType._id === selected) :
  //   allProperties;

  const filteredProperties = getAllPropertyResponse?.filter((property) => {
    if (selected && property?.propertyType?._id !== selected) {
      return false;
    }
    return property?.name?.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <Container fluid="lg">
      {getAllBannerIsLoading && <LoadingSpinner />}
      {getAllBannerError && <ErrorMessage />}
      <SwiperSliderNew
        swiperData={getAllBannerResponse}
        pagination
        promotionalBanner
      />
      <HeaderCategory />
      {/* <SmallCardSection /> */}
      <section className="mt-3">
        <SearchFilter
          addBtn
          requirementClick={handleOpenModal}
          onAddButtonClick={handleOpenCustomerModal}
          hideRequirement={true}
          showEmiCalculator={() => setShowEmiModal(true)}
        />
      </section>
      <section className="mt-5">
        <SwiperSliderDashboard />
      </section>
      {getAllPropertyIsLoading && <LoadingSpinner />}
      {getAllPropertyIsError && <ErrorMessage />}

      {filteredProperties && (
        <ProductContainers
          propertyData={filteredProperties}
          view="broker"
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        >
          <SwiperSliderNew
            swiperData={getAllBannerResponse}
            pagination
            promotionalBanner
          />
        </ProductContainers>
      )}
      <HeroSection />

      {/* <ProductContainers
        sectionTitle="Recently Added"
        productData={getAllPropertyResponse}
        view="broker"
      /> */}
      {/* <ProductContainers
        sectionTitle="Best Selling"
        productData={getAllPropertyResponse}
        view="broker"
      /> */}
      <AddCustomerModal
        show={showCustomerModal}
        onHide={handleCloseCustomerModal}
      />
      <AddRequirementModal show={showModal} onHide={handleCloseModal} />
      <EmiCalcultorModal
        show={showEmiModal}
        onHide={handleCloseEmiModal}
      />
    </Container>
  );
};

export default BrokerView;
