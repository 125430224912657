import { Container } from 'react-bootstrap';
import { GrDocumentPdf } from 'react-icons/gr';

import { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { apiEndpoints } from '../../../Api/ApiEndpoint';
import { getAPI } from '../../../Api/ApiRequest';
import CustomAccordion from './CustomAccordion';
import { Box, CircularProgress, Typography } from '@mui/material';
import { faL } from '@fortawesome/free-solid-svg-icons';

const Faq = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getSupport = async () => {
      setLoading(true);
      const response = await getAPI(apiEndpoints.getAllFaqAndSupport1);

      setData(response.data);
      setLoading(false);
    };

    getSupport();
  }, []);

  return (
    <>
      <Container className='dashboard__wrapper__filter border border-light rounded shadow-sm mt-4'>
        {loading ? (
          <Box textAlign={'center'}>
            <CircularProgress></CircularProgress>
          </Box>
        ) : data?.length > 0 ? (
          <CustomAccordion data={data} />
        ) : (
          <Box textAlign={'center'}>
            <Typography
              mt={6}
              variant='p'
              component={'h5'}
              sx={{ color: 'var(--primary-color)' }}
            >
              No data available......
            </Typography>
          </Box>
        )}
      </Container>
      <br />
    </>
  );
};
export default Faq;
