import React, { useState } from "react";
import CustomModal from "./CustomModal";
import { Button, Form } from "react-bootstrap";
import { enqueueSnackbar } from "notistack";
import { Box, Typography } from "@mui/material";

const EmiCalcultorModal = ({ show, onHide }) => {
  const [amount, setAmount] = useState("");
  const [perncentage, setPercentage] = useState("");
  const [years, setYears] = useState("");
  const [emi, setEmi] = useState("");

  const handleSaveEmi = () => {
    console.log(amount, perncentage, years);
    if (amount === "" && perncentage === "" && years === "") {
      enqueueSnackbar("All fields are neccessary", { variant: "warning" });
    } else if (
      isNaN(amount) ||
      isNaN(perncentage) ||
      isNaN(years) ||
      amount.toString().includes("e") ||
      perncentage.toString().includes("e") ||
      years.toString().includes("e") ||
      amount.toString().includes("e")
    ) {
      enqueueSnackbar("Please enter valid value", { variant: "warning" });
    } else {
      var monthlyInterestRate = perncentage / 12 / 100;
      var numberOfPayments = years * 12;

      var calculation =
        (amount *
          monthlyInterestRate *
          Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
        (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);
      setEmi(calculation.toFixed(2));
    }
  };
  return (
    <>
      <CustomModal
        show={show}
        title="Emi Calculator"
        modalHeader
        border
        closeBtn
        onHide={onHide}
        cssClassName="nes__dashboard__modal"
        // size="lg"
      >
        <Form.Group className="mb-4" controlId="companyName">
          <Form.Label>
            Load Amount<span className="text-dark">(&#8377;)</span>
          </Form.Label>
          <Form.Control
            className="rounded-0 border-0"
            type="number"
            placeholder="Enter amount"
            value={amount}
            onChange={(e) => setAmount(parseFloat(e.target.value))}
            min={0}
          />
        </Form.Group>
        <Form.Group className="mb-4" controlId="companyName">
          <Form.Label>
            Interest Rate<span className="text-dark">(%)</span>
          </Form.Label>
          <Form.Control
            className="rounded-0 border-0"
            type="number"
            placeholder="Enter value"
            value={perncentage}
            onChange={(e) => setPercentage(parseFloat(e.target.value))}
            min={0}
          />
        </Form.Group>
        <Form.Group className="mb-4" controlId="companyName">
          <Form.Label>
            Loan Tenure<span className="text-dark">(years)</span>
          </Form.Label>
          <Form.Control
            className="rounded-0 border-0"
            type="number"
            placeholder="Enter value"
            value={years}
            onChange={(e) => setYears(parseFloat(e.target.value))}
            min={0}
          />
        </Form.Group>

        <div className="d-flex gap-4 mt-2">
          <Button
            className="btn-color-primary rounded-pill btn-rounded w-100"
            onClick={handleSaveEmi}
          >
            Calculate
          </Button>
        </div>
        <Typography
          sx={{
            border: "none",
            background: "#eee",
            borderRadius: "5px",
            px: 2,
            mt: 1,
          }}
        >
          Loan Emi Amount Per Month{" "}
          {emi !== "" && (
            <Box component={"span"} sx={{ fontWeight: "bold", float: "right" }}>
              &#8377; {emi}
            </Box>
          )}
        </Typography>
      </CustomModal>
    </>
  );
};

export default EmiCalcultorModal;
