import { Header } from '../features';
import { LoginForm } from '../features/auth';

const Login = () => {
  return (
    <>
      {/* <Header /> */}
      <LoginForm />
    </>
  );
};

export default Login;
