import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Modal,
  Stack,
  FormControl,
  Autocomplete,
  TextField,
  Chip,
} from '@mui/material';
import { Clear } from '@mui/icons-material';
import { CustomBtn } from '../../../../broker/components/mui/CustomBtn';

const AmenitiesModal = (props) => {
  const [modalOpen, setModalOpen] = useState(true);
  const [selectedAmenities, setSelectedAmenities] = useState([]);

  useEffect(() => {
    setSelectedAmenities(props.data2);
  }, [props]);

  const modalContentStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: '1rem',
    borderRadius: '20px',
  };

  const onSubmitHandler = () => {
    setModalOpen(false);

    props.onAmenitiesChange(selectedAmenities);
    props.onHide();
  };

  const onModalClose = () => {
    props.onHide();
  };

  return (
    <Modal
      open={props.show}
      onClose={() => {
        props.onHide(false);
      }}
    >
      <Box
        style={modalContentStyle}
        sx={{
          width: { md: '40%', xs: '80%' },
          Height: { md: '50%', xs: '100%' },
        }}
      >
        <IconButton
          onClick={onModalClose}
          sx={{
            marginLeft: '95%',
            top: '0.1rem',
            color: 'white',
            background: 'black',
            height: '2rem',
            width: '2rem',
            fontWeight: '10px',
            '&:hover': {
              background: 'black',
            },
          }}
        >
          <Clear />
        </IconButton>

        <Typography
          textAlign={'center'}
          variant='h4'
          fontWeight={'bold'}
        >
          Amenities
        </Typography>
        <Stack pt={6} px={4}>
          <FormControl variant='standard'>
            <Typography mb={3} variant='h6'>
              Add Amenities
            </Typography>
            <Autocomplete
              multiple
              limitTags={2}
              id='amenities-autocomplete'
              options={props.data}
              getOptionLabel={(option) => option.name}
              value={selectedAmenities}
              onChange={(event, newValue) => {
                const uniqueAmenities = newValue.filter(
                  (amenity, index, self) =>
                    index ===
                    self.findIndex(
                      (am) => am.name === amenity.name
                    )
                );
                setSelectedAmenities(uniqueAmenities);
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder='Add more' />
              )}
            />
          </FormControl>
        </Stack>

        <Box
          mt={5}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CustomBtn
            variant='contained'
            color='primary'
            onClick={onSubmitHandler}
            width='60vh'
          >
            Submit
          </CustomBtn>
        </Box>
      </Box>
    </Modal>
  );
};

export default AmenitiesModal;
