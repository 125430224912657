import { Card, Form, Row, Col, FloatingLabel } from 'react-bootstrap';
import React, { useState } from 'react';
import Select from 'react-select';

import Testimonial from '../comment-history-testimonial/CommentHistoryTestimonial';
import './PropertyDetailsForm.css';
import { CustomModal } from '../../../components';
import { Box, Grid, MenuItem, Paper, Rating } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/material';
import { BiBox } from 'react-icons/bi';

const followUpTime = ['Morning Slots', 'Afternoon Slots', 'Evening Slots'];

const timeSlots = [
  '9:00 AM - 10:00 AM',
  '10:00 AM - 11:00 AM',
  '11:00 AM - 12:00 PM',
  '1:00 PM - 2:00 PM',
  '2:00 PM - 3:00 PM',
  '3:00 PM - 4:00 PM',
];

const options = [
  { value: 'hDFC Bank', label: 'HDFC Bank' },
  { value: 'bank2', label: 'Bank2' },
  { value: 'Bank3', label: 'Bank3' },
];

const PropertyDetailsForm = (show, onHide) => {
  const [selectedSlot, setSelectedSlot] = useState(null);

  return (
    <>
      <CustomModal
        show={show}
        title='Visit Summary Form'
        modalHeader
        border
        closeBtn
        onHide={onHide}
        cssClassName='nes__dashboard__modal'
        // size="lg"
      >
        <Form>
          <Row className='mb-3'>
            <Box xl={12}>
              <Form.Label>
                Client Name<span className='text-dark'>*</span>
              </Form.Label>
              <Form.Control
                className='form-control-sm'
                type='text'
                placeholder='Enter client name'
                required
              />
            </Box>
            <Grid container spacing={2} mt={4}>
              <Grid item xl={6}>
                <Form.Label>
                  Visit ID<span className='text-dark'>*</span>
                </Form.Label>
                <Form.Control
                  className='form-control-sm'
                  type='tel'
                  placeholder='Enter client ID'
                  required
                />
              </Grid>
              <Grid item xl={6}>
                <Form.Label>
                  Last Visit Date<span className='text-dark'>*</span>
                </Form.Label>
                <Form.Control
                  className='form-control-sm'
                  type='date'
                  placeholder='Last Visit Date'
                  required
                />
              </Grid>
            </Grid>

            <Box xs={12} className='mb-4' mt={4}>
              <Form.Label>Property Description</Form.Label>
              <Grid container spacing={2}>
                {[...Array(4)].map((_, index) => (
                  <Grid item key={index} xl={3}>
                    <Box
                      variant='outlined'
                      sx={{
                        width: '6.5rem',
                        height: '2.5rem',
                        overflow: 'hidden',

                        textTransform: 'none',
                        border: '1px solid #C7C9D9',
                        borderRadius: '10px',
                        color: 'black',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      Sky Apartment..
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Row>

          <Box xs={12} mt={4}>
            <Form.Label>Visit / Follow Up Comment</Form.Label>
            <Form.Control
              className='form-control-sm'
              as='textarea'
              placeholder='Enter Here.......'
              style={{ height: '100px' }}
            />
          </Box>

          <Box xs={12} mt={4}>
            <Form.Label>Comment History</Form.Label>
            <Paper sx={{ height: '20rem', overflow: 'scroll' }}>
              {Array.from({ length: 3 }, (_, index) => (
                <Testimonial />
              ))}
            </Paper>
          </Box>

          <Box xs={12} md={2} lg={4} mt={4}>
            {' '}
            <h6 className='mt-5 mb-2'>Follow Up Date</h6>
            <Form.Control type='date' />
          </Box>

          <Box xs={12} mt={4}>
            <h6 className='mb-3'>Follow Up Time</h6>
            <Grid container spacing={2}>
              {[...Array(4)].map((_, index) => (
                <Grid item key={index} xl={4}>
                  <Button
                    variant='outlined'
                    sx={{
                      width: '9rem',
                      height: '2.5rem',
                      overflow: 'hidden',

                      textTransform: 'none',
                      border: '1px solid #C7C9D9',
                      borderRadius: '10px',
                      color: 'black',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    10AM
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box xs={12} my={4}>
            <h6 className='mb-3'>Visit Status</h6>
            <Form.Select>
              <option>Select</option>
              <option value='Resolved'>Resolved</option>
              <option value='Pending'>Pending</option>
              <option value='Rejected'>Rejected</option>
            </Form.Select>
          </Box>

          <Paper mt={6}>
            <Grid container>
              <Grid item xl={6} px={2} py={3}>
                {' '}
                <h6>Ratings & Review</h6>
              </Grid>
              <Grid item xl={6} textAlign={'end'} px={2} py={3}>
                <Rating />
              </Grid>
            </Grid>
          </Paper>
          <Box mt={2}>
            <Grid container>
              <Grid item xl={6} py={3}>
                <Button
                  variant='contained'
                  type='submit'
                  className='btn-color-primary px-5'
                  sx={{
                    width: '100%',
                    background: '#278FD9',
                    height: '3rem',
                    borderRadius: '220px',
                    textTransform: 'none',
                  }}
                >
                  Loan Support
                </Button>
              </Grid>
              <Grid item xl={6} textAlign={'end'} px={2} py={3}>
                <Button
                  variant='contained'
                  type='submit'
                  className='btn-color-primary px-5'
                  sx={{
                    width: '100%',
                    background: '#278FD9',
                    height: '3rem',
                    borderRadius: '220px',
                    textTransform: 'none',
                  }}
                >
                  Visit Claim
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            {' '}
            <Button
              variant='contained'
              type='submit'
              className='btn-color-primary px-5'
              sx={{
                width: '100%',
                background: '#278FD9',
                height: '3rem',
                borderRadius: '220px',
                textTransform: 'none',
              }}
            >
              Save
            </Button>
          </Box>
        </Form>

        {/* disbursements details */}
      </CustomModal>
    </>
  );
};

export default PropertyDetailsForm;
