import { Card, Button, Row, Col } from 'react-bootstrap';
import './BrokerageManagementCard.css';
import { useNavigate } from 'react-router-dom';

const BrokerageManagementCard = ({
  type,
  text,
  eligibleClaim,
  claimHistory,
}) => {

  const navigate = useNavigate();

  


  const handleClick = () => {
    if (eligibleClaim?._id?.startsWith("BTPRT")) {
      navigate(`/broker/property-eligible-claim-details/${eligibleClaim?._id}`);
      localStorage.removeItem("claimHistory");
    } else {
      if(eligibleClaim?.claimId){
        navigate(`/broker/visit-claim-details/${eligibleClaim?.claimId}`);
      }else{
        navigate(`/broker/visit-eligible-claim-details/${eligibleClaim?._id}`);
      }
    }
  };
  const handleClaimHistory = (claimType) => {
    if (claimType === "property") {
      localStorage.setItem("claimHistory", 1);
      navigate(`/broker/property-eligible-claim-details/${claimHistory?.boughtPropertyId?._id}`);
    } else {
      navigate(`/broker/visit-claim-details/${claimHistory?._id}`);
    }
  };
  

  return (
    <>
      <Card className="brokerage__management__card border-0">
        <Row className="g-0">
          {type === 'raise' && (
            <>
              <Col md={4}>
                <Card.Img
                  src="/assets/broker/broker-img.png"
                  alt="Media"
                  className="img-fluid w-100"
                  style={{height:'180px'}}
                />
              </Col>

              <Col md={8}>
                <Card.Body>
                  <Card.Title className="d-flex justify-content-between align-items-center">
                    <span className="text-truncate fw-semibold">
                      {eligibleClaim?.propertyId?.name}
                    </span>
                    <span className="text-muted m-0">
                      {eligibleClaim?._id?.startsWith("BTPRT") ? eligibleClaim?.bookingDate : eligibleClaim?.date?.length > 0 ? eligibleClaim?.date[0] : ""}
                    </span>
                  </Card.Title>
                  {type !== 'assigned' && (
                    <Card.Text className="text-muted m-0 mb-3">
                      {eligibleClaim?.propertyId?.location}
                    </Card.Text>
                  )}
                  <Row>
                    <Col>
                      <Card.Text className="text-muted m-0">
                        Builder Name
                      </Card.Text>
                      <Card.Text>{eligibleClaim?.builderId?.companyName}</Card.Text>
                    </Col>
                    <Col>
                      <Card.Text className="text-muted m-0">
                        Brokerage Value
                      </Card.Text>
                      <Card.Text>₹  { eligibleClaim?._id.startsWith("BTPRT") ? eligibleClaim?.brokerageValue : eligibleClaim?.visitBrokerage}</Card.Text>
                    </Col>
                    <Col>
                      <Card.Text className="text-muted m-0">
                        Customer Name
                      </Card.Text>
                      <Card.Text>{eligibleClaim?.customerId?.clientName}</Card.Text>
                    </Col>
                  </Row>
                  <Button
                    variant="primary"
                    className={`mt-3 rounded-pill w-100 text-white`}
                    // className={`mt-3 rounded-pill w-100  ${
                    //   type === 'raise' || type === 'claimSubmitted'
                    //     ? `raise claim-submitted`
                    //     : type === 'paymentReceived' ||
                    //       type === 'paid' ||
                    //       type === 'assigned'
                    //     ? 'custom-green-btn'
                    //     : 'claim-approved'
                    // }`}
                    onClick={()=>handleClick()}
                  >
                    { eligibleClaim?._id.startsWith("BTPRT") ? "View Brokerage Claim" : "View Visit Claim"}
                  </Button>
                </Card.Body>
              </Col>
            </>
          )}

          {type === 'claimHistory' && (
            <>
              <Col md={4}>
                <Card.Img
                  src={claimHistory?.propertyId?.images[0]}
                  alt="Media"
                  className="img-fluid w-100 h-100"
                />
              </Col>

              <Col md={8}>
                <Card.Body>
                  <Card.Title className="d-flex justify-content-between align-items-center">
                    <span className="text-truncate fw-semibold">
                      {claimHistory?.propertyId?.name}
                    </span>
                    <span className="text-muted m-0">
                      {new Date(claimHistory?.createdAt).toLocaleDateString('en-CA')}
                      </span>
                  </Card.Title>
                  {type !== 'assigned' && (
                    <Card.Text className="text-muted m-0 mb-3">
                      {claimHistory?.propertyId?.location}
                    </Card.Text>
                  )}
                  <Row>
                    <Col>
                      <Card.Text className="text-muted m-0">
                        Builder Name
                      </Card.Text>
                      <Card.Text>{claimHistory?.builderId?.companyName}</Card.Text>
                    </Col>
                    <Col>
                      <Card.Text className="text-muted m-0">
                        Brokerage Value*
                      </Card.Text>
                      <Card.Text>{claimHistory?.brokerageAmount}</Card.Text>
                    </Col>
                    <Col>
                      <Card.Text className="text-muted m-0">
                        Customer Name
                      </Card.Text>
                      <Card.Text>{claimHistory?.boughtPropertyId?.customerName ? claimHistory?.boughtPropertyId?.customerName: claimHistory?.visitId?.clientName}</Card.Text>
                    </Col>
                  </Row>
                  <Button
                    variant="primary"
                    className={`mt-3 rounded-pill w-100 text-capitalize ${
                      type === 'claimHistory' || type === 'submitted'
                        ? `raise claim-submitted`
                        : type === 'paymentReceived' ||
                          type === 'paid' ||
                          type === 'assigned'
                        ? 'custom-green-btn'
                        : 'claim-approved'
                    }`}
                    onClick={()=>handleClaimHistory(claimHistory?.claimType)}
                  >
                   Claim {claimHistory?.claimStatus}
                  </Button>
                </Card.Body>
              </Col>
            </>
          )}
        </Row>
      </Card>

      {/* <Card className="brokerage__management__card border-0">
        <Row className="g-0">
          {type !== 'assigned' && (
            <Col md={4}>
              <Card.Img
                src="/assets/broker/broker-img.png"
                alt="Media"
                className="img-fluid w-100 h-100"
              />
            </Col>
          )}
          <Col md={type === 'assigned' ? 12 : 8}>
            <Card.Body>
              <Card.Title className="d-flex justify-content-between align-items-center">
                <span className="text-truncate fw-semibold">
                  Sky Dandelions Apartment
                </span>{' '}
                <span className="text-muted m-0">12/12/2022</span>
              </Card.Title>
              {type !== 'assigned' && (
                <Card.Text className="text-muted m-0 mb-3">
                  Sector-29,Gurugram
                </Card.Text>
              )}
              <Row>
                <Col>
                  <Card.Text className="text-muted m-0">Builder Name</Card.Text>
                  <Card.Text>Lorem Ipsum</Card.Text>
                </Col>
                <Col>
                  <Card.Text className="text-muted m-0">Lorem Ipsum</Card.Text>
                  <Card.Text>₹ 3.94 L</Card.Text>
                </Col>
                <Col>
                  <Card.Text className="text-muted m-0">
                    Customer Name
                  </Card.Text>
                  <Card.Text>Lorem Ipsum</Card.Text>
                </Col>
              </Row>
              <Button
                variant="primary"
                className={`mt-3 rounded-pill w-100 ${
                  type === 'raise' || type === 'claimSubmitted'
                    ? `raise claim-submitted`
                    : type === 'paymentReceived' ||
                      type === 'paid' ||
                      type === 'assigned'
                    ? 'custom-green-btn'
                    : 'claim-approved'
                }`}
              >
                {text}
              </Button>
            </Card.Body>
          </Col>
        </Row>
      </Card> */}
    </>
  );
};

export default BrokerageManagementCard;
