import AccordionCard from '../accordion/AccordionCard';
import './RaiseQuery.css';
import { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { apiEndpoints } from '../../../Api/ApiEndpoint';
import { getAPI } from '../../../Api/ApiRequest';
import { Box, IconButton, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const dummyData2 = [
  { heading: 'Contact 24×7 Help' },
  { heading: 'Email Us' },
];

const RaiseQuery = () => {
  const [queryData, setQueryData] = useState([]);
  const builderID = localStorage.getItem('builderId');

  useEffect(() => {
    const getQuery = async () => {
      const response = await getAPI(
        `${apiEndpoints.GET_ALL_BUILDER_QUERY}${builderID}`
      );
      setQueryData(response.data ?? []);
    };

    getQuery();
  }, [builderID]);

  return (
    <Box>
      {queryData.length > 0 ? (
        queryData.map((item, index) => (
          <Box
            key={index}
            my={2}
            p={3}
            sx={{
              boxShadow: `0px 6.34px 27.74px 0px #00000012`,
              borderRadius: '2rem',
            }}
          >
            <Typography variant='h4' component='h4'>
              {item.subject}
            </Typography>
            <Typography
              pr={6}
              mt={2}
              variant='body1'
              component='h5'
              color=' #8BA0AE'
            >
              {item.description}
            </Typography>
          </Box>
        ))
      ) : (
        <Typography
          my={6}
          textAlign={'center'}
          color={'var(--primary-color-darker)'}
          fontWeight={'bold'}
        >
          No recently added properties available
        </Typography>
      )}

      <div className='mt-5 mb-3 chat-text'>Chat With Us</div>

      {dummyData2.map((item, index) => (
        <Box
          key={index}
          my={2}
          p={3}
          sx={{
            boxShadow: `0px 6.34px 27.74px 0px #00000012`,
            borderRadius: '2rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <Typography variant='h4' component='h4'>
              {item.heading}
            </Typography>
            <IconButton
              sx={{
                color: '#278FD9',
                width: '2.3rem',
                height: '2.3rem',
                boxShadow: `0px 6.34px 27.74px 0px #00000012`,
              }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default RaiseQuery;
