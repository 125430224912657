import React, { useEffect, useState } from 'react';
import DashboardHeader from '../../header/DashboardHeader';
import SearchFilterBox from '../../search-filter/SearchFilter';
import {
  Container,
  Table,
  Button,
  Form,
  Row,
  Col,
} from 'react-bootstrap';
import FileUpload from '../../Builderprofile/file-upload/FileUpload';
import {
  Box,
  Stack,
  IconButton,
  Paper,
  Typography,
  Grid,
  CircularProgress,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import { FormInputField } from '../../../../components/common/FormInputField';
import { RiAddFill } from 'react-icons/ri';
import { ArrowDropDown } from '@mui/icons-material';
import Dropdown from 'react-bootstrap/Dropdown';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CustomizedMenu from '../../../../components/CustomizedMenu/CustomizedMenu';
import Footer from '../../Footer/Footer';

import { useParams } from 'react-router-dom';

import { getAPI } from '../../../Api/ApiRequest';
import { apiEndpoints } from '../../../Api/ApiEndpoint';

import AmenitiesModal from './AmenitiesModal';
import LocationPop from './LocationPop';
import HTMLOutput from '../../../../broker/features/Dashboard/components/HtmlOutput';
import { BsPencilFill } from 'react-icons/bs';
import brochure from '../../../Images/brochure.png';
import { CustomBtn } from '../../../../broker/components/mui/CustomBtn';
import JoditTextBox from '../../../../components/common/JoditTextBox';
import BankPop from './BankPop';
import { data } from 'jquery';
import AllLocationModal from './AllLocationModal';
import { useDispatch } from 'react-redux';
import {
  getLocationByInput,
  updateProperty,
} from '../../../../API/action';
import { enqueueSnackbar } from 'notistack';

const PropertyEdit = () => {
  const params = useParams();
  const [showAmenities, setShowAmenities] = useState(false);
  const [name, setName] = useState('');
  const [showBankModal, setShowBankModal] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [location, setLocation] = useState('');
  const [description, setDescription] = useState('');
  const [propertyCategory, setPropertyCategory] = useState('');

  const handleNameChange = (e) => {
    setName(e.target.value);
    setProperty((prevProperty) => ({
      ...prevProperty,
      name: e.target.value,
    }));
  };
  const handleBankModalOpen = () => {
    setShowBankModal(true);
  };

  // const handleLocationChange = (e) =>
  //   setLocation(e.target.value);

  // const handlePropertyDescriptionChange = (e) =>
  //   setDescription(e.target.value);

  const [uploadedFile, setUploadedFile] = useState(null);
  const [bankData, setBankData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [amenitiesData, setAmenitiesData] = useState([]);

  const [property, setProperty] = useState('');
  const [propertyType, setPropertyType] = useState('flate');
  const [loading, setLoading] = useState(true);
  const [menuValue, setMenuValue] = useState('');
  const [amenitiesDataGet, setAmenitiesDataGet] = useState([]);

  const [locationDataGet, setLocationDataGet] = useState([]);
  const [PropertyLocationDataGet, setPropertyLoanDataGet] =
    useState([]);
  const [loanDataGet, setLoanDataGet] = useState([]);
  const [alllocationDataGet, setAllLocationDataGet] = useState(
    []
  );
  const [showAllLocationModal, setShowAllLocationModal] =
    useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);

 

  const getPropertyById = async () => {
    try {
      const response = await getAPI(
        `${apiEndpoints.getPropertyById}${params?.propertyId}`
      );
      setProperty(response.data[0]);
      setLoading(false);
      console.log('the property is ', response.data[0]);

      setAmenitiesDataGet(response?.data[0]?.amenitiesId);
      console.log('amt', response?.data[0]?.location);
      setLocationDataGet(
        response?.data[0]?.locationAdvantagesId
      );
      setLoanDataGet(response?.data[0]?.loanApprovedByIds);
      setPropertyLoanDataGet(response?.data[0]?.location);
      setLocation(response?.data[0]?.location);
      setName(response?.data[0]?.name);
      setPropertyType(response?.data[0]?.propertyType?.name);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPropertyById();
  }, []);

  const getAmenities = async () => {
    try {
      setLoading(true);
      const response = await getAPI(
        apiEndpoints.getAllAmenities
      );

      console.log('amidata:', response.data);
      setAmenitiesData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const getBank = async () => {
    try {
      setLoading(true);
      const response = await getAPI(apiEndpoints.getAllBank);

      console.log('banner data:', response.data);
      setBankData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getLocationAdvantages = async () => {
    try {
      setLoading(true);
      const response = await getAPI(
        apiEndpoints.getAllLocationAdvantage
      );

      console.log('banner data:', response.data);
      setLocationData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const getLocation = async () => {
    try {
      setLoading(true);
      const response = await getAPI(
        apiEndpoints.GET_ALL_LOCATION
      );

      console.log('ALL location:', response.data);
      setAllLocationDataGet(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPropertyById();
    getLocationAdvantages();
    getBank();
    getAmenities();
    getLocation();
  }, []);

  const onAmenitiesClick = () => {
    setShowAmenities(true);
  };
 
  const onLocationClick = () => {
    setShowLocation(true);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
  };

  const All = (selectedLocations) => {
    setLocationDataGet((prevLocations) => [
      ...selectedLocations,
    ]);
  };
  const handleBankSubmit = (selectedBanks) => {
    setLoanDataGet((prevBank) => [...selectedBanks]);
  };

  const handleAmenitiesChange = (selectedAmenities) => {
    setAmenitiesDataGet((prev) => [...selectedAmenities]);
  };

  const dispatch = useDispatch();

  const handleSubmit = () => {
    setLoading(true);
    let payload = {
      builderId: property?.builderId?._id,
      name: name,
      location: location,
      locationAdvantages: locationDataGet,
      amenities: amenitiesDataGet,
      aboutProject: property?.propertyDescription,
      aboutBuilder: property?.propertyDescription,
      propertyType: propertyType,
      propertyId: property?._id,
      loanApprovedBy: loanDataGet,
    };

    dispatch(
      updateProperty(
        payload,
        (data) => {
          enqueueSnackbar('update property successfully', {
            variant: 'success',
          });
          setLoading(false);
        },
        (err) => {
          const message = err?.response?.data
            ? err?.response?.data
            : 'Error occured While update property';
          enqueueSnackbar(message, { variant: 'error' });
          setLoading(false);
        }
      )
    );
  };

  const properties = [
    { value: 'Flats/Apartment', label: 'Flats/Apartment' },
    { value: 'Farmhouse/Villa', label: 'Farmhouse/Villa' },
    { value: 'Plots/Lands', label: 'Plots/Lands' },
    { value: 'Commercial', label: 'Commercial' },
  ];

  const [locationSearch, setLocationSearch] = useState([]);

  const handleSetFormValues = (name, value) => {
    if (typeof name !== 'string' || typeof value !== 'string') {
      console.error('Both name and value must be strings.');
      return;
    }

    if (name === 'location') {
      getLocationByInput({ searchText: value })
        .then((res) => {
          if (
            res.data &&
            Array.isArray(res.data.data) &&
            res.data.data.length > 0
          ) {
            const locations = res.data.data.map(
              (item) => item.location
            );
            setLocationSearch(locations);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setLocation((prevFormValues) => ({
        ...prevFormValues,
        [name]: value,
      }));
    }
  };

  return (
    <>
      <DashboardHeader />
      {loading ? (
        <Box textAlign={'center'} my={5}>
          {' '}
          <CircularProgress />
        </Box>
      ) : (
        <Container className='pt-5 pb-4 dashboard__wrapper'>
          <SearchFilterBox />
          <h3 className='heading'>Property Edit</h3>
          {/* <Box>
            <h3> Images</h3>

            <FileUpload data={property.images} />
          </Box> */}
          <Box>
            <FormGroup>
              <Stack mt={4}>
                <h3>Name</h3>
                <FormInputField
                  width={1}
                  label='First Name'
                  required={true}
                  value={name}
                  onChange={handleNameChange}
                />
              </Stack>
              <Stack mt={4}>
                <Box
                  mt={4}
                  mb={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <h3>Location</h3>
                </Box>

                <Autocomplete
                  onChange={(event, value1) => {
                    setLocation(value1);
                  }}
                  disablePortal
                  value={location}
                  name='location'
                  id='combo-box-demo'
                  options={locationSearch}
                  sx={{
                    '& .MuiAutocomplete-tag': {
                      borderRadius: 0,
                    },
                    '& .MuiOutlinedInput-root': {
                      border: 'none !important',
                      height: '3rem',

                      '&:hover': {
                        borderColor: 'grey',
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      className='inputBox'
                      {...params}
                      name='location'
                      value={location}
                      onChange={(e) =>
                        handleSetFormValues(
                          e.target.name,
                          e.target.value
                        )
                      }
                      sx={{ bgcolor: '#F8F8F8' }}
                    />
                  )}
                />

                {/* <FormInputField
                  width={1}
                  label='Location'
                  onClick={() => setShowAllLocationModal(true)}
                  required={true}
                  value={PropertyLocationDataGet}
                  onChange={handleLocationChange}
                  endIcon={
                    <LocationOnIcon
                      sx={{ fontSize: '2.5rem', color: '#7C7C7C' }}
                    />
                  }
                /> */}
                {/* <Autocomplete
                  multiple
                  id='tags-filled'
                  options={alllocationDataGet}
                  value={selectedLocations}
                  onChange={handleLocationChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Locations'
                      placeholder='Select Locations'
                    />
                  )}
                /> */}
              </Stack>

              <Stack mt={4}>
                <h3>Property Category</h3>
                <Autocomplete
                  value={propertyType}
                  onChange={(e, newValue) =>
                    setPropertyType(newValue)
                  }
                  options={properties.map((item) => item.value)}
                  renderInput={(params) => (
                    <TextField
                      className='inputBox'
                      {...params}
                      name='location'
                      value={location}
                      onChange={(e) =>
                        handleSetFormValues(
                          e.target.name,
                          e.target.value
                        )
                      }
                      sx={{ bgcolor: '#F8F8F8' }}
                    />
                  )}
                />

                {/* <CustomizedMenu
                  menuItems={menuItems}
                  value={propertyCategory}
                  onChange={handleMenuChange}
                  title={'select the Property'}
                /> */}
              </Stack>
              <Box
                mt={4}
                mb={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <h3>Amenities</h3>
                <IconButton
                  onClick={onAmenitiesClick}
                  sx={{
                    bgcolor: '#278FD9',
                    width: { md: '3rem', xs: '2rem' },
                    height: { md: '3rem', xs: '2rem' },
                    color: 'white',
                    '&:hover': {
                      bgcolor: '#278FD9',
                    },
                  }}
                >
                  <RiAddFill
                    style={{
                      fontSize: '2.5rem',
                      fontWeight: '700',
                    }}
                  />
                </IconButton>
                <AmenitiesModal
                  show={showAmenities}
                  onHide={setShowAmenities}
                  data={amenitiesData}
                  onAmenitiesChange={handleAmenitiesChange}
                  data2={amenitiesDataGet}
                />
              </Box>
              <Box
                py={4}
                sx={{
                  borderRadius: '2rem',
                  boxShadow:
                    '0px 6.87px 30.04px 0px rgba(0, 0, 0, 0.12)',
                  height: 'auto',
                }}
              >
                <Grid container spacing={2}>
                  {amenitiesDataGet?.map(
                    ({ image, name, _id }) => (
                      <Grid
                        item
                        xl={2}
                        lg={2}
                        md={3}
                        xs={6}
                        sm={6}
                        key={_id}
                      >
                        <div className='d-flex flex-column gap-1 justify-content-center align-items-center m-0 p-0'>
                          <img
                            src={image}
                            alt={name}
                            className='img-fluid mb-2'
                            width={30}
                            height={30}
                            style={{
                              opacity: 0.5,
                              marginTop: '1.5rem',
                            }}
                          />
                          <span className='property__details__iconcard__iconsubtitle'>
                            {name}
                          </span>
                        </div>
                      </Grid>
                    )
                  )}
                </Grid>
              </Box>

              <Box
                mt={4}
                mb={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <h3>Location Advanatages</h3>
                <IconButton
                  onClick={onLocationClick}
                  sx={{
                    bgcolor: '#278FD9',
                    width: { md: '3rem', xs: '2rem' },
                    height: { md: '3rem', xs: '2rem' },
                    color: 'white',
                    '&:hover': {
                      bgcolor: '#278FD9',
                    },
                  }}
                >
                  <RiAddFill
                    style={{
                      fontSize: '2.5rem',
                      fontWeight: '700',
                    }}
                  />
                </IconButton>
              </Box>
              <LocationPop
                show={showLocation}
                onHide={setShowLocation}
                locationData={locationData}
                locationData2={locationDataGet}
                onLocationSubmit={All}
              />

              <Box
                py={4}
                sx={{
                  borderRadius: '2rem',
                  boxShadow:
                    '0px 6.87px 30.04px 0px rgba(0, 0, 0, 0.12)',
                  height: 'auto',
                }}
              >
                <Grid container spacing={4}>
                  {!!locationDataGet &&
                    locationDataGet?.length > 0 &&
                    locationDataGet?.map(
                      ({ image, name, distance, _id }) => (
                        <Grid
                          item
                          key={_id}
                          xs={6}
                          sm={6}
                          md={4}
                        >
                          <div className='d-flex flex-column gap-1 justify-content-center align-items-center'>
                            <img
                              src={image}
                              alt={name}
                              className='img-fluid mb-2 '
                              width={30}
                              height={20}
                            />
                            <span className='property__details__iconcard__icontitle'>
                              {name}
                            </span>
                            <span className='property__details__iconcard__iconsubtitle'>
                              {distance}m
                            </span>
                          </div>
                        </Grid>
                      )
                    )}
                </Grid>
              </Box>

              <Stack mt={4}>
                <h3>About The Project</h3>
                <JoditTextBox
                  label='Description'
                  value={property?.propertyDescription}
                  onUpdate={(content, inputFieldValue) => {}}
                  showButtons={false}
                />
              </Stack>

              <Box
                mt={8}
                sx={{
                  borderRadius: '3rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Table
                  className=' table table-secondary profile__transaction table'
                  style={{ background: '#F8F8F8 !important' }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          width: '20%',
                          textAlign: 'center',
                        }}
                      >
                        Payment %
                      </th>
                      <th style={{ width: '80%' }}>Milestone</th>
                    </tr>
                  </thead>
                  <tbody>
                    {' '}
                    <th
                      style={{
                        textAlign: 'center',
                        color: '#7D7F88',
                      }}
                    >
                      10%{' '}
                    </th>
                    <th
                      style={{
                        paddingLeft: '.7rem',
                        color: '#7D7F88',
                      }}
                    >
                      Lorem Ipsum is simply dummy text of the
                      printing and typesetting industry.
                    </th>
                  </tbody>
                  <tbody>
                    {' '}
                    <th
                      style={{
                        textAlign: 'center',
                        color: '#7D7F88',
                      }}
                    >
                      30%{' '}
                    </th>
                    <th
                      style={{
                        paddingLeft: '.7rem',
                        color: '#7D7F88',
                      }}
                    >
                      Lorem Ipsum is simply dummy text of the
                      printing and typesetting industry.
                    </th>
                  </tbody>
                </Table>
              </Box>

              <Box mt={4} mb={2}>
                <Box
                  mt={4}
                  mb={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <h3>Loan Approved By</h3>
                  <IconButton
                    onClick={handleBankModalOpen}
                    sx={{
                      bgcolor: '#278FD9',
                      width: { md: '3rem', xs: '2rem' },
                      height: { md: '3rem', xs: '2rem' },
                      color: 'white',
                      '&:hover': {
                        bgcolor: '#278FD9',
                      },
                    }}
                  >
                    <RiAddFill
                      style={{
                        fontSize: '2.5rem',
                        fontWeight: '700',
                      }}
                    />
                  </IconButton>
                </Box>
                <BankPop
                  show={showBankModal}
                  onHide={() => setShowBankModal(false)}
                  bankData={bankData}
                  bankData2={loanDataGet}
                  onBankSubmit={handleBankSubmit}
                />

                <Box
                  p={2}
                  mt={4}
                  sx={{
                    borderRadius: '2rem',
                    boxShadow:
                      '0px 6.87px 30.04px 0px rgba(0, 0, 0, 0.12)',
                    height: 'auto',
                  }}
                >
                  <Grid
                    container
                    spacing={4}
                    textAlign={'center'}
                  >
                    {loanDataGet?.map((bank) => (
                      <Grid item key={bank?._id} xs={6} md={3}>
                        <Box
                          component={'img'}
                          img
                          src={bank?.image}
                          alt={bank?.name}
                          sx={{
                            width: '100%',
                            height: '5rem',
                            objectFit: 'contain',
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
              <Box
                mt={4}
                mb={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <h3>About the Builder</h3>
              </Box>
              <Box
                xs={12}
                p={4}
                sx={{
                  borderRadius: '2rem',
                  boxShadow:
                    '0px 6.87px 30.04px 0px rgba(0, 0, 0, 0.12)',
                  overflow: 'scroll',
                  width: '100%',
                }}
              >
                <JoditTextBox
                  label='Description'
                  value={property?.propertyDescription}
                  onUpdate={(content, inputFieldValue) => {}}
                  showButtons={false}
                />
              </Box>
              <Box
                mt={4}
                mb={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <h3>View Official Brochure</h3>
                <label
                  htmlFor='file-upload'
                  style={{ cursor: 'pointer' }}
                >
                  <IconButton
                    sx={{
                      bgcolor: '#278FD9',
                      width: { md: '3rem', xs: '2rem' },
                      height: { md: '3rem', xs: '2rem' },
                      color: 'white',
                      '&:hover': {
                        bgcolor: '#278FD9',
                      },
                    }}
                    component='span'
                  >
                    <BsPencilFill
                      style={{
                        width: '1rem',
                        height: '1rem',
                        color: '#fff',
                      }}
                    />
                  </IconButton>
                </label>
              </Box>
              <Box className='gx-4 dashboard-cards align-items-center'>
                {uploadedFile ? (
                  <iframe
                    src={URL.createObjectURL(uploadedFile)}
                    title='Uploaded PDF'
                    width='100%'
                    height='500px'
                    className='rounded my-3 w-100'
                  />
                ) : (
                  <iframe
                    src={property?.brochureUrl}
                    className='rounded my-3 w-100'
                    alt='Default Img'
                    title='exist PDF'
                    width='100%'
                    height={'500px'}
                  />
                )}
              </Box>
              <Box mt={4}>
                <input
                  id='file-upload'
                  type='file'
                  accept='.pdf'
                  style={{ display: 'none' }}
                  onChange={handleFileUpload}
                />
              </Box>
            </FormGroup>
            <Box
              mt={8}
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <CustomBtn
                onClick={handleSubmit}
                variant={'contained'}
                width='60%'
              >
                Submit
              </CustomBtn>
            </Box>
          </Box>
        </Container>
      )}

      <Footer />
    </>
  );
};

export default PropertyEdit;
