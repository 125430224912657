import { Container, Button, Col, Row, Table } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Header from '../../../header/Header';
import Footer from '../../Footer/Footer';
import { useEffect, useState } from 'react';
import axios from 'axios';

import SearchFilterBox from '../../search-filter/SearchFilter';
import PromotedVisitItem from './PromotedVisitItem';
import { getAPI } from '../../../Api/ApiRequest';
import { apiEndpoints } from '../../../Api/ApiEndpoint';
import {
  FormControl,
  InputLabel,
  Modal,
  Select,
  Stack,
  MenuItem,
  Box,
  Grid,
  Typography,
  IconButton,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from '@mui/material';
import { CustomBtn } from '../../../../broker/components/mui/CustomBtn';
import { FaHeadset, FaStar } from 'react-icons/fa';
import ReportAbuseModal from './ReportAbuseModal';
import CountUp from 'react-countup';
const statusLabels = [
  'Pending',
  'Completed',
  'Bought',
  'Follow Up',
  'Negotiation',
];
const PromotedVisits = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [openReportAbuseModal, setOpenReportAbuseModal] = useState(false);
  const [selectedVisitId, setSelectedVisitId] = useState(null);
  const {propertyId} = useParams();

  const handleOpenReportAbuseModal = (visitId) => {
    setOpenReportAbuseModal(true);
    setSelectedVisitId(visitId); // Set the selected visit ID
  };

  const handleCloseReportAbuseModal = () => {
    setOpenReportAbuseModal(false);
    setSelectedVisitId(null); // Reset selected visit ID when modal is closed
  };
  useEffect(() => {
    const getVisit = async () => {
      const response = await getAPI(apiEndpoints.getVisitAnalytics);

      setData(response.data);
    };

    getVisit();
  }, []);

  const [data1, setData1] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getAPI(
        apiEndpoints.getBLAllVisits + propertyId
      );

      const PromotedProperties = response.data.filter(
        (itm) => itm.isPromoted === true
      );
      setData1(PromotedProperties);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [propertyId]);

  const filters = [
    (itm) => itm.visitStatus === 'pending',
    (itm) => itm.visitStatus === 'completed',
    (itm) => itm.visitStatus === 'bought',
    (itm) => itm.visitStatus === 'followUp',
    (itm) => itm.visitStatus === 'negotiation',
  ];
  const filteredLengths = filters.map(
    (filterFn) => data1.filter(filterFn).length
  );

  useEffect(() => {
    console.log('filteredLengths', filteredLengths);
  }, []);

  const tabelData = filterData.map((itm, index) => (
    <PromotedVisitItem itm={itm} className={'mb-3 py-3 border rounded'} />
  ));

  return (
    <>
      {loading ? (
        <Box textAlign={'center'}>
          <CircularProgress />
        </Box>
      ) : data1.length > 0 ? (
        <>
          {' '}
          <Container className='dashboard__wrapper__filter border border-light rounded shadow-sm mt-4'>
            <Box p={4}>
              <Grid
                container
                spacing={2}
                justifyContent={{ md: 'center', xs: 'start' }}
                alignItems={'center'}
              >
                {filteredLengths.map((length, index) => (
                  <Grid item key={index} md={2.4} xs={6}>
                    <Stack textAlign={{ md: 'center', xs: 'start' }}>
                      <Typography
                        variant='h5'
                        fontWeight={'bold'}
                        textAlign={'center'}
                      >
                        <CountUp
                          start={0}
                          end={length}
                          duration={2}
                          separator=','
                          delay={0}
                        ></CountUp>
                      </Typography>

                      <Typography
                        variant='p'
                        component={'h6'}
                        sx={{ opacity: 0.6 }}
                        textAlign={'center'}
                      >
                        {statusLabels[index]}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Container>
          <Box
            mt={4}
            p={2}
            sx={{ width: '100%', overflow: { md: 'auto', xs: 'scroll' } }}
          >
            <TableContainer sx={{ padding: '2rem' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align='center'
                      width='10%'
                      sx={{
                        border: 'none',

                        fontWeight: 'bold',
                      }}
                    >
                      Visit ID
                    </TableCell>
                    <TableCell
                      align='center'
                      width='20%'
                      sx={{
                        borderBottom: 'none',

                        fontWeight: 'bold',
                      }}
                    >
                      Broker Name
                    </TableCell>
                    <TableCell
                      align='center'
                      width='15%'
                      sx={{
                        borderBottom: 'none',

                        fontWeight: 'bold',
                      }}
                    >
                      Visit Date
                    </TableCell>
                    <TableCell
                      align='center'
                      width='16%'
                      sx={{
                        borderBottom: 'none',

                        fontWeight: 'bold',
                      }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      align='center'
                      width='16%'
                      sx={{
                        borderBottom: 'none',

                        fontWeight: 'bold',
                      }}
                    >
                      Raise Dispute
                    </TableCell>
                    {/* <TableCell
                  align='center'
                  width='20%'
                  sx={{
                    borderBottom: 'none',
                    width: '15%',
                    fontWeight: 'bold',
                  }}
                >
                  Ratings & Reviews
                </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data1.map((row, index) => (
                    <>
                      {' '}
                      <Box mt={2}></Box>
                      <TableRow
                        key={index}
                        style={{
                          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                          borderRadius: '15px',
                          marginBottom: '2rem !important',
                          marginTop: '2rem !important',
                          width: '100%',
                          border: 'none !important',
                          height: '4rem',
                        }}
                      >
                        <TableCell align='center'>{row._id}</TableCell>
                        <TableCell align='center'>
                          {' '}
                          {row?.brokerId?.name}
                        </TableCell>
                        <TableCell align='center'>
                          {new Date(row?.createdAt).toLocaleDateString(
                            'en-GB',
                            {
                              day: '2-digit',
                              month: 'short',
                              year: 'numeric',
                            }
                          )}
                        </TableCell>

                        <TableCell align='center'>{row.visitStatus}</TableCell>
                        <TableCell align='center'>
                          {' '}
                          <img
                            src='/assets/MSGhEAD.svg'
                            size='20'
                            className='cursor'
                            alt='img'
                            style={{ opacity: 0.9 }}
                            onClick={() => handleOpenReportAbuseModal(row._id)}
                          />
                        </TableCell>
                        {/* <TableCell align='center'>
                      <div className='star-rating'>
                        {[...Array(5)].map((_, index) => (
                          <FaStar
                            key={index}
                            size={25}
                            color={
                              index < row.RatingsReviews ? '#ffc107' : '#e4e5e9'
                            }
                          />
                        ))}
                      </div>
                    </TableCell> */}
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <ReportAbuseModal
              open={openReportAbuseModal}
              handleClose={handleCloseReportAbuseModal}
              builderId={propertyId}
              visitId={selectedVisitId}
            />
          </Box>
          <Box className='d-flex justify-content-center mt-2'>
            <CustomBtn
              variant='contained'
              width='60%'

              //   onClick={onContinueHandler}
            >
              Download Pdf
            </CustomBtn>
          </Box>
        </>
      ) : (
        <Typography mt={6} textAlign={'center'} color={'#1980c9'}>
          Currently, there are no promoted properties available.
        </Typography>
      )}
    </>
  );
};

export default PromotedVisits;
