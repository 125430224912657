import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import { CustomBtn } from '../../../../broker/components/mui/CustomBtn';

export default function CustomAccordion({ data }) {
  const [expanded, setExpanded] = React.useState(-1);

  const handleChange = (index) => {
    setExpanded(expanded === index ? -1 : index);
  };

  const handleDownload = (fileUrl) => {
    const anchor = document.createElement('a');
    anchor.href = fileUrl;
    anchor.download = 'download.pdf';
    anchor.target = '_blank';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  return (
    <div>
      {data.map((item, index) => (
        <Accordion
          key={index}
          defaultExpanded
          expanded={expanded === index}
          onChange={() => handleChange(index)}
          sx={{
            outline: 'none !important',
            marginTop: '2rem',
            boxShadow:
              expanded === index
                ? '0px 3.87px 10.32px 0px #4A3AFF30'
                : '0px 3.23px 10.32px 0px #080F340F',
            border:
              expanded === index
                ? '1px solid #278FD9 !important'
                : '1px solid #080F340F !important',
          }}
        >
          <AccordionSummary
            expandIcon={
              <IconButton
                sx={{
                  bgcolor: expanded === index ? '#278FD9' : 'white',
                  color: expanded === index ? 'white' : '#278FD9',
                  '&:hover': {
                    bgcolor: '#278FD9',
                    color: 'white',
                  },
                }}
              >
                <ExpandMoreIcon />
              </IconButton>
            }
            aria-controls={`panel-${index}-content`}
            id={`panel-${index}-header`}
          >
            <Typography variant='p' component={'h4'}>
              {item.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <Typography variant='p' component={'h6'} sx={{ opacity: 0.5 }}>
                  {item.answer}
                </Typography>
              </Grid>
              {item.supportFile && (
                <Grid item xs={2}>
                  <Box
                    onClick={() => handleDownload(item.supportFile)}
                    sx={{
                      position: 'relative',
                      height: '100%',
                      right: '3rem',
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                  >
                    <Box
                      component={'img'}
                      src='/assets/pdfIcon.svg'
                      alt='pdf'
                      sx={{
                        position: 'relative',
                        top: { md: '50%', xs: '70%' },
                        left: { md: '70%', xs: '0%' },
                        marginBottom: '2rem',
                        width: { md: '2rem', xs: '1.5rem' },
                      }}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
