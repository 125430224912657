import React, { useEffect, useState } from "react";
import { Footer, Header } from "../features";
import { SearchFilter } from "../../components";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch } from "react-redux";
import { getAllBrokerEarnings, getAllEarningsPdfView, getAllVisits, getTotalEarnings, getUpcomingEarnings, getReferalEarnings } from "../../API/action";
import { Link } from "react-router-dom";
import html2pdf from 'html2pdf.js';

const Wallet = () => {
  const [completedVisits, setCompletedVisits] = useState(0);
  const [pendingVisits, setPendingVisits] = useState(0);
  const [followUpVisits, setFollowUpVisits] = useState(0);
  const [negotiationVisits, setNegoTiationVisits] = useState(0);
  const [boughtVisits, setBoughtVisits] = useState(0);
  const [allBrokerEarning, setAllBrokerEarning] = useState([]);
  const [totalEarning, setTotalEarning] = useState([]);
  const [upcomingEarning, setUpcomingEarning] = useState([]);
  const [referalEarning, setReferalEarning] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {   
    dispatch(
      getAllBrokerEarnings(
        { brokerId: localStorage.getItem("brokerID"), },
        (data) => {
          setAllBrokerEarning(data.data.data);
        },
        (err) => {
          console.log(err);
        }
      )
    );
    dispatch(
      getTotalEarnings(
        { brokerId: localStorage.getItem("brokerID"), },
        (data) => {
          setTotalEarning(data.data.data);
        },
        (err) => {
          console.log(err);
        }
      )
    );
    dispatch(
      getUpcomingEarnings(
        { brokerId: localStorage.getItem("brokerID"),
        claimStatus:"submitted"
         },
        (data) => {
          setUpcomingEarning(data.data.data);
        },
        (err) => {
          console.log(err);
        }
      )
    );
    dispatch(
      getReferalEarnings(
        { refererId: localStorage.getItem("brokerID"), },
        (data) => {
          setReferalEarning(data.data.data);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }, []);

  console.log(allBrokerEarning)

  useEffect(() => {
    dispatch(
      getAllVisits(
        { visitStatus: "pending", brokerId: localStorage.getItem("brokerID") },
        (data) => {
          setPendingVisits(data.data.data.length);
        },
        (err) => {
          console.log(err);
        }
      )
    );

    dispatch(
      getAllVisits(
        {
          visitStatus: "completed",
          brokerId: localStorage.getItem("brokerID"),
        },
        (data) => {
          setCompletedVisits(data.data.data.length);
        },
        (err) => {
          console.log(err);
        }
      )
    );

    dispatch(
      getAllVisits(
        { visitStatus: "followup", brokerId: localStorage.getItem("brokerID") },
        (data) => {
          setFollowUpVisits(data.data.data.length);
        },
        (err) => {
          console.log(err);
        }
      )
    );

    dispatch(
      getAllVisits(
        {
          visitStatus: "negotiation",
          brokerId: localStorage.getItem("brokerID"),
        },
        (data) => {
          setNegoTiationVisits(data.data.data.length);
        },
        (err) => {
          console.log(err);
        }
      )
    );

    dispatch(
      getAllVisits(
        { visitStatus: "bought", brokerId: localStorage.getItem("brokerID") },
        (data) => {
          setBoughtVisits(data.data.data.length);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }, []);


  const handleDownloadClick = () => {
    dispatch(getAllEarningsPdfView({}, (data) => {
      const earningHTML = data.data;
      const options = {
        filename: 'Nesto_Earnings.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };

      html2pdf().from(earningHTML).set(options).save();
    }, (err) => {
      console.log(err);
    }))
  };

  return (
    <>
      <Header />
      <SearchFilter addBtn={false}/>
      <div className="container-lg">
        <h2 className="nes__product__section__title">Wallet</h2>
        <div
          style={{
            backgroundColor: "#ECF7FF",
            borderRadius: "10px",
            padding: "2rem 0px",
          }}
        >
          <div className="d-flex justify-content-center">
            <span
              style={{
                background: "#fff",
                borderRadius: "50%",
                padding: "13px",
              }}
            >
              <img src="/wallet.svg" />
            </span>
          </div>
          <h4 className="text-center">Additional Earning With Nesto</h4>
          <h2 className="text-center">&#8377; {allBrokerEarning?.totalEarnings?.totalEarnings} </h2>
        </div>
        <Accordion
          square="false"
          sx={{
            mt: 3,
            borderRadius: "30px",
            "&::before": { background: "transparent !important" },
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <p style={{ fontSize: "20px",margin:'0', padding:'0', width:'100%' }}>Total Earnings<span style={{float:'right'}}>&#8377; {allBrokerEarning?.totalEarnings?.totalEarnings}</span></p>
          </AccordionSummary>
          <AccordionDetails>
            <Link
              to="/broker/total-earnings"
              style={{ cursor: "pointer", color: "#000" }}
            >
              <Grid container>
                <Grid item md={9}>
                  <b>Earning Breakup</b>
                </Grid>
                <Grid item md={3}></Grid>
                <Grid item md={9} mt={1}>
                  Visit Earning
                </Grid>
                <Grid item md={3}>
                  <Typography textAlign={"right"}>&#8377; {allBrokerEarning?.totalEarnings?.visitEarnings}</Typography>
                </Grid>
                <Grid item md={9} mt={1}>
                  Property Earning
                </Grid>
                <Grid item md={3}>
                  <Typography textAlign={"right"}>&#8377; {allBrokerEarning?.totalEarnings?.propertyEarnings}</Typography>
                </Grid>
              </Grid>
            </Link>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            mt: 3,
            borderRadius: "30px",
            "&::before": { background: "transparent !important" },
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <p style={{ fontSize: "20px",margin:'0', padding:'0', width:'100%' }}>Upcoming Earnings<span style={{float:'right'}}>&#8377; {allBrokerEarning?.upcomingEarnings?.upcomingEarnings}</span></p>
          </AccordionSummary>
          <AccordionDetails>
            <Link
              to="/broker/upcoming-earnings"
              style={{ cursor: "pointer", color: "#000" }}
            >
              <Grid container>
                <Grid item md={9}>
                  <b>Claim Status</b>
                </Grid>
                <Grid item md={3}></Grid>
                <Grid item md={9} mt={1}>
                  Claim Raised
                </Grid>
                <Grid item md={3}>
                  <Typography textAlign={"right"}>&#8377; {allBrokerEarning?.upcomingEarnings?.claimRaised}</Typography>
                </Grid>
                <Grid item md={9} mt={1}>
                  Payment Received
                </Grid>
                <Grid item md={3}>
                  <Typography textAlign={"right"}>&#8377; {allBrokerEarning?.upcomingEarnings?.paymentReceived}</Typography>
                </Grid>
              </Grid>
            </Link>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            mt: 3,
            borderRadius: "30px",
            "&::before": { background: "transparent !important" },
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <p style={{ fontSize: "20px",margin:'0', padding:'0', width:'100%' }}>Visit Details<span style={{float:'right'}}>{allBrokerEarning?.visitDetails?.visitDetails > 1 ? `${allBrokerEarning?.visitDetails?.visitDetails} Visits`: `${allBrokerEarning?.visitDetails?.visitDetails} Visit`}</span></p>
          </AccordionSummary>
          <AccordionDetails>
            <Link
              to="/broker/visit-details"
              style={{ cursor: "pointer", color: "#000" }}
            >
              <Grid container>
                <Grid item md={9}>
                  <b>Visit Status</b>
                </Grid>
                <Grid item md={3}></Grid>
                <Grid item md={9} mt={1}>
                  Completed Visit
                </Grid>
                <Grid item md={3}>
                  <Typography textAlign={"right"}>
                    {allBrokerEarning?.visitDetails?.completeVisit}
                  </Typography>
                </Grid>
                <Grid item md={9} mt={1}>
                  Pending Visit
                </Grid>
                <Grid item md={3}>
                  <Typography textAlign={"right"}>
                    {allBrokerEarning?.visitDetails?.pendingVisit}
                  </Typography>
                </Grid>
                <Grid item md={9} mt={1}>
                  Follow-Up
                </Grid>
                <Grid item md={3}>
                  <Typography textAlign={"right"}>
                    {allBrokerEarning?.visitDetails?.followUpVisit}
                  </Typography>
                </Grid>
                <Grid item md={9} mt={1}>
                  Negotiation
                </Grid>
                <Grid item md={3}>
                  <Typography textAlign={"right"}>
                    {allBrokerEarning?.visitDetails?.negotiationVisit}
                  </Typography>
                </Grid>
                <Grid item md={9} mt={1}>
                  Bought
                </Grid>
                <Grid item md={3}>
                  <Typography textAlign={"right"}>
                    {allBrokerEarning?.visitDetails?.boughtVisit}
                  </Typography>
                </Grid>
              </Grid>
            </Link>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            mt: 3,
            borderRadius: "30px",
            "&::before": { background: "transparent !important" },
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <p style={{ fontSize: "20px",margin:'0', padding:'0', width:'100%' }}>Claim Approval Rate<span style={{float:'right'}}> {isNaN(allBrokerEarning?.claimApprovalRate?.claimApprovalRate)  ? '/-' : `${allBrokerEarning?.claimApprovalRate?.claimApprovalRate}%`}</span></p>
          </AccordionSummary>
          <AccordionDetails>
            {/* <Link
              to="/broker/claim-approvals"
              style={{ cursor: "pointer", color: "#000" }}
            > */}
              <Grid container>
                <Grid item md={9}>
                  <b>Claim Approval</b>
                </Grid>
                <Grid item md={3}></Grid>
                <Grid item md={9} mt={1}>
                  Visit Claim Rate
                </Grid>
                <Grid item md={3}>
                  <Typography textAlign={"right"}>{isNaN(allBrokerEarning?.claimApprovalRate?.visitClaimRate) ? '/-' : `${allBrokerEarning?.claimApprovalRate?.visitClaimRate}%`}</Typography>
                </Grid>
                <Grid item md={9} mt={1}>
                  Property Claim Rate
                </Grid>
                <Grid item md={3}>
                  <Typography textAlign={"right"}>{isNaN(allBrokerEarning?.claimApprovalRate?.propertyClaimRate) ? '/-' : `${allBrokerEarning?.claimApprovalRate?.propertyClaimRate}%`}</Typography>
                </Grid>
              </Grid>
            {/* </Link> */}
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            mt: 3,
            borderRadius: "30px !important",
            "&::before": { background: "transparent !important" },
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <p style={{ fontSize: "20px",margin:'0', padding:'0', width:'100%' }}>Total Referal Earnings<span style={{float:'right'}}>&#8377; {allBrokerEarning?.totalReferralEarnings?.totalReferralEarnings}</span></p>
          </AccordionSummary>
          <AccordionDetails>
            <Link
              to="/broker/total-referal-earnings"
              style={{ cursor: "pointer", color: "#000" }}
            >
              <Grid container>
                <Grid item md={9}>
                  <b>Refer</b>
                </Grid>
                <Grid item md={3}></Grid>
                <Grid item md={9} mt={1}>
                  Total Referral Earnings
                </Grid>
                <Grid item md={3}>
                  <Typography textAlign={"right"}>&#8377; {allBrokerEarning?.totalReferralEarnings?.totalRefers}</Typography>
                </Grid>
              </Grid>
            </Link>
          </AccordionDetails>
        </Accordion>
      </div>
      <Box mt={4} mb={3} sx={{ display: 'flex', justifyContent: 'center' }}>
          {' '}
          <Button
            variant='contained'
            sx={{
              bgcolorcolor: '#278FD9',
              borderRadius: '146.63px',
              color: 'white',
              width: '40%',
              textTransform: 'none',
              height: '3rem',
            }}
            onClick={handleDownloadClick}
          >
            {' '}
            Download PDF
          </Button>
        </Box>
      <Footer />
    </>
  );
};

export default Wallet;
