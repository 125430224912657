import { Header } from '../features';
import RegisterForm from '../features/auth/register/RegisterForm';

const Register = () => {
  return (
    <>
      <RegisterForm />
    </>
  );
};

export default Register;
