import { useEffect, useState } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getBuilderDetail } from '../../../redux/https-requests/builderDetail-http';
import FileUpload from '../file-upload/FileUpload';
import { enqueueSnackbar } from 'notistack';
import { updatePersonalInfoForBuilder, uploadDocToS3 } from '../../../../API/action';
import { FormInputField } from '../../../../components/common/FormInputField';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import ProfileDocUpload from '../file-upload/ProfileDocUpload';
import { CustomBtn } from '../../../../broker/components/mui/CustomBtn';
import { getAPI } from '../../../Api/ApiRequest';
import { apiEndpoints } from '../../../Api/ApiEndpoint';

const PersonalDetailsForm = () => {
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [companyType, setCompanyType] = useState('');
  const [gst, setGst] = useState('');
  const [panOfCompany, setPanOfCompany] = useState('');
  const [builderInfo, setBuilderInfo] = useState('');
  const builderID = localStorage.getItem('builderId');
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [id, setId] = useState('');

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    companyName: '',
    email: '',
    phoneNumber: '',
    address: '',
    companyType: '',
    gst: '',
    panOfCompany: '',
    id: '',
    documents: []
  });


  // function handleUploadImages(event) {
  //   const files = event.target.files;
  
  //   if (!files) {
  //     return; // No files selected
  //   }
  
  //   if (documents.length + files.length > 5) {
  //     enqueueSnackbar("You can not add more than 5 images", { variant: "warning" });
  //     return;
  //   }
  
  //   for (let index = 0; index < files.length; index++) {
  //     const file = files[index];
  //     const formData = new FormData();
  //     setImageLoading(true);
  //     formData.append("documents", file);
  
  //     dispatch(
  //       uploadDocToS3(
  //         formData,
  //         (data) => {
  //           if (data && data.data && data.data.documents && data.data.documents.length > 0) {
  //             setDocuments((oldArr) => [...oldArr, data.data.documents[0]]);
  //           } else {
  //             enqueueSnackbar("Invalid response format from the server", { variant: "error" });
  //           }
  //           setImageLoading(false);
  //         },
  //         (err) => {
  //           enqueueSnackbar(err?.response?.data?.message || "Error occurred while uploading", { variant: "error" });
  //           setImageLoading(false);
  //         }
  //       )
  //     );
  //   }
  // }


  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      companyName: companyName,
      email: email,
      phoneNumber: phoneNumber,
      address: address,
      companyType: companyType,
      gst: gst,
      panOfCompany: panOfCompany,
      id: builderID,
      // documents: documents, 
    }));
  }, [
    companyName,
    email,
    phoneNumber,
    address,
    companyType,
    gst,
    panOfCompany,
    builderID,
    documents // Add documents as a dependency
  ]);
  

  // useEffect(() => {
  //   setFormData({
  //     companyName: companyName,
  //     email: email,
  //     phoneNumber: phoneNumber,
  //     address: address,
  //     companyType: companyType,
  //     gst: gst,
  //     panOfCompany: panOfCompany,
  //     id: builderID,
  //     // documents: documents,
  //   });
  // }, [
  //   companyName,
  //   email,
  //   phoneNumber,
  //   address,
  //   companyType,
  //   gst,
  //   panOfCompany,
  //   builderID,
  // ]);

  const handleFileUpload = (files) => {
    setFormData({
      ...formData,
      documents: [...formData.documents, ...files],
    });
  };

  console.log('form data is', formData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('on submit handler');
  
    const formDataToSend = new FormData();
    formDataToSend.append('companyName', formData.companyName);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('phoneNumber', formData.phoneNumber);
    formDataToSend.append('address', formData.address);
    formDataToSend.append('companyType', formData.companyType);
    formDataToSend.append('gst', formData.gst);
    formDataToSend.append('panOfCompany', formData.panOfCompany);
    formDataToSend.append('id', formData.id);
  
    // Append documents
    formData.documents.forEach((document, index) => {
      formDataToSend.append(`documents[${index}]`, document);
    });
  
    dispatch(
      updatePersonalInfoForBuilder(formDataToSend, handleSuccess, handleError)
    );
  };
  const handleSuccess = () => {
    enqueueSnackbar('Personal details updated successfully!', {
      variant: 'success',
    });
  };

  const handleError = () => {
    enqueueSnackbar('Error while updating personal details!', {
      variant: 'error',
    });
  };

  const fetchBuilderInfo = async () => {
    setLoading(true);
    try {
      const response = await getAPI(
        `${apiEndpoints?.getBuilderById}${builderID}`
      );
      console.log(' fetching builder info:', response);
      setBuilderInfo(response?.data[0]);
      setCompanyName(response?.data[0].companyName);
      setEmail(response?.data[0].email);
      setAddress(response?.data[0].address);
      setPhoneNumber(response?.data[0].phoneNumber);
      setCompanyType(response?.data[0].companyType);
      setGst(response?.data[0].gst);
      setCompanyType(response?.data[0].companyType);
      setPanOfCompany(response?.data[0].panOfCompany);
    } catch (error) {
      console.error('Error fetching builder info:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBuilderInfo();
  }, [dispatch]);



  const [imageLoading, setImageLoading] = useState(false);
 

  // function handleUploadImages(event) {
  //   const files = event.target.files;
  
  //   if (!files) {
  //     return; // No files selected
  //   }
  
  //   if (documents.length + files.length > 5) {
  //     // Check if the total number of documents after adding new files exceeds 5
  //     enqueueSnackbar("You can not add more than 5 images", { variant: "warning" });
  //     return;
  //   }
  
  //   for (let index = 0; index < files.length; index++) {
  //     const file = files[index];
  //     const formData = new FormData();
  //     setImageLoading(true);
  //     formData.append("documents", file);
  
  //     dispatch(
  //       uploadDocToS3(
  //         formData,
  //         (data) => {
  //           if (data && data.data && data.data.documents && data.data.documents.length > 0) {
  //             // Check if the expected properties exist and are valid
  //             setDocuments((oldArr) => [...oldArr, data.data.documents[0]]);
  //           } else {
  //             // Handle the case where the expected properties are missing or invalid
  //             enqueueSnackbar("Invalid response format from the server", { variant: "error" });
  //           }
  //           setImageLoading(false);
  //         },
  //         (err) => {
  //           enqueueSnackbar(err?.response?.data?.message || "Error occurred while uploading", { variant: "error" });
  //           setImageLoading(false);
  //         }
  //       )
  //     );
  //   }
  // }
  
  
console.log(documents)



  return (
    <Box px={{ md: 6, xs: 1 }}>
      <h3>Personal Details</h3>
      <br />
      <Form className='profile__form row' onSubmit={handleSubmit}>
        <Typography mt={2} mb={1} fontWeight={600}>
          Company Name
        </Typography>
        <FormInputField
          width={1}
          type='text'
          label='DLF'
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          name='companyName'
        />

        <Typography mt={2} mb={1} fontWeight={600}>
          Email
        </Typography>
        <FormInputField
          width={1}
          type='email'
          label='-----@gmail.com'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <Typography mt={2} mb={1} fontWeight={600}>
          Phone Number
        </Typography>
        <FormInputField
          width={1}
          Type='number'
          id='formatted-numberformat-input'
          label='4857788898'
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />

        <Typography mt={2} mb={1} fontWeight={600}>
          Address
        </Typography>
        <FormInputField
          width={1}
          type='text'
          label='Sector 13,Karnal'
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />

        <Typography mt={2} mb={1} fontWeight={600}>
          Company Type
        </Typography>
        <FormInputField
          width={1}
          type='text'
          label='WD3 12 3321D'
          value={companyType}
          onChange={(e) => setCompanyType(e.target.value)}
        />

        <Typography mt={2} mb={1} fontWeight={600}>
          GST
        </Typography>
        <FormInputField
          width={1}
          type='text'
          label='WD3 12 3321D'
          value={gst}
          onChange={(e) => setGst(e.target.value)}
        />

        <Typography mt={2} mb={1} fontWeight={600}>
          PAN of the Company
        </Typography>
        <FormInputField
          width={1}
          type='text'
          label='WD3 12 3321D'
          value={panOfCompany}
          onChange={(e) => setPanOfCompany(e.target.value)}
        />

        {/* <Box my={4}>
          <Typography mt={2} mb={1} fontWeight={600}>
            Upload documents
          </Typography>
          <ProfileDocUpload onFileUpload={handleFileUpload} />


          <Grid container spacing={2}>

<Grid item md={2.5} xs={4}>
<label
  htmlFor="profile-image-upload"
  style={{ display: "block", cursor: "pointer",  }} // Corrected 'mt' to 'marginTop'
>

<Box
          sx={{
            width: { md: '9.373rem', xs: '9rem' },
            height: { md: '9.373rem', xs: '9rem' },
            borderRadius: '15px',
            bgcolor: '#F8F8F8',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            cursor: 'pointer',
          }}
        >
           {imageLoading ? <CircularProgress/> : 
           <>
          <img
            src='/assets/uploadIconProfile.svg'
            alt='img'
            style={{ width: '30%' }}
          />
          <Typography variant='p'>Upload Document</Typography>
          </>
        }
        </Box>

  <input
    id="profile-image-upload"
    type="file"
    accept="image/*"
    style={{ display: "none" }}
    onChange={handleUploadImages}
  />
</label>
</Grid>

            {documents.map((item, index) => (
              <Grid item md={2.5} xs={4}>
                <Box>
                  <Box
                    className="drag-drop-text"
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: '15px',
                        // objectFit: 'cover',
                      }}
                      src={item}
                      alt="img"
                    />
                  </Box>
                 
                </Box>
              </Grid>
            ))}
          </Grid>
        
   


        </Box> */}

        <div className='d-flex gap-4 mt-5'>
          <CustomBtn variant='outlined' width={'50%'}>
            Cancel
          </CustomBtn>
          <CustomBtn variant='contained' width={'50%'} type='submit'>
            Save
          </CustomBtn>
        </div>
      </Form>
    </Box>
  );
};

export default PersonalDetailsForm;
