import React from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import { SearchFilter } from '../../../../../components';
import flatsIcon from '../../assets/images/flats.svg';
import farmHouseIcon from '../../assets/images/farmhouse.svg';
import plotsIcon from '../../assets/images/plots.svg';
import commercialIcon from '../../assets/images/commercial.svg';
import './PromotedProperty.css';
import {Header} from '../../../../features';
// import Header from '../../../../Common/Header/Header';
import ProductCard from '../product-card/ProductCard';
import { useAuth } from '../../../../services/api';
import { useGetAllPropertyQuery } from '../../../../hooks/LoginQuery';
import SearchFilterBox from '../../../../../builder/dashboard/search-filter/SearchFilter';
import HeaderCategory from '../properties/HeaderCategory';

const RecentlyAddedProperties = () => {
  useAuth();
  const {
    getAllPropertyIsLoading,
    getAllPropertyResponse, // Changed to use getAllPropertyResponse directly
  } = useGetAllPropertyQuery();

  // No need for filtering, directly use getAllPropertyResponse
  const allProperties = getAllPropertyResponse || []; // Ensure there's a default value in case getAllPropertyResponse is undefined

  return (
    <>
      <Header />
      <Container fluid='lg'>
        <HeaderCategory/>

        <section className='mt-3'>
        <SearchFilter addBtn={false}/>
        </section>

        <section className='nes__product__section mt-5'>
          <Row className='gy-3'>
            <Col
              sm={12}
              className='d-flex justify-content-between align-items-center mb-3'
            >
              <h2 className='nes__product__section__title'>Recently Added Properties</h2>
              {/* <div className='form-check form-switch'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  role='switch'
                  id='flexSwitchCheckDefault'
                />
              </div> */}
            </Col>
            {allProperties.map((property) => (
              <Col
                key={property?._id}
                xs={12}
                sm={12}
                md={4}
                className='mb-4 gap-4'
              >
                <ProductCard {...property} pagination  view={"broker"} isOpen/>
              </Col>
            ))}
          </Row>
        </section>
      </Container>
    </>
  );
};

export default RecentlyAddedProperties;
