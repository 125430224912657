import CreatableSelect from "react-select/creatable";
import { Button, Col, Form, Row } from "react-bootstrap";
import { CustomModal } from "../../../../../components";
import RangeSlider from "../../../../../components/range-slider/RangeSlider";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addCustomer, getLocationByInput } from "../../../../../API/action";

const options = [
  { value: "2BHK", label: "2 BHK" },
  { value: "3BHK", label: "3 BHK" },
];

const AddCustomerModal = ({ show, onHide }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [altNumber, setAltNumber] = useState("");
  const [unitType, setUnitType] = useState([]);
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [loading, setLoading] = useState(false);
  const [preferredLocation, setPreferredLocation] = useState([]);
  const [locationSearch, setLocationSearch] = useState([]);
  const unitTypes = [
    { value: "1RK", label: "1RK" },
    { value: "2BHK", label: "2BHK" },
    { value: "3BHK", label: "3BHK" },
    { value: "4BHK", label: "4BHK" },
    { value: "5BHK", label: "5BHK" },
    { value: "5+BHK", label: "5+BHK" },
    { value: "Low Rise", label: "Low Rise" },
    { value: "High Rise", label: "High Rise" },
    { value: "Farmhouse Land", label: "Farmhouse Land" },
    { value: "SCO Plot", label: "SCO Plot" },
    { value: "Commercial Land", label: "Commercial Land" },
    { value: "Residential Plot", label: "Residential Plot" },
    { value: "Villa", label: "Villa" },
    { value: "Farmhouse", label: "Farmhouse" },
    { value: "Duplex House", label: "Duplex House" },
    { value: "Builder Floor", label: "Builder Floor" },
    { value: "Commercial Space", label: "Commercial Space" },
    { value: "SCO", label: "SCO" },
    { value: "Warehouse", label: "Warehouse" },
  ];
  // const unitTypeOptions = unitType.map((type) => ({
  //   value: type,
  //   label: type,
  // }));
  // const preferredLocationOptions = preferredLocation.map((location) => ({
  //   value: location,
  //   label: location,
  // }));

  const dispatch = useDispatch();
  const phoneNumberRegex = /^[0-9]{10}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleSaveChanges = () => {
    if (
      !name ||
      !email ||
      !number ||
      unitType?.length == 0 ||
      !min ||
      !max ||
      !preferredLocation
    ) {
      enqueueSnackbar("Please add all the required values", {
        variant: "warning",
      });
    } else if (!phoneNumberRegex.test(number)) {
      enqueueSnackbar("Please enter a valid number", { variant: "warning" });
    } else if (!emailRegex.test(email)) {
      enqueueSnackbar("Please enter a valid email", { variant: "warning" });
    } else if (altNumber && !phoneNumberRegex.test(altNumber)) {
      enqueueSnackbar("Please enter a valid alternate number", {
        variant: "warning",
      });
    } else {
      setLoading(true);
      let payload = {
        clientName: name,
        email,
        phoneNumber: number,
        unitType: unitType.map((item) => item.value),
        minPrice: min,
        maxPrice: max,
        preferredLocation: preferredLocation,
        brokerId: localStorage.getItem("brokerID"),
      };

      if (altNumber) {
        payload = {
          ...payload,
          alternatePhoneNumber: altNumber,
        };
      }

      dispatch(
        addCustomer(
          payload,
          (data) => {
            window.location.reload();
            enqueueSnackbar("Customer has been created successfully", {
              variant: "success",
            });
            setLoading(false);
            onHide();
          },
          (err) => {
            const message = err?.response?.data
              ? err?.response?.data
              : "Error occured While creating customer";
            enqueueSnackbar(message, { variant: "error" });
            setLoading(false);
          }
        )
      );
    }
  };

  const handleSetFormValues = (name, value) => {
    if (name === "location") {
      getLocationByInput({ searchText: value })
        .then((res) => {
          if (res.data.data.length > 0) {
            const locations = res.data.data.map((item) => item.location);
            setLocationSearch(locations);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setPreferredLocation((prevFormValues) => ({
        ...prevFormValues,
        [name]: value,
      }));
    }
  };

  return (
    <CustomModal
      show={show}
      title="Add New Customer"
      modalHeader
      border
      closeBtn
      onHide={onHide}
      cssClassName="nes__dashboard__modal"
      // size="lg"
    >
      <Form className="profile__form ps-2 py-3 custom__modal__form">
        <Form.Group className="mb-4" controlId="companyName">
          <Form.Label>
            Client Name<span className="text-dark">*</span>
          </Form.Label>
          <Form.Control
            className="rounded-0 border-0"
            type="text"
            placeholder="Enter client name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-4" controlId="email">
          <Form.Label>
            Email<span className="text-dark">*</span>
          </Form.Label>
          <Form.Control
            className="rounded-0 border-0"
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-4" controlId="phoneNumber">
          <Form.Label>
            Phone Number<span className="text-dark">*</span>
          </Form.Label>
          <Form.Control
            className="rounded-0 border-0"
            type="number"
            placeholder="Enter phone number"
            value={number}
            maxLength={10}
            onChange={(e) => setNumber(e.target.value.substring(0, 10))}
          />
        </Form.Group>

        <Form.Group className="mb-4" controlId="phoneNumber">
          <Form.Label>Alternate Phone Number</Form.Label>
          <Form.Control
            className="rounded-0 border-0"
            type="number"
            placeholder="Enter alternate phone number"
            value={altNumber}
            onChange={(e) => setAltNumber(e.target.value.substring(0, 10))}
          />
        </Form.Group>

        <Form.Group className="mb-4">
          <Form.Label>
            Unit Type<span className="text-dark">*</span>
          </Form.Label>
          <CreatableSelect
            isMulti
            options={unitTypes}
            onChange={(values) => setUnitType(values)}
            placeholder="Add more"
          />
        </Form.Group>

        <Form.Group className="mb-4">
          <Form.Label>
            Preferred Location<span className="text-dark">*</span>
          </Form.Label>
          {/* <CreatableSelect isMulti placeholder='Add more' /> */}

          <Autocomplete
            onChange={(event, value1) => {
              setPreferredLocation(value1);
            }}
            multiple
            disablePortal
            value={preferredLocation}
            name="location"
            id="combo-box-demo"
            options={locationSearch}
            sx={{
              "& .MuiAutocomplete-tag": {
                borderRadius: 0,
              },
              "& .MuiOutlinedInput-root": {
                border: "none !important",
                height: "3rem",

                "&:hover": {
                  borderColor: "grey",
                },
              },
            }}
            renderInput={(params) => (
              <TextField
                className="inputBox"
                {...params}
                name="location"
                value={preferredLocation}
                onChange={(e) =>
                  handleSetFormValues(e.target.name, e.target.value)
                }
                sx={{ bgcolor: "#F8F8F8" }}
              />
            )}
          />
        </Form.Group>
        <Grid container spacing={2}>
          <Grid item md={6} xs={6} sm={6}>
            <Form.Group className="mb-4" controlId="companyName">
              <Form.Label>
                Min Budget<span className="text-dark">*</span>
              </Form.Label>
              <Form.Control
                className="rounded-0 border-0"
                type="number"
                placeholder="enter value"
                value={min}
                onChange={(e) => setMin(e.target.value)}
              />
            </Form.Group>
          </Grid>
          <Grid item md={6} sm={6} xs={6}>
            <Form.Group className="mb-4" controlId="companyName">
              <Form.Label>
                Max Budget<span className="text-dark">*</span>
              </Form.Label>
              <Form.Control
                className="rounded-0 border-0"
                type="number"
                placeholder="enter value"
                value={max}
                onChange={(e) => setMax(e.target.value)}
              />
            </Form.Group>
          </Grid>
        </Grid>
        {/* <Box display='flex' flexDirection='row' justifyContent='space-between'>
          

          
        </Box> */}

        <div className="d-flex gap-4 mt-2">
          <Button
            className="btn-color-primary rounded-pill btn-rounded w-100"
            onClick={handleSaveChanges}
            disabled={loading}
          >
            {loading ? <CircularProgress /> : "Save Changes"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

export default AddCustomerModal;
