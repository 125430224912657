import { Card, Form, Row, Col, FloatingLabel, Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  Rating,
} from "@mui/material";

import { makeStyles } from "@mui/material";
import { BiBox } from "react-icons/bi";
import { CustomModal } from "../../../../../components";
import { enqueueSnackbar } from "notistack";
import {
  addBoughtProperty,
  updateVisitById,
  uploadDocToS3,
} from "../../../../../API/action";
import { useDispatch } from "react-redux";
import Testimonial from "../../../../../dsa/dashboard/comment-history-testimonial/CommentHistoryTestimonial";
import { useNavigate } from "react-router-dom";
import formatNumber from "../../../../helper/common";

const BoughtPropertyModal = ({
  showBoughtModal,
  onModalHide,
  visitDetails,
}) => {
  const [unitNumber, setUnitNumber] = useState("");
  const [bookingDate, setBookingDate] = useState("");
  const [bookingReferenceNumber, setBookingReferenceNumber] = useState("");
  const [unitType, setUnitType] = useState("");
  const [builderRepresentativeName, setBuilderRepresentativeName] =
    useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [brokerageValue, setBrokerageValue] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [unitTypes, setUnitTypes] = useState([]);

  useEffect(() => {
    if (visitDetails?.propertyId?.floorPlanAndPricing.length > 0) {
      let arr = [];
      for(var x=0; x< visitDetails?.propertyId?.floorPlanAndPricing?.length; x++){
        arr.push(...visitDetails?.propertyId?.floorPlanAndPricing[x].unitType);
      }
      setUnitTypes(arr);
    }
  }, [visitDetails]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!visitDetails) {
      enqueueSnackbar("Please add all the required values", {
        variant: "warning",
      });
    } else {
      setLoading(true);
      let payload = {
        bookingDate: bookingDate,
        unitNumber: unitNumber,
        unitType: unitType,
        builderRepresentativeName: builderRepresentativeName,
        sellingPrice: sellingPrice,
        brokerageValue: brokerageValue,
        customerId: visitDetails?.customerId?._id,
        propertyId: visitDetails?.propertyId?._id,
        builderId: visitDetails?.builderId?._id,
        brokerId: visitDetails?.brokerId?._id,
        visitId: visitDetails?._id,
        customerName: customerName,
        bookingReferenceNumber: bookingReferenceNumber,
      };

      dispatch(
        addBoughtProperty(
          payload,
          (data) => {
            enqueueSnackbar("Success", {
              variant: "success",
            });
            navigate("/broker/brokerageManagement");
            setLoading(false);
          },
          (err) => {
            const message = err?.response?.data
              ? err?.response?.data
              : "Error occured While creating bought property";
            enqueueSnackbar(message, { variant: "error" });
            setLoading(false);
          }
        )
      );
    }
  };

  function handleUploadImages(event) {
    const files = event.target.files;

    if (!files) {
      return; // No files selected
    }

    if (documents.length + files.length > 3) {
      // Check if the total number of documents after adding new files exceeds 5
      enqueueSnackbar("You can not add more than 3 images", {
        variant: "warning",
      });
      return;
    }

    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      const formData = new FormData();
      setImageLoading(true);
      formData.append("documents", file);

      dispatch(
        uploadDocToS3(
          formData,
          (data) => {
            if (
              data &&
              data.data &&
              data.data.documents &&
              data.data.documents.length > 0
            ) {
              // Check if the expected properties exist and are valid
              setDocuments((oldArr) => [...oldArr, data.data.documents[0]]);
            } else {
              // Handle the case where the expected properties are missing or invalid
              enqueueSnackbar("Invalid response format from the server", {
                variant: "error",
              });
            }
            setImageLoading(false);
          },
          (err) => {
            enqueueSnackbar(
              err?.response?.data?.message || "Error occurred while uploading",
              { variant: "error" }
            );
            setImageLoading(false);
          }
        )
      );
    }
  }

  return (
    <>
      <CustomModal
        show={showBoughtModal}
        title="Bought Form"
        modalHeader
        border
        closeBtn
        onHide={onModalHide}
        cssClassName="nes__dashboard__modal"
        // size="lg"
      >
        <Card
          bg="white"
          border="0"
          className="custom-card nes__product__card my-3"
          style={{
            background: "#FFFFFF",
            boxShadow: "0px 3.71188px 16.2395px rgba(0, 0, 0, 0.07)",
            borderRadius: "14px",
          }}
        >
          <div className="position-relative">
            <div className="nes__swiper__slider__new">
              <Card.Img
                variant="top"
                src={
                  visitDetails?.propertyId
                    ? visitDetails?.propertyId?.images[0]
                    : "/assets/broker/property-details.png"
                }
                alt="Product Image"
                className="nes__product__card__image"
              />

              <Button className="nes__product__card__btndark px-4">
                {visitDetails?.propertyId
                  ? visitDetails?.propertyId?.constructionStatus
                  : "-"}
              </Button>
            </div>
          </div>
          <Card.Body className="px-0">
            <div className="px-4">
              <Card.Title className="nes__product__card__title">
                {visitDetails?.propertyId?.name}
              </Card.Title>
              <Card.Subtitle className="mt-1 mb-2 text-muted nes__product__card__subtitle">
                {visitDetails?.propertyId?.location}
              </Card.Subtitle>
              <Row className="my-3 nes__product__card__icons gx-5">
                {visitDetails?.unitType?.map((item, index) => (
                  <Col
                    key={index}
                    className="col-md-3 d-flex flex-column justify-content-center align-items-center"
                  >
                    <img src="/assets/flat.svg" />
                    <span>{item}</span>
                  </Col>
                ))}
              </Row>
            </div>

            <div className="px-4 nes__product__card__price d-flex flex-wrap align-items-center gap-2">
              <h4 className="d-inline-block font-weight-bold m-0">
                {`${formatNumber(visitDetails?.minPrice)} - ${formatNumber(
                  visitDetails?.maxPrice
                )}`}
              </h4>
            </div>
          </Card.Body>
        </Card>
        <Form>
          <Row className="mb-3">
            <Box>
              <Form.Label>
                Date<span className="text-dark">*</span>
              </Form.Label>
              <Form.Control
                className="form-control-sm"
                type="date"
                placeholder="Select a date"
                required
                value={bookingDate}
                onChange={(e) => setBookingDate(e.target.value)}
              />
            </Box>
            <Box mt={3}>
              <Form.Label>
                Booking Reference Number<span className="text-dark">*</span>
              </Form.Label>
              <Form.Control
                className="form-control-sm"
                type="number"
                placeholder="Enter value"
                required
                value={bookingReferenceNumber}
                onChange={(e) => setBookingReferenceNumber(e.target.value)}
              />
            </Box>
            <Box mt={3}>
              <Form.Label>
                Unit Number<span className="text-dark">*</span>
              </Form.Label>
              <Form.Control
                className="form-control-sm"
                type="number"
                placeholder="Enter value"
                required
                value={unitNumber}
                onChange={(e) => setUnitNumber(e.target.value)}
              />
            </Box>
            <Box mt={3}>
              <Form.Label>
                Unit Type<span className="text-dark">*</span>
              </Form.Label>
              <Form.Select
                value={unitType}
                onChange={(e) => setUnitType(e.target.value)}
              >
                <option value="">Select Value</option>
                {unitTypes.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </Form.Select>
            </Box>

            <Box mt={3}>
              <Form.Label>
                Customer Name<span className="text-dark">*</span>
              </Form.Label>
              <Form.Control
                className="form-control-sm"
                type="text"
                placeholder="Enter value"
                required
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
              />
            </Box>
            <Box mt={3}>
              <Form.Label>
                Builder Representative Name<span className="text-dark">*</span>
              </Form.Label>
              <Form.Control
                className="form-control-sm"
                type="text"
                placeholder="Enter value"
                required
                value={builderRepresentativeName}
                onChange={(e) => setBuilderRepresentativeName(e.target.value)}
              />
            </Box>

            <Grid container spacing={2} mt={3}>
              <Grid item xl={6}>
                <Form.Label>
                  Selling Price<span className="text-dark">*</span>
                </Form.Label>
                <Form.Control
                  className="form-control-sm"
                  type="number"
                  placeholder="Enter Value"
                  required
                  value={sellingPrice}
                  onChange={(e) => setSellingPrice(e.target.value)}
                />
              </Grid>
              <Grid item xl={6}>
                <Form.Label>
                  Brokerage Value<span className="text-dark">*</span>
                </Form.Label>
                <Form.Control
                  className="form-control-sm"
                  type="number"
                  placeholder="Enter value"
                  required
                  value={brokerageValue}
                  onChange={(e) => setBrokerageValue(e.target.value)}
                />
              </Grid>
            </Grid>

            <Box mt={3}>
              <Form.Label>Upload Documents</Form.Label>

              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <label
                    htmlFor="profile-image-upload"
                    style={{
                      display: "block",
                      cursor: "pointer",
                      marginTop: 2,
                    }}
                  >
                    {imageLoading ? (
                      <CircularProgress />
                    ) : (
                      <>
                        {documents[0] ? (
                          <img
                            src={documents[0]}
                            alt="upload img"
                            className="img-fluid "
                          />
                        ) : (
                          <div className="doc-card">
                            <img
                              src="/assets/upload-icon.svg"
                              alt="upload img"
                              className="img-fluid "
                            />

                            <div className="d-flex align-items-center gap-1">
                              <span className="drag-drop-text-span fw-bold">
                                Signed sale confirmation form*
                              </span>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    <input
                      id="profile-image-upload"
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleUploadImages}
                    />
                  </label>
                </Grid>
              </Grid>
            </Box>
          </Row>

          <Box mt={2}>
            {" "}
            <Button
              variant="contained"
              type="submit"
              className="btn-color-primary px-5"
              sx={{
                width: "100%",
                background: "#278FD9",
                height: "3rem",
                borderRadius: "220px",
                textTransform: "none",
              }}
              onClick={handleSubmit}
            >
              {loading ? (
                <div
                  class="spinner-border"
                  role="status"
                  style={{ height: "16px", width: "16px" }}
                >
                  <span class="sr-only"></span>
                </div>
              ) : (
                "Save"
              )}
            </Button>
          </Box>
        </Form>
      </CustomModal>
    </>
  );
};

export default BoughtPropertyModal;
