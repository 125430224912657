import { Card, Form, Row, Col, FloatingLabel, Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Box, Grid, MenuItem, Paper, Rating } from "@mui/material";

import { makeStyles } from "@mui/material";
import { BiBox } from "react-icons/bi";
import { CustomModal } from "../../../../../components";
import { enqueueSnackbar } from "notistack";
import { updateVisitById } from "../../../../../API/action";
import { useDispatch } from "react-redux";
import Testimonial from "../../../../../dsa/dashboard/comment-history-testimonial/CommentHistoryTestimonial";
import BoughtPropertyModal from "./BoughtPropertyModal";
import formatNumber from "../../../../helper/common";

const PropertyVisitModal = ({ show, onHide, visitDetails }) => {
  const [showBoughtModal, setShowBoughtModal] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState();
  const [name, setName] = useState("");
  const [visitId, setVisitId] = useState("");
  const [lastDate, setLastDate] = useState("");
  const [comment, setComment] = useState("");
  const [followUpDate, setFollowUpDate] = useState("");
  const [followUpTime, setFollowUpTime] = useState("");
  const [chooseSlot, setChooseSlot] = useState("");
  const [status, setStatus] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [uniqueUnits, setUniqueUnits] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (visitDetails) {
      setName(visitDetails?.clientName || "");
      setVisitId(visitDetails?._id || "");
      setStatus(visitDetails?.visitStatus || "");
      setSelectedSlot(visitDetails?.chooseSlot?.split("-")[1]?.trim() || "");
      setChooseSlot(visitDetails?.chooseSlot || "");

      const date = visitDetails?.date[0]?.toString()?.substring(0, 10);
      const followDate =
        visitDetails?.followUpDate?.length > 0 && visitDetails?.followUpDate[0]?.toString()?.substring(0, 10);
      const parsedDate = date ? new Date(date) : null;
      const parsedFollowDate = followDate ? new Date(followDate) : null;

      if (parsedDate && parsedDate > new Date()) {
        setLastDate("-");
      } else {
        setLastDate(date ? date.substring(0, 10) : "-");
      }

      if (parsedFollowDate && parsedFollowDate < new Date()) {
        setFollowUpDate("-");
      } else {
        setFollowUpDate(followDate ? followDate.substring(0, 10) : "-");
      }
    }
    var arr = [];
    for(var i=0; i< visitDetails?.propertyId?.floorPlanAndPricing?.length; i++){
      arr.push(...visitDetails?.propertyId?.floorPlanAndPricing[i].unitType);
    }
    if(arr.length > 0){
      setUniqueUnits([...new Set(arr)]);
    }
  }, [visitDetails]);

  const handleTimeChange = (slotTime, slot) => {
    setSelectedSlot((prevTime) => (prevTime === slotTime ? null : slotTime));
    setChooseSlot(`${slot}-${slotTime}`);
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if (status.toLowerCase() === "bought") {
      let payload = {
        visitId: visitId,
        visitStatus: status.toLowerCase(),
        chooseSlot: chooseSlot,
        followUpDate: followUpDate !== "" ? followUpDate : null,
      };
      if (comment !== "") {
        payload.comment = comment;
      }
      setIsLoading(true);
      dispatch(
        updateVisitById(
          payload,
          (data) => {
            enqueueSnackbar("Update successful", { variant: "success" });
            setIsLoading(false);
            onHide();
            setShowBoughtModal(true);
          },
          (err) => {
            const message =
              err?.response?.data || "Error occurred while updating info";
            enqueueSnackbar(message, { variant: "error" });
            setIsLoading(false);
          }
        )
      );
    } else if (status.toLowerCase() === "completed") {
      let payload = {
        visitId: visitId,
        visitStatus: status.toLowerCase(),
        chooseSlot: chooseSlot,
        followUpDate: followUpDate !== "" ? followUpDate : null,
      };
      if (comment !== "") {
        payload.comment = comment;
      }
      setIsLoading(true);
      dispatch(
        updateVisitById(
          payload,
          (data) => {
            enqueueSnackbar("Update successful", { variant: "success" });
            setIsLoading(false);
            onHide();
            window.location.reload();
          },
          (err) => {
            const message =
              err?.response?.data || "Error occurred while updating info";
            enqueueSnackbar(message, { variant: "error" });
            setIsLoading(false);
          }
        )
      );
    } else {
      if (followUpDate === "") {
        enqueueSnackbar("Follow-up date needs to be selected", {
          variant: "warning",
        });
      }

      let payload = {
        visitId: visitId,
        visitStatus: status.toLowerCase(),
        chooseSlot: chooseSlot,
        followUpDate: followUpDate,
      };
      // Add visit date to the data if it's not empty
      if (
        visitDetails.followUpDate !== "" &&
        visitDetails.followUpDate !== null
      ) {
        payload.date = visitDetails.followUpDate;
      }
      // Check if comment is not empty and add it to the data
      if (comment !== "") {
        payload.comment = comment;
      }
      setIsLoading(true);
      dispatch(
        updateVisitById(
          payload,
          (data) => {
            enqueueSnackbar("Update successful", { variant: "success" });
            setIsLoading(false);
            onHide();
            window.location.reload();
          },
          (err) => {
            const message =
              err?.response?.data || "Error occurred while updating info";
            enqueueSnackbar(message, { variant: "error" });
            setIsLoading(false);
          }
        )
      );
    }
  };

  return (
    <>
      <CustomModal
        show={show}
        title="Visit Summary Form"
        modalHeader
        border
        closeBtn
        onHide={onHide}
        cssClassName="nes__dashboard__modal"
        // size="lg"
      >
        <Form>
          <Row className="mb-3">
            <Box xl={12}>
              <Form.Label>
                Client Name<span className="text-dark">*</span>
              </Form.Label>
              <Form.Control
                className="form-control-sm"
                type="text"
                placeholder="Enter client name"
                required
                disabled
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Box>
            <Grid container spacing={2} mt={4}>
              <Grid item xl={6}>
                <Form.Label>
                  Visit ID<span className="text-dark">*</span>
                </Form.Label>
                <Form.Control
                  className="form-control-sm"
                  type="tel"
                  placeholder="Enter client ID"
                  required
                  disabled
                  value={visitId}
                  onChange={(e) => setVisitId(e.target.value)}
                />
              </Grid>
              <Grid item xl={6}>
                <Form.Label>
                  Last Visit Date<span className="text-dark">*</span>
                </Form.Label>
                <Form.Control
                  className="form-control-sm"
                  type="date"
                  placeholder="Last Visit Date"
                  required
                  disabled
                  value={lastDate}
                  onChange={(e) => setLastDate(e.target.value)}
                />
              </Grid>
            </Grid>

            <Box xs={12} className="mb-4" mt={4}>
              <Form.Label>Property Description</Form.Label>

              <Box
                variant="outlined"
                sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}
              >
                <p className="customBorder">{visitDetails?.propertyId?.name}</p>
                <p className="customBorder">
                  {visitDetails?.propertyId?.location}
                </p>
                <p className="customBorder">
                  {formatNumber(visitDetails?.propertyId?.minPrice)} - {formatNumber(visitDetails?.propertyId?.minPrice)}
                </p>
                <p className="customBorder">
                  {uniqueUnits?.join(", ")}
                </p>
              </Box>
            </Box>
          </Row>

          <Box xs={12} mt={4}>
            <Form.Label>Visit / Follow Up Comment</Form.Label>
            <Form.Control
              className="form-control-sm"
              as="textarea"
              placeholder="Enter Here......."
              style={{ height: "100px", resize:'none' }}
              value={comment}
              disabled={visitDetails.visitStatus == "bought"}
              onChange={(e) => setComment(e.target.value)}
            />
          </Box>

          {visitDetails?.commentHistory?.length > 0 && (
            <Box xs={12} mt={4}>
              <Form.Label>Comment History</Form.Label>
              <Paper sx={{ maxHeight: "20rem", overflow: "scroll" }}>
                {visitDetails?.commentHistory?.map((comment, index) => (
                  <Testimonial comment={comment} key={index} />
                ))}
              </Paper>
            </Box>
          )}
          <Box xs={12} md={2} lg={4} mt={4}>
            {" "}
            <h6 className="mt-5 mb-2">Follow Up Date</h6>
            <Form.Control
              type="date"
              value={followUpDate}
              disabled={visitDetails.visitStatus == "bought"}
              onChange={(e) => setFollowUpDate(e.target.value)}
            />
          </Box>

          <div>
            <Form.Group className="my-3">
              <Form.Label>
                Choose Slot<span className="text-dark">*</span>
              </Form.Label>
            </Form.Group>

            <Row>
              <Col xs={12} className="mb-4">
                <Form.Label>Morning Slot</Form.Label>
                <div className="d-flex justify-content-between">
                  {["10:00 AM", "11:00 AM", "12:00 AM"].map((slotTime) => (
                    <Button
                      key={slotTime}
                      className="px-5 py-2"
                      variant={
                        selectedSlot === slotTime
                          ? "primary"
                          : "outline-primary"
                      }
                      onClick={() => handleTimeChange(slotTime, "Morning Slot")}
                      disabled={visitDetails?.visitStatus === "bought"}
                    >
                      {slotTime}
                    </Button>
                  ))}
                </div>
              </Col>
              <Col xs={12} className="mb-4">
                <Form.Label>Afternoon Slot</Form.Label>
                <div className="d-flex justify-content-between">
                  {["1:00 PM", "2:00 PM", "3:00 PM"].map((slotTime) => (
                    <Button
                      key={slotTime}
                      className="px-5 py-2"
                      variant={
                        selectedSlot === slotTime
                          ? "primary"
                          : "outline-primary"
                      }
                      onClick={() =>
                        handleTimeChange(slotTime, "Afternoon Slot")
                      }
                      disabled={visitDetails?.visitStatus === "bought"}
                      // disabled={selectedSlot && selectedSlot !== slotTime}
                    >
                      {slotTime}
                    </Button>
                  ))}
                </div>
              </Col>
              <Col xs={12} className="mb-4">
                <Form.Label>Evening Slot</Form.Label>
                <div className="d-flex justify-content-between">
                  {["4:00 PM", "5:00 PM", "6:00 PM"].map((slotTime) => (
                    <Button
                      key={slotTime}
                      className="px-5 py-2"
                      variant={
                        selectedSlot === slotTime
                          ? "primary"
                          : "outline-primary"
                      }
                      onClick={() => handleTimeChange(slotTime, "Evening Slot")}
                      disabled={visitDetails?.visitStatus === "bought"}
                      // disabled={selectedSlot && selectedSlot !== slotTime}
                    >
                      {slotTime}
                    </Button>
                  ))}
                </div>
              </Col>
            </Row>
          </div>

          <Box xs={12} my={4}>
            <h6 className="mb-3">Visit Status</h6>
            <Form.Select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              disabled={visitDetails.visitStatus == "bought"}
            >
              <option disabled>Select</option>
              <option value="bought">Bought</option>
              <option value="followup">FollowUp</option>
              <option value="completed">Completed</option>
              <option value="pending">Pending</option>
              <option value="rejected">Rejected</option>
            </Form.Select>
          </Box>
          <Box mt={2}>
            {" "}
            <Button
              variant="contained"
              type="submit"
              className="btn-color-primary px-5"
              sx={{
                width: "100%",
                background: "#278FD9",
                height: "3rem",
                borderRadius: "220px",
                textTransform: "none",
              }}
              disabled={visitDetails.visitStatus == "bought"}
              onClick={handleUpdate}
            >
              {isLoading ? (
                <div
                  class="spinner-border"
                  role="status"
                  style={{ height: "16px", width: "16px" }}
                >
                  <span class="sr-only"></span>
                </div>
              ) : (
                "Save"
              )}
            </Button>
          </Box>
        </Form>
      </CustomModal>
      <BoughtPropertyModal
        showBoughtModal={showBoughtModal}
        onModalHide={() => setShowBoughtModal(false)}
        visitDetails={visitDetails}
      />
    </>
  );
};

export default PropertyVisitModal;
