import { useSelector } from 'react-redux';
import SearchFilterBox from '../../../../../components/search-filter/SearchFilter';
import useToken from '../../../../hooks/useToken';
import Header from '../header/Header';
import ClientCards from './ClientCard';
import { useGetAllCustomer } from '../../../../hooks/LoginQuery';
import LoadingSpinner from '../../../../Common/loading-spinner/LoadingSpinner';
import ErrorMessage from '../../../../Common/error-message/ErrorMessage';
import { useState } from 'react';
import AddCustomerModal from '../customer-modal/AddCustomerModal';
import AddRequirementModal from '../requirement-modal/AddRequirementModal';

const ClientManagement = () => {
  const [showModal, setShowModal] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenCustomerModal = () => {
    setShowCustomerModal(true);
  };

  const handleCloseCustomerModal = () => {
    setShowCustomerModal(false);
  };

  useToken();
  // const brokerId = useSelector(state => state.auth.brokerID);
  const brokerId = localStorage.getItem('brokerID');

  const {
    getAllCustomerIsLoading,
    getAllCustomerIsError,
    getAllCustomerResponse,
    getAllCustomerError,
    getAllCustomerIsSuccess,
  } = useGetAllCustomer(brokerId);
  console.log('custmor ', getAllCustomerIsSuccess);
  if (getAllCustomerIsLoading) {
    return <LoadingSpinner />;
  }

  if (getAllCustomerIsError) {
    return <ErrorMessage />;
  }

  if (getAllCustomerIsSuccess) {
    return (
      <>
        <Header />
        <SearchFilterBox showClientOnly={true} addBtn={false} onAddButtonClick={handleOpenCustomerModal} />
        <ClientCards
          getAllCustomerResponse={getAllCustomerResponse}
          getAllCustomerIsSuccess={getAllCustomerIsSuccess}
        />
        <AddCustomerModal show={showCustomerModal} onHide={handleCloseCustomerModal} />
        <AddRequirementModal show={showModal} onHide={handleCloseModal} />

      </>
    );
  }
};

export default ClientManagement;
