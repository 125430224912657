import axios from 'axios';
export const baseURL = 'https://backend.nestohub.in/api/';
// export const baseURL = 'https://tv2qqpcg3u.ap-south-1.awsapprunner.com/api/';
const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 30000,
});

const setAuthorizationToken = (token) => {
  if (token) {
    axiosInstance.defaults.headers.common['authorization'] = `${token}`;
  } else {
    delete axiosInstance.defaults.headers.common['authorization'];
  }
};

const executeRequest = (
  method,
  endPoint,
  body,
  successCallback,
  errorCallback,
  manualToken
) => {
  if (manualToken) {
    setAuthorizationToken(manualToken);
  } else {
    const token = localStorage.getItem('authToken');
    setAuthorizationToken('Bearer ' + token);
  }
  const config = {
    method,
    url: endPoint,
    data: body,
    params: method === 'GET' || method === 'DELETE' ? body : null,
  };
  axiosInstance(config)
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error);
    });
};

const getAPI = (
  endPoint,
  body,
  successCallback,
  errorCallback,
  manualToken
) => {
  executeRequest(
    'GET',
    endPoint,
    body,
    successCallback,
    errorCallback,
    manualToken
  );
};

const postAPI = (
  endPoint,
  body,
  successCallback,
  errorCallback,
  manualToken
) => {
  console.log(manualToken);
  executeRequest(
    'POST',
    endPoint,
    body,
    successCallback,
    errorCallback,
    manualToken
  );
};

const putAPI = (
  endPoint,
  body,
  successCallback,
  errorCallback,
  manualToken
) => {
  executeRequest(
    'PUT',
    endPoint,
    body,
    successCallback,
    errorCallback,
    manualToken
  );
};

const patchAPI = (
  endPoint,
  body,
  successCallback,
  errorCallback,
  manualToken
) => {
  executeRequest(
    'PATCH',
    endPoint,
    body,
    successCallback,
    errorCallback,
    manualToken
  );
};

const deleteAPI = (
  endPoint,
  body,
  successCallback,
  errorCallback,
  manualToken
) => {
  executeRequest(
    'DELETE',
    endPoint,
    body,
    successCallback,
    errorCallback,
    manualToken
  );
};

export { axiosInstance, getAPI, postAPI, putAPI, patchAPI, deleteAPI };
