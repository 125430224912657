import React, { useEffect, useState } from "react";
import SearchFilterBox from "../../../dsa/dashboard/search-filter/SearchFilter";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Footer, Header } from "../../features";
import "../../../dsa/dashboard/property-details/BoughtProperty.css";
import "./BrokerPropertyClaim.css";
import { addClaim, getAllClaims, getClaimById, getClaimPDFByID } from "../../../API/action";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import formatDate from "../../helper/formatDate";
import { enqueueSnackbar } from "notistack";
import html2pdf from 'html2pdf.js';

const BrokerVisitClaim = () => {
  const [claim, setClaim] = useState([]);
  const dispatch = useDispatch();
  const { id } = useParams();

  const getData = () => {
    let payload = {
      id: id,
    };
    dispatch(
      getClaimById(
        payload,
        (data) => {
          setClaim(data.data.data[0]);
        },
        (error) => {
          // Handle error
          console.error("Error fetching claim:", error);
        }
      )
    );
  };

  useEffect(() => {
    getData();
  }, [id]);

  console.log(claim);

  const handleClaim = () => {
    if (!claim) {
      enqueueSnackbar("Please add all the required values", {
        variant: "warning",
      });
    } else {
      let payload = {
        claimType: "visit",
        milestoneNumber: "0",
        brokeragePercentage:
          claim?.propertyId?.milestonesTermsConditions[0]?.brokerage,
        brokerageAmount: parseInt(
          Math.floor(claim?.boughtPropertyId?.brokerageValue)
        ).toString(),
        visitId: claim?._id,
        propertyId: claim?.propertyId?._id,
        boughtPropertyId: claim?.boughtPropertyId?._id,
        builderId: claim?.builderId?._id,
        brokerId: claim?.brokerId?._id,
      };

      dispatch(
        addClaim(
          payload,
          (data) => {
            enqueueSnackbar("Claim has been raised successfully", {
              variant: "success",
            });
          },
          (err) => {
            const message = err?.response?.data
              ? err?.response?.data
              : "Error occured While creating claim";
            enqueueSnackbar(message, { variant: "error" });
          }
        )
      );
    }
  };

  const handleClaimPDFDownload = (claimId) => {
    dispatch(getClaimPDFByID(claimId, (data) => {
      console.log(data);
      const invoiceHtml = data; // Assuming data contains the HTML content

      const options = {
        filename: 'claim.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };

      html2pdf().from(invoiceHtml).set(options).save();
    }, (err) => {
      console.log(err);
    }))
  }

  return (
    <>
      <Header />
      <SearchFilterBox addBtn={true} />
      <Container>
        <div className="d-flex justify-content-between mt-5">
          <h3 className="heading">Visit Claim</h3>
          <h6 className="text-secondary claim-id">Eligible Claim ID: {id}</h6>
        </div>
        <Paper sx={{ height: "350px", mt: 2, mb: 2 }}>
          <Box p={4}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Typography className="subText2">Property Name</Typography>
                <Typography className="subText1">
                  {claim?.propertyId?.name}
                </Typography>
              </Box>
              <Box>
                <img src="/assets/location-1.svg" alt="location-1" />
              </Box>
            </Box>
            <Grid container spacing={"auto"}>
              <Grid container spacing={4} mt={2}>
                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Visit Id</Typography>
                  <Typography className="subText1">
                    {claim?.visitId?._id}
                  </Typography>
                </Grid>
                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Visit Date</Typography>
                  <Typography className="subText1">
                    {new Date(claim?.visitId?.createdAt).toLocaleDateString()}
                  </Typography>
                </Grid>
                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Builder Name</Typography>
                  <Typography className="subText1">
                    {claim?.builderId?.companyName}
                  </Typography>
                </Grid>
                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Customer Name</Typography>
                  <Typography className="subText1">
                    {claim?.visitId?.clientName
                      ? claim?.visitId?.clientName
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Unit Type</Typography>
                  <Typography className="subText1">
                    {claim?.visitId?.unitType
                      ? claim?.visitId?.unitType.join(", ")
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>

        <h3 className="heading mt-4">Visit Details</h3>

        <Paper sx={{ minHeight: "200px", height: "auto", mb: 5 }}>
          <Box p={4} position={"relative"}>
            <Grid container spacing={"auto"}>
              <Grid container spacing={2}>
                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Visit Amount</Typography>
                  <Typography className="subText1">
                    Rs. {claim?.visitId?.visitBrokerage}
                  </Typography>
                </Grid>
                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Claim Id</Typography>
                  <Typography className="subText1">
                    {claim?._id ? claim?._id : "-"}
                  </Typography>
                </Grid>
                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Payment Date</Typography>
                  <Typography className="subText1">-</Typography>
                </Grid>

                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Claim PDF</Typography>
                  <Typography className="subText1">
                    <IconButton
                      onClick={() =>
                        handleClaimPDFDownload(claim?._id)
                      }
                    >
                      <InsertDriveFileIcon
                        sx={{
                          width: {
                            md: "2rem",
                          },
                          height: {
                            md: "2rem",
                          },
                        }}
                      />
                    </IconButton>
                  </Typography>
                </Grid>
                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Payment PDF</Typography>
                  <Typography className="subText1"> -</Typography>
                </Grid>

                <Grid item xl={4} sm={6} xs={6}>
                  <Button
                    variant="outlined"
                    sx={{
                      borderRadius: "20px",
                      border: "1px solid #D64D00",
                      background: "#FFE4D4",
                      color: "#D64D00",
                      "&:hover": {
                        color: "#FFA500",
                        border: "1px solid #FFA500",
                        background: "#D64D00",
                      },
                    }}
                    onClick={handleClaim}
                    disabled={claim?.claimStatus === "submitted"}
                  >
                    {claim?.claimStatus === "submitted" ? "submitted" : "Claim"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
      <Footer />
    </>
  );
};

export default BrokerVisitClaim;
