import { Box, Grid, Typography } from '@mui/material';
import SoldIcon from '@mui/icons-material/MonetizationOn';
import { useEffect, useState } from 'react';
import { apiEndpoints } from '../../../Api/ApiEndpoint';
import { getAPI } from '../../../Api/ApiRequest';
import Sold from '../../../Images/sold.png';
import visit from '../../../Images/visits.png';
import Search from '../../../Images/search.png';
import view from '../../../Images/views.png';
import Share from '../../../Images/share.png';
import { useNavigate } from 'react-router-dom';

const Anlytics = ({ propertyId, data, builderId }) => {
  const [analyticsData, setAnalyticsData] = useState({});
  const [propertyData, setPropertyData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAnalyticsData = async () => {
      try {
        const analyticsResponse = await getAPI(
          `${apiEndpoints.getPropertiesAnalyticsForIndividualProperty}/${propertyId}`
        );
        console.log('analyticsResponse', analyticsResponse);
        setAnalyticsData(analyticsResponse.data ?? {});
        setLoading(false);
      } catch (error) {
        console.error('Error fetching analytics data:', error);
        setLoading(false);
      }
    };

    fetchAnalyticsData();
  }, [propertyId]);

  const handleVisitClick = (Id) => {
    navigate(`/builder/home-dashboard/visit/${Id}`);
  };
  const handleClam = (builderId) => {
    navigate(`/builder/home-dashboard/visit/${builderId}`);
  };

  return (
    <>
      <h3 className='heading'>Property Analytics</h3>
      <Box
        p={{ md: 3, xs: 0 }}
        sx={{ border: '2px solid #E3E3E7', borderRadius: '.5rem' }}
      >
        <Grid container spacing={2} justifyContent='center' textAlign='center'>
          <Grid item xs={2} md={2.4} onClick={() => handleClam()}>
            <Box>
              <img
                src={Sold}
                className='rounded my-3'
                alt='Sold'
                style={{ width: '2rem', height: '2rem' }}
              />
            </Box>
            <Typography>{data?.soldCount}</Typography>
            <Typography>Sold</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            md={2.4}
            onClick={() => handleVisitClick(data?._id)}
          >
            <Box>
              <img
                src={visit}
                className='rounded my-3'
                alt='Visit'
                style={{ width: '2rem', height: '2rem' }}
              />
            </Box>
            <Typography>{data?.visitCount}</Typography>
            <Typography>Visit</Typography>
          </Grid>
          <Grid item xs={2} md={2.4}>
            <Box>
              <img
                src={Search}
                className='rounded my-3'
                alt='Search'
                style={{ width: '2rem', height: '2rem' }}
              />
            </Box>
            <Typography>{data?.searchCount}</Typography>
            <Typography>Search</Typography>
          </Grid>
          <Grid item xs={2} md={2.4}>
            <Box>
              <img
                src={view}
                className='rounded my-3'
                alt='View'
                style={{ width: '2rem', height: '2rem' }}
              />
            </Box>
            <Typography>{data?.viewsCount}</Typography>
            <Typography>View</Typography>
          </Grid>
          <Grid item xs={2} md={2.4}>
            <Box>
              <img
                src={Share}
                className='rounded my-3'
                alt='Share'
                style={{ width: '2rem', height: '2rem' }}
              />
            </Box>
            <Typography>{data?.shareCount}</Typography>
            <Typography>Share</Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Anlytics;
