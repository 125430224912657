import { useEffect, useState } from 'react';

export function rotateNumber(num) {
  // Ensure num is between 1 and 4
  if (num < 1 || num > 4) {
    throw new Error(
      'Invalid number. Only numbers between 1 and 4 are allowed.'
    );
  }

  // Calculate the rotated number for 270 degrees
  const rotationMap = {
    1: 0,
    2: 90,
    3: 180,
    4: 270,
  };

  return rotationMap[num];
}

export const SuperImageSize = () => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Return innerWidth or a maximum value of 450
  return Math.min(innerWidth, 450);
};
