import { Col, Container, Row } from 'react-bootstrap';
import ContentCard from './dashboard-info-cards/DashboardInfoCards';
import DashboardHeader from './header/DashboardHeader';
import { Link, Outlet, useLocation } from 'react-router-dom';
import SearchFilterBox from './search-filter/SearchFilter';
// import SearchFilterBox from "../../components/search-filter/SearchFilter";
import PropertyAnalytics from './dashboard-property-analytics/PropertyAnalytics';
import SoldOut from './dashboard-soldout-properties/SoldOut';
import homeImage from '../Images/summer.png';
import PropertyInvoice from './dashboard-property-analytics/PendindInvoice';
import RecentPackage from './dashboard-property-analytics/RecentPackage';
import RecentlyAdded from './dashboard-soldout-properties/RecentlyAdded';
// import Header from "../header/Header";
import Footer from './Footer/Footer';
import React, { useEffect, useState, useRef } from 'react';
import { getBuilderDetail } from '../redux/https-requests/builderDetail-http';
import { useDispatch, useSelector } from 'react-redux';
import Charts from './dashboard-property-analytics/dashboard-property-details/Charts';
import TotalRevenueChart from '../../admin-page/broker-profile/AdminDashboard/TotalRevenue/TotalRevenueChart/TotalRevenueChart';
// import { getAPI } from "../Api/ApiRequest";
// import { apiEndpoints } from "../Api/ApiEndpoint";
import RecentlyAddedProperty from '../../builder/dashboard/dashboard-soldout-properties/dashboard-tabs/RecentlyAddedProperty';

import { Box, Typography } from '@mui/material';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from 'swiper/modules';
import { color } from 'framer-motion';
import { getAPI } from '../Api/ApiRequest';
import { apiEndpoints } from '../Api/ApiEndpoint';
// import RecentlyAddedProperty from './builder/dashboard/dashboard-soldout-properties/dashboard-tabs/RecentlyAddedProperty';

const dashboardInfoCardsData = [];

const HomeDashBoard = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const builderId = useSelector((state) => state?.auth?.builderId);
  const data = useSelector((state) => state?.builderDetail?.details);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);

  console.log('builder id', builderId);
  useEffect(() => {
    if (builderId && builderId != 'undefined') {
      dispatch(getBuilderDetail(builderId));
    }
  }, [dispatch]);

  const filteredData = data.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const [data1, setData1] = useState([]);

  useEffect(() => {
    const getBanner = async () => {
      const response = await getAPI(apiEndpoints?.getAllBanner);
      console.log('Banner response ', response);
      setData1(response?.data);
    };

    getBanner();
  }, []);

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1))',
    zIndex: 1,
    borderRadius: '1rem',
  };
  const images = [
    '/assets/Banner.png',
    '/assets/Banner.png',
    '/assets/Banner.png',
    '/assets/Banner.png',
  ];

  const token = localStorage.getItem('authToken');
  const brokerid = localStorage.getItem('builderId');
  console.log(token, brokerid)

  return (
    <>
      <DashboardHeader builderID={builderId} />
      {/* <Header /> */}

      <Container className='pt-2 pb-4 dashboard__wrapper'>
        <Box sx={{ position: 'relative' }}>
          <Swiper
            className='slider'
            modules={[Autoplay, Pagination]}
            pagination={{ clickable: true }}
            centeredSlides={true}
            slidesPerView={1}
            spaceBetween={15}
            autoplay={{ delay: 3500, disableOnInteraction: false }}
            style={{ borderRadius: '2rem' }}
          >
            {images?.map((image, index) => (
              <SwiperSlide key={index}>
                <Box style={{ position: 'relative' }}>
                  <Box style={overlayStyle}></Box>
                  <img
                    src={image}
                    alt={`img-${index}`}
                    style={{ width: '100%' }}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '20%',
                      left: { md: '10%', xs: '10%' },

                      zIndex: 100,
                      color: 'white',
                    }}
                  >
                    <Typography
                      fontSize={{ md: '3.5rem', xs: '1rem' }}
                      width={'20%'}
                      fontFamily={'Bahnschrift'}
                      fontWeight={'bold'}
                    >
                      Summer Vacation
                    </Typography>
                    <Typography
                      fontSize={{ md: '2rem', xs: '1rem' }}
                      mt={{ md: 4, xs: 1 }}
                      fontFamily={'Bahnschrift'}
                    >
                      All discount up to 60%
                    </Typography>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>

        <SearchFilterBox
          isRoleScreen
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        <PropertyAnalytics />
        <SoldOut builderID={builderId} />
        <RecentlyAdded builderID={builderId} />
        {/* <RecentPackage /> */}
        <PropertyInvoice />
        <Charts currentlyComparingData={data?.currentlyComparing} />
      </Container>

      <Outlet />

      <Footer />
    </>
  );
};

export default HomeDashBoard;
