import React from 'react';
import { Table, Card } from 'react-bootstrap';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import PriceCharts from '../../../../features/Dashboard/components/charts/PriceCharts';

const CurrentlyComparing = ({ data }) => {
  return (
    <Card className={`border-0 shadow-sm property__details__text mb-5 bg-white`} style={{ marginTop: '4rem', borderRadius: '17.69px' }}>
      <Card.Header className='bg-transparent border-0 mt-3 px-0'>
        {' '}
        <Card.Title
          className='fw-bold '
          style={{ fontSize: '20px', marginLeft: '2%' }}
        >
          Currently Comparing
        </Card.Title>
      </Card.Header>
      <Card.Body>

        <div className='table-responsive'>
          <Table bordered>
            <thead>
              <tr>
                <th>Locality</th>
                <th>Current Price</th>
                <th>Last 1 Year</th>
                <th>Last 3 Year</th>
                <th>Last 4 Year</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td style={{ color: '#838383', fontWeight: '700' }}>
                    {item.withLocality}
                  </td>
                  <td style={{ color: '#838383', fontWeight: '700' }}>
                    Rs. {item.currentPrice} / sqft
                  </td>
                  <td style={{ color: '#838383', fontWeight: '700' }}>
                    {item.last1Year > item.currentPrice ? (
                      <FaCaretDown color='red' />
                    ) : (
                      <FaCaretUp color='green' />
                    )}{' '}
                    {item.last1Year != 0 && item.currentPrice != 0 ? (
                      <span>
                        {`${Math.abs(
                          ((item.currentPrice - item.last1Year) /
                            item.last1Year) *
                            100
                        ).toFixed(2)}%`}
                      </span>
                    ) : (
                      <span>0%</span>
                    )}
                  </td>
                  <td style={{ color: '#838383', fontWeight: '700' }}>
                    {item.last3Year > item.currentPrice ? (
                      <FaCaretDown color='red' />
                    ) : (
                      <FaCaretUp color='green' />
                    )}{' '}
                    {item.last3Year != 0 && item.currentPrice != 0 ? (
                      <span>
                        {`${Math.abs(
                          ((item.currentPrice - item.last3Year) /
                            item.last3Year) *
                            100
                        ).toFixed(2)}%`}
                      </span>
                    ) : (
                      <span>0%</span>
                    )}
                  </td>
                  <td style={{ color: '#838383', fontWeight: '700' }}>
                    {item.last4Year > item.currentPrice ? (
                      <FaCaretDown color='red' />
                    ) : (
                      <FaCaretUp color='green' />
                    )}{' '}
                    {item.last4Year != 0 && item.currentPrice != 0 ? (
                      <span>
                        {`${Math.abs(
                          ((item.currentPrice - item.last4Year) /
                            item.last4Year) *
                            100
                        ).toFixed(2)}%`}
                      </span>
                    ) : (
                      <span>0%</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CurrentlyComparing;
