import React from 'react';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/system';

const StyledPolicyButton = styled(Button)(({ theme, ...props }) => ({
  boxShadow: 'none !important',

  textTransform: 'capitalize',
  fontWeight: 500,
  fontSize: theme.breakpoints.up('xl') ? '19px' : '3vh',
  lineHeight: '19px',
  fontFamily: 'Bahnschrift',
  height: props?.height ? props?.height : '6vh',
  width: props.width,
  color: props.variant === 'contained' ? 'white' : '#278FD9',
  backgroundColor: props.variant === 'contained' ? '#278FD9' : 'transparent',
  border: props.variant === 'outlined' ? '2px solid #278FD9' : 'none',
  marginLeft: props.marginLeft,
  borderRadius: props.borderRadius || '28px',
}));

export const CustomBtn = (props) => {
  const theme = useTheme(); // Access the theme object
  return <StyledPolicyButton theme={theme} {...props} />;
};
