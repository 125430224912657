import { useDispatch } from "react-redux";
import "./RaiseQuery.css";
import { useEffect, useState } from "react";
import { getAllRaiseQuery } from "../../../../API/action";
import Card from "react-bootstrap/Card";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Accordion, Button } from "react-bootstrap";
import AccordionCard from "../accordion/AccordionCard";
import { Box, Link, Paper, Typography } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";

const RaiseQuery = () => {
  const [queries, setQueries] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getAllRaiseQuery(
        {},
        (data) => {
          setQueries(data.data.data);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }, []);

  console.log(queries);

  return (
    <>
      {/* <ShadowCard
        itemLength={5}
        title="Loreum ipsum dolor sit amet, consectetur elit"
        text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
         labore et dolore magna aliqua. Ut."
      /> */}

      {/* <div className="notification__wrapper d-flex flex-column">
        {queries && (
          <>
          {queries.map((item, index) => (
        <Card className="border-0 shadow-card notification__card" key={index}>
          <Card.Body className="d-flex align-items-center p-3">
            <Card.Text className="flex-fill m-0">{item?.subject}</Card.Text>
            <Button variant="transparent">
              <RiDeleteBin6Line color="#278FD9" size={30} />
            </Button>
          </Card.Body>
        </Card>
      ))}
          </>
        )}

      </div> */}

      <Accordion className="accordion__wrapper accordion-flush">
        {queries && (
          <>
            {queries.map((item, index) => (
              <Accordion.Item eventKey={`${index}`} key={index}>
                <Accordion.Header>{item?.subject}</Accordion.Header>
                <Accordion.Body className="pt-0">
                  <div className="d-flex">
                    <div className="pe-3 flex-fill">{item?.description}</div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </>
        )}
      </Accordion>

      <div className="mt-5 mb-3 chat-text">Chat With Us</div>
      <Paper elevation={4} sx={{ borderRadius: "10px" }}>
        <Typography px={2} py={1.5}>
          Contact 24x7 Help{" "}
          <Box component={"span"} sx={{ float: "right" }}>
            {" "}
            <ArrowForwardIos />
          </Box>
        </Typography>
      </Paper>
      <Link href="mailto:info@nestohub.in">
        <Paper elevation={4} sx={{ borderRadius: "10px", marginTop:'1rem' }}>
          <Typography px={2} py={1.5}>
            Email Us
            <Box component={"span"} sx={{ float: "right" }}>
              {" "}
              <ArrowForwardIos />
            </Box>
          </Typography>
        </Paper>
      </Link>
      {/* <AccordionCard itemLength={1} accordionTitle="Email Us" /> */}
    </>
  );
};

export default RaiseQuery;
