import { Container, Row, Col } from 'react-bootstrap';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import './PropertyAnalytics.css';
import { useEffect, useState } from 'react';
import { apiEndpoints } from '../../Api/ApiEndpoint';
import { getAPI } from '../../Api/ApiRequest';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../broker/Common/loading-spinner/LoadingSpinner';

const PropertyAnalytics = () => {
  const [data, setData] = useState([]); //Later use redux
  const [loading, setLoading] = useState(false);
  const builderId = useSelector((state) => state?.auth?.builderId);
  console.log('builderId', builderId);
  const builderID = localStorage.getItem('builderId');
  const navigate = useNavigate();
  useEffect(() => {
    const getAnalytics = async () => {
      setLoading(true);
      const response = await getAPI(
        `${apiEndpoints.getPropertyAnalytics}${builderId}`
      );
      console.log('Response from Property by ID API:', response.data);
      setData(response.data ?? []);
      setLoading(false);
    };
    if (builderId && builderId != 'undefined') {
      getAnalytics();
    }
  }, []);

  return (
    <>
      <h3 className='heading mt-2'>Property Analytics</h3>
      <Container className='dashboard__wrapper__filter border border-light rounded-4 shadow-sm mt-2'>
        {loading ? (
          LoadingSpinner
        ) : (
          <Row className='p-2 align-items-center text-center'>
            <Col
              md={3}
              sm={3}
              className='d-grid my-2'
              onClick={() =>
                navigate('/builder/home-dashboard/property', {
                  state: { tab: 'added' },
                })
              }
            >
              {/* {plans} */}
              <span
                style={{
                  color: '#278FD9',
                  fontWeight: 'bold',
                  fontSize: '1.4em',
                  cursor: 'pointer',
                }}
              >
                {data?.listedProperties}
              </span>
              <span style={{ opacity: 0.5, cursor: 'pointer' }}>
                Listed <br></br> Properties
              </span>
            </Col>
            <Col md={3} sm={3} className='d-grid my-2'>
              <span
                style={{
                  color: '#278FD9',
                  fontWeight: 'bold',
                  fontSize: '1.4em',
                  cursor: 'pointer',
                }}
              >
                {data?.soldOutUnits}
              </span>
              <span
                style={{ opacity: 0.5, cursor: 'pointer' }}
                onClick={() =>
                  navigate('/builder/home-dashboard/property', {
                    state: { tab: 'soldOut' },
                  })
                }
              >
                Sold Out <br></br> Units
              </span>
            </Col>
            <Col
              md={3}
              sm={3}
              className='d-grid my-2'
              onClick={() =>
                navigate(`/builder/home-dashboard/visits/${builderId}`)
              }
            >
              <div
                style={{
                  color: '#278FD9',
                  fontWeight: 'bold',
                  fontSize: '1.4em',
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
              >
                {data?.totalVisit}
              </div>
              <span style={{ opacity: 0.5, cursor: 'pointer' }}>
                Total <br></br> Visits
              </span>
            </Col>
            <Col md={3} sm={3} className='d-grid my-2'>
              <span
                style={{
                  color: '#278FD9',
                  fontWeight: 'bold',
                  fontSize: '1.4em',
                  cursor: 'pointer',
                }}
              >
                {data?.averageTimeToSell} Days
              </span>
              <span style={{ opacity: 0.5, cursor: 'pointer' }}>
                Average <br></br> time to sell
              </span>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
  // });
};

export default PropertyAnalytics;
