import React, { useEffect, useState } from 'react';
import SearchFilterBox from '../../../components/search-filter/SearchFilter';
import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material';

import { Footer, Header } from '../../features';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import html2pdf from 'html2pdf.js';
import { getReferalEarnings, getTotalReferalEarningPdf } from '../../../API/action';
import formatDate from '../../helper/formatDate';

const dummyData = [
  {
    claimId: '2111321321',
    transactionId: '866656554444',
    paymentDate: '12 Jan 2023',
    amount: '20,000',
  },
  {
    claimId: '2111321321',
    transactionId: '866656554444',
    paymentDate: '12 Jan 2023',
    amount: '20,000',
  },
  {
    claimId: '2111321321',
    transactionId: '866656554444',
    paymentDate: '12 Jan 2023',
    amount: '20,000',
  },
  {
    claimId: '2111321321',
    transactionId: '866656554444',
    paymentDate: '12 Jan 2023',
    amount: '20,000',
  },
  {
    claimId: '2111321321',
    transactionId: '866656554444',
    paymentDate: '12 Jan 2023',
    amount: '20,000',
  },
  {
    claimId: '2111321321',
    transactionId: '866656554444',
    paymentDate: '12 Jan 2023',
    amount: '20,000',
  },
  {
    claimId: '2111321321',
    transactionId: '866656554444',
    paymentDate: '12 Jan 2023',
    amount: '20,000',
  },
  {
    claimId: '2111321321',
    transactionId: '866656554444',
    paymentDate: '12 Jan 2023',
    amount: '20,000',
  },
  {
    claimId: '2111321321',
    transactionId: '866656554444',
    paymentDate: '12 Jan 2023',
    amount: '20,000',
  },
  {
    claimId: '2111321321',
    transactionId: '866656554444',
    paymentDate: '12 Jan 2023',
    amount: '20,000',
  },
];

const TotalReferalEarnings = () => {

  const [referalEarning, setReferalEarning] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {   
   
    dispatch(
      getReferalEarnings(
        { refererId: localStorage.getItem("referalCode"), },
        (data) => {
          setReferalEarning(data.data.data);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }, []);

  console.log(referalEarning)
  
  const handleDownloadClick = () => {
    dispatch(getTotalReferalEarningPdf({}, (data) => {
      const earningHTML = data.data;
      const options = {
        filename: 'Nesto_Earnings.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };

      html2pdf().from(earningHTML).set(options).save();
    }, (err) => {
      console.log(err);
    }))
  };


// const total = referalEarning.map((item)=>item.amount)
const total = referalEarning.reduce((a, b)=> a + b.amount, 0)

console.log(total)

  return (
    <>
      {' '}
      <Header/>;
      <SearchFilterBox addBtn={false} />;
      <Container>
        <Typography
          my={2}
          className='primary-heading'
          sx={{ fontWeight: 'bold' }}
        >
          Wallet
        </Typography>
        <Paper
          style={{
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: '15px',
          }}
        >
          <Box
            sx={{
              p: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'row',
              height: '5rem',
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>
              Total Referal Earnings
            </Typography>
            <Typography sx={{ fontWeight: 'bold' }}>₹ {total ? total : 0}</Typography>
          </Box>
        </Paper>

        <Box mt={4}>
          <Table>
            <Box mb={4}>
              <TableHead>
                <TableRow sx={{ fontWeight: 'bold' }}>
                  <TableCell
                    align='center'
                    sx={{
                      borderBottom: 'none',
                      width: '10%',
                      fontWeight: 'bold',
                    }}
                  >
                    Broker ID
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{
                      borderBottom: 'none',
                      width: '45%',
                      fontWeight: 'bold',
                    }}
                  >
                    Mobile Number
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{
                      borderBottom: 'none',
                      width: '45%',
                      fontWeight: 'bold',
                    }}
                  >
                    Refer Date
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{
                      borderBottom: 'none',
                      width: '35%',
                      fontWeight: 'bold',
                    }}
                  >
                    Amount
                  </TableCell>
                </TableRow>
              </TableHead>
            </Box>
            <TableBody>
              {referalEarning.map((row, index) => (
                <Box key={index} sx={{ marginBottom: '1rem' }}>
                  <TableRow
                    key={index}
                    style={{
                      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
                      borderRadius: '15px',
                      marginBottom: '2rem !important',
                    }}
                  >
                    <TableCell
                      align='center'
                      sx={{ borderBottom: 'none', width: '10%' }}
                    >
                      {row._id}
                    </TableCell>
                    <TableCell
                      align='center'
                      sx={{ borderBottom: 'none', width: '45%' }}
                    >
                      {row.BrokerPhoneNumber}
                    </TableCell>
                    <TableCell
                      align='center'
                      sx={{ borderBottom: 'none', width: '45%' }}
                    >
                      {formatDate(row.referDate)}
                    </TableCell>
                    <TableCell
                      align='center'
                      sx={{ borderBottom: 'none', width: '25%' }}
                    >
                      {row.amount}
                    </TableCell>
                  </TableRow>
                </Box>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Box mt={4} sx={{ display: 'flex', justifyContent: 'center' }}>
          {' '}
          <Button
            variant='contained'
            sx={{
              bgcolorcolor: '#278FD9',
              borderRadius: '146.63px',
              color: 'white',
              width: '40%',
              textTransform: 'none',
              height: '3rem',
            }}
            onClick={handleDownloadClick}
          >
            {' '}
            Download PDF
          </Button>
        </Box>
        <Box mt={4}></Box>
      </Container>
      <Footer />
    </>
  );
};

export default TotalReferalEarnings;
