import { Form, Button, Alert } from 'react-bootstrap';
import DashboardTabs from '../dashboard-tabs/DashboardTabs';
import FileUpload from '../file-upload/FileUpload';
import { usePutMutation } from '../../../hooks/LoginQuery';
import { apiEndpoints } from '../../../config/apiEndpoints';
import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { updateBankInfo, updatePersonalInfoForBroker, uploadDocToS3 } from '../../../../API/action';
import { useDispatch } from 'react-redux';
import { Box, CircularProgress, Grid } from '@mui/material';
const tabKey2 = ['Personal Info', 'Bank Info'];

// personal info
const PersonalInfo = ({ getBrokerByIdResponse, profileImage }) => {
  console.log("Image", profileImage)
  const {
    mutate: updatePersonalInfo,
    isLoading: isUpdatePersonalInfoLoading,
    isSuccess: isUpdatePersonalInfoSuccess,
    isError,
    error,
    data,
  } = usePutMutation(apiEndpoints.updatePersonalInfo);


  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [reraRegistrationNumber, setReraRegistrationNumber] = useState("");
  const [disableName, setDisableName] = useState(false);
  const [disableEmail, setDisableEmail] = useState(false);
  const [disablePhoneNumber, setDisablePhoneNumber] = useState(false);
  const [disableAddress, setDisableAddress] = useState(false);
  const [disablePanNumber, setDisablePanNumber] = useState(false);
  const [disableReraRegistrationNumber, setDisableReraRegistrationNumber] = useState(false);
  const [documents, setDocuments] = useState([]);
  const dispatch = useDispatch();

  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const phoneRegex = /^[0-9]{10}$/;
  const panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

  useEffect(() => {
    // Set initial values when getBrokerByIdResponse changes
    setName(getBrokerByIdResponse?.name || '');
    setId(getBrokerByIdResponse?._id || '');
    setEmail(getBrokerByIdResponse?.email || '');
    setPhoneNumber(getBrokerByIdResponse?.phoneNumber || '');
    setAddress(getBrokerByIdResponse?.address || '');
    setPanNumber(getBrokerByIdResponse?.panNumber || '');
    setDocuments(getBrokerByIdResponse?.documents || [])
    setReraRegistrationNumber(
      getBrokerByIdResponse?.reraRegistrationNumber || ''
    );
    if(getBrokerByIdResponse?.name){
      setDisableName(true);
    }
    if(getBrokerByIdResponse?.email){
      setDisableEmail(true);
    }
    if(getBrokerByIdResponse?.phoneNumber){
      setDisablePhoneNumber(true);
    }
    if(getBrokerByIdResponse?.address){
      setDisableAddress(true);
    }
    if(getBrokerByIdResponse?.panNumber){
      setDisablePanNumber(true);
    }
    if(getBrokerByIdResponse?.reraRegistrationNumber){
      setDisableReraRegistrationNumber(true);
    }
  }, [getBrokerByIdResponse]);


 
  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (!emailRegex.test(email)) {
      enqueueSnackbar("Please enter a valid email address", { variant: "error" });
      return;
    }
  
    // Validate phone number using regex
    if (!phoneRegex.test(phoneNumber)) {
      enqueueSnackbar("Please enter a valid phone number", { variant: "error" });
      return;
    }
  
    // Validate PAN number using regex
    if (!panRegex.test(panNumber)) {
      enqueueSnackbar("Please enter a valid PAN number", { variant: "error" });
      return;
    }
  
    let payload = {
      id: id,
      name: name,
      email: email,
      address: address,
      phoneNumber: phoneNumber,
      panNumber: panNumber,
    };
    if (reraRegistrationNumber) {
      payload.reraRegistrationNumber = reraRegistrationNumber;
    }
    if (documents) {
      payload.documents = documents;
    }
  
    dispatch(
      updatePersonalInfoForBroker(
        payload,
        (data) => {
          enqueueSnackbar("Update successful", { variant: "success" });
          setLoading(false);
        },
        (err) => {
          const message =
            err?.response?.data || "Error occurred while updating info";
          enqueueSnackbar(message, { variant: "error" });
          setLoading(false);
        }
      )
    );
  };

  const [imageLoading, setImageLoading] = useState(false);
 

  function handleUploadImages(event) {
    const files = event.target.files;
  
    if (!files) {
      return; // No files selected
    }
  
    if (documents.length + files.length > 5) {
      // Check if the total number of documents after adding new files exceeds 5
      enqueueSnackbar("You can not add more than 5 images", { variant: "warning" });
      return;
    }
  
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      const formData = new FormData();
      setImageLoading(true);
      formData.append("documents", file);
  
      dispatch(
        uploadDocToS3(
          formData,
          (data) => {
            if (data && data.data && data.data.documents && data.data.documents.length > 0) {
              // Check if the expected properties exist and are valid
              setDocuments((oldArr) => [...oldArr, data.data.documents[0]]);
            } else {
              // Handle the case where the expected properties are missing or invalid
              enqueueSnackbar("Invalid response format from the server", { variant: "error" });
            }
            setImageLoading(false);
          },
          (err) => {
            enqueueSnackbar(err?.response?.data?.message || "Error occurred while uploading", { variant: "error" });
            setImageLoading(false);
          }
        )
      );
    }
  }
  
  
console.log(documents)

  return (
    <Form className="profile__form ps-2" >

      <Form.Group className="mb-4">
        <Form.Label>Name*</Form.Label>
        <Form.Control
          className="rounded-0"
          type="text"
          name="name"
          disabled={disableName}
          placeholder="enter you name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label>Email*</Form.Label>
        <Form.Control
          className="rounded-0"
          type="email"
          name="email"
          disabled={disableEmail}
          placeholder="enter you email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-4" controlId="phoneNumber">
        <Form.Label>Phone Number*</Form.Label>
        <Form.Control
          className="rounded-0"
          type="tel"
          placeholder="enter you phone number"
          disabled={disablePhoneNumber}
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          name="phoneNumber"
        />
      </Form.Group>

      <Form.Group className="mb-4" controlId="address">
        <Form.Label>Address*</Form.Label>
        <Form.Control
          className="rounded-0"
          type="text"
          placeholder="enter your address"
          value={address}
          disabled={disableAddress}
          onChange={(e) => setAddress(e.target.value)}
          name="address"
        />
      </Form.Group>
      <Form.Group className="mb-4" controlId="address">
        <Form.Label>Pan Number*</Form.Label>
        <Form.Control
          className="rounded-0"
          type="text"
          placeholder="pan number"
          disabled={disablePanNumber}
          value={panNumber}
          onChange={(e) => setPanNumber(e.target.value)}
          name="panNumber"
        />
      </Form.Group>

      <Form.Group className="mb-4" controlId="address">
        <Form.Label>RERA Registration Number</Form.Label>
        <Form.Control
          className="rounded-0"
          type="text"
          placeholder="rera register number"
          disabled={disableReraRegistrationNumber}
          value={reraRegistrationNumber}
          onChange={(e) => setReraRegistrationNumber(e.target.value)}
          name="reraRegistrationNumber"
        />
      </Form.Group>
      <Form.Label>Upload Documents</Form.Label>

    <Grid container spacing={2}>

    <Grid item md={2.5} xs={4}>
    <label
      htmlFor="profile-image-upload"
      style={{ display: "block", cursor: "pointer", marginTop: 2 }} // Corrected 'mt' to 'marginTop'
    >
      <div className="drag-drop-text">
        {imageLoading ? <CircularProgress/> : 
        <img
          src="/assets/upload-icon.svg"
          alt="upload img"
          className="img-fluid mb-2"
        />
  }
        <div className="d-flex align-items-center gap-1">
          <span className="drag-drop-text-span fw-bold">
            Drag and drop files or
          </span>{" "}
          <span className="upload-link color-blue fw-bold">browse</span>
        </div>
        <p>Supported formats: JPEG, PNG</p>
      </div>

      <input
        id="profile-image-upload"
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleUploadImages}
      />
    </label>
    </Grid>

                {documents.map((item, index) => (
                  <Grid item md={2.5} xs={4}>
                    <Box>
                      <Box
                        className="drag-drop-text"
                      >
                        <img
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: '22.6664px',
                          }}
                          // className="img-fluid mb-2"
                          src={item}
                          alt="img"
                        />
                      </Box>
                     
                    </Box>
                  </Grid>
                ))}
              </Grid>
            
       

              

      {/* <FileUpload /> */}

      {/* success message */}
      {isUpdatePersonalInfoSuccess && (
        <Alert variant="success" className="py-1" dismissible>
          <Alert.Heading>Successful Updated</Alert.Heading>
        </Alert>
      )}
      {/* success message code end here */}

      <div className="d-flex gap-4 mt-5">
        <Button
          className="btn-color-outline-primary rounded-100 btn-rounded"
          type="submit"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className="btn-color-primary rounded-100 btn-rounded"
          disabled={loading}
          onClick={handleSubmit}
        >
          {isUpdatePersonalInfoLoading ? (
            <>
              <span>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {'  '}
                Update...
              </span>
            </>
          ) : (
            'Save'
          )}
        </Button>
      </div>
    </Form>
  );
};

const BankInfo = ({ getBrokerByIdResponse }) => {


  const [bankName, setBankName] = useState('');
  const [recipientName, setRecipientName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [confirmAccountNumber, setConfirmAccountNumber] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [id, setId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const [accountNumberBorderColor, setAccountNumberBorderColor] = useState(''); // State to manage the border color
  useEffect(() => {
    // Assuming getBrokerByIdResponse is an object containing the data
    // retrieved from some API call or other source
    if (getBrokerByIdResponse) {
      setBankName(getBrokerByIdResponse.bankName ?? '');
      setAccountNumber(getBrokerByIdResponse.accountNumber ?? '');
      setConfirmAccountNumber(getBrokerByIdResponse.accountNumber ?? '');
      setIfscCode(getBrokerByIdResponse.ifscCode ?? '');
      setId(getBrokerByIdResponse._id ?? '');
      setRecipientName(getBrokerByIdResponse.recipientName ?? '');
      if(getBrokerByIdResponse.accountNumber){
        setIsDisable(true);
      }
    }
  }, [getBrokerByIdResponse]);

  const dispatch = useDispatch();

  // const accountNumberRegex = /^\d{9,18}$/; // Adjust the minimum and maximum digits as needed
  // const ifscCodeRegex = /^[A-Z]{4}\d{7}$/;

  const handleAccountNumberChange = (e) => {
    setAccountNumber(e.target.value);

    // Check if the Confirm Account Number matches
    if (e.target.value === confirmAccountNumber) {
      setAccountNumberBorderColor('green'); // Set green border color
    } else {
      setAccountNumberBorderColor('red'); // Set red border color
    }
  };

  const handleConfirmAccountNumberChange = (e) => {
    setConfirmAccountNumber(e.target.value);

    // Check if the Account Number matches
    if (e.target.value === accountNumber) {
      setAccountNumberBorderColor('green'); // Set green border color
    } else {
      setAccountNumberBorderColor('red'); // Set red border color
    }
  };


  const handleBankSubmit = e => {
    e.preventDefault();

    if (accountNumberBorderColor === 'red') {
      enqueueSnackbar("Account Number and Confirm Account Number must match", { variant: 'error' });
      return;
    }

    // if (!accountNumberRegex.test(getBrokerByIdResponse.accountNumber)) {
    //   enqueueSnackbar("Please enter a valid account number", { variant: 'error' });
    //   return;
    // }

    // // Validate IFSC code using regex
    // if (!ifscCodeRegex.test(getBrokerByIdResponse.ifscCode)) {
    //   enqueueSnackbar("Please enter a valid IFSC code", { variant: 'error' });
    //   return;
    // }

    const formData = {
      id,
      bankName,
      accountNumber,
      recipientName,
      // confirmAccountNumber,
      ifscCode,
    };
    setIsLoading(true)

    dispatch(
      updateBankInfo(formData,
        (data) => {
          // dispatch({
          //   type: "LOG_IN",
          //   formData: data?.data?.user
          // })
          enqueueSnackbar("Bank Details Updated,", { variant: 'success' });
          setIsLoading(false)
          // navigateTo(navigate, '/profile')
        },
        (err) => {

          setIsLoading(false)
          if (err?.request?.response) {
            let errorMessage = JSON.parse(err?.request?.response)?.msg;
            enqueueSnackbar(errorMessage, { variant: 'error' });

          }
        }
      )
    );

  };

  return (
    <Form className="profile__form ps-2">
      <Form.Group className="mb-4">
        <Form.Label>Bank Name*</Form.Label>
        <Form.Control
          className="rounded-0"
          type="text"
          placeholder="Bank Name"
          disabled={isDisable}
          value={bankName}
          onChange={(e) => setBankName(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-4" controlId="accountNumber">
        <Form.Label>Account Number*</Form.Label>
        <Form.Control
          className="rounded-0"
          type="tel"
          placeholder="Account Number"
          disabled={isDisable}
          value={accountNumber}
          onChange={handleAccountNumberChange}
          style={{ borderColor: accountNumberBorderColor }} // Apply border color
        />
      </Form.Group>

      <Form.Group className="mb-4" controlId="confirmAccountNumber">
        <Form.Label>Confirm Account Number*</Form.Label>
        <Form.Control
          className="rounded-0"
          type="tel"
          disabled={isDisable}
          placeholder="Confirm Account Number"
          value={confirmAccountNumber}
          onChange={handleConfirmAccountNumberChange}
          style={{ borderColor: accountNumberBorderColor }} // Apply border color
        />
      </Form.Group>

      <Form.Group className="mb-4" controlId="ifscCode">
        <Form.Label>IFSC Code*</Form.Label>
        <Form.Control
          className="rounded-0"
          type="text"
          disabled={isDisable}
          placeholder="IFSC Code"
          value={ifscCode}
          onChange={(e) => setIfscCode(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Recipient Name*</Form.Label>
        <Form.Control
          className="rounded-0"
          type="text"
          disabled={isDisable}
          placeholder="Enter Recipient Name"
          value={recipientName}
          onChange={(e) => setRecipientName(e.target.value)}
        />
      </Form.Group>

      <div className="d-flex gap-4 mt-5">
        <Button disabled={isDisable} className="btn-color-outline-primary rounded-100 btn-rounded">
          Cancel
        </Button>
        <Button
          type="submit"
          className="btn-color-primary rounded-100 btn-rounded"
          disabled={isDisable}
          onClick={handleBankSubmit}
        >
          {isLoading ? (
            <div class="spinner-border" role="status" style={{ height: '16px', width: '16px' }}>
              <span class="sr-only"></span>
            </div>
          ) : (
            'Save'
          )}
        </Button>
      </div>
    </Form>
  );
};

const PersonalDetailsForm = ({ getBrokerByIdResponse, profileImage }) => {
console.log(profileImage)
  return (
    <>
      <DashboardTabs tabsKey={tabKey2} activeState={tabKey2[0]}>
        <PersonalInfo
          tabKey={tabKey2[0]}
          getBrokerByIdResponse={getBrokerByIdResponse}
          profileImage={profileImage}
        />
        <BankInfo
          tabKey={tabKey2[1]}
          getBrokerByIdResponse={getBrokerByIdResponse}
        />
      </DashboardTabs>
    </>
  );
};

export default PersonalDetailsForm;
