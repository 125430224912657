import React, { useEffect, useRef, useState } from "react";
import SearchFilterBox from "../../../dsa/dashboard/search-filter/SearchFilter";
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Footer, Header } from "../../features";
import "../../../dsa/dashboard/property-details/BoughtProperty.css";
import "./BrokerPropertyClaim.css";
import {
  addClaim,
  addVisitClaim,
  getAllClaims,
  getAllEligibleClaims,
  getClaimById,
  verifyVisitOtp,
} from "../../../API/action";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import formatDate from "../../helper/formatDate";
import { enqueueSnackbar } from "notistack";
import { CustomModal } from "../../../components";
import { Form } from "react-bootstrap";
import { RiTimerLine } from "react-icons/ri";
import OTPInput from "react-otp-input";
import { otpStyle } from "../../../Style";

const VisitEligibleClaim = () => {
  let eligibleClaims = JSON.parse(localStorage.getItem("eligibleClaims"));

  const [claim, setClaim] = useState([]);
  const [visitOTPModal, setVisitOTPModal] = useState(false);
  const [otpPassword, setOtpPassword] = useState(false);
  const [timer, setTimer] = useState(30);
  const [inputOTP, setInputOTP] = useState("");
  const [otp, setOtp] = useState("");
  const [token, setToken] = useState("");
  // const otpInputs = useRef([]);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    let payload = {
      brokerId: localStorage.getItem("brokerID")
    };
    dispatch(
      getAllEligibleClaims(
        payload,
        (data) => {
          console.log(data.data.data.find((item) => item._id === id));
          setClaim(data.data.data.find((item) => item._id === id));
          // localStorage.setItem('eligibleClaims', JSON.stringify(data.data.data));
        },
        (error) => {
          // Handle error
          console.error("Error fetching all claims:", error);
        }
      )
    );
  }, [id]);

  const handleClaim = () => {
    if (!claim) {
      enqueueSnackbar("Please add all the required values", {
        variant: "warning",
      });
    } else {
      let payload = {
        visitId: claim?._id,
      };

      dispatch(
        addVisitClaim(
          payload,
          (data) => {
            enqueueSnackbar("Claim Verification has been started", {
              variant: "success",
            });
            setToken(data.data.data.token);
            setOtp(data.data.data.otp);
            setVisitOTPModal(true);
          },
          (err) => {
            const message = err?.response?.data
              ? err?.response?.data
              : "Error occured While creating claim";
            enqueueSnackbar(message, { variant: "error" });
          }
        )
      );
    }
  };

  const handleOtpInputChange = (e, index) => {
    const otpInput = e.target;
    otpInput.value = otpInput.value.replace(/[^0-9]/g, "");

    if (otpInput.value && otpInput.nextSibling) {
      otpInput.nextSibling.focus();
    }

    // if (index === 5 && otpInputs.current.every(input => input.value)) {
    //   // clearInterval(timerInterval);
    //   if (otpPassword) {
    //     navigate('/dsa/home-dashboard');
    //   }
    // }
  };

  // const handleKeyDown = (e, index) => {
  //   if (e.key === "Tab") {
  //     e.preventDefault();
  //     otpInputs.current[index + 1]?.focus();
  //   } else if (e.key === "ArrowRight" && otpInputs.current[index + 1]) {
  //     otpInputs.current[index + 1].focus();
  //   } else if (e.key === "ArrowLeft" && otpInputs.current[index - 1]) {
  //     otpInputs.current[index - 1].focus();
  //   } else if (e.key === "Backspace" && !otpInputs.current[index].value) {
  //     e.preventDefault();
  //     otpInputs.current[index - 1]?.focus();
  //   }
  // };

  const resendOTP = async () => {
    // resetOtpInputs();
    setInputOTP("");
    startTimer();
    handleClaim();
  };

  // const resetOtpInputs = () => {
  //   otpInputs.current.forEach((input) => {
  //     input.value = '';
  //   });
  //   otpInputs.current[0].focus();
  // };

  useEffect(() => {
    // otpInputs.current[0]?.focus();
  }, []);

  const startTimer = () => {
    setTimer(30);
  };

  useEffect(() => {
    // otpInputs.current[0]?.focus();
    const timerInterval = setInterval(() => {
      if (timer != 0) {
        setTimer((prevTimer) => prevTimer - 1);
      }
    }, 1000);
    return () => clearInterval(timerInterval);
  }, [timer]);

  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const handleverifyVisitOTP = (e) => {
    e.preventDefault();
    if(inputOTP == ""){
      setMessage("Please Enter valid Otp");
    }else{
      dispatch(verifyVisitOtp({otp:parseInt(inputOTP), token: token}, (data)=>{
        setVisitOTPModal(false);
        enqueueSnackbar("Visit Verified Successfully",{variant:'success'});
        navigate("/broker/brokerageManagement");
      },(err) => {
        console.log(err);
      }))
    }
  };

  return (
    <>
      <Header />
      <SearchFilterBox addBtn={true} />
      <Container>
        <div className="d-flex justify-content-between mt-5">
          <h3 className="heading">Visit Claim</h3>
          <h6 className="text-secondary claim-id">Eligible Claim ID: {claim?.claimId ? claim?.claimId : id}</h6>
        </div>
        <Paper sx={{ height: "350px", mt: 2, mb: 2 }}>
          <Box p={4}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Typography className="subText2">Property Name</Typography>
                <Typography className="subText1">
                  {claim?.propertyId?.name}
                </Typography>
              </Box>
              <Box>
                <img src="/assets/location-1.svg" alt="location-1" />
              </Box>
            </Box>
            <Grid container spacing={"auto"}>
              <Grid container spacing={4} mt={2}>
                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Visit Id</Typography>
                  <Typography className="subText1">{claim?._id}</Typography>
                </Grid>
                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Visit Date</Typography>
                  <Typography className="subText1">{claim?.date}</Typography>
                </Grid>
                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Builder Name</Typography>
                  <Typography className="subText1">
                    {claim?.builderId?.companyName}
                  </Typography>
                </Grid>
                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Customer Name</Typography>
                  <Typography className="subText1">
                    {claim?.customerId?.clientName
                      ? claim?.customerId?.clientName
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Unit Type</Typography>
                  <Typography className="subText1">
                    {claim?.unitType ? claim?.unitType[0] : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>

        <h3 className="heading mt-4">Visit Details</h3>

        <Paper sx={{ minHeight: "200px", height: "auto", mb: 5 }}>
          <Box p={4} position={"relative"}>
            <Grid container spacing={"auto"}>
              <Grid container spacing={2}>
                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Visit Amount</Typography>
                  <Typography className="subText1">
                    Rs. {claim?.visitBrokerage}
                  </Typography>
                </Grid>
                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Claim Id</Typography>
                  <Typography className="subText1">{claim?.claimId ? claim?.claimId : "-"}</Typography>
                </Grid>
                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Payment Date</Typography>
                  <Typography className="subText1">-</Typography>
                </Grid>

                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Claim PDF</Typography>
                  <Typography className="subText1">-</Typography>
                </Grid>
                <Grid item xl={4} sm={6} xs={6}>
                  <Typography className="subText2">Payment PDF</Typography>
                  <Typography className="subText1"> -</Typography>
                </Grid>

                <Grid item xl={4} sm={6} xs={6}>
                  <Button
                    variant="outlined"
                    sx={{
                      borderRadius: "20px",
                      border: "1px solid #D64D00",
                      background: "#FFE4D4",
                      color: "#D64D00",
                      "&:hover": {
                        color: "#FFA500",
                        border: "1px solid #FFA500",
                        background: "#D64D00",
                      },
                    }}
                    disabled={claim?.claimId !== undefined}
                    onClick={handleClaim}
                  >
                    {claim?.claimId ? "Submitted" : "Claim"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <CustomModal
          show={visitOTPModal}
          title="Visit Verification Code"
          modalHeader
          border
          closeBtn
          onHide={() => setVisitOTPModal(false)}
          cssClassName="nes__dashboard__modal"
          // size="lg"
        >
          <Form onSubmit={handleverifyVisitOTP}>
            <Box sx={otpStyle}>
              <OTPInput
                value={inputOTP}
                onChange={(e) => {setInputOTP(e); setMessage("")}}
                numInputs={6}
                inputType="number"
                // renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
              {message != "" ? <Box component={"span"} sx={{color:'red'}}>{message}</Box> : ""}
            </Box>
            <Form.Group className="mb-3">
              {/* <div className="d-flex justify-content-between align-items-center gap-4 mb-3">
                {[...Array(6)].map((_, index) => (
                  <Form.Control
                    key={index}
                    type="text"
                    maxLength={1}
                    onChange={(e) => handleOtpInputChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    ref={(ref) => (otpInputs.current[index] = ref)}
                  />
                ))}
              </div> */}
              <div className="d-flex justify-content-center align-items-center my-4">
                <Form.Text className="text-muted me-3">
                  <div className="d-flex align-items-center justify-content-center gap-1">
                    <RiTimerLine color="#278FD9" />
                    <span className="otp-timer">
                      {timer >= 10 ? `00:${timer}` : `00:0${timer}`}
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    <span>Didn't received code?</span>
                    <Box 
                    component={"span"}
                    sx={{"&:hover":{color:'#1565c0'},cursor:'pointer',marginLeft:'5px'}}
                      // onClick={() => {
                      //   resetOtpInputs();
                      //   startTimer();
                      // }}
                      onClick={() => timer == 0 ? resendOTP() : {}}
                    >
                      Resend
                    </Box>
                  </div>
                </Form.Text>
              </div>
              <Button
                type="submit"
                variant="contained"
                className="w-100 rounded-pill bg-color-primary"
              >
                Claim
              </Button>
            </Form.Group>
          </Form>
        </CustomModal>
      </Container>
      <Footer />
    </>
  );
};

export default VisitEligibleClaim;
