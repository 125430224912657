import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  Divider,
  IconButton,
} from '@mui/material';
import CustomizedMenu from '../../../../components/CustomizedMenu/CustomizedMenu';
import { Clear } from '@mui/icons-material';
import { FormInputField } from '../../../../components/common/FormInputField';
import { CustomBtn } from '../../../../broker/components/mui/CustomBtn';
import { postAPI } from '../../../Api/ApiRequest';
import { apiEndpoints } from '../../../Api/ApiEndpoint';

import { enqueueSnackbar } from 'notistack';

const menuItems = [
  { value: 'repair', label: 'Need for structural repairs' },
  { value: 'upgrade', label: 'Upgrade required for compliance' },
  { value: 'maintenance', label: 'Equipment maintenance overdue' },
  { value: 'safety', label: 'Safety hazard identified' },
  { value: 'modification', label: 'Client request for modification' },
  { value: 'shortage', label: 'Material shortage detected' },
  { value: 'environment', label: 'Environmental concerns addressed' },
];

const ReportAbuseModal = ({ open, handleClose, builderId, visitId }) => {
  const [menuValue, setMenuValue] = useState('');
  const [comments, setComments] = useState('');

  const handleMenuChange = (event) => {
    setMenuValue(event.target.value);
  };

  const handleCommentChange = (event) => {
    setComments(event.target.value);
  };

  const submitHandler = async () => {
    const formData = {
      reason: menuValue,
      comments: comments,
      visitId: visitId,
      builderId: builderId,
    };
    console.log(formData);

    try {
      const response = await postAPI(apiEndpoints.addRaiseDispute, formData);
      enqueueSnackbar('Data submitted successfully', { variant: 'success' });
      handleClose();
    } catch (error) {
      console.error('Error submitting data:', error);
      enqueueSnackbar('Error submitting data', { variant: 'error' });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='report-abuse-modal-title'
      aria-describedby='report-abuse-modal-description'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { md: '40%', xs: '70%' },
          height: 'auto',
          bgcolor: 'background.paper',
          boxShadow: 24,
          outline: 'none',
          borderRadius: '2.5rem',
          p: 4,
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: '1rem',
            right: '1rem',
            color: 'black',
            border: '1px solid black',
            height: '2rem',
            width: '2rem',
            fontWeight: '10px',
            '&:hover': {
              color: 'var(--primary-color)',
            },
          }}
        >
          <Clear />
        </IconButton>
        <Typography
          textAlign={'center'}
          sx={{
            fontWeight: '400',
            fontFamily: 'Bahnschrift',
            fontSize: { xl: '2.5rem', xs: '1rem' },
          }}
        >
          Report Abuse
        </Typography>
        <Divider sx={{ bgcolor: 'black' }} />
        <Typography variant='h5' mt={2} mb={2}>
          Reason*
        </Typography>

        <CustomizedMenu
          menuItems={menuItems}
          value={menuValue}
          onChange={handleMenuChange}
          title={'Choose'}
        />
        <Typography variant='h5' mt={2} mb={2}>
          Any Comment
        </Typography>
        <FormInputField
          width={1}
          label='Comments'
          required={true}
          multiline
          line={15}
          textarea={true}
          height={'auto'}
          onChange={handleCommentChange}
        />
        <Box
          mt={2}
          sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <CustomBtn variant={'contained'} width='60%' onClick={submitHandler}>
            Submit
          </CustomBtn>
        </Box>
      </Box>
    </Modal>
  );
};

export default ReportAbuseModal;
