import React, { useEffect, useState } from 'react';
import { getAPI } from '../../Api/ApiRequest';
import { apiEndpoints } from '../../Api/ApiEndpoint';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { BsPencilFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import { RiVipCrownFill } from 'react-icons/ri';
import build from '../../Images/build.png';
const FilterProperty = () => {
  const selectedData = JSON.parse(localStorage.getItem('selectedData'));
  console.log('hello selected is ', selectedData);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState('');

  //   useEffect(() => {
  //     const getBanner = async () => {
  //       try {
  //         const response = await getAPI(apiEndpoints?.getAllproperty);
  //         console.log('Banner response ', response);
  //         setData(response?.data);
  //         console.log('data get api', response.data);

  //         const filteredData = response?.data.filter((property) => {
  //           const propertyTypeMatches =
  //             property?.propertyType?.name === selectedData.SelectedProperty;

  //           const locationMatches = property?.location.includes(
  //             selectedData.SelectedLocation
  //           );

  //           const unitTypeMatches = property?.unitTypeNew.includes(
  //             selectedData.SelectedUnitType
  //           );

  //           const constructionStatusMatches =
  //             (selectedData.activeTab === 1 &&
  //               property?.constructionStatus === 'Under Construction') ||
  //             (selectedData.activeTab === 2 &&
  //               property?.constructionStatus === 'Ready to Move') ||
  //             (selectedData.activeTab === 3 &&
  //               property?.constructionStatus === 'Possession Soon');

  //           // Add conditions for other selected values
  //           // For now, let's assume other fields are exactly matched
  //           // Modify this as per your requirements

  //           return (
  //             propertyTypeMatches &&
  //             locationMatches &&
  //             unitTypeMatches &&
  //             constructionStatusMatches
  //           );
  //         });
  //         console.log('filtered data', filteredData);
  //       } catch (error) {
  //         console.error('Error fetching data:', error);
  //       }
  //     };

  //     getBanner();
  //   }, []);

  useEffect(() => {
    const getProperties = async () => {
      try {
        const response = await getAPI(apiEndpoints?.getAllproperty);
        console.log('properties', response?.data);

        const filteredData = response?.data.filter((property) => {
          const propertyTypeMatches =
            !selectedData.SelectedProperty ||
            property?.propertyType?.name === selectedData.SelectedProperty;

          const locationMatches =
            !selectedData.SelectedLocation ||
            property?.location.includes(selectedData.SelectedLocation);

          const unitTypeMatches =
            !selectedData.SelectedUnitType ||
            property?.unitTypeNew.includes(selectedData.SelectedUnitType);

          const constructionStatusMatches =
            !selectedData.activeTab ||
            (selectedData.activeTab === 1 &&
              property?.constructionStatus === 'Under Construction') ||
            (selectedData.activeTab === 2 &&
              property?.constructionStatus === 'Ready to Move') ||
            (selectedData.activeTab === 3 &&
              property?.constructionStatus === 'Possession Soon');

          const hasBuilderDiscount =
            !selectedData.selectedOptions.includes('discountOffer') ||
            property?.builderDiscount;

          const isPromoted =
            !selectedData.selectedOptions.includes('promotedProperties') ||
            property?.isPromoted;

          return (
            propertyTypeMatches &&
            locationMatches &&
            unitTypeMatches &&
            constructionStatusMatches &&
            hasBuilderDiscount &&
            isPromoted
          );
        });

        console.log('filtered data', filteredData);
        setData(filteredData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    getProperties();
  }, []);
  const formatPrice = (price) => {
    if (price >= 10000000) {
      return `₹ ${(price / 10000000).toFixed(2)} Cr`;
    } else if (price >= 100000) {
      return `₹ ${(price / 100000).toFixed(2)} L`;
    } else {
      return `₹ ${price}`;
    }
  };

  return (
    <div>
      {' '}
      {loading ? (
        <Box textAlign={'center'} my={5}>
          {' '}
          <CircularProgress />
        </Box>
      ) : (
        <>
          {data?.length > 0 ? (
            <Box my={5}>
              <Grid container spacing={7}>
                {data?.map((itm, index) => (
                  <Grid
                    item
                    md={4}
                    sm={6}
                    xs={12}
                    key={index}
                    sx={{ textAlign: 'center' }}
                  >
                    <Box
                      className='card  mb-4 shadow-sm rounded-4 col-md-3 p-0 border-0 '
                      id={itm?._id}
                      key={itm?._id}
                      sx={{
                        width: { md: '21.75rem', sm: '100%', xs: '100%' },
                        margin: 'auto',
                      }}
                    >
                      <Link
                        to={`/builder/home-dashboard/description/${itm?._id}`}
                      >
                        <Box
                          sx={{
                            position: 'relative',
                            height: '11rem',
                          }}
                        >
                          <Card.Img
                            className='w-100 bl-prop-img '
                            variant='top'
                            src={itm?.images[0]}
                            style={{
                              height: '11rem',
                              borderRadius: '1rem',
                            }}
                          />
                          <Box
                            sx={{
                              bgcolor: '#EFEFEF',
                              position: 'absolute',
                              padding: '3px 5px',
                              top: 12,
                              right: 0,
                            }}
                          >
                            Listing Date {itm?.createdAt?.substring(0, 10)}
                          </Box>
                          <Box
                            px={{ md: 1, xs: 4 }}
                            py={{ md: 1, xs: 1 }}
                            sx={{
                              backgroundColor: '#FE5E6A',
                              color: '#fff',

                              bottom: 50,
                              borderRadius: '.3rem',
                              width: { md: '55%', xs: '100%' },
                            }}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={6}>
                                --
                              </Grid>
                              <Grid item xs={6}>
                                {itm?.visitCount}/{itm?.noOfVisits} visits
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Link>
                      <Card.Body>
                        <Card.Text>
                          <Grid container>
                            <Grid item md={10} sm={10} textAlign={'start'}>
                              <div>
                                {' '}
                                <Link
                                  className='recent-heading'
                                  to={`/builder/home-dashboard/description/${itm?.propertyId?._id}`}
                                >
                                  {itm?.name}
                                </Link>
                              </div>
                              <p style={{ opacity: 0.5 }}>{itm?.location}</p>
                            </Grid>
                            <Grid item md={2} sm={2}>
                              <RiVipCrownFill
                                style={{
                                  padding: '5px',
                                  borderRadius: '50%',
                                  background: '#ffd600',
                                  color: '#fff',
                                  width: '1.85em',
                                  height: '1.85em',
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Row className='mt-2 mb-2'>
                            {itm?.unitTypeNew?.slice(0, 4).map((item) => (
                              <Col md={3} sm={3} className='text-center'>
                                <img
                                  src={build}
                                  alt='build'
                                  className='build-icon'
                                />
                                <p style={{ opacity: 0.5 }}>{item}</p>
                              </Col>
                            ))}
                          </Row>

                          <Grid
                            container
                            spacing={2}
                            className='align-items-center mb-3'
                          >
                            <Grid
                              item
                              md={6}
                              style={{
                                color: '#000000',
                                fontSize: '1.35em',
                                fontWeight: 'bold',
                              }}
                            >
                              {formatPrice(itm?.minPrice)}-
                              {formatPrice(itm?.maxPrice)}
                            </Grid>
                            <Grid
                              item
                              md={6}
                              style={{
                                color: '#7D7F88',
                                fontSize: '0.8em',
                                textAlign: 'start',
                              }}
                            >
                              {itm?.builderDiscount}
                            </Grid>
                          </Grid>
                          <div className='d-flex justify-content-end gap-2 mt-2'>
                            <Button
                              variant='transparent'
                              type='button'
                              className='rounded-pill px-4 py-2 border border-primary'
                              style={{ color: '#278FD9', fontSize: '14px' }}
                              as={Link}
                              // to={`/builder/home-dashboard/visit/${itm?.builderId?._id}`}
                              to={`/builder/home-dashboard/visit/${itm?._id}`}
                            >
                              {itm?.noOfVisits} Visits
                            </Button>
                            <Button
                              variant='transparent'
                              type='button'
                              className='primary rounded-pill px-4 py-2 border border-primary'
                              as={Link}
                              to={`/builder/home-dashboard/propertyedit/${itm?._id}`}
                            >
                              <BsPencilFill
                                style={{
                                  width: '0.9em',
                                  color: '#fff',
                                  background: '#278fd9',
                                  padding: '2px',
                                  borderRadius: '50%',
                                  marginRight: '4px',
                                  marginBottom: '5px',
                                }}
                              />
                              Edit
                            </Button>
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : (
            <Typography
              my={6}
              textAlign={'center'}
              color={'var(--primary-color-darker)'}
              fontWeight={'bold'}
            >
              No recently added properties available
            </Typography>
          )}
        </>
      )}
    </div>
  );
};

export default FilterProperty;
