import CreatableSelect from 'react-select/creatable';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { CustomModal } from '../../../../../components';
import RangeSlider from '../../../../../components/range-slider/RangeSlider';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { updateCustomer } from '../../../../../API/action';
import { enqueueSnackbar } from 'notistack';
import { CircularProgress } from '@mui/material';

const options = [
  { value: '2 BHK', label: '2 BHK' },
  { value: '3 BHK', label: '3 BHK' },
];

const CustomerModal = ({ show, onHide, data }) => {
  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [alternatePhoneNumber, setAlternatePhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const phoneNumberRegex = /^[0-9]{10}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    setClientName(data?.clientName);
    setEmail(data?.email);
    setPhoneNumber(data?.phoneNumber);
    setAlternatePhoneNumber(data?.alternatePhoneNumber);
  }, [data]);

  // const unitTypeOptions = unitType.map((type) => ({
  //   value: type,
  //   label: type,
  // }));
  // const preferredLocationOptions = preferredLocation.map((location) => ({
  //   value: location,
  //   label: location,
  // }));

  const handleSaveChanges = () => {
    if (!clientName || !email || !phoneNumber) {
      enqueueSnackbar("Please add all the required values", { variant: 'warning' });
    } else if (!phoneNumberRegex.test(phoneNumber)) {
      enqueueSnackbar("Please enter a valid number", { variant: 'warning' });
    }
    else if (!emailRegex.test(email)) {
      enqueueSnackbar("Please enter a valid email", { variant: 'warning' });
    } else if (alternatePhoneNumber && !phoneNumberRegex.test(alternatePhoneNumber)) {
      enqueueSnackbar("Please enter a valid alternate number", { variant: 'warning' });
    } else {
      setLoading(true);
      console.log(data);
      let payload = {
        clientName,
        email,
        phoneNumber,
        brokerId: localStorage.getItem("brokerID"),
        id: data.customerId
      };

      if (alternatePhoneNumber) {
        payload = {
          ...payload,
          alternatePhoneNumber,
        }
      }
      dispatch(updateCustomer(payload, (data) => {
        window.location.reload();
        enqueueSnackbar('Customer has been updated successfully', { variant: 'success' });
        onHide();
        setLoading(false);
      }, (err) => {
        const message = err?.response?.data ? err?.response?.data : 'Error occured While updating customer';
        enqueueSnackbar(message, { variant: 'error' });
        setLoading(false);
      }));
    }

  };

  return (
    <CustomModal
      show={show}
      title='Edit Customer Detail'
      modalHeader
      border
      closeBtn
      onHide={onHide}
      cssClassName='nes__dashboard__modal'
    // size="lg"
    >
      <Form className='profile__form ps-2 py-3 custom__modal__form'>
        <Form.Group className='mb-4' controlId='companyName'>
          <Form.Label>
            Client Name<span className='text-dark'>*</span>
          </Form.Label>
          <Form.Control
            className='rounded-0 border-0'
            type='text'
            placeholder='Enter client name'
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
            disabled
          />
        </Form.Group>

        <Form.Group className='mb-4' controlId='email'>
          <Form.Label>
            Email<span className='text-dark'>*</span>
          </Form.Label>
          <Form.Control
            className='rounded-0 border-0'
            type='email'
            placeholder='Enter email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled
          />
        </Form.Group>

        <Form.Group className='mb-4' controlId='phoneNumber'>
          <Form.Label>
            Phone Number<span className='text-dark'>*</span>
          </Form.Label>
          <Form.Control
            className='rounded-0 border-0'
            type='number'
            placeholder='Enter phone number'
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value.substring(0, 10))}
            disabled
          />
        </Form.Group>

        <Form.Group className='mb-4' controlId='phoneNumber'>
          <Form.Label>Alternate Phone Number</Form.Label>
          <Form.Control
            className='rounded-0 border-0'
            type='number'
            placeholder='Enter phone number'
            value={alternatePhoneNumber}
            onChange={(e) => setAlternatePhoneNumber(e.target.value.substring(0, 10))}
          />
        </Form.Group>

        <div className='d-flex gap-4 mt-5'>
          <Button
            className='btn-color-primary rounded-pill btn-rounded w-100'
            onClick={handleSaveChanges}
          >
            {loading
              ?
              <CircularProgress />
              :
              "Save Changes"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

export default CustomerModal;
