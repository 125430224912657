import { Button, Box, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
} from 'recharts';

const data = [
  { year: '2018', value1: 4000, value2: 2400 },
  { year: '2019', value1: 3000, value2: 1398 },
  { year: '2020', value1: 2000, value2: 9800 },
  { year: '2021', value1: 2780, value2: 3908 },
  { year: '2022', value1: 1890, value2: 4800 },
];

const Charts = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Container className='pt-5'>
      <h3 className='heading'>Property Analysis</h3>
      <Box
        className='dashboard__wrapper__filter border border-light rounded shadow-sm'
        style={{
          width: '100%',
          height: 'auto',
          minHeight: '200px',
          boxShadow: '0px 10.7036px 46.8282px rgba(0, 0, 0, 0.07)',
          margin: '1rem auto 3rem auto',
        }}
      >
        <Box
          px={2}
          mt={4}
          sx={{ display: 'flex', justifyContent: 'space-between', flex: 'row' }}
        >
          <Typography variant='p' component={'h5'}>
            The graph shows the quarterly average rates of properties
          </Typography>
          {/* <Button
            sx={{
              bgcolor: '#F8F7F7',
              color: 'black',
              boxShadow: 'none',
              borderRadius: '2rem',
            }}
            variant='contained'
          ></Button> */}
        </Box>
        <Typography
          px={2}
          variant='p'
          component={'p'}
          sx={{ opacity: 0.5, fontWeight: 700 }}
        >
          AVG. PROPERTY RATE
        </Typography>
        <Container
          style={{
            width: '100%',
            height: '400px',
            minHeight: '200px',
          }}
        >
          <ResponsiveContainer width='100%' height='100%'>
            <LineChart
              data={data}
              margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
            >
              <defs>
                <linearGradient id='value1Gradient' x1='0' y1='0' x2='1' y2='1'>
                  <stop offset='95%' stopColor='#0B24FB66' />
                  <stop offset='95%' stopColor='#0B24FB' stopOpacity={0} />
                </linearGradient>
                <linearGradient id='value2Gradient' x1='1' y1='0' x2='1' y2='1'>
                  <stop offset='5%' stopColor='rgba(254, 94, 106, 1)' />
                  <stop offset='95%' stopColor='rgba(254, 94, 106, 01)' />
                </linearGradient>
              </defs>
              <XAxis dataKey='year' />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type='monotone'
                dataKey='value1'
                stroke='url(#value1Gradient)'
              />
              <Line
                type='monotone'
                dataKey='value2'
                stroke='url(#value2Gradient)'
              />
            </LineChart>
          </ResponsiveContainer>
        </Container>
      </Box>
    </Container>
  );
};

export default Charts;
