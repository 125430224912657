import { Tab, Tabs } from 'react-bootstrap';

import SupportMenu from './SupportMenu';
import Faq from './Faq';

const Support = () => {
  return (
    <>
      <Tabs
        defaultActiveKey='Support'
        id='fill-tab-example'
        className='visit-details-tab mb-3 mx-auto w-50 col-12 col-sm-12'
        style={{ fontWeight: '200' }}
        fill
      >
        <Tab eventKey='Support' title='Support'>
          <SupportMenu />
        </Tab>

        <Tab eventKey='FAQ' title='FAQ '>
          <Faq />
        </Tab>
      </Tabs>
    </>
  );
};

export default Support;
