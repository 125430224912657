import { apiEndpoints } from './ApiEndpoint';
import { getAPI } from './ApiRequest';

import axios from 'axios';
export const getAllInvoicesForBuilder = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      apiEndpoints?.GET_ALL_INVOICES_FOR_BUILDER,
      payload,
      (response) => {
        console.log('response of get all invoice : ', response);
        // if (payload.status === 'pending') {
        //     dispatch({
        //         type: actionNames?.BUILDER_HOME_REDUCER,
        //         payload: {
        //             allBuilderPendingInvoices: response?.data?.data
        //         }
        //     })
        //     callBack(response?.data);
        // }
        // else {
        //     dispatch({
        //         type: actionNames?.BUILDER_HOME_REDUCER,
        //         payload: {
        //             allBuilderSettledInvoices: response?.data?.data
        //         }
        //     })
        //     callBack(response?.data);
        // }
        callBack(response?.data);
      },
      (error) => {
        console.log('Error Occured While getting All Invoices', error);
      }
    );
  };
};
export const getBuilderByID = (id, callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      apiEndpoints?.getBuilderById,
      { id },
      (response) => {
        callBack(response?.data);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const getInvoicePDFByID = (id, callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      `${apiEndpoints?.GET_INVOICE_PDF}${id}`,
      { id },
      (response) => {
        callBack(response?.data);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const getFAQ = (callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      apiEndpoints?.GET_FAQ,
      (response) => {
        callBack(response?.data);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};
