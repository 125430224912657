import { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import RangeSlider from "../../../builder/dashboard/Builderprofile/range-slider/RangeSlider";
import CreatableSelect from "react-select/creatable";
import CustomizedMenu from "../../CustomizedMenu/CustomizedMenu";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import "./Filter.css";
import {
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
  Modal,
  Box,
  FormControl,
  IconButton,
} from "@mui/material";
import { CustomBtn } from "../../../broker/components/mui/CustomBtn";
import { getAllAmenties, getAllpropertyCategory, getLocationByInput } from "../../../API/action";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";

const options = [
  { value: "Gurugram, Haryana", label: "Gurugram, Haryana" },
  { value: "Noida, Uttar Pradesh", label: "Noida, Uttar Pradesh" },
];

const sort = [
  { value: "featured", label: "Featured " },
  { value: "recent", label: "Recent " },
  { value: "bestSeller", label: "Bestseller" },
  { value: "promotional", label: "Promotional" },
  { value: "sold", label: "sold" },
  { value: "Low-High", label: "Price: low to high" },
  { value: "High-Low", label: "Price: high to low" },
  { value: "a-z", label: "Sort by A-Z" },
  { value: "z-a", label: "Sort by Z-A" },
];

const property = [
  { value: "Commercial", label: "Commercial" },
  { value: "Farmhouse/Villa", label: "Farmhouse/Villa" },
  { value: "Flats/Apartments", label: "Flats/Apartments" },
  { value: "Land/Plots", label: "Land/Plots" },
];

const unitType = [
  "1RK", "2BHK", "3BHK", "4BHK", "5BHK", "5+BHK", "Low Rise", "High Rise",
  "Farmhouse Land", "SCO Plot", "Commercial Land", "Residential Plot", "Villa",
  "Farmhouse", "Duplex House", "Builder Floor", "Commercial Space", "SCO", "Warehouse"
];

// const AmenitiesArray = [
//   "Manicured Garden", "Dart Board", "Volleyball Court", "Golf Course", "Foosball", 
//   "Air Hockey", "Open Space", "High Speed Elevator", "ATM", "Chess", "Carrom", 
//   "Community Hall", "Billiards", "Wi-Fi Connectivity", "Barbecue", "Card Room", 
//   "Reflexology Park", "Bar/Chill out Lounge", "CCTV Camera Security", "Table Tennis", 
//   "Earthquake Resistance", "Video Door Security", "Property Staff", "Reading Lounge", 
//   "Yoga/Meditation Area", "Multipurpose Hall", "Entrance Lobby", "Cricket Pitch", 
//   "Steam Room", "Sauna", "Aerobic Centre", "Conference Room", "Business Lounge", 
//   "Football", "Security Cabin", "Spa", "Restaurant", "Salon", "Fire NOC", 
//   "Garbage Disposal", "Sewage Treatment Plant", "Multipurpose Court", "Lounge", 
//   "Fire Fighting System", "Intercom", "Paved Compound", "Internal Street Light", 
//   "Senior Citizen Sitout", "Car Parking", "Lifts", "Children's Play Area", 
//   "Landscape Garden", "24X7 Water Supply", "Gymnasium", "Gated Community", 
//   "24X7 Power Backup", "Club House", "24X7 Security", "Rain Water Harvesting", 
//   "Jogging Track", "Indoor Games", "Badminton Court", "Basketball Court", "Party Lawn", 
//   "Toddler Pool", "Amphitheatre", "Lawn Tennis Court", "Sun Deck", "Library", 
//   "Shopping Centre", "Fountain", "Banquet Hall", "Cafeteria", "Pool Table", "Gazebo", 
//   "Squash Court", "Grocery Shop", "Skating Rink", "Creche/Day Care", "Pergola", 
//   "Terrace Garden", "School", "Flower Garden", "Swimming Pool", "Mini Theatre"
// ];

const Filter = ({ open, setOpen, handleClose }) => {
  const [menuValue, setMenuValue] = useState("");
  const [locationSearch, setLocationSearch] = useState([]);
  const [locationSelected, setLocationSelected] = useState([]);
  const [selectedUnitType, setSelectedUnitType] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState([]);
  const [status, setStatus] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isPermoted, setIsPermoted] = useState(null);
  const [maxPrice, setMaxPrice] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [amenities, setAmenities] = useState([]);
  const [allAmenties, setAllAmenties] = useState([]);
  const [allPropertyTypes, setAllPropertyTypes] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllAmenties((data) => {
      setAllAmenties(data.data.data);
    }, (err) => {
      console.log(err);
    }))

    dispatch(getAllpropertyCategory({},(data) => {
      setAllPropertyTypes(data.data.data);
    }, (err) => {
      console.log(err);
    }))
  }, []);

  const handleOptionChange = (option) => {
    setSelectedOptions(prevOptions => 
      prevOptions.includes(option) 
        ? prevOptions.filter(item => item !== option) 
        : [...prevOptions, option]
    );
  };

  const handleTabClick = (tabIndex) => setStatus(tabIndex);
  
  const handleMenuChange = (event) => setMenuValue(event.target.value);

  const handleAutoCompleteChange = (setter) => (event, value) => setter(value.map(v => v.value));

  const handleLocationSearchChange = (name, value) => {
    if (name === "location") {
      getLocationByInput({ searchText: value })
        .then(res => setLocationSearch(res.data.data.map(item => item.location)))
        .catch(console.log);
    }
  };



  // const collectSelectedData = () => ({
  //   sort : menuValue,
  //   propertyType : selectedProperty,
  //   selectedLocation: locationSelected,
  //   unitType :selectedUnitType,
  //   amenities : amenities,
  //   priceRange : `${minPrice}-${maxPrice}`,
  //   constructionStatus: status
   
  // });

  const collectSelectedData = () => {
    const data = {};
  
    if (menuValue) data.sort = menuValue;
    if (selectedProperty.length > 0) data.propertyType = selectedProperty;
    if (locationSelected.length > 0) data.location = locationSelected[0];
    if (selectedUnitType.length > 0) data.unitType = selectedUnitType;
    if (selectedOptions.length > 0) data.isPromoted = true;
    if (amenities.length > 0) data.amenities = amenities.map((item) => item.value);
    if (minPrice && maxPrice) data.priceRange = `${minPrice}-${maxPrice}`;
    if (status) data.constructionStatus = status;
  
    return data;
  };

  const handleCloseAndNavigate = () => {
    const selectedData = collectSelectedData();
    localStorage.setItem("selectedData", JSON.stringify(selectedData));
    handleClose(selectedData);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { md: "60%", xs: "90%" },
    height: "auto",
    bgcolor: "background.paper",
    borderRadius: "2rem",
    boxShadow: 24,
    p: 4,
    overflow: "auto",
    maxHeight: { xs: "80vh", md: "auto" },
  };

  return (
    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" BackdropProps={{ onClick: () => setOpen(false) }}>
      <Box sx={style}>
        <FormControl fullWidth>
       
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Typography mt={2} mb={1} className="main-heading">Sort By</Typography>
              <CustomizedMenu menuItems={sort} value={menuValue} onChange={handleMenuChange} title={"select"} height="5rem" />
              <Typography mt={2} mb={1} className="main-heading">Property Type</Typography>
              <Autocomplete
                multiple
                limitTags={2}
                options={allPropertyTypes.map((item) => {return {label: item?.name, value: item?._id}})}
                getOptionLabel={(option) => option.label}
                onChange={handleAutoCompleteChange(setSelectedProperty)}
                renderInput={(params) => <TextField {...params} placeholder="Add More" sx={{ bgcolor: "#F8F8F8" }} />}
                sx={{ backgroundColor: "#F2F2F2", borderRadius: ".3rem", "& .MuiOutlinedInput-root": { border: "none !important" } }}
              />
              <Grid container spacing={2}>
                <Grid item md={6} xs={6}>
                  <Typography mt={2} mb={1} className="main-heading"  >Min Price</Typography>
                  <TextField sx={{ bgcolor: "#F8F8F8" }} value={minPrice} onChange={(e)=>setMinPrice(e.target.value)}/>
                </Grid>
                <Grid item md={6} xs={6}>
                  <Typography mt={2} mb={1} className="main-heading" >Max Price</Typography>
                  <TextField sx={{ bgcolor: "#F8F8F8" }} value={maxPrice} onChange={(e)=>setMaxPrice(e.target.value)}/>
                </Grid>
              </Grid>
              <Typography mt={2} className="main-heading">Show Only</Typography>
              <Stack>
                <FormControlLabel
                  control={<Checkbox checked={selectedOptions.includes("promotedProperties")} onChange={() => handleOptionChange("promotedProperties")} />}
                  label={<Typography mt={2} mb={1} sx={{ fontSize: "0.8rem !important" }}>Promoted Properties</Typography>}
                  className="mb-2"
                />
              </Stack>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography mt={2} mb={1} className="main-heading">Location</Typography>
              <Autocomplete
                multiple
                disablePortal
                value={locationSelected}
                options={locationSearch}
                onChange={(event, value) => setLocationSelected(value)}
                renderInput={(params) => <TextField {...params} onChange={(e) => handleLocationSearchChange("location", e.target.value)} placeholder="Enter location" sx={{ bgcolor: "#F8F8F8" }} />}
                sx={{ backgroundColor: "#F2F2F2", borderRadius: ".3rem", "& .MuiOutlinedInput-root": { border: "none !important" } }}
              />
              <Typography mt={2} mb={1} className="main-heading">Unit Type</Typography>
              <Autocomplete
                multiple
                limitTags={2}
                options={unitType}
                onChange={(event, value) => setSelectedUnitType(value)}
                renderInput={(params) => <TextField {...params} placeholder="Add More" sx={{ bgcolor: "#F8F8F8" }} />}
                sx={{ backgroundColor: "#F2F2F2", borderRadius: ".3rem", "& .MuiOutlinedInput-root": { border: "none !important" } }}
              />
              <Typography mt={2} mb={1} className="main-heading">Amenities</Typography>
              <Autocomplete
                multiple
                limitTags={2}
                options={allAmenties.map((item) => {return {label: item?.name, value: item?._id}})}
                onChange={(event, value) => setAmenities(value)}
                renderInput={(params) => <TextField {...params} placeholder="Add More" sx={{ bgcolor: "#F8F8F8" }} />}
                sx={{ backgroundColor: "#F2F2F2", borderRadius: ".3rem", "& .MuiOutlinedInput-root": { border: "none !important" } }}
              />
            

              <Typography mt={2} mb={1} className="main-heading">Construction Status</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6} md={4}>
                  <Button className="construction-status"  size="small" fullWidth variant={status === "Under Construction" ? "contained" : "outlined"} onClick={() => handleTabClick("Under Construction")}>Under Construction</Button>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Button className="construction-status"  size="small" fullWidth variant={status === "Ready to move" ? "contained" : "outlined"} onClick={() => handleTabClick("Ready to move")}>Ready to Move</Button>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Button fullWidth className="construction-status"  size="small" variant={status === "New Launch" ? "contained" : "outlined"} onClick={() => handleTabClick("New Launch")}>New Launch</Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
              <CustomBtn variant="contained" fullWidth onClick={handleCloseAndNavigate}>Apply Filter</CustomBtn>
            </Grid>
          </Grid>
        </FormControl>
      </Box>
    </Modal>
  );
};

export default Filter;
