import { Button, Card } from "react-bootstrap";
import { MdContentCopy } from "react-icons/md";
import "./Refer.css";
import { enqueueSnackbar } from "notistack";

const Refer = ({ getBrokerByIdResponse }) => {
  const copyCode = (referalCode) => {
    navigator.clipboard
      .writeText(referalCode)
      .then(() => {
        enqueueSnackbar("Referal code has been copied", {
          variant: "success",
        });
      })
      .catch((error) => {
        console.error("Failed to copy referalCode: ", error);
      });
  };

  const handleRefer = async () => {
    try {
      await navigator.clipboard
        .writeText(`Hey Friends! Elevate your real estate game with NestoHub! Register now using my referral code BR-SFMn7hjr and unleash the full potential of your real estate business.

📲 App Store: https://apps.apple.com/app/in/nestohub-broker/6480410078
📲 Play Store: https://play.google.com/store/apps/details?id=com.nestohub_broker.nesto_hub_broker`);
      enqueueSnackbar("Link has been copied succesfully", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("Failed to copy!", { variant: "error" });
    }
  };
  return (
    <Card border="0" className="refer">
      <Card.Img
        variant="top"
        src="/assets/broker/Refer-a-friend-rafiki.svg"
        className="img-fluid w-75 mx-auto my-3"
      />
      <Card.Body>
        <div className="d-flex justify-content-center">
          <div
            className="refer-component gap-2"
            onClick={() => copyCode(getBrokerByIdResponse?.referalCode)}
          >
            <span className="code">{getBrokerByIdResponse?.referalCode}</span>
            <MdContentCopy size={25} />
          </div>
        </div>

        <ul className="card-text mt-5">
          <li>Step 1. Share this referral code with your fellow brokers.</li>
          <li>
            Step 2. Encourage them to register on the Nestohub platform using
            this code.
          </li>
          <li>
            Step 3. Earn a 1% of brokerage as referral bonus on each sale made
            by the referred broker for a period of one year.
          </li>
        </ul>
        <Button
          className="btn-color-primary rounded-100 btn-rounded mt-5 w-100"
          onClick={handleRefer}
        >
          Refer
        </Button>
      </Card.Body>
    </Card>
  );
};

export default Refer;
