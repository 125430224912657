import React from 'react';
import InformativeNavbar from '../../Navbar/Navbar';
import InformativeFooter from '../../InformativeFooter/InformativeFooter';
import BuilderPDbanner from './BuilderPDbanner/BuilderPDbanner';
import BuilderPDapartment from './BuilderPDapartment/BuilderPDapartment';
import BuilderPDamenities from './BuilderPDamenities/BuilderPDamenities';
import BuilderPDlocationAdvantage from './BuilderPDlocationAdvantage/BuilderPDlocationAdvantage';
import BuilderPDbrokerage from './BuilderPDbrokerage/BuilderPDbrokerage';

const BuilderPD = () => {
  return (
    <>
      <InformativeNavbar />
      <BuilderPDbanner />
      <BuilderPDapartment />
      <BuilderPDamenities />
      <BuilderPDlocationAdvantage />
      <BuilderPDbrokerage />

      <InformativeFooter />
    </>
  );
};

export default BuilderPD;
